import React, { useState } from 'react';
import { Button, Typography, Box, Link } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { wmPalette } from '../../../Theme/WorldSportsGroupTheme';
import MailDrawer from './MailDrawer';
import Grid from '@mui/material/Unstable_Grid2';
import { Emails, ExternalLinks } from '../../../Utils/urls';
const useStyles = makeStyles((theme) => ({
  content: {
    width: '100%',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  webhookItem: {
    color: wmPalette.black[50],
    paddingBottom: theme.spacing(1),
  },
  link: {
    color: wmPalette.blue[100],
    textDecoration: 'none',
  },
}));

const WebhookAbout = (props) => {
  const classes = useStyles();
  const [openDrawer, setDrawerOpen] = useState(false);

  const handleOpenDrawer = () => {
    setDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
  };

  return (
    <>
      <Grid
        container
        spacing={2}
        justifyContent="flex-end"
        className={classes.topToolbar}
      >
        <Grid>
          <Button color="primary" variant="outlined" onClick={handleOpenDrawer}>
            Send documentation
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        <Box className={classes.content}>
          <Typography variant="body1" gutterBottom>
            <b>What do you need to know?</b>
          </Typography>
          <Typography variant="body1">
            World’s Marathons webhook API automates data transfer from World’s
            Marathons to your registration system, so you can focus on creating
            unforgettable sporting events. After integrating the Webhook API,
            every successful order will be sent to your registration system. Due
            to World’s Marathons 24hrs cancellation policy, each order is sent
            24 hours after its completion.
          </Typography>
        </Box>
        <Box className={classes.content}>
          <Typography variant="body1" gutterBottom>
            <b>Why use webhook API?</b>
          </Typography>
          <Typography variant="body1">Time-saving</Typography>
          <Typography className={classes.webhookItem} variant="body1">
            Ditch the manual work and allow data to flow automatically to your
            registration system for every order.
          </Typography>
          <Typography variant="body1">Higher accuracy</Typography>
          <Typography className={classes.webhookItem} variant="body1">
            Say goodbye to the laborious manual work involved in transferring
            data from one place to another.
          </Typography>
          <Typography variant="body1">No more missed or lost orders</Typography>
          <Typography className={classes.webhookItem} variant="body1">
            Every order will be automatically transferred to your system.
          </Typography>
          <Typography variant="body1">Easy to manage</Typography>
          <Typography className={classes.webhookItem} variant="body1">
            All orders and participant details are stored in one place
            regardless of whether they are coming from your system or ours.
          </Typography>
        </Box>
        <Box className={classes.content}>
          <Typography variant="body1" gutterBottom>
            <b>What is needed to get started?</b>
          </Typography>
          <ul className={classes.list}>
            <li>
              <Typography component="span">
                Endpoints using https as the information transferred can be
                sensitive
              </Typography>
            </li>
            <li>
              <Typography component="span">
                A registration system that supports incoming webhooks in your
                registration system API
              </Typography>
            </li>
            <li>
              <Typography component="span">
                The method has to be HTTP POST (SSL mandatory)
              </Typography>
            </li>
          </ul>
        </Box>
        <Box className={classes.content}>
          <Typography variant="body1" gutterBottom>
            <b>How can I get started?</b>
          </Typography>
          <ul>
            <li>
              <Typography>
                <Link
                  className={classes.link}
                  target="_blank"
                  rel="noopener"
                  href={ExternalLinks.WebHookDocumentation}
                >
                  Documentation
                </Link>
              </Typography>
            </li>
          </ul>
        </Box>
        <Box className={classes.content}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              props.setValue(1);
            }}
          >
            Get it started
          </Button>
        </Box>
        <Box className={classes.content}>
          <Typography>
            Is something unclear? Email to{' '}
            <Link
              className={classes.link}
              href={`mailto:${Emails.CustomerSuccess}`}
            >
              {Emails.CustomerSuccess}
            </Link>{' '}
            to schedule a call with our tech support team.
          </Typography>
        </Box>
      </Grid>
      {openDrawer && (
        <MailDrawer open={openDrawer} handleCloseDrawer={handleCloseDrawer} />
      )}
    </>
  );
};

export default WebhookAbout;
