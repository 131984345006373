import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { Card, CardContent } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import StepTitle from '../Common/PageLayout/StepTitle';
import EmailPreferencesDrawer from './EmailPreferencesDrawer';
import EmailPreferencesTable from './EmailPreferencesTable';
import { Context } from '../../Stores/EventInfoStore';
import { useSnackbar } from 'notistack';
import { useApi, methods } from '../../Hooks/useApi';
import EmailPreferencesEmptyState from './EmailPreferencesEmptyState';

const putSignalEmailEndpoint = (eventId, editionId) =>
  `/ro/events/${eventId}/races/${editionId}/settings/signalemail`;

const putSignalGdprEndpoint = (eventId, editionId) =>
  `/ro/events/${eventId}/races/${editionId}/settings/signals/gdpr`;

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(1),
  },
  cardListEmpty: {
    minHeight: '400px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  topToolbar: {
    marginBottom: theme.spacing(2),
  },
}));

const EmailPreferences = () => {
  const classes = useStyles();
  const [eventInfo] = useContext(Context);
  const { executeApiCall } = useApi();
  const { eventId, editionId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { signalEmail, isGdprCompliant } = eventInfo.emailSettings;
  const [emailSettings, setEmailSettings] = useState({
    signalEmail: signalEmail,
    isGdprCompliant: isGdprCompliant,
  });
  const [emailPreferencesDrawer, setEmailPreferencesDrawer] = useState({
    open: false,
  });
  const addNewEmail = () => setEmailPreferencesDrawer({ open: true });

  useEffect(() => {
    setEmailSettings({
      signalEmail: signalEmail,
      isGdprCompliant: isGdprCompliant,
    });
  }, [signalEmail, isGdprCompliant]);

  const updateEmailSignal = async (email) => {
    try {
      await executeApiCall(
        putSignalEmailEndpoint(eventId, editionId),
        methods.put,
        {
          email: email,
        }
      );
      enqueueSnackbar('Email(s) updated successfully', { variant: 'success' });
      setEmailSettings({
        signalEmail: email,
        isGdprCompliant: emailSettings.isGdprCompliant,
      });
      if (email === '' && !isGdprCompliant) {
        await executeApiCall(
          putSignalGdprEndpoint(eventId, editionId),
          methods.put
        );
        setEmailSettings({
          signalEmail: email,
          isGdprCompliant: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateGdpr = async () => {
    try {
      await executeApiCall(
        putSignalGdprEndpoint(eventId, editionId),
        methods.put
      );
      enqueueSnackbar('GDPR updated successfully', { variant: 'success' });
      setEmailSettings({
        isGdprCompliant: !emailSettings.isGdprCompliant,
        signalEmail: emailSettings.signalEmail,
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <StepTitle
        title="Email preferences"
        subtitle={`Email addresses listed below will receive an email after 24hrs an athlete has successfully registered to your race.`}
      />
      <Card>
        <CardContent
          className={
            isEmpty(emailSettings.signalEmail) ? classes.cardListEmpty : ''
          }
        >
          {isEmpty(emailSettings.signalEmail) && (
            <EmailPreferencesEmptyState handleAddCoupon={addNewEmail} />
          )}
          {!isEmpty(emailSettings.signalEmail) && (
            <EmailPreferencesTable
              signalEmail={emailSettings.signalEmail}
              gdprStatus={emailSettings.isGdprCompliant}
              handleAddEmail={addNewEmail}
              updateEmailSignal={updateEmailSignal}
              updateGdpr={updateGdpr}
            />
          )}
        </CardContent>
      </Card>
      <EmailPreferencesDrawer
        open={emailPreferencesDrawer.open}
        handleClose={() => setEmailPreferencesDrawer({ open: false })}
        updateEmailSignal={updateEmailSignal}
        signalEmail={emailSettings.signalEmail}
      />
    </>
  );
};

export default EmailPreferences;
