import React, { useState } from 'react';
import {
  Button,
  Drawer,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Formik } from 'formik';
import * as yup from 'yup';
import { wmPalette } from '../../../Theme/WorldSportsGroupTheme';
import FormikTextField from '../../Common/Formik/FormikTextField';
import ConfirmationModal from '../../Common/ConfirmationModal/ConfirmationModal';
import WrappedDivider from '../../Common/WrappedDivider/WrappedDivider';
import Grid from '@mui/material/Unstable_Grid2';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.up('sm')]: {
      width: 567,
    },
  },
  drawerActionsContainer: {
    display: 'flex',
    position: 'fixed',
    marginLeft: `-${theme.spacing(2)}`,
    bottom: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    width: '100%',
    padding: theme.spacing(2),
    backgroundColor: '#fff',
    zIndex: 1000,
    [theme.breakpoints.up('sm')]: {
      width: 567,
      bottom: 0,
      padding: theme.spacing(1),
    },
  },
  revealBTN: {
    height: '100%',
  },
  link: {
    color: wmPalette.blue[100],
    textDecoration: 'none',
  },
}));

const validationSchema = yup.object().shape({
  endpointURL: yup
    .string()
    .url("Invalid URL. Ensure to include 'http://' or 'https://'")
    .required('Endpoint URL is required'),
  email: yup
    .string()
    .email('Invalid email')
    .required('An alert email is required'),
});

const EndpointDrawer = ({
  open,
  handleCloseDrawer,
  webhookEditState,
  handleWebhookSubmit,
}) => {
  const classes = useStyles();
  const [eventTypes, setEventTypes] = useState({
    successful: true,
    changed: false,
  });

  const [openModal, setOpenModal] = useState(false);
  const changeEventType = (event) => {
    setEventTypes({ ...eventTypes, [event.target.name]: event.target.checked });
  };

  const submitEndpoint = async (values) => {
    await handleWebhookSubmit(values);
    handleCloseDrawer();
  };

  const closeDrawer = () => {
    setOpenModal(false);
    handleCloseDrawer();
  };

  return (
    <Formik
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={true}
      initialValues={{
        endpointURL: webhookEditState.endpoint ? webhookEditState.endpoint : '',
        email: webhookEditState.alertEmail ? webhookEditState.alertEmail : '',
      }}
      onSubmit={submitEndpoint}
    >
      {({ handleSubmit, isSubmitting, handleBlur, handleChange, values }) => (
        <Drawer anchor="right" open={open} onClose={() => setOpenModal(true)}>
          <Grid
            container
            justifyContent="center"
            className={classes.root}
            spacing={0}
          >
            <Grid xs={12} sm={11} container spacing={2}>
              <Grid xs={12}>
                <Typography variant="h3" gutterBottom>
                  {webhookEditState.isEdit ? 'Edit endpoint' : 'Add endpoint'}
                </Typography>
              </Grid>
              <Grid xs={12}>
                <FormikTextField
                  variant="outlined"
                  fullWidth
                  required
                  label="Endpoint URL"
                  name="endpointURL"
                />
              </Grid>
              <Grid xs={12}>
                <WrappedDivider className={classes.divider} />
              </Grid>
              <Grid xs={12}>
                <Typography>Status: Enabled</Typography>
                <Typography>Version: 2019-06-09</Typography>
                <Typography>Mode: Live</Typography>
              </Grid>
              <Grid xs={12}>
                <WrappedDivider className={classes.divider} />
              </Grid>
              <Grid xs={12}>
                <Typography>Event Types</Typography>
                <FormGroup
                  name="eventTypes"
                  value={values.eventTypes}
                  onBlur={handleBlur}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    label="order.successful"
                    control={
                      <Checkbox
                        disabled
                        checked={eventTypes.successful}
                        name="successful"
                        onChange={changeEventType}
                      />
                    }
                  />
                  <FormControlLabel
                    label="order.changed"
                    control={
                      <Checkbox
                        disabled
                        checked={eventTypes.changed}
                        name="changed"
                        onChange={changeEventType}
                      />
                    }
                  />
                </FormGroup>
              </Grid>
              <Grid xs={12}>
                <WrappedDivider className={classes.divider} />
              </Grid>
              <Grid xs={12}>
                <Typography gutterBottom>Alert email</Typography>
                <Typography gutterBottom>
                  If your servers do not respond with HTTP Status Code 200, we
                  will retry sending the webhook 4 times with an interval of 10
                  minutes. If your server is not responding with a status code
                  200 after 4 times, we will alert you by sending an email to
                  the following address:
                </Typography>
              </Grid>
              <Grid xs={12}>
                <FormikTextField
                  variant="outlined"
                  fullWidth
                  label="Email address"
                  name="email"
                />
              </Grid>
              <Grid
                container
                className={classes.drawerActionsContainer}
                spacing={2}
              >
                <Grid xs={4}>
                  <Button
                    variant="outlined"
                    onClick={() => setOpenModal(true)}
                    disabled={isSubmitting}
                    fullWidth
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid xs={8}>
                  <Button
                    variant="contained"
                    fullWidth
                    color="primary"
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? 'Saving' : 'Save'}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {openModal && (
            <ConfirmationModal
              cancel={() => setOpenModal(false)}
              confirm={closeDrawer}
              open={openModal}
              title="Are you sure you want to cancel the action?"
              cancelText="No, continue"
              confirmText="Yes, cancel"
            />
          )}
        </Drawer>
      )}
    </Formik>
  );
};

export default EndpointDrawer;
