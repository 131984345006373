import * as yup from 'yup';
import regexvalidation from '../../../Utils/regexvalidation';
import {
  nameContainsEmail,
  nameContainsLink,
} from '../../../Consts/validationErrorMessages';

yup.addMethod(yup.array, 'laterStepDates', function (message) {
  return this.test('laterStepDates', message, function (values) {
    for (let i = 0; i < values.length; i++) {
      if (i === 0) continue;
      if (values[i].activationAt <= values[i - 1].activationAt) {
        return this.createError({
          path: `priceSteps[${i}].activationAt`,
          message:
            'Activation date must be at least one day later than the previous step activation date',
        });
      }
    }
    return true;
  });
});

const getValidationSchema = (tickets, validateMultiple) => {
  return yup.object().shape({
    id: yup.string(),
    name: yup
      .string()
      .required('Name is required')
      .test('no duplicates', 'Ticket name must be unique', function (value) {
        const currentId = this.parent.id;
        let found = false;
        if (tickets.length && value) {
          found = tickets
            .filter((ticket) => ticket.id !== currentId)
            .some(
              (ticket) => ticket.name.toLowerCase() === value.toLowerCase()
            );
        }
        return !found;
      })
      .test('test-name', nameContainsEmail, (value) =>
        regexvalidation(value, 'email')
      )
      .test('test-name', nameContainsLink, (value) =>
        regexvalidation(value, 'link')
      ),
    description: yup
      .string()
      .max(800, 'Maximum of 800 characters are allowed')
      .optional()
      .test(
        'test-name',
        "The description can't include email-addresses",
        (value) => regexvalidation(value, 'email')
      )
      .test('test-name', "The description can't include links", (value) =>
        regexvalidation(value, 'link')
      ),
    distance: yup.string().required('Distance is required'),
    customDistance: yup
      .number()
      .nullable()
      .when('distance', {
        is: 'custom',
        then: yup
          .number()
          .typeError('Distance must be a number')
          .positive('Number must be positive')
          .required('Distance is required when adding a custom distance'),
      }),
    customDistanceUnit: yup
      .string()
      .nullable()
      .when('distance', {
        is: 'custom',
        then: yup
          .string()
          .typeError('Unit is missing')
          .required('Please choose metric for this custom distance'),
      }),
    vat: yup
      .number('VAT must be a number')
      .typeError('VAT must be a number')
      .min(0, 'VAT cannot be lower than 0')
      .max(100, 'VAT cannot be greater than 100'),
    ticketType: yup.string().required('Please select Ticket type'),
    minParticipantCount: yup
      .number()
      .nullable()
      .when('ticketType', {
        is: 'team',
        then: yup
          .number()
          .positive()
          .required('Min participants is required')
          .nullable()
          .test(
            'min',
            'The minimum number of participants cannot be below 2.',
            (value) =>
              value === undefined ||
              value === null ||
              isNaN(value) ||
              value >= 2
          ),
      }),
    numParticipantsIncluded: yup
      .number()
      .nullable()
      .when(
        ['ticketType', 'minParticipantCount'],
        (ticketType, minParticipantCount, schema) => {
          return ticketType === 'team'
            ? schema
                .required('Max participants is required')
                .test(
                  'min,max',
                  'The maximum number of participants must be equal or greater than the minimum number of participants',
                  (value) =>
                    value === undefined ||
                    value === null ||
                    isNaN(value) ||
                    (value >= 2 && value >= minParticipantCount)
                )
            : schema;
        }
      ),
    additionalTeamMemberPrice: yup
      .number()
      .nullable()
      .when('additionalTickets', {
        is: true,
        then: yup
          .number()
          .typeError('Please enter a number')
          .positive('Additional cost must be greater than 0')
          .required('Additional cost is required'),
      }),
    priceSteps: yup.array().when('priceType', {
      is: 'multiple',
      then: yup
        .array()
        .of(
          yup.object().shape({
            price: yup
              .number()
              .min(0, 'Price cannot be less than 0')
              .required('Price is required'),
            activationAt: yup
              .date('A date is required')
              .required('Date is required'),
          })
        )
        .test(
          'count',
          "At least two steps are required. Otherwise, please choose 'Single' price type",
          function (val) {
            if (validateMultiple) {
              return val.length >= 2;
            }
            return true;
          }
        )
        .laterStepDates(),
      otherwise: yup.array().notRequired(),
    }),
    price: yup.number().when('priceType', {
      is: 'single',
      then: yup
        .number()
        .min(0, 'Price cannot be less than 0')
        .required('Price is required'),
      otherwise: yup.number(),
    }),
    localStartDate: yup.date().nullable().default(null),
    localStartTime: yup
      .string()
      .matches(/^([0-2][0-9]):[0-5][0-9]$/, 'Time format must be HH:mm')
      .nullable()
      .default(null),
    ticketLimit: yup
      .number()
      .nullable()
      .integer()
      .positive('Please set a positive integer'),
    ageMin: yup
      .number()
      .min(0, 'Min age cannot be less than 0')
      .integer()
      .nullable(),
    ageMax: yup
      .number()
      .min(0, 'Max age cannot be less than 0')
      .integer()
      .nullable(),
  });
};

export { getValidationSchema };
