import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useSnackbar } from 'notistack';
import { Box, Link, FormHelperText } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ImagePreview from './ImagePreview';
import { wmPalette } from '../../../Theme/WorldSportsGroupTheme';
import { useFormData } from '../../../Hooks/useFormData';
import { methods } from '../../../Hooks/useApi';
import { maximumNumberOfImages } from '../../../Consts/imageCount';

const getError = (code) => {
  switch (code) {
    case 'file-invalid-type':
      return 'The file must be an image';
    case 'file-too-large':
      return 'The maximum file size is 5MB';
    case 'too-many-files':
      return 'You have added too many files';
    case 'wrong-dimensions':
      return 'The dimensions of the image are not valid. The width must be greater than the height';
    case 'wrong-ratio':
      return 'Please choose an image which has more squared aspect ratio. The pixel height must be greater than 1/4 of the pixel width';
    default:
      return 'The file is invalid. Please check the file requirements and choose another';
  }
};

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  dropzone: {
    border: `2px dashed ${theme.palette.grey['300']}`,
    backgroundColor: wmPalette.green[5],
    textAlign: 'center',
    '&focused': {
      borderColor: theme.palette.success.main,
    },
    paddingBottom: theme.spacing(4),
    paddingTop: theme.spacing(4),
  },
  link: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  helperText: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const imageDimsCheck = (file) => {
  if (file.width < file.height) {
    return {
      code: 'wrong-dimensions',
    };
  }

  if (file.width / 3.5 > file.height) {
    return {
      code: 'wrong-ratio',
    };
  }

  return null;
};

const convertToImage = async (event) => {
  const files = event.target.files;
  const promises = [];
  for (let index = 0; index < files.length; index++) {
    const file = files[index];
    const promise = new Promise((resolve, _) => {
      const image = new Image();
      let url;
      image.onload = function () {
        file.width = image.width;
        file.height = image.height;
        resolve(file);
      };
      url = URL.createObjectURL(file);
      image.src = url;
    });
    promises.push(promise);
  }
  return await Promise.all(promises);
};

const ImageUpload = ({
  files,
  onChange,
  errorMessage,
  onReorder,
  uploadUrl,
  helperText,
  allowVerticalImages = false,
  maxFiles = maximumNumberOfImages,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { executeFormDataCall, formLoading } = useFormData();
  const [errorMessageState, setErrorMessageState] = useState();
  const [tempFiles, setTempFiles] = useState([]);

  const uploadEventPageImages = async (uploadedFiles) => {
    try {
      const res = await executeFormDataCall(
        uploadUrl,
        methods.post,
        uploadedFiles
      );
      if (res.imageErrors?.length) {
        res.imageErrors.forEach((x) =>
          enqueueSnackbar(x, { variant: 'error' })
        );
      }
      return res.images;
    } catch (err) {
      if (JSON.parse(err.message)[0]) {
        enqueueSnackbar(JSON.parse(err.message)[0], { variant: 'error' });
      } else {
        enqueueSnackbar(err.message, { variant: 'error' });
      }
      return [];
    }
  };

  const imageCountCheck = () => {
    if (files.length >= maxFiles) {
      return { code: 'too-many-files' };
    }
    return null;
  };

  const GetUniqueId = () => {
    const start = new Date();
    while (new Date() < new Date(start.getTime() + 1)) {}
    return new Date().getTime();
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/jpeg, image/png',
    maxFiles: maxFiles,
    maxSize: 5242880, //5MB
    validator: allowVerticalImages ? '' : imageDimsCheck,
    validator: imageCountCheck,
    getFilesFromEvent: convertToImage,
    onDrop: async (acceptedFiles) => {
      setErrorMessageState();
      let newFiles = [];
      if (uploadUrl) {
        setTempFiles(tempFiles.concat(acceptedFiles));
        newFiles = await uploadEventPageImages(acceptedFiles);
        if (!formLoading) {
          setTempFiles([]);
        }
      } else {
        newFiles = acceptedFiles.map((file) =>
          Object.assign(file, {
            id: `image-${file.lastModified}-${GetUniqueId()}`,
            preview: URL.createObjectURL(file),
          })
        );
      }
      onChange(newFiles);
    },
    onDropRejected: (rejectedFiles) => {
      rejectedFiles.forEach(({ errors }) =>
        errors.forEach((e) => {
          setErrorMessageState(getError(e.code));
        })
      );
    },
  });

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks
    setErrorMessageState();
    return () =>
      files?.forEach((file) => {
        if (file.newFile) URL.revokeObjectURL(file.preview);
      });
  }, []);

  return (
    <Box component="section" className={classes.container}>
      <Box {...getRootProps({ className: classes.dropzone })}>
        <input name="eventImages" {...getInputProps()} />
        {formLoading && <p>Image(s) uploading</p>}
        {!formLoading && (
          <>
            <Link className={classes.link} underline="always" color="primary">
              Browse files
            </Link>
          </>
        )}
      </Box>
      <FormHelperText className={classes.helperText} error={true}>
        {errorMessageState}
      </FormHelperText>
      <FormHelperText
        className={classes.helperText}
        error={Boolean(errorMessage)}
      >
        {errorMessage ? errorMessage : helperText}
      </FormHelperText>
      <aside>
        <ImagePreview
          files={files}
          setImages={onReorder}
          tempFiles={tempFiles}
          isLoading={formLoading}
        />
      </aside>
    </Box>
  );
};

export default ImageUpload;
