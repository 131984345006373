import React from 'react';
import { Chart } from 'react-google-charts';
import { Typography, Box } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  box: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    direction: 'column',
    [theme.breakpoints.only('md')]: {
      width: '150px',
      direction: 'row',
    },
  },
  numberPosition: {
    position: 'relative',
    zIndex: '2',
    bottom: '90px',
    height: 0,
  },
  marginTop: {
    marginTop: theme.spacing(2),
  },
  marginBottom: {
    marginBottom: theme.spacing(4),
  },
  paddingZero: {
    padding: 0,
  },
}));

const TimelineDonuts = ({ title, slices, action }) => {
  const classes = useStyles();
  const data = action;

  return (
    <>
      <Box className={classes.box}>
        <Grid
          container
          direction="column"
          alignItems="center"
          spacing={1}
          className={classes.paddingZero}
        >
          <Chart
            width={'100%'}
            height={'150px'}
            chartType="PieChart"
            loader={<div>Loading Chart</div>}
            data={[
              ['', ''],
              ['Days passed', data[0]],
              ['Days until', data[1]],
            ]}
            options={{
              pieHole: 0.6,
              legend: 'none',
              chartArea: { width: '60%', height: '100%' },
              pieSliceText: 'none',
              tooltip: { trigger: 'none' },
              slices: slices,
              enableInteractivity: false,
            }}
            rootProps={{ 'data-testid': '3' }}
          />
          <Typography className={classes.numberPosition} variant="h3">
            {data[1] === 0 ? '-' : data[1]}
          </Typography>
          <Typography className={classes.marginTop}>Days until</Typography>
          <Typography className={classes.marginBottom} variant="h5">
            {title}
          </Typography>
        </Grid>
      </Box>
    </>
  );
};

export default TimelineDonuts;
