import React, { useState, useContext, useEffect } from 'react';
import { Button, Card, CardContent } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useSnackbar } from 'notistack';
import StepTitle from '../../Common/PageLayout/StepTitle';
import CreateAddOnFormDrawer from './AddOnDrawer/CreateAddOnFormDrawer';
import AddOnEmptyState from './AddOnEmptyState';
import { actionTypes, Context } from '../../../Stores/EventInfoStore';
import { useApi, methods } from '../../../Hooks/useApi';
import { prepareAddOnForEdit } from './addOnsUtils';
import AddOnListItem from './AddOnListItem';
import DeleteConfirmationModal from '../../Common/ConfirmationModal/DeleteConfirmationModal';
import { useEdition } from '../../../Hooks/useEdition';
import Grid from '@mui/material/Unstable_Grid2';
import { mutate } from 'swr';
const getToggleActiveEndpoint = (eventId, editionId, id) =>
  `/addOn/${eventId}/${editionId}/${id}/toggle-active`;

const getDeleteProductEndpoint = (eventId, editionId, id) =>
  `/ro/events/${eventId}/races/${editionId}/products/${id}`;

const useStyles = makeStyles((theme) => ({
  topToolbar: {
    marginBottom: theme.spacing(2),
  },
  deactivatedLabel: {
    marginLeft: theme.spacing(3),
  },
}));

const AddOns = () => {
  const classes = useStyles();
  const { executeApiCall, apiError } = useApi();
  const [eventInfo, dispatch] = useContext(Context);
  const {
    event,
    addOns = [],
    basicInfo: { eventCurrency: currency },
  } = eventInfo || {};
  const { eventId, editionId } = event || {};
  const { edition } = useEdition(eventId, editionId);
  const [isEditing, setIsEditing] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [addOnEditState, setAddOnEditState] = useState({});
  const [deleteAddOnState, setDeleteAddOnState] = useState({});
  const handleAddAddOn = () => {
    setAddOnEditState({
      isNew: true,
      addOnIndex: -1,
    });
    setIsEditing(true);
  };

  useEffect(() => {
    if (!addOnEditState.isNew && addOnEditState.addOn && edition.addOns) {
      setAddOnEditState({
        addOn: prepareAddOnForEdit(
          edition.addOns.find((a) => a.id === addOnEditState.addOn.id)
        ),
      });
    }
  }, [edition]);

  const toggleActive = async (id, active) => {
    try {
      await executeApiCall(
        getToggleActiveEndpoint(eventId, editionId, id),
        methods.put
      );
      dispatch({
        type: active
          ? actionTypes.addons_deactivate
          : actionTypes.addons_activate,
        payload: { id },
      });
      enqueueSnackbar(
        'Add-on successfully ' + (active ? 'deactivated' : 'activated'),
        { variant: 'success' }
      );
    } catch {}
  };

  const deleteAddOn = async (id) => {
    try {
      await executeApiCall(
        getDeleteProductEndpoint(eventId, editionId, id),
        methods.delete
      );
      dispatch({
        type: actionTypes.addons_remove,
        payload: { id },
      });
      enqueueSnackbar('Add-on successfully deleted', { variant: 'success' });
      setDeleteAddOnState({});
      mutate(`/event/${event.eventId}/edition/${event.editionId}`);
    } catch {}
  };
  const handleOnDuplicate = (addOn) => {
    setAddOnEditState({
      isNew: true,
      isDuplicate: true,
      addOn: prepareAddOnForEdit(addOn),
    });
    setIsEditing(true);
  };
  const handleOnEdit = (addOn) => {
    setAddOnEditState({
      isNew: false,
      addOn: prepareAddOnForEdit(addOn),
    });

    setIsEditing(true);
  };

  const handleOnDelete = (addonId) => {
    const addon = addOns.find((a) => a.id == addonId);
    setDeleteAddOnState({
      id: addon?.id,
      name: addon?.name,
    });
  };

  const onFormDrawerClose = () => {
    setIsEditing(false);
  };
  useEffect(() => {
    if (apiError) enqueueSnackbar(apiError, { variant: 'error' });
  }, [enqueueSnackbar, apiError]);

  return (
    <>
      <StepTitle
        title="Add-ons (additional products or services)"
        subtitle="Add-ons can be displayed during the checkout process so that your participants can easily purchase them together with their tickets. The add-ons can be products such as t-shirts, or services such as medal engraving, shuttle transfer, etc."
      />
      <Card>
        <CardContent>
          {!addOns.length && (
            <AddOnEmptyState handleAddAddOn={handleAddAddOn} />
          )}
          {addOns.length > 0 && (
            <>
              <Grid
                container
                spacing={2}
                justifyContent="flex-end"
                className={classes.topToolbar}
              >
                <Grid>
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={handleAddAddOn}
                  >
                    + Add add-on
                  </Button>
                </Grid>
              </Grid>
              {addOns.map((addOn) => (
                <AddOnListItem
                  key={addOn.id}
                  addOn={addOn}
                  onEdit={handleOnEdit}
                  classes={classes}
                  onToggleActive={toggleActive}
                  onDelete={handleOnDelete}
                  currency={currency}
                  onDuplicate={handleOnDuplicate}
                />
              ))}
            </>
          )}
        </CardContent>
      </Card>
      {isEditing && (
        <CreateAddOnFormDrawer
          open={isEditing}
          onClose={onFormDrawerClose}
          addOnState={addOnEditState}
          addOns={addOns}
          currency={currency}
          eventInfo={eventInfo}
        />
      )}
      {deleteAddOnState != null && deleteAddOnState.id != null && (
        <DeleteConfirmationModal
          open={deleteAddOnState !== null}
          confirm={() => deleteAddOn(deleteAddOnState?.id)}
          cancel={() => setDeleteAddOnState({})}
          ticket={deleteAddOnState?.name}
          deleteText={'add-on'}
        />
      )}
    </>
  );
};

export default AddOns;
