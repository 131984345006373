import Grid from '@mui/material/Unstable_Grid2';
import BasicEventCard from './BasicEventCard';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
  },
}));

const EventGridList = ({ events }) => {
  const classes = useStyles();
  return (
    <Grid container spacing={2} className={classes.root}>
      {events.map((event) => (
        <Grid key={event.id} xs={12} sm={6} md={4}>
          <BasicEventCard {...event} />
        </Grid>
      ))}
    </Grid>
  );
};

export default EventGridList;
