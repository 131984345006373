import React, { useState } from 'react';
import {
  Typography,
  Link,
  Button,
  TextField,
  Divider,
  IconButton,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Alert from '@mui/material/Alert';
import { Warning, Close } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import PasswordField from '../Common/PasswordField/PasswordField';
import { useAuth } from '../../Contexts/AuthContext';
import { useTracking } from '../../Analytics/TrackingContext';
import RaceOfficeLogo from '../../images/raceoffice_logo.svg';
import { useApi, methods } from '../../Hooks/useApi';
import useQueryParams from '../../Hooks/useQueryParams';
import Grid from '@mui/material/Unstable_Grid2';
const loginUrl = '/useraccount/login';
const touchUrl = '/useraccount/touch';

const useStyles = makeStyles((theme) => ({
  section1: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },
  section2: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    width: '100%',
  },
  img: {
    marginBottom: '5%',
    width: '50%',
    minHeight: 40,
  },
}));

const Login = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { setLoginData } = useAuth();
  const { trackEvent } = useTracking();
  const { apiLoading, executeApiCall } = useApi();
  const redirect = useQueryParams().get('loginRedirect');

  const [state, setState] = useState({
    email: '',
    password: '',
    showError: false,
    errorMessage: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const login = async (e) => {
    e.preventDefault();

    try {
      let loggedIn = await executeApiCall(loginUrl, methods.post, {
        username: state.email,
        password: state.password,
      });
      if (!loggedIn) return;

      trackEvent({ category: 'Login', action: 'Login successful' });

      if (loggedIn.isRedirect) {
        window.location.href = loggedIn.url;
        return;
      }
      await executeApiCall(touchUrl, methods.put);
      setLoginData(loggedIn);
      navigate(redirect ?? '/app');
    } catch (err) {
      trackEvent({ category: 'Login', action: err.message });
      setError(err.message);
    }
  };

  const setError = (err) => {
    setState((prevState) => ({
      ...prevState,
      showError: err !== null,
      errorMessage: err,
    }));
  };

  return (
    <form onSubmit={login}>
      <Grid container spacing={2} className={classes.section1}>
        <Grid xs={12}>
          <img
            className={classes.img}
            src={RaceOfficeLogo}
            alt="Company logo"
          />
        </Grid>
        <Grid xs={12}>
          <Typography component="h1" variant="h5">
            Login
          </Typography>
        </Grid>
        {state.showError && (
          <Alert
            severity="error"
            icon={<Warning color="inherit" fontSize="inherit" />}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setError(null);
                }}
              >
                <Close fontSize="inherit" />
              </IconButton>
            }
          >
            {state.errorMessage}
          </Alert>
        )}
        <Grid xs={12}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={state.email}
            onInput={handleChange}
          />
        </Grid>
        <Grid xs={12}>
          <PasswordField
            password={state.password}
            handleChange={handleChange}
          />
        </Grid>
        <Grid xs={12}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={apiLoading}
          >
            {apiLoading ? 'Logging in...' : 'Login'}
          </Button>
        </Grid>
        <Grid xs={12}>
          <Link href="/forgot-password" variant="body2" disabled={apiLoading}>
            Forgot password?
          </Link>
        </Grid>
      </Grid>
      <Divider variant="middle" />
      <Grid container spacing={2} className={classes.section2}>
        <Grid xs={12}>
          <Typography component="h2" variant="h5">
            Don't have an account?
          </Typography>
        </Grid>
        <Grid xs={12}>
          <Button
            fullWidth
            variant="outlined"
            className={classes.submit}
            href="/signup"
            disabled={apiLoading}
          >
            Create account
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default Login;
