import React, { useState, memo } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import useSWR, { useSWRConfig } from 'swr';
import {
  Typography,
  AppBar,
  Tabs,
  Tab,
  IconButton,
  List,
  ListItem,
  ListItemText,
  SwipeableDrawer,
  Toolbar,
  Badge,
  Popover,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import makeStyles from '@mui/styles/makeStyles';
import MenuIcon from '@mui/icons-material/Menu';
import clsx from 'clsx';
import MenuItems from './menu';
import RaceOfficeLogo from '../../images/raceoffice_logo.svg';
import { useApi, methods } from '../../Hooks/useApi';
import { useAuth } from '../../Contexts/AuthContext';
import EventNavItems from './ManageEvents/EventNavItems';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'sticky',
    boxShadow: 'none',
    borderBottom: `1px solid ${theme.palette.grey['300']}`,
    backgroundColor: 'white',
    zIndex: 1200,
  },
  inline: {
    display: 'inline',
  },
  flex: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      justifyContent: 'space-evenly',
      alignItems: 'center',
    },
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
    display: 'flex',
  },
  logo: {
    display: 'inline-block',
    width: 140,
    height: 32,
    marginTop: 16,
  },
  logout: {
    wordBreak: 'keep-all',
    cursor: 'pointer',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  separator: {
    borderLeft: `1px solid ${theme.palette.grey['300']}`,
    marginLeft: theme.spacing(4),
    paddingLeft: theme.spacing(0.2),
  },
  iconContainer: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
  iconButton: {
    float: 'right',
  },
  tabContainer: {
    marginLeft: 32,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  list: {
    width: 250,
  },
  tabItem: {
    paddingTop: 20,
    paddingBottom: 20,
    minWidth: 'auto',
    minHeight: 64.5,
  },
  paper: {
    padding: theme.spacing(1),
  },
  popover: {
    pointerEvents: 'none',
  },
}));

const logoutUrl = '/useraccount/logout';

const AppNav = (props) => {
  const classes = useStyles();
  const { executeApiCall } = useApi();
  const { setLoginData, loginData } = useAuth();
  const { roles } = loginData;
  const navigate = useNavigate();
  const { data: highlightPayouts } = useSWR('/payout/highlight-payout');
  const { cache } = useSWRConfig();

  const handleLogout = async () => {
    await executeApiCall(logoutUrl, methods.get);
    setLoginData(null);
    cache.clear();
    navigate('/login');
  };

  return (
    <React.Fragment>
      <AppBar position="absolute" color="default" className={classes.appBar}>
        <Toolbar>
          <Grid
            container
            spacing={0}
            alignItems="baseline"
            justifyContent="space-between"
          >
            <Grid xs={12} className={classes.flex}>
              <div className={classes.inline}>
                <Typography
                  variant="h6"
                  color="inherit"
                  noWrap
                  style={{ flex: 1 }}
                >
                  <Link to="/" className={classes.link}>
                    <img
                      src={RaceOfficeLogo}
                      className={classes.logo}
                      alt="Race Office logo"
                    />
                  </Link>
                </Typography>
              </div>
              {!props.noTabs && (
                <React.Fragment>
                  <div className={classes.separator} />
                  <NavItems
                    highlightPayouts={highlightPayouts}
                    classes={classes}
                    roles={roles}
                  />
                </React.Fragment>
              )}
            </Grid>
          </Grid>
          <div
            className={clsx(classes.tabItem, classes.logout)}
            onClick={handleLogout}
          >
            Log&nbsp;out
          </div>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

const NavItems = ({ classes, highlightPayouts, roles }) => {
  const { pathname } = useLocation();
  const [menuDrawerOpen, setMenuDrawerOpen] = useState(false);
  const mobileMenuOpen = () => setMenuDrawerOpen(true);
  const mobileMenuClose = () => setMenuDrawerOpen(false);
  const splitPath = pathname.split('/');
  let needsManageEventMenu = false;
  let highlightedTab = 0;
  if (splitPath.length > 2) {
    needsManageEventMenu = splitPath[2] === 'manage-event';
    switch (splitPath[2]) {
      case 'payouts':
        highlightedTab = 1;
        break;
      case 'team':
        highlightedTab = 2;
        break;
      case 'help':
        window.open(
          'https://app.tettra.co/teams/worldsmarathons/categories/204952'
        );
        highlightedTab = 3;
        break;
      case 'admin':
        highlightedTab = 4;
        break;
      default:
        highlightedTab = 0;
    }
  }
  const [value, setValue] = useState(highlightedTab);
  const handleChange = (_, val) => {
    if (val !== 3) setValue(val);
  };

  return (
    <React.Fragment>
      <div className={classes.iconContainer}>
        <IconButton
          onClick={mobileMenuOpen}
          className={classes.iconButton}
          color="inherit"
          aria-label="Menu"
          size="large"
        >
          <MenuIcon />
        </IconButton>
      </div>
      <div className={classes.tabContainer}>
        <SwipeableDrawer
          anchor="right"
          open={menuDrawerOpen}
          onClose={mobileMenuClose}
          onOpen={mobileMenuOpen}
        >
          <AppBar title="Menu" />
          <List className={classes.list}>
            {MenuItems.map((item, _) => (
              <ListItem
                component={Link}
                to={{
                  pathname: item.pathname,
                }}
                button
                key={item.label}
              >
                <ListItemText primary={item.label} />
              </ListItem>
            ))}
            {needsManageEventMenu && <EventNavItems />}
          </List>
        </SwipeableDrawer>
        <Tabs
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
        >
          {MenuItems.map((item, index) => {
            if (item.label === 'Admin' && roles.indexOf('admin') === -1)
              return null;
            else if (item.label === 'Help')
              return (
                <Tab
                  key={index}
                  label={item.label}
                  onClick={() =>
                    window.open(
                      'https://app.tettra.co/teams/worldsmarathons/categories/204952'
                    )
                  }
                  classes={{ root: classes.tabItem }}
                />
              );
            else
              return (
                <Tab
                  key={index}
                  component={Link}
                  to={{
                    pathname: item.pathname,
                  }}
                  classes={{ root: classes.tabItem }}
                  label={
                    item.label === 'Payouts' && highlightPayouts ? (
                      <HighlightedItem />
                    ) : (
                      item.label
                    )
                  }
                />
              );
          })}
        </Tabs>
      </div>
    </React.Fragment>
  );
};

//Can be extended to be more reusable as needed
const HighlightedItem = memo(() => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Badge color="secondary" variant="dot">
        <Typography
          variant="subtitle1"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          Payouts
        </Typography>
      </Badge>
      <Popover
        id="highlight-payouts-tab"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        disableRestoreFocus
      >
        <Typography>We are missing your payout information</Typography>
      </Popover>
    </>
  );
});
export default AppNav;
