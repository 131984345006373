import React, { useState } from 'react';
import { Menu, Button } from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';

const ButtonDropdownMenu = ({ disabled, buttonText, startIcon, ...props }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const openActions = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const performAction = (action) => {
    action();
    handleClose();
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        startIcon={startIcon}
        endIcon={<ArrowDropDown />}
        onClick={openActions}
        disabled={disabled}
      >
        {buttonText}
      </Button>
      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {props.children(performAction)}
      </Menu>
    </>
  );
};

export default ButtonDropdownMenu;
