import React, { useState, useEffect } from 'react';
import {
  CssBaseline,
  ThemeProvider,
  StyledEngineProvider,
} from '@mui/material';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useRoutes } from 'react-router-dom';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import WSGTheme from './Theme/WorldSportsGroupTheme';
import { hotjar } from 'react-hotjar';
import { AuthContext } from './Contexts/AuthContext';
import { Onboarding } from './Components/Onboarding/Onboarding';
import { TrackingProvider } from './Analytics/TrackingContext';
import ErrorBoundary from './Components/Common/ErrorBoundary/ErrorBoundary';
import SWRWrapper from './Components/Common/SWRWrapper/SWRWrapper';
import Snackbar from './Components/Common/Snackbar/Snackbar';
import QueryStringAlertHandler from './Components/Common/QueryStringAlertHandler/QueryStringAlertHandler';
import useQueryParams from './Hooks/useQueryParams';
import config from './config';
import routes from './Routes';

const App = () => {
  const currentUser = localStorage.getItem('currentUser');
  const redirect = useQueryParams().get('loginRedirect');
  let existingLogin = null;
  if (currentUser && currentUser !== 'null' && currentUser !== 'undefined')
    existingLogin = JSON.parse(currentUser);

  const [loginData, setLoginData] = useState(existingLogin);
  const [canOnboard, setCanOnboard] = useState(false);
  const setLogin = (data) => {
    if (data) localStorage.setItem('currentUser', JSON.stringify(data));
    else localStorage.removeItem('currentUser');
    setLoginData(data);
    if (data) {
      setCanOnboard(true);
    }
  };
  const isAdmin = loginData && loginData.roles?.includes('admin');

  useEffect(() => {
    if (config.IS_PRODUCTION_ENV) {
      const siteId = 2465592;
      hotjar.initialize(siteId, 6);
    }
  }, [config.IS_PRODUCTION_ENV]);

  let loggedIn = !redirect && Date.now() < loginData?.expiry;
  const roles = loginData?.roles;
  if (!loggedIn) localStorage.removeItem('currentUser');
  const routing = useRoutes(routes(loggedIn, roles));
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={config.RECAPTCHA_KEY}
      useRecaptchaNet={true}
    >
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={WSGTheme}>
          <CssBaseline />
          <ErrorBoundary>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              >
                <SWRWrapper>
                  <AuthContext.Provider
                    value={{ loginData, setLoginData: setLogin, isAdmin }}
                  >
                    <TrackingProvider>{routing}</TrackingProvider>
                    <Onboarding
                      canOnboard={!!loginData && canOnboard}
                      onOnboard={() => setCanOnboard(false)}
                      organiserNewInfoShown={loginData?.organiserNewInfoShown}
                    />
                  </AuthContext.Provider>
                </SWRWrapper>
                <QueryStringAlertHandler />
              </Snackbar>
            </LocalizationProvider>
          </ErrorBoundary>
        </ThemeProvider>
      </StyledEngineProvider>
    </GoogleReCaptchaProvider>
  );
};

export default App;
