import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import handleApiError from '../Utils/handleApiError';
import config from '../config.js';

export const methods = {
  get: 'GET',
  post: 'POST',
  put: 'PUT',
  delete: 'DELETE',
};

const headerType = 'application/json';

export const useApi = () => {
  const [apiLoading, setIsLoading] = useState(false);
  const [apiError, setError] = useState(null);
  const [apiSuccess, setSuccess] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const executeApiCall = async (
    url,
    method,
    data,
    customHeaders = null,
    skipParsing = false,
    fileDownload = false,
    fileName = ''
  ) => {
    try {
      setSuccess(false);
      setError(null);
      setIsLoading(true);
      var headers = customHeaders
        ? {
            'Content-Type': headerType,
            accepts: headerType,
            ...customHeaders,
          }
        : { 'Content-Type': headerType, accepts: headerType };
      const response = await fetch(config.API_URL + url, {
        credentials: 'include',
        headers: headers,
        method: method,
        body: data ? JSON.stringify(data) : null,
      });
      const text = await response.clone().text();
      let res;
      if (text) {
        try {
          if (skipParsing) {
            res = text;
          } else {
            res = JSON.parse(text);
          }
        } catch (e) {
          res = text;
        }
      } else {
        res = {};
      }

      if (fileDownload) {
        var contentType = response.headers.get('content-type');
        const blob = await response.clone().blob();

        const url = window.URL.createObjectURL(
          new Blob([blob], { type: contentType })
        );
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName ?? 'file-download';
        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      }

      if (!response.ok) {
        const error = handleApiError(res);

        switch (response.status) {
          case 400:
            throw new Error(error);
          case 401:
            navigate(`/login?loginRedirect=${pathname}`);
            break;
          default:
            throw new Error(
              "An error occurred while processing your request. We're on it"
            );
        }
      } else {
        setSuccess(true);
      }
      return res;
    } catch (e) {
      setError(e.message);
      throw e;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    apiLoading,
    apiError,
    executeApiCall,
    apiSuccess,
  };
};
