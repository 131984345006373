import { Tabs, Tab } from '@mui/material';
import { withStyles } from '@mui/styles';
import { wmPalette } from '../../../Theme/WorldSportsGroupTheme';

export const AntTabs = withStyles({
  indicator: {
    backgroundColor: wmPalette.green[70],
  },
})(Tabs);

export const AntTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);
