import React, { useEffect, useState } from 'react';
import {
  Typography,
  Button,
  TableContainer,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import EditIcon from '@mui/icons-material/Edit';
import SendIcon from '@mui/icons-material/Send';
import SyncDisabledIcon from '@mui/icons-material/SyncDisabled';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { wmPalette } from '../../../Theme/WorldSportsGroupTheme';
import { useApi } from '../../../Hooks/useApi';
import { useWebhooks } from '../../../Hooks/useWebhooks';
import KebabMenu from '../../Common/KebabMenu/KebabMenu';
import ConfirmationModal from '../../Common/ConfirmationModal/ConfirmationModal';
import EndpointDrawer from './EndpointDrawer';
import Grid from '@mui/material/Unstable_Grid2';
const useStyles = makeStyles((theme) => ({
  root: {
    padding: 10,
    paddingTop: theme.spacing(2),
    backgroundColor: wmPalette.white[100],
    '& .MuiDataGrid-columnsContainer': {
      backgroundColor: wmPalette.white[100],
    },
    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    '& .MuiTableCell-head': {
      color: wmPalette.black[90],
    },
  },
  noBorder: {
    border: 'none',
  },
  btnEndpoint: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  webhookDetail: {
    cursor: 'pointer',
    maxWidth: '400px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  kebabIcon: {
    marginRight: theme.spacing(1),
  },
  disabled: {
    color: wmPalette.black[50],
  },
}));

const WebhookEndpoints = ({
  eventId,
  addWebhook,
  updateWebhook,
  disableWebhook,
  deleteWebhook,
  sendTestWebhook,
}) => {
  const { apiError } = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [webhookEditState, setWebhookEditState] = useState({});
  const [deleteWebhookId, setDeleteWebhookId] = useState('');
  const classes = useStyles();
  const navigate = useNavigate();

  const { webhooks: data, key } = useWebhooks(eventId);

  const confirmDeleteWebhook = (webhookId) => {
    setDeleteWebhookId(webhookId);
    setOpenModal(true);
  };

  const handleEditWebhook = (webhook) => {
    setWebhookEditState({
      isEdit: true,
      id: webhook.id,
      endpoint: webhook.endpoint,
      alertEmail: webhook.alertEmail,
    });
    handleOpenDrawer();
  };

  useEffect(() => {
    if (apiError) enqueueSnackbar(apiError, { variant: 'error' });
  }, [enqueueSnackbar, apiError]);

  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  };

  const handleCloseDrawer = () => {
    setWebhookEditState({});
    setOpenDrawer(false);
  };

  const openWebhookDetailPage = async (webhook) => {
    navigate(`detail/${webhook.id}`);
  };

  const handleWebhookSubmit = async (values) => {
    if (webhookEditState.isEdit === true) {
      await updateWebhook(webhookEditState.id, values, key);
    } else {
      await addWebhook(values, key);
    }
  };

  return (
    <>
      {isEmpty(data) && (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
          spacing={0}
        >
          <Typography>You have no endpoints yet</Typography>
          <Button
            className={classes.btnEndpoint}
            variant="contained"
            color="primary"
            onClick={handleOpenDrawer}
          >
            + Add endpoint
          </Button>
        </Grid>
      )}

      {!isEmpty(data) && (
        <Grid container spacing={0}>
          <Grid container justifyContent="flex-end" spacing={0}>
            <Grid xs={3} md={2}>
              <Button
                className={classes.btnEndpoint}
                variant="outlined"
                color="primary"
                onClick={handleOpenDrawer}
              >
                + Add endpoint
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={0}>
            <TableContainer className={classes.root}>
              <Table className={classes.noBorder}>
                <TableHead>
                  <TableRow>
                    <TableCell>Endpoint URL</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Version</TableCell>
                    <TableCell>Mode</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                {data && (
                  <TableBody>
                    {data?.map((webhook) => (
                      <TableRow
                        key={webhook.endpoint}
                        className={classes.disabled}
                      >
                        <TableCell
                          className={clsx(
                            classes.webhookDetail,
                            webhook.status === 'disabled'
                              ? classes.disabled
                              : ''
                          )}
                          onClick={() => {
                            openWebhookDetailPage(webhook);
                          }}
                        >
                          {webhook.endpoint}
                        </TableCell>
                        <TableCell
                          className={clsx(
                            classes.webhookDetail,
                            webhook.status === 'disabled'
                              ? classes.disabled
                              : ''
                          )}
                          onClick={() => {
                            openWebhookDetailPage(webhook);
                          }}
                        >
                          {webhook.status}
                        </TableCell>
                        <TableCell
                          className={clsx(
                            classes.webhookDetail,
                            webhook.status === 'disabled'
                              ? classes.disabled
                              : ''
                          )}
                          onClick={() => {
                            openWebhookDetailPage(webhook);
                          }}
                        >
                          {webhook.version}
                        </TableCell>
                        <TableCell
                          className={clsx(
                            classes.webhookDetail,
                            webhook.status === 'disabled'
                              ? classes.disabled
                              : ''
                          )}
                          onClick={() => {
                            openWebhookDetailPage(webhook);
                          }}
                        >
                          {webhook.eventTypes}
                        </TableCell>
                        <TableCell>
                          <KebabMenu
                            dense={false}
                            optionActions={[
                              {
                                label: 'Edit',
                                action: () => handleEditWebhook(webhook),
                                icon: (
                                  <EditIcon
                                    className={classes.kebabIcon}
                                    fontSize="small"
                                  />
                                ),
                              },
                              {
                                label: 'Send test',
                                action: async () =>
                                  await sendTestWebhook(webhook.id),
                                icon: (
                                  <SendIcon
                                    className={classes.kebabIcon}
                                    fontSize="small"
                                  />
                                ),
                              },
                              {
                                label:
                                  webhook.status === 'disabled'
                                    ? 'Enable'
                                    : 'Disable',
                                action: async () =>
                                  await disableWebhook(webhook, key),
                                icon: (
                                  <SyncDisabledIcon
                                    className={classes.kebabIcon}
                                    fontSize="small"
                                  />
                                ),
                              },
                              {
                                label: 'Delete',
                                action: () => confirmDeleteWebhook(webhook.id),
                                icon: (
                                  <DeleteIcon
                                    className={classes.kebabIcon}
                                    fontSize="small"
                                  />
                                ),
                              },
                            ]}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      )}
      {openModal && (
        <ConfirmationModal
          cancel={() => {
            setDeleteWebhookId('');
            setOpenModal(false);
          }}
          confirm={async () => {
            await deleteWebhook(deleteWebhookId, key);
            setOpenModal(false);
          }}
          open={openModal}
          title="Are you sure you want to delete?"
          cancelText="No, cancel"
          confirmText="Yes, delete"
        />
      )}
      {openDrawer && (
        <EndpointDrawer
          open={openDrawer}
          handleCloseDrawer={handleCloseDrawer}
          addWebhook={addWebhook}
          updateWebhook={updateWebhook}
          webhookEditState={webhookEditState}
          handleWebhookSubmit={handleWebhookSubmit}
        />
      )}
    </>
  );
};

export default WebhookEndpoints;
