import React from 'react';
import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { wmPalette } from '../../../Theme/WorldSportsGroupTheme';
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Unstable_Grid2';
const useStyles = makeStyles(() => ({
  kpiCard: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '16px',
    backgroundColor: wmPalette.grey[10],
    height: '86px',
    cursor: 'pointer',
  },
  kpiInactive: {
    backgroundColor: wmPalette.grey[10],
    '&:hover': {
      backgroundColor: wmPalette.grey[20],
    },
  },
  kpiActive: {
    backgroundColor: wmPalette.green[70],
    color: 'white',
  },
}));

const KpiCard = ({ cardId, activeCard, onClick, title, number, loading }) => {
  const classes = useStyles();

  return (
    <Grid xs={6} lg xl={2}>
      {loading ? (
        <Skeleton
          variant="rectangular"
          className={clsx(
            classes.kpiCard,
            activeCard === cardId ? classes.kpiActive : classes.kpiInactive
          )}
          animation="wave"
        />
      ) : (
        <Box
          className={clsx(
            classes.kpiCard,
            activeCard === cardId ? classes.kpiActive : classes.kpiInactive
          )}
          onClick={() => onClick(cardId)}
        >
          <Typography gutterBottom>{title}</Typography>
          <Typography variant="h4">{number}</Typography>
        </Box>
      )}
    </Grid>
  );
};

export default KpiCard;
