import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import Skeleton from '@mui/material/Skeleton';
import {
  Card,
  Divider,
  CardActionArea,
  Button,
  CardActions,
  CardContent,
  Box,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    padding: theme.spacing(2),
  },
  removeShadow: {
    boxShadow: 'none',
  },
  tablePadding: {
    paddingTop: `${theme.spacing(27)} !important`,
  },
}));
const Loading = ({ variant, disableElevation, isTable }, ...props) => {
  const classes = useStyles();
  const Component = props.component || Paper;
  return (
    <Component
      className={`${classes.root} ${
        disableElevation ? classes.removeShadow : ''
      } ${isTable ? classes.tablePadding : ''}`}
    >
      {variant === 'table' && <TableIndicator />}
      {variant === 'spinner' && <SpinnerIndicator />}
      {variant === 'card' && <CardIndicator classes={classes} />}
      <Typography gutterBottom color="initial" className={classes.message}>
        Loading...
      </Typography>
    </Component>
  );
};
const CardIndicator = ({ classes }, ...props) => {
  const cards = props.cards || 3;
  return (
    <Box>
      <Grid container spacing={2}>
        {Array(cards)
          .fill('')
          .map((c, i) => (
            <Grid sm={1} md={4} key={i}>
              <Card style={{ width: '100%' }}>
                <CardActionArea>
                  <Skeleton variant="rectangular" style={{ height: 140 }} />
                </CardActionArea>
                <CardContent className={classes.cardContent}>
                  <Typography variant="h6" gutterBottom>
                    <Skeleton />
                  </Typography>
                  <Typography variant="body2">
                    <Skeleton />
                  </Typography>
                  <Typography variant="body2">
                    <Skeleton variant="text" />
                  </Typography>

                  <Typography variant="body2">
                    <Skeleton variant="text" />
                  </Typography>

                  <Box>
                    <Typography variant="h6">
                      <Skeleton />
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid sm="1" md="6">
                        <Typography variant="body2">
                          <Skeleton />
                        </Typography>
                      </Grid>
                      <Grid sm="1" md="6">
                        <Skeleton />
                      </Grid>
                    </Grid>
                  </Box>
                </CardContent>
                <Divider />
                <CardActions>
                  <Button>
                    <Skeleton style={{ width: 40 }} variant="text" />
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
      </Grid>
    </Box>
  );
};

const SpinnerIndicator = (props) => {
  return <CircularProgress />;
};
const TableIndicator = (props) => {
  const rows = props.rows || 3;
  const cols = props.cols || 5;
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {Array(cols)
              .fill('x')
              .map((col, index) => (
                <TableCell key={index}>
                  <Skeleton />
                </TableCell>
              ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {Array(rows)
            .fill('x')
            .map((row, rowIndex) => (
              <TableRow>
                {Array(cols)
                  .fill('x')
                  .map((col, index) => (
                    <TableCell key={index}>
                      <Skeleton />
                    </TableCell>
                  ))}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

Loading.propTypes = {
  variant: PropTypes.oneOf(['table', 'card', 'spinner']),
};

Loading.defaultProps = {
  variant: 'spinner',
};

export default Loading;
