import React, { useState } from 'react';
import { Formik } from 'formik';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useNavigate } from 'react-router-dom';
import SignupFormFields from './SignupFormFields';
import { useAuth } from '../../Contexts/AuthContext';
import { useApi, methods } from '../../Hooks/useApi';

const loginUrl = '/useraccount/login';
const registerUrl = '/useraccount/register';
const SignupForm = () => {
  const navigate = useNavigate();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { executeApiCall } = useApi();
  const { setLoginData } = useAuth();
  const [registrationError, setRegistrationError] = useState();
  const [passwordErrors, setState] = useState({
    lowercase: { isValid: false, errMessage: 'lowercase letter (a-z)' },
    uppercase: { isValid: false, errMessage: 'uppercase letter (A-Z)' },
    number: { isValid: false, errMessage: 'number (0-9)' },
    symbol: { isValid: false, errMessage: 'symbol (!"#£$%&\'()*+,-./)' },
    long: { isValid: false, errMessage: 'at least 8 characters' },
  });

  const setPasswordError = (
    hasLowerCase,
    hasUpperCase,
    hasSymbol,
    isLongEnough,
    hasNumber
  ) => {
    setState((prevState) => ({
      lowercase: { ...prevState.lowercase, isValid: hasLowerCase },
      uppercase: { ...prevState.uppercase, isValid: hasUpperCase },
      number: { ...prevState.number, isValid: hasNumber },
      symbol: { ...prevState.symbol, isValid: hasSymbol },
      long: { ...prevState.long, isValid: isLongEnough },
    }));
  };

  const login = async (email, password) => {
    try {
      const loggedIn = await executeApiCall(loginUrl, methods.post, {
        username: email,
        password: password,
      });
      setLoginData(loggedIn);
      navigate('/app');
    } catch (e) {
      throw e;
    }
  };

  const signUp = async (values, { setFieldError }) => {
    try {
      if (!executeRecaptcha) {
        return;
      }
      const token = await executeRecaptcha('signUp');
      await executeApiCall(registerUrl, methods.post, {
        recatpchaToken: token,
        ...values,
      });
      await login(values.email, values.password);
    } catch (e) {
      var error = JSON.parse(e.message);
      setRegistrationError(error.message);
      if (error.isTaken) {
        setFieldError('email', 'Email address already in use.');
      }
    }
  };

  return (
    <Formik
      initialValues={{
        email: '',
        firstName: '',
        lastName: '',
        organizationName: '',
        password: '',
        tosandpp: false,
        newsletter: false,
        organizerType: 'organizer',
        roNext: true,
      }}
      validate={(values) => {
        // email Validation
        const errors = {};
        if (!values.email) {
          errors.email = 'Email address is required';
        } else if (!/\S+@\S+\.\S+/.test(values.email)) {
          errors.email = 'This email address is invalid';
        }

        // firstName Validation
        if (!values.firstName) {
          errors.firstName = 'First name is required';
        }

        // lastName Validation
        if (!values.lastName) {
          errors.lastName = 'Last name is required';
        }

        // organisation Validation
        if (!values.organizationName) {
          errors.organizationName =
            'Name of your organisation or event is required';
        }

        // password Validation
        let hasUpperCase = /[A-Z]/.test(values.password);
        let hasLowerCase = /[a-z]/.test(values.password);
        let hasNumbers = /\d/.test(values.password);
        let hasSymbol = /[-!$%^&£*()_+|~=`{}[\]:/;<>?,.@#]/.test(
          values.password
        );
        if (
          !values.password ||
          !hasUpperCase ||
          !hasLowerCase ||
          !hasNumbers ||
          !hasSymbol ||
          values.password.length < 8
        ) {
          errors.password = 'Password is required';
        }

        if (!values.tosandpp) {
          errors.tosandpp = '(Required)';
        }

        setPasswordError(
          hasLowerCase,
          hasUpperCase,
          hasSymbol,
          values.password.length > 7,
          hasNumbers
        );

        return errors;
      }}
      onSubmit={signUp}
    >
      {(props) => (
        <SignupFormFields
          {...props}
          registrationError={registrationError}
          passwordErrors={passwordErrors}
        />
      )}
    </Formik>
  );
};

export default SignupForm;
