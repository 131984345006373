export const titleContainsEmail = "The title can't have an email-address";
export const titleContainsLink = "The title can't have an link";
export const descriptionContainsEmail =
  "The description can't have an email-address";
export const descriptionContainsLink = "The description can't have a link";
export const fieldContainsEmail = "The field can't have an email-address";
export const fieldContainsLink = "The field can't have an link";
export const nameContainsEmail = "The name can't have an email-address";
export const nameContainsLink = "The name can't have a link";
export const answerContainsEmail =
  "The question title can't include email-addresses";
export const answerContainsLink = "The question answer can't include links";
