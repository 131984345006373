import React, { useState } from 'react';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const PasswordField = (props) => {
  const { isNew = false } = props;

  const [showPassword, setShowPassword] = useState(false);

  return (
    <TextField
      variant="outlined"
      required
      fullWidth
      name="password"
      label="Password"
      type={showPassword ? 'text' : 'password'}
      id="password"
      autoComplete={isNew ? 'new-password' : 'current-password'}
      value={props.password}
      onInput={props.handleChange}
      onFocus={props.onFocus}
      onBlur={props.onBlur}
      error={props.error}
      helperText={props.helperText}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShowPassword(!showPassword)}
              size="large"
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default PasswordField;
