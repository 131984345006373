import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Button,
  TableContainer,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  Chip,
  Collapse,
  IconButton,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import EditIcon from '@mui/icons-material/Edit';
import SendIcon from '@mui/icons-material/Send';
import DeleteIcon from '@mui/icons-material/Delete';
import ReplayIcon from '@mui/icons-material/Replay';
import SyncDisabledIcon from '@mui/icons-material/SyncDisabled';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import useSWR from 'swr';
import { useSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router-dom';
import format from 'date-fns/format';
import { wmPalette } from '../../../Theme/WorldSportsGroupTheme';
import { useApi, methods } from '../../../Hooks/useApi';
import { useWebhook } from '../../../Hooks/useWebhooks';
import EndpointDrawer from './EndpointDrawer';
import StepTitleBreadcrumb from '../StepTitleBreadcrumb';
import Breadcrumbs from './Breadcrumbs';
import KebabMenu from '../../Common/KebabMenu/KebabMenu';
import ConfirmationModal from '../../Common/ConfirmationModal/ConfirmationModal';
import SigningSecret from './SigningSecret';
import WrappedDivider from '../../Common/WrappedDivider/WrappedDivider';
import Loading from '../../Loading/Loading';
import Grid from '@mui/material/Unstable_Grid2';
const useStyles = makeStyles((theme) => ({
  root: {
    padding: 10,
    paddingTop: theme.spacing(2),
    backgroundColor: wmPalette.white[100],
    '& .MuiDataGrid-columnsContainer': {
      backgroundColor: wmPalette.white[100],
    },
    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    '& .MuiTableCell-head': {
      color: wmPalette.black[90],
    },
  },
  noBorder: {
    border: 'none',
  },
  noBorderBottom: {
    borderBottom: 'none',
  },
  card: {
    padding: theme.spacing(4),
  },
  loading: {
    minHeight: '50vh',
  },
  kebabIcon: {
    marginRight: theme.spacing(1),
  },
  collapseContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  link: {
    color: wmPalette.blue[100],
    textDecoration: 'none',
  },
  payload: {
    backgroundColor: wmPalette.grey[20],
  },
  payloadPre: {
    whiteSpace: 'pre-wrap',
  },
  heading: {
    overflowWrap: 'anywhere',
  },
  success: {
    color: wmPalette.green[100],
    backgroundColor: wmPalette.green[10],
  },
  failed: {
    color: wmPalette.red[100],
    backgroundColor: wmPalette.red[20],
  },
}));

const baseWebhookUrl = (eventId) => `/ro/events/${eventId}/webhooks`;

const baseWebhookWithId = (eventId, webhookId) =>
  `${baseWebhookUrl(eventId)}/${webhookId}`;

const getWebhookSecretUrl = (eventId, webhookId) =>
  `${baseWebhookWithId(eventId, webhookId)}/secret`;

const resendWebhookUrl = (endpointId) => `/webhook/${endpointId}/resend`;

const WebhookDetail = ({
  updateWebhook,
  deleteWebhook,
  sendTestWebhook,
  disableWebhook,
}) => {
  const classes = useStyles();
  const { executeApiCall, apiError } = useApi();
  const params = useParams();
  const eventId = params.eventId;

  const [openDrawer, setOpenDrawer] = useState(false);
  const { webhook, key, isLoading } = useWebhook(eventId, params.id);
  const { enqueueSnackbar } = useSnackbar();
  const [webhookEditState, setWebhookEditState] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [deleteWebhookId, setDeleteWebhookId] = useState('');
  const navigate = useNavigate();
  const [secret, setSecret] = useState('');
  const { data } = useSWR(`/webhook/${params.id}/sent`);

  const baseURL = `/app/manage-event/${params.eventId}/${params.editionId}`;

  const breadcrumb = {
    'All Integrations': baseURL + '/integration',
    'Webhook API (Endpoints)': baseURL + '/integration/webhook',
    [`${webhook?.endpoint}`]:
      baseURL + `/integration/webhook/detail/${params.id}`,
  };

  useEffect(() => {
    if (!secret) {
      async function getSecret() {
        setSecret(await getWebhookSecret());
      }
      getSecret();
    }
  }, [secret]);

  useEffect(() => {
    if (apiError) enqueueSnackbar(apiError, { variant: 'error' });
  }, [apiError, enqueueSnackbar]);

  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  };

  const handleCloseDrawer = () => {
    setWebhookEditState({});
    setOpenDrawer(false);
  };

  const getWebhookSecret = async () => {
    try {
      return await executeApiCall(
        getWebhookSecretUrl(params.eventId, params.id),
        methods.get
      );
    } catch {}
  };

  const resendWebhook = async (webhookEventId) => {
    try {
      await executeApiCall(resendWebhookUrl(params.id), methods.post, {
        webhookEventId,
      });
      enqueueSnackbar('Webhook has been sent', { variant: 'info' });
    } catch {}
  };

  const handleEditWebhook = (webhook) => {
    setWebhookEditState({
      isEdit: true,
      id: webhook.id,
      endpoint: webhook.endpoint,
      alertEmail: webhook.alertEmail,
    });
    handleOpenDrawer();
  };

  const confirmDeleteWebhook = (webhookId) => {
    setDeleteWebhookId(webhookId);
    setOpenModal(true);
  };

  const handleWebhookSubmit = async (values) => {
    await updateWebhook(webhook.id, values, key);
  };

  return (
    <>
      <StepTitleBreadcrumb
        title="Integration"
        breadcrumb={<Breadcrumbs breadcrumb={breadcrumb} />}
      />
      <Card>
        <CardContent className={classes.card}>
          {isLoading && (
            <Grid
              className={classes.loading}
              container
              justifyContent="center"
              alignItems="center"
              spacing={0}
            >
              <Grid>
                <Typography>Loading...</Typography>
              </Grid>
            </Grid>
          )}
          {!isLoading && (
            <>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                spacing={0}
              >
                <Grid>
                  <Loading disableElevation={true} variant="spinner" />
                </Grid>
              </Grid>
              <WrappedDivider />
              <Grid container spacing={2}>
                <Grid xs={12}>
                  <Typography variant="h4">Webhook Details</Typography>
                </Grid>
                <Grid xs={12}>
                  <Typography variant="body1">
                    Status: {webhook.status}
                  </Typography>
                  <Typography variant="body1">
                    Version: {webhook.version}
                  </Typography>
                  <Typography variant="body1">Mode: Live</Typography>
                </Grid>
              </Grid>
              <WrappedDivider />
              <Grid container spacing={0}>
                <Grid xs={12}>
                  <SigningSecret secret={secret} />
                </Grid>
              </Grid>
              <WrappedDivider />
              <Grid container spacing={0}>
                <Grid xs={12}>
                  <Typography variant="h4" gutterBottom>
                    Logs
                  </Typography>
                </Grid>
                <Grid xs={12}>
                  <TableContainer className={classes.root}>
                    <Table className={classes.noBorder}>
                      <TableHead>
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell>Result</TableCell>
                          <TableCell>Event type </TableCell>
                          <TableCell>Order reference</TableCell>
                          <TableCell>Created</TableCell>
                          <TableCell>HTTP Status</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data?.map((data) => (
                          <Row
                            key={data.webhookEventId}
                            data={data}
                            resendWebhook={resendWebhook}
                          />
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </>
          )}
        </CardContent>
      </Card>
      {openModal && (
        <ConfirmationModal
          cancel={() => {
            setDeleteWebhookId('');
            setOpenModal(false);
          }}
          confirm={async () => {
            await deleteWebhook(deleteWebhookId, key);
            setOpenModal(false);
            navigate(baseURL + '/integration/webhook');
          }}
          open={openModal}
          title="Are you sure you want to delete?"
          cancelText="No, cancel"
          confirmText="Yes, delete"
        />
      )}
      {openDrawer && (
        <EndpointDrawer
          open={openDrawer}
          handleCloseDrawer={handleCloseDrawer}
          webhookEditState={webhookEditState}
          handleWebhookSubmit={handleWebhookSubmit}
        />
      )}
    </>
  );
};

const Row = ({ data, resendWebhook }) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  return (
    <>
      <TableRow>
        <TableCell className={open ? classes.noBorderBottom : ''}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell className={open ? classes.noBorderBottom : ''}>
          <Chip
            className={
              data.httpResponseStatus === 200 ? classes.success : classes.failed
            }
            label={data.httpResponseStatus === 200 ? 'Delivered' : 'Failed'}
          />
        </TableCell>
        <TableCell className={open ? classes.noBorderBottom : ''}>
          {JSON.parse(data.payload).type}
        </TableCell>
        <TableCell className={open ? classes.noBorderBottom : ''}>
          {data.orderReference}
        </TableCell>
        <TableCell className={open ? classes.noBorderBottom : ''}>
          {format(new Date(data.createdAt), 'PPpp')}
        </TableCell>
        <TableCell className={open ? classes.noBorderBottom : ''}>
          {data.httpResponseStatus}
        </TableCell>
        <TableCell className={open ? classes.noBorderBottom : ''}>
          {data.httpResponseStatus === 200 ? (
            ''
          ) : (
            <Button
              color="primary"
              onClick={() => resendWebhook(data.webhookEventId)}
            >
              <ReplayIcon /> Resend
            </Button>
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Grid className={classes.collapseContainer} container spacing={4}>
              <Grid xs={3}>
                <Typography variant="body1" gutterBottom>
                  Request to your endpoint
                </Typography>
              </Grid>
              <Grid xs={9} className={classes.payload}>
                <pre>{JSON.stringify(JSON.parse(data.payload), null, 2)}</pre>
              </Grid>
            </Grid>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default WebhookDetail;
