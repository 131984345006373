import React from 'react';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import * as yup from 'yup';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useApi, methods } from '../../Hooks/useApi';
import { useAuth } from '../../Contexts/AuthContext';
import FormikTextField from '../Common/Formik/FormikTextField';
import { timeoutErrorMessage } from '../../Consts/apiErrorMessages';

const validationSchema = yup.object().shape({
  email: yup.string().email('Invalid email').nullable(),
});

const ExportModal = ({
  reportType,
  open,
  onClose,
  exportURL,
  title,
  description,
  buttonText,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { executeApiCall } = useApi();
  const { isAdmin } = useAuth();

  const confirm = async (values) => {
    const payload = {
      reportType: reportType,
      emailContent: 1,
      email: values.email,
    };
    try {
      await executeApiCall(exportURL, methods.post, payload, null, false);
      enqueueSnackbar(
        'Your export has been started and will be ready in a few minutes.',
        { variant: 'success' }
      );
      onClose();
    } catch (e) {
      if (e.message === timeoutErrorMessage) {
        enqueueSnackbar(
          'Your export has been started and will be ready in a few minutes.',
          { variant: 'success' }
        );
        onClose();
      }
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          email: '',
        }}
        onSubmit={confirm}
      >
        {({ handleSubmit, isSubmitting }) => (
          <>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
              <DialogContentText>{description}</DialogContentText>
              {isAdmin && (
                <FormikTextField label="Email address" name="email" fullWidth />
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose}>Cancel</Button>
              <Button
                disabled={isSubmitting}
                onClick={handleSubmit}
                color="primary"
              >
                {buttonText}
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export default ExportModal;
