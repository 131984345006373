import React, { useRef, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
import { Button, Card, CardContent, Paper } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import makeStyles from '@mui/styles/makeStyles';
import { ArrowBackIos as ArrowBackIosIcon } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import ConfirmationEmail from '../ConfirmationEmail/ConfirmationEmail';
import BasicInfo from '../BasicInfo/BasicInfo';
import DiscardModal from './DiscardModal';
import EventInfoStore from '../../../Stores/EventInfoStore';
import GridWrapper from '../../Common/GridWrapper/GridWrapper';
import ConfirmationModal from '../../Common/ConfirmationModal/ConfirmationModal';
import Loading from '../../Loading/Loading';
import RegistrationForm from '../RegistrationForm/RegistrationForm';
import Tickets from '../Tickets/Tickets';
import AddOns from '../AddOns/AddOns';
import { useEdition } from '../../../Hooks/useEdition';
import { useTracking } from '../../../Analytics/TrackingContext';
import { useApi, methods } from '../../../Hooks/useApi';
import EventInfoUpdater from '../EventInfoUpdater/EventInfoUpdater';
import { CreateEventSteps, getSteps } from './CreateEventSteps';
import CourseDetail from '../CourseDetail/CourseDetail';
import WhatsOffered from '../WhatsOffered/WhatsOffered';
import { scrollToTop } from '../../../Utils/scrollUtils';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  paper: {
    padding: theme.spacing(2),
  },
  grid: {
    margin: 'auto',
    display: 'flex',
    paddingTop: theme.spacing(4),
    flexGrow: 1,
  },
  button: {
    marginRight: theme.spacing(1),
  },
  nextButton: {
    marginRight: theme.spacing(1),
    width: '28%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  cardContent: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),

    '&:last-child': {
      paddingBottom: theme.spacing(5),
    },
  },
  buttonsGrid: {
    margin: 'auto',
    display: 'flex',
    flexGrow: 1,
  },
  buttonsCardContent: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(1),
    paddingRight: theme.spacing(2),
  },
}));

const initialState = {
  basicInfo: {},
  courseDetails: {},
  tickets: [],
  ticketIndex: 0,
  customQuestions: [],
  waiver: null,
  additionalInfo: {},
};

const updateWizardStepUrl = (eventId, editionId) =>
  `/event/${eventId}/${editionId}/create-event-progress`;

const publishEventUrl = (eventId) => `/event/${eventId}/publish`;

const activateEditionUrl = (eventId, editionId) =>
  `/event/${eventId}/${editionId}/reactivate`;

const CreateEventBase = ({ isReactivation }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { eventId, editionId } = useParams();
  const { trackEvent } = useTracking();
  const { edition, isLoading } = useEdition(eventId, editionId);
  const { executeApiCall } = useApi();
  const { enqueueSnackbar } = useSnackbar();

  const [confirmationShown, toggleConfirmation] = useState(false);
  const formRef = useRef();
  const ticketChildRef = useRef(null);
  const [formRefValues, setFormRefValues] = useState(undefined);

  const isUpdate = Boolean(eventId && editionId);
  const steps = getSteps();

  useEffect(() => {
    setFormRefValues(formRef?.current?.values);
  }, [formRef?.current?.values]);

  const formRefNotify = () => {
    setFormRefValues({ ...(formRef?.current?.values ?? {}) });
  };

  const initReactivating = isReactivation && !editionId;

  const { data: basicInfoForReactivation } = useSWR(
    initReactivating ? `/event/${eventId}/reactivate` : null
  );

  const [state, setState] = useState({
    activeStep: 0,
    openDiscardModal: false,
    disableButton: false,
    disableTicketButton: false,
    stepUpdate: 0,
  });

  useEffect(() => {
    scrollToTop();
  }, [state.activeStep]);

  const changeBaseState = (name, value) => {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (edition?.createEventWizardStep)
      changeBaseState('activeStep', edition.createEventWizardStep);
  }, [edition?.createEventWizardStep]);

  //course details
  const submitCourseDetails = async () => {
    if (formRef.current && formRef.current.isValid) {
      await submitFormInfo();
      return await continueUpdateCreateEventStep(state.activeStep);
    }
  };

  const submitFormInfo = async () => {
    return await formRef.current.submitForm();
  };

  const publishEvent = async () => {
    try {
      await executeApiCall(publishEventUrl(eventId), methods.post);
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' });
      throw err;
    }
  };

  const activateEdition = async () => {
    try {
      await executeApiCall(
        activateEditionUrl(eventId, editionId),
        methods.post
      );
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' });
      throw err;
    }
  };

  const handleBack = () => {
    changeBaseState('activeStep', state.activeStep - 1);
  };

  const goToNext = () => {
    changeBaseState('activeStep', state.activeStep + 1);
  };

  const stepUpdate = async (step) => {
    state.activeStep = step;
    changeBaseState('activeStep', state.activeStep);
  };

  const continueUpdateCreateEventStep = async (step) => {
    var isUpdateNeeded = false;
    if (step === 3) {
      isUpdateNeeded = ticketChildRef.current.checkIsUpdateNeeded();
    }
    if (!isUpdateNeeded)
      try {
        changeBaseState('disableButton', true);
        await executeApiCall(
          updateWizardStepUrl(eventId, editionId),
          methods.put,
          { createEventWizardStep: step }
        );
      } catch {
      } finally {
        changeBaseState('disableButton', false);
        goToNext();
      }
  };

  const onConfirmationCancel = () => {
    toggleConfirmation(false);
    scrollToTop();
  };

  const handleNext = async (checkConfirm) => {
    const activeStep = steps[state.activeStep];
    const {
      options: { gaEventAction, gaEventLabel },
    } = activeStep;

    if (
      checkConfirm &&
      activeStep.needConfirmation &&
      activeStep.needConfirmation(edition, formRefValues)
    ) {
      toggleConfirmation(true);
      return;
    }

    if (!!gaEventAction) {
      trackEvent({
        category: 'Create Event',
        action: gaEventAction,
        label: gaEventLabel,
      });
    }

    toggleConfirmation(false);
    switch (state.activeStep) {
      case 0:
        submitFormInfo();
        break;
      case 1:
        await submitCourseDetails();
        break;
      case 2:
        await submitFormInfo();
        break;
      default:
        await continueUpdateCreateEventStep(state.activeStep);
        return;
    }
  };

  const handleSaveDraft = () => {
    if (
      state.activeStep === 0 ||
      state.activeStep === 1 ||
      state.activeStep === 6
    ) {
      if (formRef.current) {
        formRef.current
          .submitForm()
          .then(() => navigateHome())
          .catch(() => {});
      }
    } else {
      navigateHome();
    }
  };

  const navigateHome = () => {
    navigate('/app/events');
  };

  const handlePublishClick = async () => {
    trackEvent({
      category: 'Create Event',
      action: 'Event Review',
      label: 'Send event page to review',
    });
    try {
      changeBaseState('disableButton', true);
      if (formRef.current) {
        await formRef.current.submitForm();
      }
      await publishEvent();
      navigate('/app/create-event-confirmation');
    } catch {
    } finally {
      changeBaseState('disableButton', false);
    }
  };

  const handleReactivateClick = async () => {
    try {
      changeBaseState('disableButton', true);
      await submitFormInfo();
      await activateEdition();
      navigate(`success?title=${edition.basicInfo.eventTitle}`);
    } catch {
    } finally {
      changeBaseState('disableButton', false);
    }
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <BasicInfo
            formRef={formRef}
            onSaveSuccess={goToNext}
            isUpdate={isUpdate}
            initReactivating={initReactivating}
            isManage={false}
            disableButton={(disable) =>
              changeBaseState('disableButton', disable)
            }
            stepUpdate={(step) => stepUpdate(step)}
          />
        );
      case 1:
        return (
          <CourseDetail
            formRef={formRef}
            disableButton={(disable) =>
              changeBaseState('disableButton', disable)
            }
            formRefNotify={formRefNotify}
          />
        );
      case 2:
        return (
          <WhatsOffered
            formRef={formRef}
            formRefNotify={formRefNotify}
            disableButton={(disable) =>
              changeBaseState('disableButton', disable)
            }
            onSaveSuccess={goToNext}
          />
        );
      case 3:
        return (
          <Tickets
            ref={ticketChildRef}
            setHasTickets={(hasTickets) =>
              changeBaseState('disableTicketButton', !hasTickets)
            }
            isReactivation={isReactivation}
          />
        );
      case 4:
        return <AddOns />;
      case 5:
        return <RegistrationForm />;
      case 6:
        return <ConfirmationEmail formRef={formRef} isManage={false} />;
      case 7:
        navigate('/app/create-event-confirmation');
        break;
      default:
        throw new Error('Unknown step');
    }
  };

  return (
    <GridWrapper className={classes.root}>
      <CreateEventSteps
        activeStep={state.activeStep}
        isBottomNaviation={false}
      />
      <Grid xs={12}>
        {(isUpdate && isLoading) ||
        (initReactivating && !basicInfoForReactivation) ? (
          <Loading variant="spinner" />
        ) : (
          <EventInfoStore
            initialState={
              isUpdate
                ? {
                    ticketIndex: -1,
                    event: {
                      eventId: eventId ?? '',
                      editionId: editionId ?? '',
                    },
                    ...edition,
                  }
                : initReactivating
                ? {
                    event: {
                      eventId: eventId ?? '',
                    },
                    basicInfo: basicInfoForReactivation,
                  }
                : initialState
            }
          >
            <React.Fragment>
              {getStepContent(state.activeStep)}
              {isUpdate && <EventInfoUpdater edition={edition} />}
            </React.Fragment>
          </EventInfoStore>
        )}
      </Grid>
      <Grid xs={12}>
        <Paper>
          <Card>
            <CardContent className={classes.buttonsCardContent}>
              <Grid
                container
                className={classes.buttonsGrid}
                justifyContent="flex-end"
                spacing={0}
              >
                {state.activeStep === 0 && !isUpdate && (
                  <Button
                    onClick={() => changeBaseState('openDiscardModal', true)}
                    className={classes.button}
                    variant="outlined"
                    color="secondary"
                    disabled={state.disableButton}
                  >
                    Discard
                  </Button>
                )}
                {state.activeStep > 0 && (
                  <Button
                    onClick={handleBack}
                    className={classes.button}
                    disabled={state.disableButton}
                  >
                    <ArrowBackIosIcon fontSize="small" /> {` Back`}
                  </Button>
                )}
                {state.activeStep !== 0 || isUpdate ? (
                  <Button
                    className={classes.button}
                    variant="outlined"
                    onClick={handleSaveDraft}
                    disabled={state.disableButton}
                    color="primary"
                  >
                    Save as draft
                  </Button>
                ) : (
                  <></>
                )}
                {state.activeStep < steps.length - 1 && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleNext(true)}
                    className={classes.nextButton}
                    disabled={
                      state.activeStep === steps.length - 1 ||
                      state.disableButton ||
                      (state.disableTicketButton && state.activeStep === 3)
                    }
                  >
                    {state.disableButton
                      ? 'Saving...'
                      : `${
                          steps[state.activeStep].getNextStepLabel !== undefined
                            ? steps[state.activeStep].getNextStepLabel(
                                edition,
                                formRefValues
                              )
                            : 'Save & go to'
                        }${' '}
                    ${steps[state.activeStep + 1].title.toLowerCase()}`}
                  </Button>
                )}
                {state.activeStep === steps.length - 1 && (
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={(e) => {
                      if (formRef.current.isValid) {
                        isReactivation
                          ? handleReactivateClick(e)
                          : handlePublishClick(e);
                      }
                    }}
                    disabled={state.disableButton}
                  >
                    {state.disableButton
                      ? 'Saving...'
                      : `${
                          isReactivation
                            ? 'Activate next edition'
                            : 'Submit for review'
                        }`}
                  </Button>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Paper>
      </Grid>
      <CreateEventSteps
        activeStep={state.activeStep}
        isBottomNaviation={true}
      />
      <ConfirmationModal
        title={steps[state.activeStep].confirmationText}
        content={steps[state.activeStep].confirmationBody}
        cancelText={steps[state.activeStep].confirmationButton}
        confirmText="Skip"
        open={confirmationShown}
        confirm={() => handleNext(false)}
        cancel={onConfirmationCancel}
      />
      {state.openDiscardModal && (
        <DiscardModal
          open={state.openDiscardModal}
          handleClose={() => changeBaseState('openDiscardModal', false)}
          discard={navigateHome}
        />
      )}
    </GridWrapper>
  );
};

export default CreateEventBase;
