import React from 'react';
import { Collapse, MenuItem } from '@mui/material';
import FormikTextField from '../../Common/Formik/FormikTextField';
import {
  distanceOptions,
  getDistanceLabel,
} from '../../../Utils/distanceUtils';
import Grid from '@mui/material/Unstable_Grid2';
const TicketDistanceSelector = ({ values }) => {
  return (
    <React.Fragment>
      <Grid xs={12}>
        <FormikTextField
          name="distance"
          fullWidth
          label="Distance"
          required
          select
        >
          {distanceOptions.map((d) => (
            <MenuItem key={d} value={d}>
              {getDistanceLabel(d)}
            </MenuItem>
          ))}
          <MenuItem value="custom">+ Add custom distance</MenuItem>
        </FormikTextField>
      </Grid>

      <Grid xs={12}>
        <Collapse in={values.distance === 'custom'}>
          <Grid container spacing={3}>
            {values.distance === 'custom' && (
              <>
                <Grid xs={4}>
                  <FormikTextField
                    fullWidth
                    type="number"
                    label="Distance"
                    name="customDistance"
                    required
                  />
                </Grid>
                <Grid xs={4}>
                  <FormikTextField
                    name="customDistanceUnit"
                    fullWidth
                    label="Unit"
                    select
                    required
                  >
                    <MenuItem value="km">Km</MenuItem>
                    <MenuItem value="mi">Miles</MenuItem>
                  </FormikTextField>
                </Grid>
              </>
            )}
          </Grid>
        </Collapse>
      </Grid>
    </React.Fragment>
  );
};

export default TicketDistanceSelector;
