import React from 'react';
import { Paper, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import InfoIcon from '@mui/icons-material/Info';
import { wmPalette } from '../../../../src/Theme/WorldSportsGroupTheme';
import Grid from '@mui/material/Unstable_Grid2';
const useStyles = makeStyles((theme) => ({
  defaultPaper: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: wmPalette.green[5],
    boxShadow: 'none',
  },
  defaultList: {
    marginLeft: theme.spacing(3),
    marginBottom: '0',
    padding: '0',
    columns: '3',
    minWidth: 420,
  },
}));

const defaultList = [
  'First name',
  'Last name',
  'Gender',
  'Email address',
  'Date of birth',
  'Nationality',
];

const DefaultQuestionsBox = () => {
  const classes = useStyles();
  return (
    <Paper elevation={0} className={classes.defaultPaper} p={2}>
      <Grid container spacing={0} direction="row">
        <Grid xs={1} display="flex" align="center">
          <InfoIcon color="primary" />
        </Grid>
        <Grid xs={11}>
          <Typography variant="body1" display="inline">
            These 6 questions are always included and required for all tickets.
          </Typography>
        </Grid>
        <Grid xs={1}></Grid>
        <Grid
          xs={11}
          md={7}
          display="flex"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <ul className={classes.defaultList}>
            {defaultList.map((item) => (
              <li key={item}>{item}</li>
            ))}
          </ul>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default DefaultQuestionsBox;
