import React from 'react';
import { useParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import SuccessBase from '../../Common/SuccessBase/SuccessBase';
import useQueryParams from '../../../Hooks/useQueryParams';
import BlueLink from '../../Common/BlueLink/BlueLink';
import config from '../../../config';

const getContent = (eventId) => [
  <>
    <Typography>We recommend you:</Typography>
    <ul>
      <li>
        <Typography>
          visit{' '}
          <BlueLink
            target="_blank"
            href={`${config.WM_URL}/marathon/${eventId}`}
            text={`${config.WM_URL}/marathon/${eventId}`}
          />{' '}
          to check how it looks to athletes
        </Typography>
      </li>
      <li>
        <Typography>
          ensure the <BlueLink text="payouts information" href="/app/payouts" />{' '}
          is correct before new orders come in!
        </Typography>
      </li>
      <li>
        <Typography>
          if they are any changes in the future, please don't forget to update
          the event information
        </Typography>
      </li>
    </ul>
  </>,
];

const ReactivateSuccess = () => {
  const { eventId } = useParams();
  const title = useQueryParams().get('title');
  return (
    <SuccessBase
      title={`Congratulations! You have activated a new edition ${
        title ? `for ${title}!` : ''
      } 🏅`}
      contentArray={getContent(eventId)}
    />
  );
};

export default ReactivateSuccess;
