import React from 'react';
import { Breadcrumbs as MUIBreadcrumbs, Link } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  link: {
    display: 'block',
    maxWidth: '300px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
}));

const Breadcrumbs = ({ breadcrumb }) => {
  const classes = useStyles();

  return (
    <MUIBreadcrumbs separator=">" aria-label="breadcrumb">
      {Object.entries(breadcrumb).map(([key, value]) => {
        return (
          <Link className={classes.link} key={key} href={value} color="inherit">
            {key}
          </Link>
        );
      })}
    </MUIBreadcrumbs>
  );
};

export default Breadcrumbs;
