import React, { useState } from 'react';
import useSWR from 'swr';
import { useSnackbar } from 'notistack';
import {
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  TableContainer,
  Button,
  Box,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Add } from '@mui/icons-material';
import StepTitle from '../Common/PageLayout/StepTitle';
import StepWrapper from '../Common/PageLayout/StepWrapper';
import GridWrapper from '../Common/GridWrapper/GridWrapper';
import AddTeamMemberDrawer from './AddTeamMemberDrawer';
import ConfirmationModal from '../Common/ConfirmationModal/ConfirmationModal';
import Loading from '../Loading/Loading';
import { methods, useApi } from '../../Hooks/useApi';
import Grid from '@mui/material/Unstable_Grid2';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  buttonPlacing: {
    float: 'right',
    marginBottom: theme.spacing(5),
  },
}));

const teamMembersUrl = '/ro/settings/teammembers';

const TeamMembers = () => {
  const classes = useStyles();
  const { executeApiCall } = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const [addMemberDrawer, toggleaddMemberDrawer] = useState(false);
  const [removeAccessModal, setRemoveAccessModal] = useState({
    open: false,
    id: '',
  });
  const { data: teamMembers, mutate, error } = useSWR(teamMembersUrl);

  const closeModal = () => setRemoveAccessModal({ open: false, id: '' });

  const removeAccess = async (userId) => {
    await mutate(async (teamMembers) => {
      try {
        await executeApiCall(teamMembersUrl + `/${userId}`, methods.delete);
        enqueueSnackbar('Team member access successfully removed', {
          variant: 'success',
        });
        return teamMembers.filter((m) => m.userId !== userId);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    }, false);
  };

  return (
    <>
      <GridWrapper className={classes.root}>
        <Grid xs={12}>
          <StepTitle
            title="Add team members"
            subtitle="Manage your Race Office account with your team members."
          />
        </Grid>
        <Grid xs={12}>
          {teamMembers === undefined && !error && <Loading variant="spinner" />}
          {teamMembers !== undefined && (
            <StepWrapper>
              <Box>
                <Button
                  onClick={() => toggleaddMemberDrawer(true)}
                  variant="contained"
                  className={classes.buttonPlacing}
                  startIcon={<Add />}
                >
                  Add team member
                </Button>
              </Box>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Last name</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {teamMembers
                      .sort((x, y) => y.mainOwner - x.mainOwner)
                      .map((t) => (
                        <TableRow key={t.userId}>
                          <TableCell>{t.firstName}</TableCell>
                          <TableCell>{t.lastName}</TableCell>
                          <TableCell>{t.email}</TableCell>
                          <TableCell>
                            {t.mainOwner
                              ? 'Owner'
                              : Boolean(t.lastSeenAt)
                              ? 'Member'
                              : 'Invited'}
                          </TableCell>
                          <TableCell>
                            {!t.mainOwner ? (
                              <Button
                                onClick={() =>
                                  setRemoveAccessModal({
                                    open: true,
                                    id: t.userId,
                                  })
                                }
                              >
                                Remove access
                              </Button>
                            ) : null}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </StepWrapper>
          )}
        </Grid>
      </GridWrapper>
      <AddTeamMemberDrawer
        open={addMemberDrawer}
        handleClose={() => toggleaddMemberDrawer(false)}
        teamMemberCacheKey={teamMembersUrl}
      />
      {removeAccessModal.open && (
        <ConfirmationModal
          open={removeAccessModal.open}
          cancel={() => closeModal()}
          confirm={() => {
            removeAccess(removeAccessModal.id);
            closeModal();
          }}
          title="Are you sure you want to remove access for this team member?"
          cancelText="No, keep access"
          confirmText=" Yes, remove access"
        />
      )}
    </>
  );
};

export default TeamMembers;
