import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Close } from '@mui/icons-material';
import TicketSelecBox from '../../Common/TicketSelectBox/TicketSelectBox';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const TicketSelect = ({
  availableTickets,
  question,
  handleClose,
  handleSubmit,
}) => {
  const classes = useStyles();
  const ticketIdArray = availableTickets.map((x) => x.id);
  const [selectedTickets, setSelected] = useState(
    question.ticketsApplied.length ? question.ticketsApplied : ticketIdArray
  );

  return (
    <Dialog open={Boolean(question.open)} maxWidth="sm" fullWidth>
      <DialogTitle>
        {`Registration Form for which tickets will contain “${question.label}”`}
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
          size="large"
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <TicketSelecBox
          availableTickets={availableTickets}
          selectedTickets={selectedTickets}
          setTickets={setSelected}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          disabled={!Boolean(selectedTickets.length)}
          onClick={() => handleSubmit(question.id, selectedTickets)}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TicketSelect;
