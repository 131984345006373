import React, { useState } from 'react';
import { Typography, Link, TextField, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { wmPalette } from '../../../Theme/WorldSportsGroupTheme';
import { useSnackbar } from 'notistack';
import Grid from '@mui/material/Unstable_Grid2';
import { ExternalLinks } from '../../../Utils/urls';
const useStyles = makeStyles((theme) => ({
  link: {
    color: wmPalette.blue[100],
    textDecoration: 'none',
  },
  secretContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  revealBTN: {
    height: '100%',
  },
}));

const SigningSecret = ({ secret }) => {
  const classes = useStyles();
  const [revealed, setRevealed] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleCopyReveal = () => {
    if (revealed === false) {
      setRevealed(true);
      return;
    }
    navigator.clipboard.writeText(secret);
    enqueueSnackbar('Signing secret copied to clipboard', {
      variant: 'success',
    });
  };
  return (
    <Grid container spacing={0}>
      <Grid xs={12}>
        <Typography variant="h4" gutterBottom>
          Signing secret
        </Typography>
        <Typography gutterBottom>
          Use the endpoint's signing secret as the key for determining the
          expected signature.{' '}
          <Link
            className={classes.link}
            target="_blank"
            rel="noopener"
            href={ExternalLinks.WebHookDocumentation}
          >
            Learn how it works
          </Link>
        </Typography>
        <Grid
          className={classes.secretContainer}
          container
          direction="row"
          spacing={2}
        >
          <Grid xs={6}>
            <TextField
              fullWidth
              type={revealed ? 'text' : 'password'}
              value={secret}
            />
          </Grid>
          <Grid xs={2}>
            <Button
              disabled={secret ? false : true}
              className={classes.revealBTN}
              variant="outlined"
              color="primary"
              onClick={handleCopyReveal}
              fullWidth
            >
              {revealed ? 'Copy' : 'Reveal'}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SigningSecret;
