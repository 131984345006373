const getErrorMessage = (country, numberLetterRequirement) =>
  `Please make sure the IBAN contains the IBAN code (${country}), ${numberLetterRequirement}`;

export const partialIbanRegexes = {
  AO: {
    regex: /^(AO)[0-9]{23}$/,
    error: getErrorMessage('AO', `the starting letters 'AO', 25 characters`),
  },
  BF: {
    regex: /^(BF)[0-9]{2}(BF)[0-9]{22}$/,
    error: getErrorMessage('BF', `the starting letters 'BF', 28 characters`),
  },
  BJ: {
    regex: /^(BJ)[0-9]{2}(BJ)[0-9]{22}$/,
    error: getErrorMessage('BJ', `the starting letters 'BJ', 28 characters`),
  },
  CF: {
    regex: /^(CF)[0-9]{25}$/,
    error: getErrorMessage('CF', `the starting letters 'CF', 27 characters`),
  },
  CG: {
    regex: /^(CG)[0-9]{25}$/,
    error: getErrorMessage('CG', `the starting letters 'CG', 27 characters`),
  },
  CI: {
    regex: /^(CI)[0-9]{2}(CI)[0-9]{22}$/,
    error: getErrorMessage('CI', `the starting letters 'CI', 28 characters`),
  },
  CM: {
    regex: /^(CM)[0-9]{25}$/,
    error: getErrorMessage('CM', `the starting letters 'CM', 27 characters`),
  },
  CV: {
    regex: /^(CV)[0-9]{23}$/,
    error: getErrorMessage('CV', `the starting letters 'CV', 25 characters`),
  },
  DZ: {
    regex: /^(DZ)[0-9]{24}$/,
    error: getErrorMessage('DZ', `the starting letters 'DZ', 26 characters`),
  },
  GA: {
    regex: /^(GA)[0-9]{25}$/,
    error: getErrorMessage('GA', `the starting letters 'GA', 27 characters`),
  },
  GQ: {
    regex: /^(GQ)[0-9]{25}$/,
    error: getErrorMessage('GQ', `the starting letters 'GQ', 27 characters`),
  },
  GW: {
    regex: /^(GW)[0-9]{2}(GW)[0-9]{19}$/,
    error: getErrorMessage('GW', `the starting letters 'GW', 25 characters`),
  },
  HN: {
    regex: /^(HN)[0-9]{2}(PISA)[0-9]{20}$/,
    error: getErrorMessage('HN', `the starting letters 'HN', 28 characters`),
  },
  IR: {
    regex: /^(IR)[0-9]{24}$/,
    error: getErrorMessage('IR', `the starting letters 'IR', 26 characters`),
  },
  KM: {
    regex: /^(KM)[0-9]{25}$/,
    error: getErrorMessage('KM', `the starting letters 'KM', 27 characters`),
  },
  MA: {
    regex: /^(MA)[0-9]{26}$/,
    error: getErrorMessage('MA', `the starting letters 'MA', 28 characters`),
  },
  MG: {
    regex: /^(MG)[0-9]{25}$/,
    error: getErrorMessage('MG', `the starting letters 'MG', 27 characters`),
  },
  ML: {
    regex: /^(ML)[0-9]{2}(ML)[0-9]{22}$/,
    error: getErrorMessage('ML', `the starting letters 'ML', 28 characters`),
  },
  MZ: {
    regex: /^(MZ)[0-9]{23}$/,
    error: getErrorMessage('MZ', `the starting letters 'MZ', 25 characters`),
  },
  NE: {
    regex: /^(NE)[0-9]{2}(NE)[0-9]{22}$/,
    error: getErrorMessage('NE', `the starting letters 'NE', 28 characters`),
  },
  SN: {
    regex: /^(SN)[0-9]{2}(SN)[0-9]{22}$/,
    error: getErrorMessage('SN', `the starting letters 'SN', 28 characters`),
  },
  TD: {
    regex: /^(TD)[0-9]{25}$/,
    error: getErrorMessage('TD', `the starting letters 'TD', 27 characters`),
  },
  TG: {
    regex: /^(TG)[0-9]{2}(TG)[0-9]{22}$/,
    error: getErrorMessage('TG', `the starting letters 'TG', 28 characters`),
  },
};
