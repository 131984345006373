const getErrorMessage = (country, numberLetterRequirement) =>
  `Please make sure the VAT number contains the country code (${country}), ${numberLetterRequirement} and is registered for cross-border transactions`;

export const euVatRegexes = {
  AT: {
    regex: /^(AT)?U[0-9]{8}$/,
    error: getErrorMessage('AT', `the letter 'U', 8 numbers`),
  },
  BE: {
    regex: /^(BE)?0[0-9]{9}$/,
    error: getErrorMessage('BE', `the number '0', 9 numbers`),
  },
  BG: {
    regex: /^(BG)?[0-9]{9,10}$/,
    error: getErrorMessage('BG', `9 or 10 numbers`),
  },
  HR: { regex: /^(HR)?[0-9]{11}$/, error: getErrorMessage('HR', `11 numbers`) },
  CY: {
    regex: /^(CY)?[0-9]{8}L$/,
    error: getErrorMessage('CY', `8 numbers, followed by the letter 'L'`),
  },
  CZ: {
    regex: /^(CZ)?[0-9]{8,10}$/,
    error: getErrorMessage('CZ', `8-10 numbers`),
  },
  DE: { regex: /^(DE)?[0-9]{9}$/, error: getErrorMessage('DE', `9 numbers`) },
  DK: { regex: /^(DK)?[0-9]{8}$/, error: getErrorMessage('DK', `8 numbers`) },
  EE: { regex: /^(EE)?[0-9]{9}$/, error: getErrorMessage('EE', `9 numbers`) },
  ES: {
    regex: /^(ES)?[0-9A-Z][0-9]{7}[0-9A-Z]$/,
    error: getErrorMessage(
      'ES',
      `9 characters, including 1-2 alphabetical characters`
    ),
  },
  FI: { regex: /^(FI)?[0-9]{8}$/, error: getErrorMessage('FI', `8 numbers`) },
  FR: {
    regex: /^(FR)?[0-9A-Z]{2}[0-9]{9}$/,
    error: getErrorMessage(
      'FR',
      `11 characters, including the possibility of alphabetical as first two`
    ),
  },
  GR: {
    regex: /^(EL|GR)?[0-9]{9}$/,
    error: getErrorMessage('EL or GR', `9 numbers`),
  },
  HU: { regex: /^(HU)?[0-9]{8}$/, error: getErrorMessage('HU', `8 numbers`) },
  IE: {
    regex: /^(IE)?[0-9A-Z\*\+]{7}[A-Z]{1,2}$/,
    error: getErrorMessage(
      'IE',
      `8 characters including 1 or 2 alphabetical characters`
    ),
  },
  IT: { regex: /^(IT)?[0-9]{11}$/, error: getErrorMessage('IT', `11 numbers`) },
  LT: {
    regex: /^(LT)?([0-9]{9}|[0-9]{12})$/,
    error: getErrorMessage('LT', `9 or 12 numbers`),
  },
  LU: { regex: /^(LU)?[0-9]{8}$/, error: getErrorMessage('LU', `8 numbers`) },
  LV: { regex: /^(LV)?[0-9]{11}$/, error: getErrorMessage('LV', `11 numbers`) },
  MT: { regex: /^(MT)?[0-9]{8}$/, error: getErrorMessage('MT', `8 numbers`) },
  NL: {
    regex: /^(NL)?[0-9]{9}B[0-9]{2}$/,
    error: getErrorMessage(
      'NL',
      `9 numbers, followed by the letter 'B', 2 more numbers`
    ),
  },
  PL: { regex: /^(PL)?[0-9]{10}$/, error: getErrorMessage('PL', `10 numbers`) },
  PT: { regex: /^(PT)?[0-9]{9}$/, error: getErrorMessage('PT', `9 numbers`) },
  RO: {
    regex: /^(RO)?[0-9]{2,10}$/,
    error: getErrorMessage('RO', `10 numbers`),
  },
  SK: { regex: /^(SK)?[0-9]{10}$/, error: getErrorMessage('SK', `10 numbers`) },
  SI: { regex: /^(SI)?[0-9]{8}$/, error: getErrorMessage('SI', `8 numbers`) },
  SE: { regex: /^(SE)?[0-9]{12}$/, error: getErrorMessage('SI', `12 numbers`) },
};
