import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Drawer,
  Toolbar,
  List,
  ListItem,
  ListItemText,
  Typography,
  Hidden,
  Select,
  MenuItem,
  Box,
  Link,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Skeleton from '@mui/material/Skeleton';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { wmPalette } from '../../../Theme/WorldSportsGroupTheme';
import { getWMUrl } from '../../../Utils/wmUtils';
import EventNavItems from './EventNavItems';
import StatusPill from '../../Common/StatusPill/StatusPill';
import { Context } from '../../../Stores/EventInfoStore';
import ReactivateBanner from '../../ManageEvents/ReactivateEvent/ReactivateBanner';
import { publish } from '../../../Consts/eventStatus';

const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
    zIndex: 1100,
  },
  listItemEventName: {
    paddingTop: theme.spacing(2),
  },
  box: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  linkIcon: {
    color: wmPalette.black[50],
  },
}));

const EventNavSidebar = () => {
  const classes = useStyles();
  const [eventInfo] = useContext(Context);
  const navigate = useNavigate();
  const changeEdition = (event) =>
    navigate(
      `/app/manage-event/${eventInfo.event.eventId}/${event.target.value}`
    );
  const needsReactivate =
    eventInfo.isActiveEdition &&
    new Date(eventInfo.basicInfo.eventEndDate) < new Date();
  return (
    <nav className={classes.drawer}>
      <Hidden mdDown implementation="css">
        <Drawer classes={{ paper: classes.drawerPaper }} variant="permanent">
          <Toolbar />
          <List className={classes.listItemEventName}>
            {eventInfo?.underReview && (
              <ListItem>
                <StatusPill text="UNDER REVIEW" colour="orange" />
              </ListItem>
            )}
            <ListItem>
              <ListItemText>
                <Box className={classes.box}>
                  <Typography variant="h6">
                    {!eventInfo?.basicInfo?.eventTitle && <Skeleton />}
                    {eventInfo.basicInfo.eventTitle}
                  </Typography>
                  {eventInfo?.eventPageStatus === publish && (
                    <Link
                      target="_blank"
                      rel="noopener"
                      href={getWMUrl(eventInfo.event.eventId)}
                    >
                      <OpenInNewIcon className={classes.linkIcon} />
                    </Link>
                  )}
                </Box>
              </ListItemText>
            </ListItem>
            {Boolean(eventInfo?.allEditions?.length > 0) && (
              <ListItem>
                <Select
                  onChange={changeEdition}
                  value={
                    eventInfo.allEditions.find(
                      (x) => eventInfo.event.editionId === x.id
                    ).id
                  }
                >
                  {eventInfo.allEditions
                    .filter((x) => !x.inProgress)
                    .map((x) => (
                      <MenuItem key={x.id} value={x.id}>
                        {new Date(x.date).toDateString()}
                      </MenuItem>
                    ))}
                </Select>
              </ListItem>
            )}
            {needsReactivate && (
              <ListItem>
                <ReactivateBanner isNav={true} />
              </ListItem>
            )}
            <EventNavItems eventInfo={eventInfo} />
          </List>
        </Drawer>
      </Hidden>
    </nav>
  );
};

export default EventNavSidebar;
