import React, { useContext, useEffect, useMemo } from 'react';
import useSWR from 'swr';
import StepTitle from '../../Common/PageLayout/StepTitle';
import { Card, CardContent } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import OfferedServicesForm from './OfferedServicesForm';
import { Context } from '../../../Stores/EventInfoStore';
import Grid from '@mui/material/Unstable_Grid2';
const emptyService = {
  name: null,
  details: null,
  detailsAllowed: true,
  serviceCategory: 0,
};

const useStyles = makeStyles((theme) => ({
  offeredCard: {
    overflow: 'visible',
    marginBottom: theme.spacing(7),
  },
}));

const WhatsOffered = ({
  formRef,
  formRefNotify,
  disableButton,
  onSaveSuccess,
  isManage,
}) => {
  const classes = useStyles();
  const [eventInfo] = useContext(Context);
  const { data: presetData } = useSWR(`/offered-services/preset`);
  const { result: presetOfferedServices = [] } = presetData ?? {};
  const customServices =
    eventInfo.offeredServices?.filter((x) => !x.presetId) ?? [];
  const customAdditionalServices = new Array(6).fill({}).map((x, i) => {
    const customForCurrent = customServices[i];
    return {
      id: i,
      ...emptyService,
      ...(customForCurrent || {}),
      checked: !!customForCurrent,
    };
  });

  useEffect(() => {
    disableButton && disableButton(false);
  }, []);

  const data = useMemo(() => {
    const grouped = Object.entries(
      presetOfferedServices
        ?.concat(customAdditionalServices)
        .reduce((acc, x, i) => {
          const element = { ...x, totalIdx: i };
          if (eventInfo.offeredServices?.length) {
            const foundSvc =
              !!element.presetId && // we map custom ones above
              eventInfo.offeredServices.find(
                (x) => x.presetId === element.presetId
              );

            if (foundSvc) {
              element.details = foundSvc.details ?? '';
              element.checked = true;
            } else {
              element.checked = element.checked ?? false;
              element.details = element.details ?? '';
            }
            if (!element.name) {
              element.name = '';
            }
          } else {
            element.checked = false;
          }

          if (acc[element.serviceCategory]) {
            acc[element.serviceCategory].push(element);
          } else {
            acc[element.serviceCategory] = [element];
          }
          return acc;
        }, {})
    );

    const _0 = grouped.find((x) => x[0] === '0');
    if (_0) {
      const resul = [...grouped.filter((x) => x !== _0), _0];
      return resul;
    }
    return grouped;
  }, [presetOfferedServices]);

  return (
    <>
      <StepTitle
        title="What’s included in entry (optional)"
        subtitle="Show what services, facilities, or items are included in the entry fee. This step can be skipped and added later.
      If any service/product should be available for purchase in the checkout process, please include it as an add-on in the “Add-ons” step."
      />

      <Card className={classes.offeredCard}>
        <CardContent>
          <Grid container justifyContent="center" spacing={0}>
            <Grid xs={12} sm={8} container spacing={3}>
              {!!presetOfferedServices.length && (
                <OfferedServicesForm
                  data={data}
                  disableButton={disableButton}
                  onSaveSuccess={onSaveSuccess}
                  formRef={formRef}
                  formRefNotify={formRefNotify}
                  eventInfo={eventInfo}
                  isManage={isManage}
                />
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default WhatsOffered;
