import React, { createContext, useReducer } from 'react';
import { cloneDeep } from 'lodash';

// Defines actions for the reducer
const actionTypes = {
  basicInfo_set: 'SET_BASICINFO',
  courseDetails_set: 'SET_COURSEDETAILS',

  tickets_add: 'ADD_TICKET',
  tickets_remove: 'REMOVE_TICKET',
  tickets_duplicate: 'DUPLICATE_TICKET',
  tickets_set: 'SET_TICKET',
  tickets_setEditIndex: 'SET_TICKET_INDEX',
  ticket_priceStep_add: 'ADD_TICKET_PRICESTEP',
  ticket_priceStep_remove: 'REMOVE_TICKET_PRICESTEP',
  ticket_bulk_update: 'BULK_UPDATE_TICKETS',

  addons_add: 'ADD_ADDON',
  addons_remove: 'REMOVE_ADDON',
  addons_update: 'UPDATE_ADDON',
  addons_activate: 'ACTIVATE_ADDON',
  addons_deactivate: 'DEACTIVATE_ADDON',
  addons_setEditIndex: 'SET_ADDON_INDEX',

  customQuestions_add: 'ADD_CUSTOMQUESTION',
  customQuestions_update: 'UPDATE_CUSTOMQUESTION',
  customQuestions_delete: 'DELETE_CUSTOMQUESTION',

  waiver_set: 'SET_WAIVER',
  additionalInfo_set: 'SET_ADDITIONALINFO',
  offeredServices_set: 'SET_OFFEREDSERVICES',
  event_set: 'SET_EVENT',
  full_info_set: 'SET_FULLINFO',
  faqs_set: 'SET_FAQS',
};

// Defines handlers for the reducer,
// this is to avoid lenghty switch statements and to keep code clean(ish).
const handlers = {
  [actionTypes.full_info_set]: (state, action) => {
    const edition = action.payload;
    const { eventId, editionId } = edition;
    return {
      ticketIndex: -1,
      event: {
        eventId: eventId,
        editionId: editionId,
      },
      ...edition,
    };
  },
  //Set event id
  [actionTypes.event_set]: (state, action) => ({
    event: action.payload,
  }),
  // Basic info actions
  [actionTypes.basicInfo_set]: (state, action) => {
    const { basicInfo } = state;
    const { name, value } = action.payload;
    if (name && value) {
      basicInfo[name] = value;
      return { basicInfo };
    } else {
      return { basicInfo: { ...basicInfo, ...action.payload } };
    }
  },

  // Course details
  [actionTypes.courseDetails_set]: (state, action) => {
    return {
      courseDetails: action.payload,
    };
  },

  [actionTypes.offeredServices_set]: (state, action) => {
    const addedServices = action.payload;
    return { offeredServices: addedServices };
  },
  // Tickets actions
  [actionTypes.tickets_add]: (state, action) => {
    return {
      tickets: [...state.tickets, action.payload],
      ticketIndex: state.tickets.length,
    };
  },
  [actionTypes.tickets_remove]: (state, action) => {
    const { index } = action.payload;
    const tickets = cloneDeep(state.tickets);
    tickets.splice(index, 1);
    return { tickets };
  },
  [actionTypes.tickets_duplicate]: (state, action) => {
    const { index } = action.payload;
    const source = cloneDeep(state.tickets[index]);
    source.name = 'Copy of ' + source.name;
    source.hasSaved = false;
    return {
      ticketIndex: state.tickets.length,
      tickets: [...state.tickets, source],
    };
  },
  [actionTypes.tickets_set]: (state, action) => {
    const { ticketIndex } = action.payload;
    const { ticket } = action.payload;
    const newState = cloneDeep(state.tickets);
    newState.splice(ticketIndex, 1, { ...ticket, hasSaved: true });
    return { tickets: newState };
  },
  [actionTypes.tickets_setEditIndex]: (state, action) => {
    const { index } = action.payload;
    return { ticketIndex: index };
  },
  [actionTypes.ticket_priceStep_add]: (state, action) => {
    const { ticketIndex } = action.payload;
    const newTickets = cloneDeep(state.tickets);
    const currentTicket = newTickets[ticketIndex];

    const minDate = Math.max(
      state.basicInfo.salesStartDate,
      state.tickets[ticketIndex - 1]?.minDate
    );

    currentTicket.priceSteps.push({ price: '', until: '', minDate });

    newTickets.splice(ticketIndex, 1, currentTicket);

    alert(JSON.stringify(newTickets, null, 2));

    return { tickets: newTickets };
  },
  [actionTypes.ticket_priceStep_remove]: (state, action) => {
    const { ticketIndex, stepIndex } = action.payload;
    const newTickets = cloneDeep(state.tickets);
    const currentTicket = newTickets[ticketIndex];

    currentTicket.priceSteps.splice(stepIndex, 1);

    return { tickets: newTickets };
  },
  [actionTypes.ticket_bulk_update]: (state, action) => {
    const updatedTickets = [];
    state.tickets.forEach((t) => {
      const result = action.payload.find((t2) => t2.ticketId === t.id);
      updatedTickets.push({
        ...t,
        localStartDate: result.localStartDate,
        localStartTime: result.localStartTime,
      });
    });
    return { tickets: updatedTickets };
  },
  [actionTypes.tickets_toggleactive]: (state, action) => {
    const tickets = cloneDeep(state.tickets);
    const currentTicket = tickets[action.payload];
    tickets.splice(action.payload, 1, {
      ...currentTicket,
      active: !currentTicket.active,
    });
    return { tickets };
  },
  //Add-on actions
  [actionTypes.addons_add]: (state, action) => {
    let firstInactive = state.addOns.findIndex((x) => !x.active);
    const insertIndex = firstInactive < 0 ? state.addOns.length : firstInactive;
    const addons = cloneDeep(state.addOns);
    addons.splice(insertIndex, 0, action.payload);
    return {
      addOns: [...addons],
    };
  },
  [actionTypes.addons_update]: (state, action) => {
    let addons = [...state.addOns];
    let i = addons.findIndex((a) => a.id === action.payload.id);
    addons[i] = { ...action.payload };
    return { addOns: addons };
  },
  [actionTypes.addons_remove]: (state, action) => ({
    addOns: state.addOns.filter((a) => a.id !== action.payload.id),
  }),
  [actionTypes.addons_activate]: (state, action) => {
    let addons = [...state.addOns];
    const i = addons.findIndex((a) => a.id === action.payload.id);
    addons[i] = { ...addons[i], active: true };
    return { addOns: addons };
  },
  [actionTypes.addons_deactivate]: (state, action) => {
    let addons = [...state.addOns];
    const i = addons.findIndex((a) => a.id === action.payload.id);
    addons[i] = { ...addons[i], active: false };
    return { addOns: addons };
  },
  // Registration form actions
  [actionTypes.customQuestions_add]: (state, action) => ({
    customQuestions: [...state.customQuestions, action.payload],
  }),
  [actionTypes.customQuestions_update]: (state, action) => {
    let questions = cloneDeep(state.customQuestions);
    let i = questions.findIndex((q) => q.id === action.payload.id);
    questions[i] = action.payload;
    return { customQuestions: questions };
  },

  [actionTypes.customQuestions_delete]: (state, action) => ({
    customQuestions: state.customQuestions.filter(
      (q) => q.id !== action.payload.id
    ),
  }),

  [actionTypes.waiver_set]: (state, action) => ({
    waiver: action.payload,
  }),

  // Sales info actions
  [actionTypes.additionalInfo_set]: (state, action) => {
    return { additionalInfo: action.payload };
  },

  //FAQs actions
  [actionTypes.faqs_set]: (state, action) => {
    return { faqs: action.payload };
  },
};

const EventInfoReducer = (state, action) => {
  const handler = handlers[action.type];

  // If no handler is found, throw an error
  if (!handler) {
    throw new Error(`Unknown action type: ${action.type}`);
  }
  const nextState = handler(state, action);

  // Merge old state with new state, this is to keep the action handlers clean from merging state
  return { ...state, ...nextState };
};

// Used to wrap context to be able to re-use
const EventInfoStore = ({ initialState, children }) => {
  const [state, dispatch] = useReducer(EventInfoReducer, initialState);
  return (
    <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>
  );
};
export const Context = createContext();
export { actionTypes };
export default EventInfoStore;
