import React from 'react';
import { Typography, FormControlLabel, Checkbox, Box } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const MultipleChoice = ({ setValues, label, values, currentValues }) => {
  const classes = useStyles();

  const toggleOption = (v) => {
    let array = [...currentValues] ?? [];
    if (array.length && array.indexOf(v) !== -1) {
      array.splice(array.indexOf(v), 1);
    } else {
      array.push(v);
    }
    setValues(array);
  };

  return (
    <Box className={classes.container}>
      <Typography>{label}</Typography>
      {values.map((v, i) => (
        <FormControlLabel
          key={`${v}-${i}`}
          control={
            <Checkbox
              value={v}
              onChange={() => toggleOption(v)}
              checked={currentValues.some((x) => x === v)}
              color="primary"
            />
          }
          label={<Typography style={{ fontSize: '0.875rem' }}>{v}</Typography>}
        />
      ))}
    </Box>
  );
};

export default MultipleChoice;
