import React from 'react';
import { FormLabel } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import TicketSelectBox from '../../../Common/TicketSelectBox/TicketSelectBox';
import Grid from '@mui/material/Unstable_Grid2';
const useStyles = makeStyles((theme) => ({
  radioGroupLabel: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(7),
  },
}));

const AddOnTicketsSelector = ({ availableTickets = [], formik, required }) => {
  const classes = useStyles();
  const {
    setFieldValue,
    values: { availableToTickets = [] },
  } = formik;

  return (
    <Grid xs={12}>
      <FormLabel component="legend" className={classes.radioGroupLabel}>
        This add-on is available for*:
      </FormLabel>
      <TicketSelectBox
        availableTickets={availableTickets}
        selectedTickets={availableToTickets}
        setTickets={(t) => setFieldValue('availableToTickets', t)}
        error={
          formik.touched.availableToTickets &&
          formik.errors.availableToTickets &&
          !Array.isArray(formik.errors.availableToTickets)
            ? formik.errors.availableToTickets
            : null
        }
        disableTeamTickets={true}
      />
    </Grid>
  );
};

export default AddOnTicketsSelector;
