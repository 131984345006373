import useSWR from 'swr';
import _ from 'lodash';
import * as ticketStatuses from '../Consts/ticketStatus';
import { extra } from '../Consts/productType';
import { awaitingInformationFromParticipant } from '../Consts/orderStatus';

export const orderUrl = (eventId, editionId) =>
  `/ro/events/${eventId}/races/${editionId}/orders?${new URLSearchParams({
    showAll: true,
  }).toString()}`;

const invitationPending = {
  status: ticketStatuses.invitationPending,
  title: 'Invitation pending',
};
const changedTicket = { status: ticketStatuses.changed, title: 'Changed' };

export const formatData = (data, orders, participants, count) => {
  orders = data.orders.map((o) => ({
    ...o,
    firstName: o.participants[0].firstName,
    lastName: o.participants[0].lastName,
    email: o.participants[0].email,
    ticketStatus:
      o.status === awaitingInformationFromParticipant
        ? invitationPending
        : o.participants.some((p) => p.ticket?.changedTicket)
        ? changedTicket
        : null,
  }));
  participants = data.orders
    .map((o) =>
      o.participants.map((p) => ({
        ...o,
        ...p.ticket,
        ...p,
        addOns: p.orderLines.filter((l) => l.productType === extra),
        ticketStatus:
          o.status === awaitingInformationFromParticipant
            ? invitationPending
            : p.ticket?.changedTicket
            ? changedTicket
            : null,
        id: _.uniqueId(),
      }))
    )
    .flatMap((o) => o);
  count = data.count ?? 0;

  return {
    orders,
    participants,
    count,
  };
};

const useOrders = ({ eventId, editionId, hideError = true }) => {
  const { data, error } = useSWR([orderUrl(eventId, editionId), hideError]);

  let orders = [];
  let participants = [];
  let count = 0;
  if (data) {
    let formattedData = formatData(data, orders, participants, count);
    orders = formattedData.orders;
    participants = formattedData.participants;
    count = formattedData.count;
  }

  return {
    orders,
    participants,
    count,
    isLoading: !error && !data,
    isError: error,
  };
};

export default useOrders;
