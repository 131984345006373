import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

const ConfirmationModal = ({
  open,
  cancel,
  confirm,
  handleAdditional,
  additionalText,
  title,
  content,
  cancelText,
  confirmText,
  dialogSize = 'sm',
  cancelColor = 'primary',
  additionalColor = 'primary',
  confirmColor = 'theme.palette.',
}) => {
  return (
    <Dialog open={open} onClose={cancel} fullWidth={true} maxWidth={dialogSize}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {cancelText && (
          <Button onClick={cancel} color={cancelColor}>
            {cancelText}
          </Button>
        )}
        {additionalText && (
          <Button onClick={handleAdditional} color={additionalColor}>
            {additionalText}
          </Button>
        )}
        {confirmText && (
          <Button onClick={confirm} color="secondary">
            {confirmText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationModal;
