import React, { useEffect } from 'react';
import { Add, Close, Info } from '@mui/icons-material';
import {
  Button,
  IconButton,
  InputAdornment,
  FormHelperText,
  Tooltip,
} from '@mui/material';
import { FieldArray, FormikProvider, getIn } from 'formik';
import withStyles from '@mui/styles/withStyles';
import { add, format } from 'date-fns';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import FormikTextField from '../../Common/Formik/FormikTextField';
import Grid from '@mui/material/Unstable_Grid2';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import { useCallback } from 'react';
import { wmPalette } from '../../../Theme/WorldSportsGroupTheme';
import { onKeyDown } from '../../../Utils/dateUtils';
import StopNumberInputScrolling from '../../../Utils/StopNumberInputScrolling';

const helperTexts = {
  vat: 'Specify the value-added tax (VAT) for this ticket',
};

const PriceSteps = ({
  formik,
  eventSalesEndDate,
  validatePrices,
  eventSalesStartDate,
  classes,
  currency,
  isAdding,
  setValidateMultiple,
}) => {
  const getDatePickerMinDate = (step) => {
    const salesStartDate = new Date(eventSalesStartDate);
    if (step === 0) {
      return new Date().minDate;
    } else {
      return getMinDate(step);
    }
  };

  const IsSalesStartDateMismatch = useCallback((activationDate) => {
    let firstPriceStepDate = new Date(activationDate).getTime();
    let salesStartDate = new Date(eventSalesStartDate).getTime();
    if (firstPriceStepDate !== salesStartDate) {
      return true;
    } else {
      return false;
    }
  }, []);

  const getMinDate = (step) => {
    const dayAfterLastPriceStepDate = add(
      new Date(formik.values.priceSteps[step - 1].activationAt),
      { days: 1 }
    );

    return dayAfterLastPriceStepDate;
  };

  const addPricestepDisabled = () => {
    let length = formik.values.priceSteps.length;
    return (
      length > 1 &&
      (!Boolean(formik.values.priceSteps[length - 1].activationAt) ||
        !Boolean(formik.values.priceSteps[length - 1].price))
    );
  };

  const getFieldError = useCallback(
    (formik, field = undefined, when = undefined) => {
      if (field) {
        const customFieldValue = getIn(formik.values, field);
        if (customFieldValue && when && customFieldValue === when) {
          return {
            hasError: true,
            message: 'Field required',
          };
        }
      }
      return undefined;
    },
    []
  );

  /**
   * @param {{field:string,is:boolean}|undefined} when
   */
  const getFieldHasError = (formik, index, field, when) => {
    const hasError = Boolean(
      formik.touched?.priceSteps?.[index]?.[field] &&
        formik.errors?.priceSteps?.[index]?.[field]
    );
    const customFieldError = getFieldError(formik, when?.field, when?.is);
    return customFieldError?.hasError || hasError;
  };

  /**
   * @param {{field:string,is:boolean}|undefined} when
   */
  const getHelperText = (formik, index, field, when) => {
    const customFieldError = getFieldError(formik, when?.field, when?.is);
    if (customFieldError) return customFieldError.message;
    return getFieldHasError(formik, index, field)
      ? formik.errors.priceSteps[index][field]
      : '';
  };

  const onPriceStepAddClick = (helpers) => {
    let steps = formik.values.priceSteps;
    let newDate = new Date(steps[steps.length - 1].activationAt);
    newDate.setDate(newDate.getDate() + 1);
    helpers.push({ price: '', activationAt: newDate.toDateString() });
  };

  useEffect(() => {
    const { setValues, values } = formik;

    if (!isAdding && values.priceSteps.length > 0) {
      if (values.priceType === 'multiple') {
        //make sure initial price step is sale start date to avoid error
        //where initial date is auto set when reactivating or ticket start sale date is changed
        const priceSteps = [...values.priceSteps];
        setValues({
          ...values,
          priceSteps,
        });
      }
      return;
    }

    if (values.priceType === 'multiple') {
      setValues({
        ...values,
        priceSteps: [
          {
            price: undefined,
            activationAt: new Date(eventSalesStartDate),
          },
        ],
      });
    } else {
      setValues({ ...values, priceSteps: [] });
    }
  }, [formik.values.priceType]);

  const PriceMismatchToolTip = withStyles((theme) => ({
    tooltip: {
      ...wmPalette.MuiTooltip,
      padding: '16px 8px 8px 14px',
      lineHeight: '24px',
    },
  }))(Tooltip);

  StopNumberInputScrolling([formik.values.priceType]);

  return (
    <React.Fragment>
      <Grid xs={12}>
        <FormControl>
          <RadioGroup
            value={formik.values.priceType ?? 'single'}
            onChange={formik.handleChange}
            name="priceType"
            onBlur={formik.handleBlur}
          >
            <FormLabel component="legend" className={classes.radioGroupLabel}>
              Ticket price
            </FormLabel>
            <FormControlLabel
              value="single"
              control={<Radio />}
              label="Single, fixed price"
            />
            <FormControlLabel
              value="multiple"
              control={<Radio />}
              label="Multiple prices (price steps)"
              onClick={() => setValidateMultiple(false)}
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      {formik.values.priceType === 'single' && (
        <Grid xs={12}>
          <Grid container spacing={2}>
            <Grid xs={6}>
              <FormikTextField
                label="Ticket price"
                type="number"
                placeholder="100"
                required
                fullWidth
                className={classes.numberInput}
                onBlur={formik.handleBlur}
                onInput={() => {
                  setValidateMultiple(true);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment>{currency?.toUpperCase()}</InputAdornment>
                  ),
                }}
                name="price"
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      {formik.values.priceType === 'multiple' && (
        <FormikProvider value={formik}>
          <FieldArray
            name="priceSteps"
            render={(helpers) => (
              <Grid sm={12}>
                {formik.values.priceSteps.map((step, index) => (
                  <Grid
                    key={index}
                    container
                    spacing={2}
                    style={{ marginBottom: 8 }}
                  >
                    <Grid sm={6}>
                      <FormikTextField
                        label="Ticket price"
                        type="number"
                        name={`priceSteps[${index}].price`}
                        placeholder="100"
                        fullWidth
                        required
                        className={classes.numberInput}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment>
                              {currency?.toUpperCase()}
                            </InputAdornment>
                          ),
                        }}
                        helperText={getHelperText(formik, index, 'price')}
                      />
                    </Grid>
                    <Grid sm={5}>
                      <DatePicker
                        name={`priceSteps[${index}].activationAt`}
                        label="Price activation date"
                        value={step.isUpdateNeeded ? null : step.activationAt}
                        inputVariant="outlined"
                        variant="inline"
                        format="PPP"
                        inputFormat="dd MMM yyyy"
                        autoOk
                        fullWidth
                        required
                        InputLabelProps={{ shrink: true }}
                        minDate={getDatePickerMinDate(index)}
                        maxDate={eventSalesEndDate}
                        helperText={getHelperText(
                          formik,
                          index,
                          'activationAt',
                          {
                            field: `priceSteps[${index}].isUpdateNeeded`,
                            is: true,
                          }
                        )}
                        error={getFieldHasError(formik, index, 'activationAt', {
                          field: `priceSteps[${index}].isUpdateNeeded`,
                          is: true,
                        })}
                        onBlur={formik.handleBlur}
                        onChange={(value) => {
                          formik.setFieldValue(
                            `priceSteps[${index}].activationAt`,
                            value.toDateString()
                          );
                          formik.setFieldValue(
                            `priceSteps[${index}].isUpdateNeeded`,
                            false
                          );
                          if (index === 0) validatePrices(value, index);
                        }}
                        renderInput={(props) => (
                          <TextField
                            fullWidth
                            onKeyDown={onKeyDown}
                            required
                            {...props}
                            helperText={getHelperText(
                              formik,
                              index,
                              'activationAt',
                              {
                                field: `priceSteps[${index}].isUpdateNeeded`,
                                is: true,
                              }
                            )}
                            error={getFieldHasError(
                              formik,
                              index,
                              'activationAt',
                              {
                                field: `priceSteps[${index}].isUpdateNeeded`,
                                is: true,
                              }
                            )}
                          />
                        )}
                      />
                    </Grid>
                    <Grid sm={1} alignContent="center">
                      {index === 0 &&
                      IsSalesStartDateMismatch(step.activationAt) ? (
                        <PriceMismatchToolTip
                          arrow
                          title={
                            <React.Fragment>
                              <div style={{ color: wmPalette.black[100] }}>
                                <b>Date is not matching</b>
                              </div>
                              <div style={{ color: wmPalette.black[50] }}>
                                The first day of sales for this event is set to{' '}
                                {format(new Date(eventSalesStartDate), 'PPP')}.
                              </div>
                            </React.Fragment>
                          }
                          placement="top-start"
                        >
                          <IconButton size="large">
                            <Info />
                          </IconButton>
                        </PriceMismatchToolTip>
                      ) : (
                        index !== 0 && (
                          <IconButton
                            color="primary"
                            onClick={() => {
                              setValidateMultiple(true);
                              helpers.remove(index);
                            }}
                            size="large"
                          >
                            <Close />
                          </IconButton>
                        )
                      )}
                    </Grid>
                  </Grid>
                ))}
                {formik.touched.priceSteps &&
                  formik.errors.priceSteps &&
                  typeof formik.errors.priceSteps === 'string' && (
                    <FormHelperText error={true}>
                      {formik.errors.priceSteps}
                    </FormHelperText>
                  )}
                <Button
                  startIcon={<Add />}
                  variant="text"
                  disabled={addPricestepDisabled()}
                  onClick={(e) => {
                    onPriceStepAddClick(helpers);
                    setTimeout(() => setValidateMultiple(true), 1);
                  }}
                >
                  Add Price Step
                </Button>
              </Grid>
            )}
          />

          <Grid xs={12}></Grid>
        </FormikProvider>
      )}

      <Grid xs={12}>
        <Grid container spacing={2}>
          <Grid xs={6}>
            <TextField
              label="VAT"
              fullWidth
              InputProps={{
                endAdornment: <InputAdornment>%</InputAdornment>,
              }}
              name="vat"
              value={formik.values.vat === 0 ? '' : formik.values.vat}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={formik.touched.vat && Boolean(formik.errors.vat)}
              helperText={
                (formik.touched.vat && formik.errors.vat) || helperTexts.vat
              }
            />
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default PriceSteps;
