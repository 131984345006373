import { Dialog } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { WelcomeOnboarding } from './WelcomeOnboarding';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
}));

const OnboardingModal = ({ open, onEnd }) => {
  //here you can add more modals and its logic
  const classes = useStyles();
  return (
    <Dialog open={open} PaperProps={{ className: classes.root }}>
      <WelcomeOnboarding onEnd={onEnd} />
    </Dialog>
  );
};

export { OnboardingModal };
