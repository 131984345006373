import React, { useContext } from 'react';
import { Formik } from 'formik';
import {
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  Typography,
  InputAdornment,
  Tooltip,
  TextField,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import makeStyles from '@mui/styles/makeStyles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useSnackbar } from 'notistack';
import { mutate } from 'swr';
import { isEmpty } from 'lodash';
import DrawerBase from '../../Common/Drawer/DrawerBase';
import TicketSelectBox from '../../Common/TicketSelectBox/TicketSelectBox';
import FormikTextField from '../../Common/Formik/FormikTextField';
import WrappedDivider from '../../Common/WrappedDivider/WrappedDivider';
import FocusFormikError from '../../Common/Formik/FocusFormikError';
import { methods, useApi } from '../../../Hooks/useApi';
import { Context } from '../../../Stores/EventInfoStore';
import { getValidationSchema } from './couponValidationSchema';
import * as discountTypes from './discountType';
import { onKeyDown } from '../../../Utils/dateUtils';

const useStyles = makeStyles((theme) => ({
  paddingBottom: {
    paddingBottom: `${theme.spacing(1.5)} !important`,
  },
}));

const CouponDrawer = ({ open, handleClose, couponUrl, currency, coupon }) => {
  const classes = useStyles();
  const { executeApiCall } = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const [eventInfo] = useContext(Context);
  const { tickets } = eventInfo;
  const ticketIdArray = tickets?.map((x) => x.id) ?? [];
  const existingTickets = coupon.discountedTickets?.filter((x) =>
    ticketIdArray.some((y) => y === x)
  );
  coupon.discountedTickets = existingTickets;

  const mapToPayload = (newCoupon) => {
    const ticketsApplied =
      newCoupon.discountType === discountTypes.AMOUNT ||
      newCoupon.discountedTickets.length === tickets.length
        ? []
        : newCoupon.discountedTickets;
    return {
      ...newCoupon,
      id: coupon?.id,
      discountedTickets: ticketsApplied,
      discountType: Number(newCoupon.discountType),
      redeemBy: Boolean(newCoupon.redeemBy)
        ? new Date(newCoupon.redeemBy).toDateString()
        : null,
    };
  };

  const createCoupon = async (values) => {
    try {
      await mutate(
        couponUrl,
        async (coupons) => {
          const newCoupon = await executeApiCall(
            couponUrl,
            methods.post,
            mapToPayload(values)
          );
          return [...coupons, newCoupon];
        },
        false
      );
      enqueueSnackbar('Coupon created successfully', { variant: 'success' });
      handleClose();
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' });
    }
  };

  const updateCoupon = async (values) => {
    try {
      await mutate(
        couponUrl,
        async (coupons) => {
          const updatedCoupon = await executeApiCall(
            couponUrl + `/${values.code}`,
            methods.put,
            mapToPayload(values)
          );
          if (Array.isArray(coupons)) {
            const couponArray = coupons.filter(
              (c) => c.code !== updatedCoupon.code
            );
            let array = [...couponArray, updatedCoupon];
            return array.sort((x, y) => x.id - y.id);
          } else {
            return [updatedCoupon];
          }
        },
        false
      );
      enqueueSnackbar('Coupon updated successfully', { variant: 'success' });
      handleClose();
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' });
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        code: coupon.code ?? '',
        discountType:
          coupon.discountType === 0 || !Boolean(coupon.discountType)
            ? discountTypes.PERCENTAGE
            : discountTypes.AMOUNT,
        discount: coupon.discount ?? '',
        redeemBy: coupon.localRedeemBy ?? null,
        maxRedemptions: coupon.maxRedemptions,
        discountedTickets: isEmpty(coupon?.discountedTickets)
          ? ticketIdArray
          : coupon.discountedTickets,
      }}
      onSubmit={coupon.code ? updateCoupon : createCoupon}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={getValidationSchema()}
    >
      {({
        handleSubmit,
        isSubmitting,
        values,
        handleChange,
        handleBlur,
        setFieldValue,
        handleReset,
        errors,
      }) => (
        <DrawerBase
          open={open}
          onClose={() => {
            handleReset();
            handleClose();
          }}
          handleSubmit={handleSubmit}
          isSubmitting={isSubmitting}
          submittingMessage="Saving..."
          submitText="Save"
          cancelText="Cancel"
          spacing={4}
        >
          <Grid xs={12} className={classes.paddingBottom}>
            <Typography gutterBottom variant="h3">
              {coupon.code ? 'Edit' : 'Create'} coupon
            </Typography>
          </Grid>
          <Grid xs={6}>
            <FormikTextField
              variant="outlined"
              label="Coupon code"
              required
              name="code"
              helperText="A coupon code cannot be changed after it has been created."
              disabled={Boolean(coupon.code)}
            />
          </Grid>
          <Grid xs={12}>
            <FormControl>
              <RadioGroup
                value={values.discountType}
                onChange={handleChange}
                name="discountType"
                onBlur={handleBlur}
              >
                <FormControlLabel
                  value="0"
                  control={<Radio />}
                  label="Use discount %"
                />
                <FormControlLabel
                  value="1"
                  control={<Radio />}
                  label="Use fixed amount"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid xs={12}>
            <FormikTextField
              variant="outlined"
              label={
                values.discountType === discountTypes.PERCENTAGE
                  ? 'Discount percentage'
                  : 'Discount amount'
              }
              required
              name="discount"
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    {values.discountType === discountTypes.PERCENTAGE
                      ? '%'
                      : currency?.toUpperCase()}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <WrappedDivider />
          <Grid xs={12}>
            <Typography>Advanced settings</Typography>
          </Grid>
          <Grid xs={12}>
            <DatePicker
              name="redeemBy"
              label="Expiry date"
              inputVariant="outlined"
              variant="inline"
              format="PPP"
              disableMaskedInput
              inputFormat="dd MMM yyyy"
              autoOk
              value={values.redeemBy}
              onChange={(date) => setFieldValue('redeemBy', date)}
              placeholder="No expiry"
              InputLabelProps={{ shrink: true }}
              renderInput={(props) => (
                <TextField name="redeemBy" onKeyDown={onKeyDown} {...props} />
              )}
            />
          </Grid>
          <Grid xs={12}>
            <FormikTextField
              variant="outlined"
              label="Redemption limit"
              name="maxRedemptions"
              type="number"
              placeholder="Unlimited"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid xs={12}>
            <Typography>This coupon code can be applied to*:</Typography>
          </Grid>
          <Grid xs={12}>
            <Tooltip
              title={
                values.discountType !== discountTypes.PERCENTAGE
                  ? 'You cannot unselect tickets for fixed amount discount coupons.'
                  : ''
              }
            >
              <span>
                <TicketSelectBox
                  disabled={values.discountType !== discountTypes.PERCENTAGE}
                  availableTickets={tickets}
                  selectedTickets={values.discountedTickets}
                  setTickets={(selected) =>
                    setFieldValue('discountedTickets', selected)
                  }
                  error={
                    errors.discountedTickets?.length
                      ? errors.discountedTickets
                      : null
                  }
                />
              </span>
            </Tooltip>
          </Grid>
          <FocusFormikError />
        </DrawerBase>
      )}
    </Formik>
  );
};

export default CouponDrawer;
