import React, { useState } from 'react';
import { TextField, Button, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useApi, methods } from '../../Hooks/useApi';
import { useSnackbar } from 'notistack';
import RaceOfficeLogo from '../../images/raceoffice_logo.svg';
import Grid from '@mui/material/Unstable_Grid2';
const forgotPasswordUrl = '/account/sendpasswordlink';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  img: {
    marginBottom: '5%',
    width: '50%',
  },
}));

const ForgotPassword = () => {
  const { apiLoading, executeApiCall } = useApi();
  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();
  const [state, setState] = useState({
    email: '',
    sent: false,
  });

  const setEmail = (e) => {
    const { value } = e.target;
    setState((prevState) => ({
      ...prevState,
      email: value,
      message: '',
    }));
  };

  const handleSubmit = () => {
    executeApiCall(forgotPasswordUrl, methods.post, { email: state.email })
      .then((res) =>
        setState((prevState) => ({
          ...prevState,
          sent: true,
          message: res,
        }))
      )
      .catch((err) => enqueueSnackbar(err.message), { variant: 'error' });
  };

  return (
    <Grid container spacing={3} className={classes.paper}>
      <Grid xs={12}>
        <img className={classes.img} src={RaceOfficeLogo} alt="Company logo" />
      </Grid>
      <Grid xs={12}>
        <Typography component="h1" variant="h5">
          {!state.sent ? 'Forgot your password?' : 'Check your inbox'}
        </Typography>
      </Grid>
      <Typography component="p">
        {!state.sent
          ? 'Confirm your email address and we will send you a link to reset your password.'
          : state.message}
      </Typography>
      {!state.sent ? (
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={state.email}
            onInput={setEmail}
          />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handleSubmit}
            disabled={!state.email || apiLoading}
          >
            Send Password Link
          </Button>
        </form>
      ) : (
        <Button
          fullWidth
          className={classes.submit}
          variant="outlined"
          href="/login"
        >
          Back to login
        </Button>
      )}
    </Grid>
  );
};

export default ForgotPassword;
