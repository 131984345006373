import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { Autorenew } from '@mui/icons-material';
import ReactivationModal from './ReactivationModal';
import { Context } from '../../../Stores/EventInfoStore';

const ReactivationButton = () => {
  const navigate = useNavigate();
  const [reactivateInfoModal, toggleReactivateInfoModal] = useState(false);
  const [eventInfo, _] = useContext(Context);
  const { eventId } = eventInfo.event;
  const editionInProg = eventInfo.allEditions.find((x) => x.inProgress)?.id;

  const action = editionInProg
    ? () => navigate(`/app/reactivate-event/${eventId}/${editionInProg}`)
    : () => toggleReactivateInfoModal(true);

  return (
    <>
      <Button
        onClick={action}
        variant="contained"
        fullWidth
        endIcon={<Autorenew />}
      >
        {Boolean(editionInProg) ? 'Continue' : 'Activate next edition'}
      </Button>
      {reactivateInfoModal && (
        <ReactivationModal
          open={reactivateInfoModal}
          handleClose={() => toggleReactivateInfoModal(false)}
          eventId={eventId}
          eventTitle={eventInfo.basicInfo.eventTitle}
        />
      )}
    </>
  );
};

export default ReactivationButton;
