import { useEffect, useContext } from 'react';
import { actionTypes, Context } from '../../../Stores/EventInfoStore';

//Component which goes within the EventStoreWrapper and detects changes on the SWR event fetcher
const EventInfoUpdater = ({ edition }) => {
  const [_, dispatch] = useContext(Context);
  useEffect(() => {
    dispatch({ type: actionTypes.full_info_set, payload: edition });
  }, [edition]);

  return null;
};

export default EventInfoUpdater;
