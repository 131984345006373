import React from 'react';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AddOnTicketsSelector from './AddOnTicketsSelector';
import Grid from '@mui/material/Unstable_Grid2';
const useStyles = makeStyles((theme) => ({
  radioGroupLabel: {
    marginBottom: theme.spacing(2),
  },
}));

const AddOnAdditionalInformation = ({ formik, eventInfo }) => {
  const classes = useStyles();
  const { tickets } = eventInfo;
  return (
    <Grid xs={12}>
      <FormControl
        required
        component="fieldset"
        error={formik.touched.required && Boolean(formik.errors.required)}
      >
        <FormLabel component="legend" className={classes.radioGroupLabel}>
          Is this add-on optional or required?
        </FormLabel>
        <RadioGroup
          value={formik.values.required}
          name="required"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
        >
          <FormControlLabel
            value="false"
            control={<Radio />}
            label="Optional (Participants can select this add-on but they can also skip it)"
          />
          <FormControlLabel
            value="true"
            control={<Radio />}
            label="Required (Participants must select this add-on to complete the checkout)"
          />
        </RadioGroup>
        {formik.touched.required && formik.errors.required && (
          <FormHelperText>{formik.errors.required}</FormHelperText>
        )}
      </FormControl>
      <AddOnTicketsSelector
        required={formik.values.required === 'true'}
        availableTickets={tickets}
        formik={formik}
      />
    </Grid>
  );
};

export default AddOnAdditionalInformation;
