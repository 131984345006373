import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  TextField,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import FormikTimeTextfield from '../../Common/Formik/FormikTimeTextfield';
import { onKeyDown } from '../../../Utils/dateUtils';

const EventDatesModal = ({
  open,
  tickets,
  startDate,
  endDate,
  updateTickets,
  eventId,
  editionId,
  handleClose,
}) => {
  const initialValues = { tickets };
  const { enqueueSnackbar } = useSnackbar();

  const submitTickets = async (eventId, editionId, values) => {
    try {
      await updateTickets(eventId, editionId, values.tickets);
      enqueueSnackbar('Race start dates successfully updated.', {
        variant: 'success',
      });
      handleClose();
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' });
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={async (values) => {
        await submitTickets(eventId, editionId, values);
      }}
    >
      {({ values, handleSubmit, setFieldValue }) => (
        <>
          <Dialog open={open} onClose={handleClose} disableEscapeKeyDown>
            <DialogTitle>
              You have changed the event dates. Please make sure that the race
              start dates are also updated.
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                They are not mandatory information. Keep the fields empty if you
                are not sure yet.
              </DialogContentText>
              <Grid container spacing={2}>
                {tickets.map((ticket, index) => (
                  <React.Fragment key={ticket.id}>
                    <Grid xs={12}>
                      <Typography>{ticket.name}</Typography>
                    </Grid>
                    <Grid xs={6}>
                      <DatePicker
                        variant="outlined"
                        fullWidth
                        disablePast
                        label="Race start date"
                        minDate={startDate}
                        maxDate={endDate}
                        name={`tickets[${index}].localStartDate`}
                        format="PPP"
                        inputFormat="dd MMM yyyy"
                        value={values.tickets[index].localStartDate}
                        onChange={(date) => {
                          if (date) date = date.toDateString();
                          setFieldValue(
                            `tickets[${index}].localStartDate`,
                            date,
                            false
                          );
                        }}
                        renderInput={(props) => (
                          <TextField
                            onKeyDown={onKeyDown}
                            fullWidth
                            {...props}
                          />
                        )}
                      />
                    </Grid>
                    <Grid xs={6}>
                      <FormikTimeTextfield
                        variant="outlined"
                        fullWidth
                        label="Race start time"
                        name={`tickets[${index}].localStartTime`}
                        type="time"
                        value={values.tickets[index].localStartTime}
                      />
                    </Grid>
                  </React.Fragment>
                ))}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleSubmit} color="primary">
                Save
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </Formik>
  );
};

export default EventDatesModal;
