import React from 'react';
import { Formik } from 'formik';
import { getValidationSchema } from './OfferedServicesFormValidationSchema';
import { Typography, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import OfferedServiceItem from './OfferedServiceItem';
import { serviceCategories } from '../../../Consts/serviceCategories';
import { useApi, methods } from '../../../Hooks/useApi';
import { useSnackbar } from 'notistack';
import { useContext } from 'react';
import { actionTypes, Context } from '../../../Stores/EventInfoStore';
import Grid from '@mui/material/Unstable_Grid2';
const useStyles = makeStyles((theme) => ({
  grouping: {
    margin: theme.spacing(2.5, 0),
    padding: `0 !important`,
  },
  buttonsGrid: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    backgroundColor: '#FFFFFF',
    zIndex: 1,
    paddingTop: `${theme.spacing(2)} !important`,
    paddingBottom: `${theme.spacing(2)} !important`,
    paddingRight: `15% !important`,
    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.12)',
  },
}));

const getPostEndpoint = (eventId, editionId) =>
  `/${eventId}/${editionId}/offered-services`;

const OfferedServicesForm = ({
  data,
  formRef,
  formRefNotify,
  disableButton,
  onSaveSuccess,
  eventInfo,
  isManage,
}) => {
  const { eventId, editionId } = eventInfo?.event || {};
  const { executeApiCall } = useApi();
  const [_, dispatch] = useContext(Context);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const submit = async (values) => {
    const mapped = values.services.map((x) => x[1]).flat();
    try {
      const onlyChecked = mapped.filter((x) => !!x.checked);
      disableButton && disableButton(true);
      const addedServices = await executeApiCall(
        getPostEndpoint(eventId, editionId),
        methods.put,
        {
          offeredServices: onlyChecked,
        }
      );
      dispatch({
        type: actionTypes.offeredServices_set,
        payload: addedServices,
      });
      enqueueSnackbar('Services successfully updated', {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    } finally {
      disableButton && disableButton(false);
      onSaveSuccess && onSaveSuccess();
    }
  };

  return (
    <Formik
      initialValues={{ services: data }}
      innerRef={formRef}
      onSubmit={async (values, { resetForm }) => {
        await submit(values);
        resetForm({ values });
      }}
      validationSchema={() => getValidationSchema()}
    >
      {({
        values: formikvalues,
        setFieldValue,
        handleSubmit,
        isSubmitting,
        errors,
        dirty,
      }) => (
        <>
          {data &&
            data.map(([key, values], outerIndex) => (
              <Grid
                className={classes.grouping}
                container
                justifyContent="flex-start"
                direction="column"
                key={key}
                spacing={0}
              >
                <Typography variant="subtitle2">
                  {serviceCategories[key]}
                </Typography>

                {values.map((x, i) => {
                  const prefix = `services[${outerIndex}][1][${i}]`;
                  return (
                    <OfferedServiceItem
                      service={formikvalues.services[outerIndex][1][i] || x}
                      prefix={prefix}
                      formRefNotify={formRefNotify}
                      errors={errors.services && errors.services[x.totalIdx]}
                      formikvalues={formikvalues}
                      setFieldValue={setFieldValue}
                    />
                  );
                })}
              </Grid>
            ))}

          {isManage && (
            <Grid
              container
              justifyContent="flex-end"
              className={classes.buttonsGrid}
              spacing={0}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={!dirty || isSubmitting}
              >
                {isSubmitting
                  ? "Saving what's included…"
                  : "Save what's included"}
              </Button>
            </Grid>
          )}
        </>
      )}
    </Formik>
  );
};

export default OfferedServicesForm;
