import React, { useMemo, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';

const autocompleteService = { current: null };

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));

const LocationPicker = ({
  isVirtualEvent,
  handleBlur,
  setFieldValue,
  errors,
  touched,
  setTouched,
  location,
  name,
  label,
  required,
  returnLatLng,
  swapLatLng = false,
  notify = () => {},
}) => {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const placesService = { current: null };
  const fetch = useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    []
  );

  const getLatLng = (location) => {
    if (!placesService.current && window.google) {
      placesService.current = new window.google.maps.places.PlacesService(
        document.getElementById('map')
      );
    }
    const latLong = [];
    placesService.current.getDetails(
      { placeId: location?.place_id },
      (details, status) => {
        if (swapLatLng)
          latLong.push(
            details?.geometry?.location?.lng(),
            details?.geometry?.location?.lat()
          );
        else
          latLong.push(
            details?.geometry?.location?.lat(),
            details?.geometry?.location?.lng()
          );
      }
    );
    return latLong;
  };

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google?.maps?.places) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }
    if (inputValue === '') {
      setOptions(location ? [location] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (location) {
          newOptions = [location];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }
        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [location, inputValue, fetch]);

  const handleChange = (event, value, reason) => {
    notify();
    if (returnLatLng) value = { ...value, latlng: getLatLng(value) };
    if (reason === 'clear') {
      setFieldValue(`${name}.place`, { description: '', latlng: [] });
    } else if (reason === 'blur' && value.length === 0) {
      setFieldValue(`${name}.place`, { description: '', latlng: [] });
    } else {
      setFieldValue(`${name}.place`, value);
    }
    setTouched({ ...touched, [name]: true });
  };

  return (
    <Autocomplete
      id={name}
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.description
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      freeSolo
      includeInputInList
      filterSelectedOptions
      placeholder="Search for a venue or address"
      value={location}
      onInputChange={(e, newInputValue) => setInputValue(newInputValue)}
      onChange={handleChange}
      onBlur={() => {
        if (options.length !== 0 && returnLatLng) {
          handleChange('', options[0]);
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          value={location}
          name={name}
          error={touched.location && Boolean(errors.location)}
          onBlur={handleBlur}
          label={label}
          variant="outlined"
          fullWidth
          required={required}
          placeholder="Search for a venue or address"
          helperText={
            errors.location ||
            (isVirtualEvent &&
              'On the event page the location will be displayed as "Anywhere" but still your event will be searchable by country.')
          }
        />
      )}
      renderOption={(props, option) => {
        const matches =
          option.structured_formatting?.main_text_matched_substrings ?? [];
        const parts = parse(
          option.structured_formatting?.main_text,
          matches.map((match) => [match.offset, match.offset + match.length])
        );

        return (
          <li {...props}>
            <Grid container alignItems="center" spacing={0}>
              <Grid>
                <LocationOnIcon className={classes.icon} />
              </Grid>
              <Grid xs>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{ fontWeight: part.highlight ? 700 : 400 }}
                  >
                    {part.text}
                  </span>
                ))}

                <Typography variant="body2" color="textSecondary">
                  {option.structured_formatting?.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
};

export default LocationPicker;
