import React from 'react';
import { Drawer } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import DrawerActions from './DrawerActions';
import Grid from '@mui/material/Unstable_Grid2';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(8),
    [theme.breakpoints.up('sm')]: {
      width: 567,
    },
  },
}));

const DrawerBase = ({ open, onClose, spacing = 2, ...props }) => {
  const classes = useStyles();
  return (
    <Drawer open={open} anchor="right" onClose={onClose}>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        className={classes.root}
        spacing={spacing}
      >
        {props.children}
        <DrawerActions
          onClose={onClose}
          handleSubmit={props.handleSubmit}
          isSubmitting={props.isSubmitting}
          submittingMessage={props.submittingMessage}
          submitText={props.submitText}
          cancelText={props.cancelText}
        />
      </Grid>
    </Drawer>
  );
};

export default DrawerBase;
