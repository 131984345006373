import React from 'react';
import clsx from 'clsx';
import { Button, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Add as AddIcon } from '@mui/icons-material';
import Grid from '@mui/material/Unstable_Grid2';
const backgroundImages = [
  {
    className: 'tShirt',
  },
  {
    className: 'restaurant',
  },
  {
    className: 'shuttle',
  },
  {
    className: 'box',
  },
];

const useStyles = makeStyles((theme) => ({
  gridContent: {
    display: 'flex',
    minHeight: theme.spacing(50),
    flexFlow: 'row nowrap',
    width: '100%',
  },
  gridContentEmpty: {
    position: 'relative',
  },
  startCreatingAddOns: {
    marginTop: theme.spacing(4),
  },
  background: {
    position: 'absolute',
    display: 'grid',
    gridTemplateColumns: 'repeat(2,1fr)',
    gridGap: theme.spacing(5),
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
    height: '100%',
  },
  backgroundImage: {
    display: 'inline-flex',
    margin: '0 auto',
    width: '150px',
    height: '150px',
    maskSize: '100% 100%',
    maskRepeat: 'no-repeat',
    opacity: 0.1,
    backgroundColor: theme.palette.primary.main,
  },
  box: {
    maskImage: "url('/images/package-svgrepo-com.svg')",
    transform: 'rotate(9deg)',
  },
  shuttle: {
    maskImage: "url('/images/ic_airport_shuttle.svg')",
    transform: 'rotate(-9deg)',
  },
  restaurant: {
    maskImage: "url('/images/ic_restaurant_menu.svg')",
    transform: 'rotate(-9deg)',
  },
  tShirt: {
    maskImage: "url('/images/t-shirt-svgrepo-com.svg')",
    transform: 'rotate(9deg)',
  },
}));

const AddOnEmptyState = ({ handleAddAddOn }) => {
  const classes = useStyles();
  return (
    <Grid
      container
      spacing={3}
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Grid
        xs={12}
        className={clsx(classes.gridContent, classes.gridContentEmpty)}
        justifyContent="center"
        alignItems="center"
        container
        spacing={0}
      >
        <Grid>
          <section className={classes.background}>
            {backgroundImages.map((x) => (
              <div
                key={x.className}
                className={clsx(classes.backgroundImage, classes[x.className])}
              />
            ))}
          </section>
        </Grid>
        <Grid container alignItems="center" direction="column" spacing={0}>
          <Typography>You have no add-ons yet.</Typography>
          <Typography>
            (You can skip this page and create add-ons later)
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddAddOn}
            className={classes.startCreatingAddOns}
          >
            <AddIcon fontSize="small" />
            &nbsp;Start creating add-ons
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AddOnEmptyState;
