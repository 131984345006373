import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Formik } from 'formik';
import { mutate } from 'swr';
import { useSnackbar } from 'notistack';
import { InputAdornment, Typography, MenuItem } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import DrawerBase from '../../../Common/Drawer/DrawerBase';
import FormikTextField from '../../../Common/Formik/FormikTextField';
import WrappedDivider from '../../../Common/WrappedDivider/WrappedDivider';
import ConfirmationModal from '../../../Common/ConfirmationModal/ConfirmationModal';
import { useApi, methods } from '../../../../Hooks/useApi';
import { orderUrl } from '../../../../Hooks/useOrders';
import { refundReasons } from '../../../../Consts/refundReasons';
import { getValidationSchema } from './refundOrderValidationSchema';
import Grid from '@mui/material/Unstable_Grid2';
const useStyles = makeStyles((theme) => ({
  paddingBottom: {
    paddingBottom: `${theme.spacing(4)} !important`,
  },
}));

const getRefundUrl = (eventId, editionId, orderId) =>
  `/order/${eventId}/${editionId}/${orderId}/refund`;

const OTHER = 5;

const RefundOrderDrawer = ({ open, handleClose, order }) => {
  const [modalOpen, toggleModal] = useState(false);
  const classes = useStyles();
  const { executeApiCall } = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const { eventId, editionId } = useParams();

  const refundOrder = async (values) => {
    await mutate([orderUrl(eventId, editionId), true], async () => {
      try {
        await executeApiCall(
          getRefundUrl(eventId, editionId, order.orderId),
          methods.post,
          values
        );
        handleClose();
        enqueueSnackbar('Order successfully refunded', {
          variant: 'success',
        });
      } catch (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    });
  };

  return (
    <>
      <Formik
        initialValues={{
          refundValue: order.totalValueForOrganizer.toFixed(2),
          reasonType: '',
          reason: '',
          messageToParticipant: '',
          email: order.orderer.email,
        }}
        validationSchema={() => getValidationSchema(order)}
        onSubmit={refundOrder}
      >
        {({ handleSubmit, isSubmitting, values }) => (
          <DrawerBase
            open={open}
            onClose={() => toggleModal(true)}
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            submittingMessage="Refunding order..."
            submitText="Confirm & refund order"
            cancelText="Cancel"
          >
            <Grid xs={12} className={classes.paddingBottom}>
              <Typography variant="h3" gutterBottom>
                Refund order
              </Typography>
              <Typography variant="body1">
                This feature allows you to make partial or full refunds to your
                participants. The participant will receive an email to inform
                them of the refund. Their money will be transferred back
                automatically within 5-10 working days.
              </Typography>
            </Grid>
            <Grid xs={12}>
              <Typography variant="body1">
                Refund to: {order.orderer.fullName}
              </Typography>
            </Grid>
            <Grid xs={12}>
              <FormikTextField
                name="refundValue"
                label="Refund Amount"
                fullWidth
                required
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      {order.currency?.toUpperCase()}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid xs={12}>
              <FormikTextField
                name="reasonType"
                label="Reason"
                required
                fullWidth
                select
              >
                {refundReasons.map((r) => (
                  <MenuItem key={r.value} value={r.value}>
                    {r.label}
                  </MenuItem>
                ))}
              </FormikTextField>
            </Grid>
            {values.reasonType === OTHER && (
              <Grid xs={12}>
                <FormikTextField
                  name="reason"
                  required
                  label="Please ellaborate on the reason"
                  fullWidth
                  multiline
                  rows={3}
                />
              </Grid>
            )}
            <Grid xs={12}>
              <FormikTextField
                name="messageToParticipant"
                label="Message to participant"
                fullWidth
                multiline
                rows={3}
                helperText="This text will be included in the email which will be sent to the participant when completing the refund. 
                Please include any instructions or comments."
              />
            </Grid>
            <WrappedDivider />
            <Grid xs={12}>
              <Typography variant="body1">
                The email will be sent to the following email address.
              </Typography>
            </Grid>
            <Grid xs={12}>
              <FormikTextField
                name="email"
                label="Email address"
                fullWidth
                required
              />
            </Grid>
          </DrawerBase>
        )}
      </Formik>
      {modalOpen && (
        <ConfirmationModal
          open={modalOpen}
          cancel={() => toggleModal(false)}
          confirm={() => {
            toggleModal(false);
            handleClose();
          }}
          title="Are you sure you want to cancel the refund?"
          cancelText="No, continue"
          confirmText=" Yes, cancel"
        />
      )}
    </>
  );
};

export default RefundOrderDrawer;
