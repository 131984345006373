import { InputAdornment, Typography, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormikTextField from '../../Common/Formik/FormikTextField';
import FormikTimeTextfield from '../../Common/Formik/FormikTimeTextfield';
import { onKeyDown } from '../../../Utils/dateUtils';
import Grid from '@mui/material/Unstable_Grid2';
const TicketAdditionalDetails = ({ classes, formik, eventInfo }) => {
  return (
    <>
      <Grid className={classes.detailsHeading}>
        <Typography variant="body2">Details (optional)</Typography>
      </Grid>
      <Grid container direction="row" xs={12} spacing={2}>
        <Grid xs={6}>
          <DatePicker
            fullWidth
            variant="inline"
            label="Race start date"
            name="localStartDate"
            format="PPP"
            inputFormat="dd MMM yyyy"
            minDate={new Date(eventInfo.basicInfo.eventStartDate)}
            maxDate={new Date(eventInfo.basicInfo.eventEndDate)}
            value={formik.values.localStartDate}
            error={
              formik.touched.localStartDate &&
              Boolean(formik.errors.localStartDate)
            }
            onChange={(date) => {
              if (date) date = date.toDateString();
              formik.setFieldValue('localStartDate', date, true);
            }}
            onBlur={formik.handleBlur}
            renderInput={(props) => (
              <TextField onKeyDown={onKeyDown} fullWidth {...props} />
            )}
          />
        </Grid>
        <Grid xs={6}>
          <FormikTimeTextfield
            name="localStartTime"
            label="Race start time"
            value={formik.values.localStartTime}
            handleBlur={formik.handleBlur}
          />
        </Grid>
        <Grid xs={6}>
          <FormikTextField
            variant="outlined"
            label="Min age limit"
            placeholder="No limit"
            fullWidth
            name="ageMin"
            type="number"
            InputProps={{
              endAdornment: <InputAdornment>Years old</InputAdornment>,
            }}
          />
        </Grid>
        <Grid xs={6}>
          <FormikTextField
            variant="outlined"
            label="Max age limit"
            placeholder="No limit"
            fullWidth
            name="ageMax"
            type="number"
            InputProps={{
              endAdornment: <InputAdornment>Years old</InputAdornment>,
            }}
          />
        </Grid>
        <Grid xs={12}>
          <FormikTextField
            variant="outlined"
            label="Short description about this ticket"
            rows={4}
            multiline
            fullWidth
            name="description"
            helperText='You can add information here, which is specific to this ticket (for example: what is included in the fee, course details, requirements, etc.)
            This will be displayed in the "More information" section on the ticket together with the race start time and age limit. (Max. 800 characters)'
          />
        </Grid>
        <Grid xs={6} className={classes.detailsMarginTop}>
          <FormikTextField
            variant="outlined"
            label="Ticket limit"
            placeholder="No limit"
            type="number"
            multiline
            fullWidth
            name="ticketLimit"
            helperText='Once the limit is reached, this ticket will be marked as "Sold Out"'
          />
        </Grid>
      </Grid>
    </>
  );
};
export default TicketAdditionalDetails;
