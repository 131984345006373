const Menu = [
  {
    label: 'My events',
    pathname: '/app',
  },
  {
    label: 'Payouts',
    pathname: '/app/payouts',
  },
  {
    label: 'Team',
    pathname: '/app/team',
  },
  {
    label: 'Help',
    pathname: '/app/help',
  },
  {
    label: 'Admin',
    pathname: '/app/admin',
  },
];

export default Menu;
