import React, { useState, useEffect } from 'react';
import { Card } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { subYears, differenceInDays, closestTo, isPast } from 'date-fns';
import { wmPalette } from '../../Theme/WorldSportsGroupTheme';
import TimelineDonuts from './TimelineDonuts';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Unstable_Grid2';
const useStyles = makeStyles((theme) => ({
  padding: {
    paddingTop: theme.spacing(4),
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(2),
    },
  },
  paddingZero: {
    padding: 0,
  },
}));

const EventTimeline = ({ eventInfo }) => {
  const classes = useStyles();
  const lastYear = subYears(new Date(eventInfo.basicInfo.eventStartDate), 1);
  const [salesActivationDate, setSalesActivationDate] = useState([0, 0]);
  const [salesDate, setSalesDate] = useState([0, 0]);
  const [raceDate, setRaceDate] = useState([0, 0]);
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.only('md'));

  const calcSalesActivationDate = () => {
    let upcomingDates = [];
    let pastDates = [];
    eventInfo.tickets.forEach((ticket) => {
      ticket.priceSteps.forEach((priceStep) => {
        const priceStepDate = new Date(priceStep.activationAt);
        isPast(priceStepDate)
          ? pastDates.push(priceStepDate)
          : upcomingDates.push(priceStepDate);
      });
    });
    const daysPassed = differenceInDays(
      new Date(),
      pastDates.length === 0 ? lastYear : closestTo(new Date(), pastDates)
    );
    let daysToGo = differenceInDays(
      upcomingDates.length === 0
        ? new Date()
        : closestTo(new Date(), upcomingDates),
      new Date()
    );
    if (daysToGo < 0) daysToGo = 0;
    setSalesActivationDate([daysPassed, daysToGo]);
  };

  const calcSalesDate = () => {
    const daysPassed = differenceInDays(new Date(), lastYear);
    let daysToGo = differenceInDays(
      new Date(eventInfo.basicInfo.eventSaleEndDate),
      new Date()
    );
    if (daysToGo < 0) daysToGo = 0;
    setSalesDate([daysPassed, daysToGo]);
  };

  const calcRaceDate = () => {
    const daysPassed = differenceInDays(new Date(), lastYear);
    let daysToGo = differenceInDays(
      new Date(eventInfo.basicInfo.eventStartDate),
      new Date()
    );
    if (daysToGo < 0) daysToGo = 0;
    setRaceDate([daysPassed, daysToGo]);
  };

  useEffect(() => {
    calcSalesActivationDate();
    calcSalesDate();
    calcRaceDate();
  }, []);

  return (
    <Card className={classes.padding}>
      <Grid
        container
        direction={isTablet ? 'row' : 'column'}
        justifyContent="space-evenly"
        spacing={4}
        className={classes.paddingZero}
      >
        <Grid>
          <TimelineDonuts
            title="Next price step"
            slices={{
              0: {
                color:
                  salesActivationDate[1] === 0
                    ? wmPalette.black[20]
                    : wmPalette.green[70],
              },
              1: {
                color:
                  salesActivationDate[1] === 0
                    ? wmPalette.black[20]
                    : wmPalette.green[10],
              },
            }}
            action={salesActivationDate}
          />
        </Grid>
        <Grid>
          <TimelineDonuts
            title="Sales end date"
            slices={{
              0: {
                color:
                  salesDate[1] === 0 ? wmPalette.black[20] : wmPalette.blue[70],
              },
              1: {
                color:
                  salesDate[1] === 0 ? wmPalette.black[20] : wmPalette.blue[20],
              },
            }}
            action={salesDate}
          />
        </Grid>
        <Grid>
          <TimelineDonuts
            title="Race Day"
            slices={{
              0: {
                color:
                  raceDate[1] === 0
                    ? wmPalette.black[20]
                    : wmPalette.purple[90],
              },
              1: {
                color:
                  raceDate[1] === 0
                    ? wmPalette.black[20]
                    : wmPalette.purple[10],
              },
            }}
            action={raceDate}
          />
        </Grid>
      </Grid>
    </Card>
  );
};

export default EventTimeline;
