import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    margin: 'auto',
    [theme.breakpoints.up('lg')]: {
      maxWidth: 1024, // TODO: Verify with design
    },
  },
}));
const GridWrapper = (props) => {
  const classes = useStyles();
  return (
    <Grid container spacing={3} className={classes.container}>
      {props.children}
    </Grid>
  );
};

export default GridWrapper;
