import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Card,
  Typography,
  CardActionArea,
  CardMedia,
  CardContent,
  CardActions,
  CardHeader,
  Button,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import {
  Autorenew,
  Event,
  ConfirmationNumberOutlined,
} from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { wmPalette } from '../../Theme/WorldSportsGroupTheme';
import ReactivationModal from '../ManageEvents/ReactivateEvent/ReactivationModal';
import { formatEventDate, formatDateStandard } from '../../Format';
import { publish, onboard, draft } from '../../Consts/eventStatus';
import { getEndOfDay } from './../../Utils/dateUtils';

const useStyles = makeStyles((theme) => ({
  card: {
    position: 'relative',
    height: '100%',
  },
  cardHeader: {
    position: 'absolute',
    color: wmPalette.white[100],
    padding: theme.spacing(1),
    borderRadius: `${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(
      1
    )} 0`,
    zIndex: 1,
  },
  review: {
    backgroundColor: wmPalette.orange[100],
  },
  draft: {
    backgroundColor: wmPalette.red[100],
  },
  publish: {
    backgroundColor: wmPalette.green[70],
  },
  finished: {
    lineHeight: '24px',
    fontWeight: 'bold',
    color: wmPalette.green[100],
  },
  media: {
    height: 232,
  },
  header: {
    marginBottom: theme.spacing(2),
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    minHeight: 130,
  },
  topMargin30: {
    marginTop: '-30px',
  },
  needsReactivate: {
    color: wmPalette.black[50],
  },
  needsReactivateImage: {
    filter: 'grayscale(100%)',
  },
  emptyColumn: {
    marginLeft: '32px',
  },
  subSectionText: {
    fontSize: '14px',
    marginTop: '1px',
    marginBottom: '0px',
    lineHeight: '24px',
  },
  subSectionText2: {
    fontSize: '14px',
    marginTop: '-5px',
    marginBottom: '0px',
    lineHeight: '24px',
  },
  subSectionText3: {
    fontSize: '14px',
    marginTop: '1px',
    marginBottom: '0px',
    lineHeight: '24px',
  },
  sectionLineHeight: {
    lineHeight: '24px',
    fontSize: '14px',
    marginLeft: '-2px',
  },
  gridBottomMargin: {
    marginTop: '-9px',
  },
}));

const BasicEventCard = ({ ...props }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [reactivateInfoModal, toggleReactivateInfoModal] = useState(false);
  const reactivationNecessary = props.reactivationInfo?.reactivationNecessary;
  const underReview =
    (props.eventPageStatus === draft || props.eventPageStatus === onboard) &&
    props.currentWizardStep === 'confirmation';
  const published = props.eventPageStatus === publish;
  const currentDate = new Date();
  const handleClick = () => {
    navigate(
      props.eventPageStatus === draft &&
        props.currentWizardStep !== 'confirmation'
        ? `/app/create-event/${props.id}/${props.activeEditionId}`
        : `/app/manage-event/${props.id}/${props.activeEditionId}/dashboard`
    );
  };

  const reactivationButtonClick = () => {
    if (props.reactivationInfo.editionInProgress)
      navigate(
        `/app/reactivate-event/${props.id}/${props.reactivationInfo.editionInProgress}`
      );
    else toggleReactivateInfoModal(true);
  };

  const endDate = getEndOfDay(new Date(props.saleEndDate));

  return (
    <>
      <Card className={classes.card}>
        <CardActionArea onClick={handleClick}>
          <CardHeader
            className={clsx(
              classes.cardHeader,
              underReview
                ? classes.review
                : published
                ? classes.publish
                : classes.draft
            )}
            title={
              <Typography style={{ fontWeight: 'bold' }} variant="body2">
                {underReview
                  ? 'UNDER REVIEW'
                  : published
                  ? 'PUBLISHED'
                  : 'DRAFT'}
              </Typography>
            }
          />
          <CardMedia
            className={clsx(
              classes.media,
              reactivationNecessary ? classes.needsReactivateImage : null
            )}
            image={props.image}
            title={props.name}
          />
          <CardContent
            className={clsx(
              classes.content,
              reactivationNecessary ? classes.needsReactivate : null
            )}
          >
            <Typography className={classes.header} variant="h6">
              {props.name}
            </Typography>
            <Grid spacing={1} container direction="row">
              <Grid>
                <Event />
              </Grid>
              <Grid>
                <Typography
                  variant="subtitle3"
                  className={classes.sectionLineHeight}
                >
                  &nbsp;{formatEventDate(new Date(props.startDate))}
                </Typography>
              </Grid>
              {reactivationNecessary && (
                <Grid>
                  <Typography
                    variant="subtitle3"
                    className={
                      classes.finished + ' ' + classes.sectionLineHeight
                    }
                  >
                    Finished
                  </Typography>
                </Grid>
              )}
            </Grid>
          </CardContent>
          <CardContent
            className={clsx(
              classes.content + ' ' + classes.topMargin30,
              props.activationStatus != 'active' ||
                !props.hasActiveTickets ||
                reactivationNecessary
                ? classes.needsReactivate
                : null
            )}
          >
            <Grid spacing={1} container direction="row">
              <Grid>
                <ConfirmationNumberOutlined />
              </Grid>
              <Grid xs={3} md={3}>
                <Typography class={classes.subSectionText3}> Sales:</Typography>
              </Grid>
              <Grid xs={3} md={7}>
                {props.eventPageStatus == publish &&
                  new Date(props.saleStartDate) < currentDate &&
                  endDate > currentDate &&
                  props.hasActiveTickets && (
                    <Typography class={classes.subSectionText}>
                      {' '}
                      <b>Active </b> until{' '}
                      {formatDateStandard(props.saleEndDate)}
                    </Typography>
                  )}
                {props.eventPageStatus == publish && endDate < currentDate && (
                  <Typography class={classes.subSectionText}>
                    {' '}
                    <b>Ended</b> on {formatDateStandard(props.saleEndDate)}
                  </Typography>
                )}
                {props.eventPageStatus == publish &&
                  new Date(props.saleStartDate) > currentDate && (
                    <Typography class={classes.subSectionText}>
                      {' '}
                      <b>Starting on </b>{' '}
                      {formatDateStandard(props.saleStartDate)}
                    </Typography>
                  )}
                {props.eventPageStatus == publish &&
                  new Date(props.saleStartDate) < currentDate &&
                  endDate > currentDate &&
                  !props.hasActiveTickets && (
                    <Typography class={classes.subSectionText}>
                      {' '}
                      <b>No tickets available</b>
                    </Typography>
                  )}
                {props.eventPageStatus == draft && (
                  <Typography class={classes.subSectionText}>
                    {' '}
                    <b>Not started</b>
                  </Typography>
                )}
              </Grid>
              <Grid class={classes.emptyColumn}> </Grid>
              <Grid xs={3} md={3}>
                <Typography class={classes.subSectionText2}>
                  {' '}
                  Tickets:
                </Typography>
              </Grid>
              <Grid xs={3} md={7}>
                <Typography class={classes.subSectionText2}>
                  {' '}
                  {props.totalSoldTickets} Sold
                </Typography>
              </Grid>
              <Grid class={classes.emptyColumn}> </Grid>
              <Grid xs={3} md={3}>
                <Typography class={classes.subSectionText2}>
                  {' '}
                  Revenue:
                </Typography>
              </Grid>
              <Grid xs={3} md={7}>
                <Typography class={classes.subSectionText2}>
                  {' '}
                  {props.totalRevenue} {props.currency}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
        {reactivationNecessary && (
          <CardActions>
            <Button onClick={reactivationButtonClick} startIcon={<Autorenew />}>
              ACTIVATE NEXT EDITION
            </Button>
          </CardActions>
        )}
      </Card>
      {reactivateInfoModal && (
        <ReactivationModal
          open={reactivateInfoModal}
          handleClose={() => toggleReactivateInfoModal(false)}
          eventId={props.id}
          eventTitle={props.name}
        />
      )}
    </>
  );
};

export default BasicEventCard;
