import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import FormikTextField from '../Common/Formik/FormikTextField';
import FormikCountrySelect from '../Common/Formik/FormikCountrySelect';

const PayoutAddressInput = ({ country, setCountry }) => (
  <>
    <Grid xs={12}>
      <FormikTextField
        label="Address line 1"
        name="billingAddress.address1"
        required
        fullWidth
      />
    </Grid>
    <Grid xs={12}>
      <FormikTextField
        label="Address line 2"
        name="billingAddress.address2"
        fullWidth
      />
    </Grid>
    <Grid xs={6}>
      <FormikTextField
        label="City"
        name="billingAddress.city"
        required
        fullWidth
      />
    </Grid>
    <Grid xs={6}>
      <FormikTextField label="State" name="billingAddress.state" fullWidth />
    </Grid>
    <Grid xs={6}>
      <FormikTextField
        label="Zip/Post Code"
        name="billingAddress.postalCode"
        required
        fullWidth
      />
    </Grid>
    <Grid xs={6}></Grid>
    <Grid xs={12}>
      <FormikCountrySelect
        country={country}
        setCountry={setCountry}
        placeholder="Select your country"
        required
      />
    </Grid>
  </>
);

export default PayoutAddressInput;
