export const successful = 'successful';
export const awaitingPayment = 'awaitingPayment';
export const partiallyRefunded = 'partiallyRefunded';
export const awaitingInformationFromParticipant =
  'awaitingInformationFromParticipant';
export const cancelled = 'cancelled';
export const refunded = 'refunded';
export const refundPending = 'refundPending';
export const refundPendingCancellationPolicy =
  'refundPendingCancellationPolicy';
export const refundPendingOrganiserCancelled =
  'refundPendingOrganiserCancelled';
