import React from 'react';
import { Container, Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Outlet } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flex: 1,
    height: '100%',
  },
  container: {
    height: '100%',
    marginTop: '100px',
    // [theme.breakpoints.up("sm")]: {
    //   marginLeft: "10%",
    // },
  },
  paper: {
    padding: theme.spacing(3),
  },
}));
const AuthLayout = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Container component="main" maxWidth="xs" className={classes.container}>
        <Paper className={classes.paper}>
          <Outlet />
        </Paper>
      </Container>
    </div>
  );
};

export default AuthLayout;
