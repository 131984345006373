import useSWR from 'swr';

export const useWebhooks = (eventId) => {
  const key = `/ro/events/${eventId}/webhooks`;
  const { data, error, mutate } = useSWR(key);

  return {
    key: key,
    webhooks: data,
    isLoading: !error && !data,
    error,
    mutate,
  };
};

export const useWebhook = (eventId, webhookId) => {
  const key = `/ro/events/${eventId}/webhooks/${webhookId}`;
  const { data, error, mutate } = useSWR(key);
  return {
    key: key,
    webhook: data,
    isLoading: !error && !data,
    error,
    mutate,
  };
};
