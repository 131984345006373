import { Box, Chip, Popover, Typography } from '@mui/material';
import React, { useState } from 'react';
import { marathon, halfMarathon } from '../../Consts/distanceType';

const SoldItemPopover = ({ classes, avatar, avatarcolor, distance }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Box
        className={classes.chipBox}
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <Chip
          size="small"
          label={avatar}
          className={`${avatarcolor} ${classes.chip}`}
        />
      </Box>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 1 }}>
          <p className={classes.popoverText}>
            {distance > marathon ? (
              <>
                <b>Ultra Marathon</b>
                <br />
              </>
            ) : (
              ''
            )}
            {distance === marathon ? (
              <>
                <b>Marathon</b>
                <br />
              </>
            ) : (
              ''
            )}
            {distance === halfMarathon ? (
              <>
                <b>Half Marathon</b>
                <br />
              </>
            ) : (
              ''
            )}
            <span>{(distance / 1000).toFixed(1) + ' Km'}</span>
          </p>
        </Typography>
      </Popover>
    </>
  );
};

export default SoldItemPopover;
