import * as inputType from '../Consts/inputType';

export const mapCustomQuestion = (question) => {
  const doesFileObjExist = (customQuestionObject) => {
    return (
      customQuestionObject.type === inputType.file_download &&
      customQuestionObject.file
    );
  };

  if (!question) {
    return question;
  }

  const subQuestions = question.subQuestions
    ? question.subQuestions.map((sq) => {
        if (doesFileObjExist(sq)) {
          sq.fileName = sq.file.name;
        }

        return sq;
      })
    : [];

  return {
    ...question,
    ...(doesFileObjExist(question) ? { fileName: question.file.name } : {}),
    subQuestions,
  };
};
