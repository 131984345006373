import React from 'react';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { wmPalette } from '../../Theme/WorldSportsGroupTheme';
import { useState } from 'react';
import clsx from 'clsx';
import { steps } from './onboardingSteps';
import Grid from '@mui/material/Unstable_Grid2';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '535px',
    [theme.breakpoints.down('sm')]: {
      minHeight: '575px',
    },
  },
  text: {
    textAlign: 'center',
    margin: theme.spacing(4, 'auto', 5, 'auto'),
  },
  innerText: {
    margin: theme.spacing(1.75, 0),
  },
  textSameMargin: {
    margin: theme.spacing(4, 'auto'),
  },
  skipButton: {
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      top: 0,
    },
  },
  pageDot: {
    display: 'inline-block',
    width: theme.spacing(1),
    height: theme.spacing(1),
    boxSizing: 'border-box',
    border: `1px solid ${wmPalette.green[70]}`,
    borderRadius: theme.spacing(1),
    margin: theme.spacing('auto', 0.5),
    cursor: 'pointer',
  },
  pageDotActive: {
    backgroundColor: wmPalette.green[70],
  },
  dotsContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'auto',
  },
  imagesSteps: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(5),
    },
  },
  imagesContainer: {
    position: 'relative',
    width: '100%',
    height: theme.spacing(26),
    [theme.breakpoints.down('lg')]: {
      height: theme.spacing(22),
    },
    [theme.breakpoints.down('sm')]: {
      maxHeight: theme.spacing(17),
    },
  },
  stepImage: {
    display: 'block',
    position: 'absolute',
    marginLeft: 'auto',
    marginRight: 'auto',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    height: theme.spacing(26),
    [theme.breakpoints.down('lg')]: {
      height: theme.spacing(22),
    },
    [theme.breakpoints.down('sm')]: {
      height: theme.spacing(17),
    },
  },
  title: {
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2.5, 1.5),
    },
  },
  content: {
    paddingTop: theme.spacing(3),
    paddingBottom: 0,
    flexGrow: 2,
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(6, 2, 3, 2),
    },
  },
  actions: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2.5, 1.5),
    },
  },
  lastStepHeading: {
    marginTop: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2.5),
    },
  },
}));

export const WelcomeOnboarding = ({ onEnd }) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const totalPages = 5;

  const nextPage = () => {
    if (page >= totalPages - 1) {
      return;
    }

    setPage(page + 1);
  };

  const previousPage = () => {
    if (page <= 0) {
      return;
    }

    setPage(page - 1);
  };

  const getContent = () => {
    const contentToRender =
      page >= 1 && page <= 3
        ? steps.pages[1]
        : steps.pages[page > 3 ? 2 : page];
    return contentToRender({ classes, nextPage, page });
  };

  return (
    <aside className={classes.root}>
      <DialogTitle className={classes.title}>
        <Grid container spacing={0} justifyContent="flex-end">
          <Button className={classes.skipButton} onClick={onEnd}>
            {page !== totalPages - 1 ? 'Skip' : 'Close'}
          </Button>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.content}>{getContent()}</DialogContent>
      <DialogActions className={classes.actions}>
        {page !== 0 && (
          <Grid container spacing={0}>
            <Grid xs={4}>
              <Button onClick={previousPage}>Previous</Button>
            </Grid>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              xs={4}
              spacing={0}
            >
              <div className={classes.dotsContainer}>
                {new Array(totalPages).fill(null).map((p, idx) => (
                  <span
                    onClick={() => setPage(idx)}
                    className={clsx(
                      classes.pageDot,
                      page === idx && classes.pageDotActive
                    )}
                  ></span>
                ))}
              </div>
            </Grid>
            <Grid container xs={4} justifyContent="flex-end" spacing={0}>
              {page < totalPages - 1 ? (
                <Button onClick={nextPage}>Next</Button>
              ) : (
                <span></span> /* as its grid we need to have any object in dom */
              )}
            </Grid>
          </Grid>
        )}
      </DialogActions>
    </aside>
  );
};
