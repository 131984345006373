import { useRef, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
} from '@mui/x-data-grid';
import GetAppIcon from '@mui/icons-material/GetApp';
import { wmPalette, pxToRemCssValue } from '../../Theme/WorldSportsGroupTheme';
import GenericModal from '../Common/GenericModal/GenericModal';
import ExportModal from './ExportModal';
import Loading from '../Loading/Loading';
import clsx from 'clsx';
import Grid from '@mui/material/Unstable_Grid2';

const getExcelExportUrl = (eventId, editionId) =>
  `/ro/events/${eventId}/races/${editionId}/orders/xlsexport/email/ron`;
const getCustomExportUrl = (eventId, editionId) =>
  `/ro/events/${eventId}/races/${editionId}/orders/custom-export`;

const locales = {
  noRowsLabel: 'No results found.',
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 10,
    paddingTop: theme.spacing(2),
    backgroundColor: wmPalette.white[100],
    '& .MuiDataGrid-columnsContainer': {
      backgroundColor: wmPalette.white[100],
    },
    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
  },
  noBorder: {
    border: 'none',
  },
  toolbarButton: {
    padding: '4px 5px 4px 8px',
  },
  exportButton: {
    fontSize: pxToRemCssValue(13),
    fontWeight: 600,
    whiteSpace: 'noWrap',
  },
}));
const ROW_HEIGHT = 60;
export const Table = ({
  rows,
  columns,
  reportType,
  loading,
  hasCustomExportEnabled,
  ...tableProps
}) => {
  const { eventId, editionId } = useParams();
  const classes = useStyles();
  const exportButtonRef = useRef();
  const [pageSize, setPageSize] = useState(10);
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const [clicking, setClicking] = useState(false);
  const [excelExportModal, toggleExcelExportModal] = useState(false);
  const [customExportModal, toggleCustomExportModal] = useState(false);

  const onExportClick = (e) => {
    if (!clicking) {
      setIsWarningModalOpen(true);
    }
  };

  const handleModalClose = (e) => {
    e.stopPropagation();
    setIsWarningModalOpen(false);
    setClicking(true);
  };

  useEffect(() => {
    if (clicking) {
      exportButtonRef.current.click();
    }
  }, [clicking]);

  const LoadingIndicator = () => (
    <Loading disableElevation={true} isTable={true} variant="spinner" />
  );

  const CustomNoRowsOverlay = () => (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      direction="column"
      spacing={0}
    >
      <Typography>You have no orders yet</Typography>
    </Grid>
  );

  const Toolbar = () => (
    <GridToolbarContainer>
      <GridToolbarColumnsButton className={classes.toolbarButton} />
      <GridToolbarFilterButton className={classes.toolbarButton} />
      <i onClick={onExportClick}>
        <GridToolbarExport
          className={classes.toolbarButton}
          ref={exportButtonRef}
          printOptions={{ disableToolbarButton: true }}
        />
      </i>
      <Button
        className={clsx(classes.exportButton, classes.toolbarButton)}
        startIcon={<GetAppIcon fontSize="small" />}
        onClick={() => toggleExcelExportModal(true)}
      >
        <Typography variant="inherit">Export Excel</Typography>
      </Button>
      {hasCustomExportEnabled && (
        <Button
          className={clsx(classes.exportButton, classes.toolbarButton)}
          startIcon={<GetAppIcon fontSize="small" />}
          onClick={() => toggleCustomExportModal(true)}
        >
          <Typography variant="inherit">Custom Export</Typography>
        </Button>
      )}
    </GridToolbarContainer>
  );

  const handlePageSize = (x) => setPageSize(x);

  return (
    <Box className={classes.root}>
      <div style={{ height: pageSize * ROW_HEIGHT + 200, width: '100%' }}>
        <DataGrid
          {...tableProps}
          className={classes.noBorder}
          rows={rows ?? []}
          columns={columns}
          pageSize={pageSize}
          onPageSizeChange={handlePageSize}
          rowsPerPageOptions={[5, 10, 25]}
          rowHeight={ROW_HEIGHT}
          localeText={locales}
          components={{
            Toolbar,
            LoadingOverlay: LoadingIndicator,
            NoRowsOverlay: CustomNoRowsOverlay,
          }}
          disableSelectionOnClick
          loading={loading}
        />
      </div>
      {isWarningModalOpen && (
        <GenericModal
          open={isWarningModalOpen}
          handleClose={handleModalClose}
          content={
            <>
              This option generates a CSV file based on columns and filters
              selected in this view.
              <br />A CSV file can be opened in all popular spreadsheet
              applications such as Microsoft Excel and Google Sheets, or
              uploaded to a registration or timing system.
              <br />
              <br />
              To obtain all (unfiltered) data in MS Excel format, please click
              on “Export Excel”.
            </>
          }
        />
      )}
      {excelExportModal && (
        <ExportModal
          open={excelExportModal}
          onClose={() => toggleExcelExportModal(false)}
          reportType={reportType}
          title={'Excel Export'}
          exportURL={getExcelExportUrl(eventId, editionId)}
          description={
            <>
              This option generates a XLSX file (MS Excel file) containing
              unfiltered data.
              <br />
              This file can be opened in all popular spreadsheet applications
              such as Microsoft Excel, Google Sheets, etc. <br />
              We will send you a link to the file download once the list is
              processed.
            </>
          }
          buttonText={'Download as Excel'}
        />
      )}
      {customExportModal && (
        <ExportModal
          open={customExportModal}
          onClose={() => toggleCustomExportModal(false)}
          reportType={reportType}
          title={'Custom Export'}
          exportURL={getCustomExportUrl(eventId, editionId)}
          description={
            <>
              This option generates a custom CSV file download, specific to your
              requirements.
              <br />
              We will send you a link to the file download once the file is
              processed.
              <br />
              In case your requirements changed, please contact our Customer
              Success team.
            </>
          }
          buttonText={'Download Custom Export'}
        />
      )}
    </Box>
  );
};
