import React, { useState, useContext, useEffect } from 'react';
import { Drawer, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { Context, actionTypes } from '../../../../Stores/EventInfoStore';
import FormikTextField from '../../../Common/Formik/FormikTextField';
import AddOnMultipleOptionsSelector from './AddOnMultipleOptionsSelector';
import AddOnBasicInformation from './AddOnBasicInformation';
import AddOnAdditionalInformation from './AddOnAdditonalInformation';
import AddOnVariantSelector from './AddOnVariantSelector';
import ConfirmationModal from '../../../Common/ConfirmationModal/ConfirmationModal';
import DrawerActions from '../../../Common/Drawer/DrawerActions';
import WrappedDivider from './WrappedDivider';
import { getValidationSchema } from '../CreateAddOnFormValidationSchema';
import { useApi, methods } from '../../../../Hooks/useApi';
import { mapAddOnForApiCall } from '../addOnsUtils';
import Grid from '@mui/material/Unstable_Grid2';
import { mutate } from 'swr';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.up('sm')]: {
      width: 567,
    },
    [theme.breakpoints.down('lg')]: {
      paddingBottom: theme.spacing(15),
    },
  },
  addOnLabel: {
    marginBottom: theme.spacing(1.5),
  },
}));

const CreateAddOnFormDrawer = ({ open, onClose, addOnState, addOns }) => {
  const classes = useStyles();
  const [showDialog, setShowDialog] = useState(false);
  const [eventInfo, dispatch] = useContext(Context);
  const { eventCurrency } = eventInfo.basicInfo;
  const { executeApiCall } = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const { eventId, editionId } = eventInfo.event || {};
  const { event } = eventInfo;
  const [addon, setAddon] = useState({
    name: '',
    active: true,
    description: '',
    price: 0,
    vat: 0,
    required: null,
    multipleOptions: null,
    availableToTickets: [],
    addOnVariant: {
      name: '',
      options: [],
    },
  });

  useEffect(() => {
    const { isDuplicate, isNew } = addOnState;
    if (isDuplicate) {
      // Destructure addon to take ID out
      delete addOnState.addOn.id;
      setAddon({
        ...structuredClone(addOnState.addOn),
        name: 'Copy of ' + addOnState.addOn.name,
      }); /* Prepend addon name with "Copy of" */
    }
    if (!isNew) {
      setAddon(addOnState.addOn);
    }
  }, [open, addOnState]);

  const onDrawerClose = (isDirty) => () => {
    if (isDirty) {
      setShowDialog(true);
    } else {
      onClose();
    }
  };

  const closeDialog = () => () => {
    setShowDialog(false);
  };

  const closeDialogDrawer = () => () => {
    setShowDialog(false);
    onClose();
  };

  const handleSave = async (values) => {
    try {
      const mappedAddOn = mapAddOnForApiCall(values, eventInfo);
      const callData = {
        url:
          `/addon/${eventId}/${editionId}` +
          (!addOnState.isNew ? `/${addOnState.addOn.id}` : ''),
        method: addOnState.isNew ? methods.post : methods.put,
      };

      const addOnId = await executeApiCall(
        callData.url,
        callData.method,
        mappedAddOn
      );

      dispatch({
        type: addOnState.isNew
          ? actionTypes.addons_add
          : actionTypes.addons_update,
        payload: {
          ...mappedAddOn,
          ...(addOnState.isNew ? { id: addOnId } : {}),
        },
      });
      mutate(`/event/${event.eventId}/edition/${event.editionId}`);
      onClose();
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' });
    }
  };

  return (
    <>
      <Formik
        initialValues={addon}
        validationSchema={() => getValidationSchema(addOns)}
        onSubmit={handleSave}
        enableReinitialize
      >
        {(formik) => (
          <Drawer
            anchor="right"
            open={open}
            onClose={onDrawerClose(formik.dirty)}
          >
            <form onReset={formik.handleReset} onSubmit={formik.handleSubmit}>
              <Grid
                container
                justifyContent="center"
                className={classes.root}
                spacing={0}
              >
                <Grid
                  xs={12}
                  sm={11}
                  direction="row"
                  justifyContent="flex-start"
                  container
                  spacing={2}
                >
                  <Grid xs={12}>
                    <Typography className={classes.addOnLabel} variant="h3">
                      {addOnState.isNew ? 'Add add-on' : 'Edit add-on'}
                    </Typography>
                  </Grid>
                  <Grid xs={12}>
                    <FormikTextField
                      variant="outlined"
                      label="Name"
                      placeholder="Name"
                      required
                      fullWidth
                      name="name"
                      helperText="e.g.: “T-shirts”, “Shuttle to the starting point”, “Shipping fee”, etc"
                    />
                  </Grid>

                  <WrappedDivider />
                  <AddOnMultipleOptionsSelector formik={formik} />

                  <WrappedDivider />
                  <AddOnBasicInformation
                    formik={formik}
                    eventId={eventId}
                    editionId={editionId}
                    currency={eventCurrency}
                  />

                  {formik.values.multipleOptions === 'true' && (
                    <>
                      <WrappedDivider />
                      <AddOnVariantSelector
                        addOnVariant={formik.values.addOnVariant}
                        handleChange={formik.handleChange}
                        formik={formik}
                      />
                    </>
                  )}

                  <AddOnAdditionalInformation
                    formik={formik}
                    eventInfo={eventInfo}
                  />

                  <DrawerActions
                    onClose={onDrawerClose(formik.dirty)}
                    handleSubmit={formik.handleSubmit}
                    isSubmitting={formik.isSubmitting}
                    submitText={'Save'}
                    cancelText={addOnState.isNew ? 'Cancel' : 'Close'}
                    submittingMessage={'Saving...'}
                  />
                </Grid>
              </Grid>
              {showDialog && (
                <ConfirmationModal
                  title="You haven't saved your work!"
                  content="Closing this will discard any unsaved actions."
                  cancelText="Close & Discard"
                  confirmText="Save"
                  additionalText="Continue editing"
                  open={showDialog}
                  handleAdditional={closeDialog()}
                  confirm={() => {
                    closeDialog()();
                    formik.handleSubmit();
                  }}
                  cancel={closeDialogDrawer()}
                />
              )}
            </form>
          </Drawer>
        )}
      </Formik>
    </>
  );
};

export default CreateAddOnFormDrawer;
