import React from 'react';
import { Button, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';
import Grid from '@mui/material/Unstable_Grid2';
const CouponsEmptyState = ({ handleAddCoupon }) => {
  return (
    <Grid
      container
      spacing={3}
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Grid>
        <Typography>You have no coupons yet</Typography>
      </Grid>
      <Grid>
        <Button
          onClick={() => handleAddCoupon()}
          variant="contained"
          color="primary"
          startIcon={<Add />}
        >
          Start adding coupons
        </Button>
      </Grid>
    </Grid>
  );
};

export default CouponsEmptyState;
