import React from 'react';
import { MenuItem } from '@mui/material';
import FormikTextField from '../../Common/Formik/FormikTextField';

const TicketSelect = ({ tickets, setTicket }) => (
  <FormikTextField
    name="ticketId"
    label="Select ticket"
    fullWidth
    select
    required
    onChange={(e) => setTicket(e.target.value)}
  >
    {tickets
      .filter((t) => !t.teamTicket)
      .map((t) => (
        <MenuItem key={t.id} value={t.id}>
          {t.name}
        </MenuItem>
      ))}
  </FormikTextField>
);

export default TicketSelect;
