import { Divider } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}));

const WrappedDivider = () => {
  const classes = useStyles();
  return (
    <Grid xs={12}>
      <Divider className={classes.divider} />
    </Grid>
  );
};

export default WrappedDivider;
