import React from 'react';
import {
  Dialog,
  DialogContent,
  Typography,
  useTheme,
  DialogTitle,
  IconButton,
  DialogActions,
  Button,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Close } from '@mui/icons-material';
import useMediaQuery from '@mui/material/useMediaQuery';

const useStyles = makeStyles((theme) => ({
  imgWidth: {
    width: '80%',
  },
  exampleImage: { width: '50%' },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  center: {
    textAlign: 'center',
  },
}));

const ImagePreviewModal = ({ image, open, handleClose, isExampleImage }) => {
  const theme = useTheme();
  const classes = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen={fullScreen}
      fullWidth
      maxWidth={'md'}
    >
      {!isExampleImage && (
        <DialogTitle>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
            size="large"
          >
            <Close />
          </IconButton>
        </DialogTitle>
      )}
      <DialogContent className={classes.center}>
        <img
          className={isExampleImage ? classes.exampleImage : classes.imgWidth}
          src={image.path ?? image.preview}
          alt="title shot preview"
        />
        <Typography>{image.name}</Typography>
      </DialogContent>
      {isExampleImage && (
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default ImagePreviewModal;
