const config = {
  API_URL: process.env.REACT_APP_API_URL,
  TRACKING_CODE: process.env.REACT_APP_TRACKING_CODE,
  IS_DEVELOPMENT_ENV: process.env.REACT_APP_ENVIRONMENT === 'development',
  IS_TESTING_ENV: process.env.REACT_APP_ENVIRONMENT === 'testing',
  IS_PRODUCTION_ENV: process.env.REACT_APP_ENVIRONMENT === 'production',
  NODE_ENV: process.env.NODE_ENV,
  GOOGLE_API_KEY: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
  RECAPTCHA_KEY: process.env.REACT_APP_RECAPTCHA_KEY,
  WM_URL: process.env.REACT_APP_WM_URL,
  AHOTU_URL: process.env.REACT_APP_AHOTU_URL,
  TABLE_PAGE_SIZE: process.env.REACT_APP_TABLE_PAGE_SIZE,
};

export default config;
