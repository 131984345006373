import React from 'react';
import { Typography, Button, Fade } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import welcomeIconSvg from '../../images/welcome_icon.svg';
import page1Svg from '../../images/onboarding/welcome_page1.svg';
import page2Svg from '../../images/onboarding/welcome_page2.svg';
import page3Svg from '../../images/onboarding/welcome_page3.svg';
import clsx from 'clsx';
import BlueLink from '../Common/BlueLink/BlueLink';
import Grid from '@mui/material/Unstable_Grid2';
import { Emails } from '../../Utils/urls';

export const steps = {
  pages: [
    ({ classes, nextPage }) => {
      return (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="space-between"
          spacing={0}
        >
          <Grid>
            <div>
              <img src={welcomeIconSvg} />
            </div>
          </Grid>

          <section className={classes.text}>
            <Typography variant="h4">Welcome to the new Race Office</Typography>
            <Typography className={classes.innerText}>
              The new RaceOffice has been rebuilt from the ground up for a fast,
              easy and user-friendly experience!
            </Typography>
          </section>
          <Grid>
            <Button
              color="primary"
              variant="outlined"
              onClick={nextPage}
              endIcon={<ArrowForwardIosIcon fontSize="small" />}
            >
              Whats new?
            </Button>
          </Grid>
        </Grid>
      );
    },
    ({ classes, page }) => {
      const contents = [
        {
          image: page1Svg,
          text: 'Smooth event creation and reactivation process',
        },
        {
          image: page2Svg,
          text: 'Clear, detailed dashboard to monitor ticket sales and performance',
        },
        {
          image: page3Svg,
          text: 'Customisable order and participant export functionalities',
        },
      ];
      return (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="space-between"
          className={classes.imagesSteps}
          spacing={0}
        >
          <div className={classes.imagesContainer}>
            {contents.map(({ text, image }, i) => (
              <Fade timeout={400} key={text} in={page === i + 1}>
                <img
                  className={classes.stepImage}
                  src={image}
                  alt="Feature image"
                />
              </Fade>
            ))}
          </div>

          <section className={clsx(classes.text, classes.textSameMargin)}>
            <Typography variant="body1">{contents[page - 1].text}</Typography>
          </section>
        </Grid>
      );
    },
    ({ classes }) => {
      return (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={0}
        >
          <Typography
            variant="h4"
            align="center"
            className={classes.lastStepHeading}
          >
            More awesome features are on the way!
          </Typography>
          <section className={classes.text}>
            <Typography>
              <p>
                We are constantly adding new features and improving usability!
              </p>
              <p>
                Enjoy exploring your new RaceOffice and contact us if you have
                any questions, wishes or ideas for improvements!
                <br />
                <BlueLink
                  href={`mailto:${Emails.Partners}`}
                  text={Emails.Partners}
                ></BlueLink>
              </p>
            </Typography>
          </section>
        </Grid>
      );
    },
  ],
};
