import React, { useEffect, useState } from 'react';
import { Currencies } from '../../../Consts/currencies';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

const alwaysAllowedCurrencies = ['eur', 'gbp', 'usd', 'sek'];

const CurrencyPicker = ({
  eventCurrency,
  errors,
  touched,
  handleBlur,
  setFieldValue,
  disabled = false,
  isUpdate,
}) => {
  const [currency, setCurrency] = useState(null);

  useEffect(() => {
    if (eventCurrency)
      setCurrency(Currencies.find((x) => x.code === eventCurrency));
    else setCurrency(null);
  }, [eventCurrency]);

  useEffect(() => {
    if (!isUpdate && !alwaysAllowedCurrencies.includes(eventCurrency)) {
      setFieldValue('eventCurrency', '');
      setCurrency(null);
    }
  }, []);

  return (
    <Autocomplete
      id="eventCurrency"
      style={{ width: '100%' }}
      value={currency}
      options={Currencies.filter((c) => {
        if (
          alwaysAllowedCurrencies.includes(c.code) ||
          (isUpdate && eventCurrency === c.code)
        ) {
          return c;
        }
      })}
      classes={{}}
      autoHighlight
      disabled={disabled}
      getOptionLabel={(option) =>
        `${option.label} (${option.code.toUpperCase()})`
      }
      onChange={(e, v) => setFieldValue('eventCurrency', v?.code ?? '')}
      renderInput={(params) => (
        <TextField
          {...params}
          error={touched.eventCurrency && Boolean(errors.eventCurrency)}
          onBlur={handleBlur}
          name="eventCurrency"
          fullWidth
          label="Currency"
          placeholder="Currency you will use for your set up"
          variant="outlined"
          required
          inputProps={{ ...params.inputProps }}
          helperText={
            errors.eventCurrency ||
            'After the first ticket is sold the currency can not be changed anymore.'
          }
          disabled={disabled}
        />
      )}
    />
  );
};

export default CurrencyPicker;
