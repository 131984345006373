import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  List,
  ListItem,
  Typography,
  IconButton,
  ListItemIcon,
  ListItemText,
  Divider,
  LinearProgress,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Delete, DragHandle, Visibility } from '@mui/icons-material';
import ImagePreviewModal from './ImagePreviewModal';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';

const useStyles = makeStyles((theme) => ({
  lowOpacity: {
    opacity: '60%',
  },
  firstItemDivider: {
    marginTop: theme.spacing(2),
  },
  boldText: {
    fontWeight: 'bold',
  },
}));

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const ImagePreview = ({
  files,
  setImages,
  isUpdate,
  onDelete,
  showHelpText,
  isLoading,
  tempFiles,
}) => {
  const classes = useStyles();
  const [deleteModal, toggleDeleteModal] = useState({
    open: false,
    id: '',
  });

  const [previewModal, toggleModal] = useState({
    open: false,
    image: {},
  });
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    setImages &&
      setImages(reorder(files, result.source.index, result.destination.index));
  };

  const deleteImage = (id) => {
    if (!!setImages) {
      setImages(files.filter((f, _) => f.id !== id));
    } else {
      onDelete(id);
    }

    toggleDeleteModal({ open: false });
  };

  const showImagePreview = (image) => {
    toggleModal({ open: true, image: image });
  };

  return (
    <>
      {showHelpText && (
        <>
          <Typography className={classes.lowOpacity} variant="subtitle2">
            The first image will be your main image (always visible on your
            event page)
          </Typography>
          <Typography className={classes.lowOpacity} variant="body2">
            You can reorder the images
          </Typography>
        </>
      )}
      <Divider />
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="images">
          {(provided) => (
            <List ref={provided.innerRef} {...provided.droppableProps}>
              {files.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided) => (
                    <>
                      <ListItem
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        {files.length > 1 && (
                          <ListItemIcon>
                            <DragHandle fontSize="large" />
                          </ListItemIcon>
                        )}
                        <ListItemText
                          primary={
                            <Typography
                              className={index === 0 ? classes.boldText : null}
                            >
                              {item.name ?? `Image ${index + 1}`}
                            </Typography>
                          }
                          secondary={
                            <Typography
                              className={index === 0 ? classes.boldText : null}
                              variant="caption"
                              color="textSecondary"
                            >
                              {Boolean(item.size)
                                ? `${item.size / 1000} KB`
                                : 'Image size not available'}
                            </Typography>
                          }
                        />
                        <IconButton
                          onClick={() => showImagePreview(item)}
                          size="large"
                        >
                          <Visibility fontSize="default" />
                        </IconButton>
                        <IconButton
                          onClick={() =>
                            toggleDeleteModal({ open: true, id: item.id })
                          }
                          size="large"
                        >
                          <Delete fontSize="default" />
                        </IconButton>
                      </ListItem>
                      <Divider />
                      {index === 0 && files.length > 1 && (
                        <Divider className={classes.firstItemDivider} />
                      )}
                    </>
                  )}
                </Draggable>
              ))}
              {isLoading &&
                tempFiles.map((item, index) => (
                  <Draggable
                    key={files.length + index}
                    draggableId={String(files.length + index)}
                    index={files.length + index}
                    isDragDisabled={true}
                  >
                    {(provided) => (
                      <>
                        <ListItem
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          {files.length > 1 && (
                            <ListItemIcon>
                              <DragHandle fontSize="large" />
                            </ListItemIcon>
                          )}
                          <ListItemText
                            primary={
                              <Typography
                                className={
                                  index === 0 ? classes.boldText : null
                                }
                              >
                                {item.name ?? `Image ${index + 1}`}
                              </Typography>
                            }
                            secondary={
                              <>
                                <Typography
                                  className={
                                    index === 0 ? classes.boldText : null
                                  }
                                  variant="caption"
                                  color="textSecondary"
                                >
                                  {Boolean(item.size)
                                    ? `${item.size / 1000} KB`
                                    : 'Image size not available'}
                                </Typography>
                                <LinearProgress />
                              </>
                            }
                          />
                          <IconButton size="large">
                            <Visibility fontSize="default" />
                          </IconButton>
                          <IconButton size="large">
                            <Delete fontSize="default" />
                          </IconButton>
                        </ListItem>
                        <Divider />
                        {index === 0 && tempFiles.length > 1 && (
                          <Divider className={classes.firstItemDivider} />
                        )}
                      </>
                    )}
                  </Draggable>
                ))}
              {provided.placeholder}
            </List>
          )}
        </Droppable>
      </DragDropContext>
      {previewModal.open && (
        <ImagePreviewModal
          handleClose={() => toggleModal({ open: false })}
          image={previewModal.image}
          open={previewModal.open}
          isUpdate={isUpdate}
        />
      )}
      {deleteModal.open && (
        <ConfirmationModal
          cancel={() => toggleDeleteModal({ open: false })}
          confirm={() => deleteImage(deleteModal.id)}
          open={deleteModal.open}
          title="Are you sure you want to delete the image?"
          cancelText="No, keep"
          confirmText="Yes, delete"
        />
      )}
    </>
  );
};

export default ImagePreview;
