import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

const GenericModal = ({
  open,
  handleClose,
  title,
  content,
  closeText = 'Close',
}) => (
  <Dialog open={open} onClose={handleClose}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <DialogContentText>{content}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} color="secondary">
        {closeText}
      </Button>
    </DialogActions>
  </Dialog>
);

export default GenericModal;
