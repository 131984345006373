import * as distanceType from '../Consts/distanceType';
export const kmToMiles = (kmDistance) => (kmDistance / 1.609).toFixed(2);

export const milesToKm = (miDistance) => (miDistance * 1.609).toFixed(2);

export const distanceOptions = [
  distanceType.marathon,
  distanceType.halfMarathon,
  5000,
  10000,
  20000,
  30000,
  100000,
];

export const getDistanceLabel = (distance) => {
  switch (distance) {
    case distanceType.marathon:
      return 'Marathon';
    case distanceType.halfMarathon:
      return 'Half-Marathon';
    case 'custom':
      return 'custom';
    default:
      return `${distance / 1000} Km / ${kmToMiles(distance / 1000)} mi`;
  }
};

export const getAvatarColor = (distance) => {
  if (distance === distanceType.marathon) return 'avatarMarathon';
  if (distance === distanceType.halfMarathon) return 'avatarHalf';
  return '';
};

export const distanceAvatarMapping = {
  42195: { avatar: 'M' },
  21098: { avatar: 'H' },
  5000: { avatar: '5K' },
  10000: { avatar: '10K' },
  20000: { avatar: '20k' },
  30000: { avatar: '30k' },
  100000: { avatar: '100k' },
  custom: { avatar: 'Custom' },
};

export const getAvatar = (distance) => {
  const isCustomDistance = !distanceOptions.includes(distance);
  const distanceKey = isCustomDistance ? 'custom' : distance;
  return distanceAvatarMapping[distanceKey]
    ? distanceAvatarMapping[distanceKey].avatar
    : distance;
};
