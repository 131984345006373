import React from 'react';
import { Card, Typography, Link } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { wmPalette } from '../../Theme/WorldSportsGroupTheme';
import { getWMUrl, getAhotuUrl } from '../../Utils/wmUtils';
import Grid from '@mui/material/Unstable_Grid2';
const useStyles = makeStyles((theme) => ({
  eventCard: {
    padding: theme.spacing(4),
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(2),
    },
  },
  mt: {
    marginTop: theme.spacing(2),
  },
  link: {
    color: wmPalette.blue[100],
    textDecoration: 'none',
  },
  paddingZero: {
    padding: 0,
  },
}));

const EventPages = ({ eventId, eventTitle, permalink }) => {
  const classes = useStyles();
  return (
    <Card className={classes.eventCard}>
      <Grid
        container
        direction="column"
        spacing={0}
        className={classes.paddingZero}
      >
        <Typography variant="h5">Event page URL for {eventTitle}</Typography>
        <Typography className={classes.mt} variant="body1">
          World’s&nbsp;Marathons:&nbsp;
          <Link
            className={classes.link}
            target="_blank"
            rel="noopener"
            href={getWMUrl(eventId)}
          >
            {getWMUrl(eventId)}
          </Link>
        </Typography>
        {permalink && (
          <Typography className={classes.mt} variant="body1">
            Ahotu:&nbsp;
            <Link
              className={classes.link}
              target="_blank"
              rel="noopener"
              href={getAhotuUrl(permalink)}
            >
              {getAhotuUrl(permalink)}
            </Link>
          </Typography>
        )}
      </Grid>
    </Card>
  );
};

export default EventPages;
