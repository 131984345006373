import React from 'react';
import { Card, Typography, Modal, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Close as CloseIcon } from '@mui/icons-material';
import ReactCountryFlag from 'react-country-flag';
import { wmPalette } from '../../Theme/WorldSportsGroupTheme';
import Nationalities from './Nationalities';
import Grid from '@mui/material/Unstable_Grid2';
const useStyles = makeStyles((theme) => ({
  modal: {
    overflow: 'scroll',
    outline: 'none',
  },
  card: {
    width: '50vw',
    [theme.breakpoints.down('lg')]: {
      width: '80vw',
    },
    marginTop: theme.spacing(4),
    margin: 'auto',
    padding: theme.spacing(4),
  },
  crossIconBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '50px',
    height: '50px',
    cursor: 'pointer',
  },
  idBox: {
    width: '24px',
  },
  flagBox: {
    height: '24px',
    width: '24px',
    borderRadius: '48px',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  nationBox: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.spacing(1),
  },
  leftBold: {
    marginLeft: 'auto',
    fontWeight: 'bold',
  },
  close: {
    fontWeight: 'bold',
    color: wmPalette.blue[100],
    cursor: 'pointer',
    marginTop: theme.spacing(4),
  },
}));

const NationalitiesModal = ({
  open,
  handleClose,
  nationalities,
  uniqueNationalities,
}) => {
  const classes = useStyles();
  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={() => handleClose(false)}
      sx={{ outline: 'none' }}
      disableAutoFocus={true}
      disableEnforceFocus={true}
    >
      <Card className={classes.card}>
        <Grid container direction="column" spacing={4}>
          <Grid container direction="row" justifyContent="flex-end" spacing={0}>
            <Box
              className={classes.crossIconBox}
              onClick={() => handleClose(false)}
            >
              <CloseIcon />
            </Box>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={0}
          >
            <Grid>
              <Typography variant="h5">
                Nationalities of your participants
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={0}>
            <Typography>
              You’ve got{' '}
              <span style={{ fontWeight: 'bold' }}>
                {uniqueNationalities} nationalities
              </span>{' '}
              joining this event.
            </Typography>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            spacing={0}
          >
            <Nationalities nationalities={nationalities} />
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              spacing={0}
            >
              <Typography
                className={classes.close}
                onClick={() => handleClose(false)}
              >
                CLOSE
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </Modal>
  );
};

export default NationalitiesModal;
