import { useApi, methods } from '../Hooks/useApi';
import config from '../config';
import { orderUrl, formatData } from './useOrders';
const useFetchAllPages = ({ eventId, editionId }) => {
  const { executeApiCall, apiError } = useApi();
  let error;
  const allPagesData = [];
  const pageSize = config.TABLE_PAGE_SIZE;
  async function fetchPage(continuationToken = '') {
    let queryParams = `?showAll=true&pageSize=${pageSize}&pagination=true`;
    try {
      let headers =
        continuationToken !== ''
          ? { ContinuationToken: continuationToken }
          : null;
      const response = await executeApiCall(
        `${orderUrl(eventId, editionId)}${queryParams}`,
        methods.get,
        null,
        headers
      );
      if (apiError) {
        throw new Error(apiError);
      }
      const { orders, continuationToken: newContinuationToken } = response;
      allPagesData.push(...orders);
      //Check if there are more pages to fetch
      if (newContinuationToken) {
        await fetchPage(newContinuationToken); // Fetch the next page
      }
    } catch (apiError) {
      error = apiError;
      console.error('Error fetching data:', error);
    }
  }
  const fetchAllPages = async () => {
    await fetchPage(); // Start fetching the first page

    let orders = [];
    let participants = [];
    let count = 0;
    const data = {
      count: allPagesData.length,
      orders: allPagesData,
    };
    if (data) {
      let formattedData = formatData(data, orders, participants, count);
      orders = formattedData.orders;
      participants = formattedData.participants;
      count = formattedData.count;
    }
    return {
      orders,
      participants,
      count,
      isLoading: !error && !data,
      isError: error,
    };
  };
  return { fetchAllPages };
};
export default useFetchAllPages;
