import React, { useContext } from 'react';
import { isEmpty } from 'lodash';
import { Table } from './Table';
import { formatDateTime, formatDate, formatPrice } from '../../Format';
import { file_download } from '../../Consts/inputType';
import { number } from '../../Consts/customQuestionInputValidationType';
import { countries } from '../../Consts/countries';
import { Context } from '../../Stores/EventInfoStore';
import OrderStatusPill from './OrderStatusPill';
import EmailWithContextOption from '../Common/EmailWithContextOption/EmailWithContextOption';
import { PARTICIPANT_BREAKDOWN } from './reportType';

const tableTypes = {
  order: 0,
  participants: 1,
};

const emergencyContactLabels = [
  'Emergency contact',
  'In case of emergency contact',
];

const headerNames = {
  emergencyContactName: 'Emergency contact name',
  emergencyContactRelationship: 'Emergency contact relationship',
  emergencyContactPhone: 'Emergency contact phone',
  address: 'Address',
  addressCode: 'Zip/Postal code',
  addressCity: 'City',
  addressState: 'State',
  addressCountry: 'Country',
  addressCountryCode: 'Country Code',
};
const columns = (participants, customQuestions, addons, currency) => {
  let cols = [
    {
      field: 'orderReference',
      headerName: 'Order reference',
      width: 160,
      tableTypes: [tableTypes.order, tableTypes.participants],
    },
    {
      field: 'orderDate2',
      headerName: 'Order date',
      type: 'dateTime',
      width: 180,
      valueGetter: (params) => new Date(params.row.orderDate),
      valueFormatter: (params) => formatDateTime(params.value),
    },
    {
      field: 'statusTitle',
      headerName: 'Order status',
      width: 150,
      renderCell: (params) => {
        return (
          <OrderStatusPill
            title={params.row.statusTitle}
            status={params.row.status}
            size="small"
          />
        );
      },
    },
    {
      field: 'coupon?.code',
      headerName: 'Coupon',
      valueGetter: (params) => {
        const tickets = params.row.orderLines.filter(
          (l) => l.productType === 'race'
        );
        const productDiscountCodes = tickets.map((t) => t.productDiscountCode);
        return !isEmpty(params.row.coupon?.code)
          ? params.row.coupon.code
          : productDiscountCodes
          ? productDiscountCodes[0]
          : null;
      },
      width: 130,
    },
    {
      field: 'totalValue',
      headerName: 'Total value',
      type: 'number',
      width: 130,
      valueGetter: (params) =>
        params.row.originalTotalValue - params.row.addOnTotalPrice,
      valueFormatter: (params) => formatPrice(params.value, currency),
    },
    { field: 'productId', headerName: 'Ticket ID', width: 180 },
    { field: 'productName', headerName: 'Ticket name', width: 185 },
    {
      field: 'price',
      headerName: 'Price',
      width: 120,
      type: 'number',
      valueFormatter: (params) => formatPrice(params.value, currency),
    },
    { field: 'firstName', headerName: 'First name', width: 150 },
    { field: 'lastName', headerName: 'Last name', width: 150 },
    {
      field: 'email',
      headerName: 'Email address',
      width: 225,
      renderCell: (params) => (
        <EmailWithContextOption email={params.row.email} />
      ),
    },
    { field: 'gender', headerName: 'Gender', width: 120 },
    {
      field: 'birthDate2',
      headerName: 'Date of birth',
      type: 'dateTime',
      width: 160,
      valueGetter: (params) =>
        params.row.birthDate?.date ? new Date(params.row.birthDate.date) : null,
      valueFormatter: (params) =>
        params.value ? formatDate(params.value) : null,
    },
    {
      field: 'nationality',
      headerName: 'Nationality',
      width: 150,
      valueFormatter: (params) => countries[params.value]?.label,
    },
    {
      field: 'nationalityCode',
      headerName: 'Nationality Code',
      width: 150,
      valueGetter: (params) => params.row.nationality,
      valueFormatter: (params) => countries[params.value]?.code,
    },
    {
      field: 'ticketStatus2',
      headerName: 'Ticket status',
      width: 150,
      hide: true,
      valueGetter: (params) =>
        !isEmpty(params.row.ticketStatus)
          ? params.row.ticketStatus.title
          : null,
      renderCell: (params) => {
        return (
          !isEmpty(params.row.ticketStatus) && (
            <OrderStatusPill
              title={params.row.ticketStatus.title}
              status={params.row.ticketStatus.status}
              size="small"
            />
          )
        );
      },
    },
  ];

  if (customQuestions && participants) {
    addOptionalColumns(customQuestions, cols);
    addCustomColumns(
      customQuestions.filter((q) => !q.presetQuestionName),
      cols
    );
  }

  if (addons?.length && participants) {
    addons.forEach((a) => {
      addAddOnColumn(a, cols);
    });
  }

  return cols;
};

const addAddOnColumn = (addOn, cols) => {
  cols.push({
    field: addOn.name,
    headerName: addOn.name,
    width: 180,
    valueGetter: (params) => {
      const bought = params.row.addOns.find((x) => x.productId === addOn.id);
      if (bought) {
        let details = `1x`;
        if (bought.details) {
          const detailsArr = Object.entries(bought.details);
          let i = 0;
          for (const [key, value] of detailsArr) {
            details += ` ${key}: ${value}${
              i + 1 < detailsArr.length ? ',' : ''
            }`;
            i++;
          }
        }
        return details;
      }
    },
  });
};

const addCustomColumns = (customQuestions, cols) => {
  for (const question of customQuestions.filter(
    (q) => q.type !== null && q.type !== file_download
  )) {
    addCustomColumn(cols, question);

    for (const subQuestion of question.subQuestions?.filter(
      (q) => q.type !== file_download
    ) ?? [])
      addCustomColumn(cols, subQuestion);
  }
};

function addCustomColumn(cols, question) {
  cols.push({
    field: question.id,
    headerName: question.label,
    width: 180,
    valueGetter: (params) => {
      const extraInfo = params.row.extraInformationObject.find(
        (x) => x.questionId === question.id
      );
      if (extraInfo) {
        return extraInfo.value;
      }
      return params.row.extraInfo[question.label];
    },
    type: question.validation === number ? 'number' : 'string',
  });
}

const addOptionalColumns = (customQuestions, cols) => {
  addOptionalQuestionIfExists('Sports Club', customQuestions, cols);
  addOptionalQuestionIfExists('ID or Passport number', customQuestions, cols, {
    width: 180,
    renderHeader: () => multilineHeader('ID or Passport', 'number'),
  });
  addOptionalQuestionIfExists('Target Time', customQuestions, cols);
  addOptionalQuestionIfExists('T-shirt size', customQuestions, cols);
  addOptionalQuestionIfExists('Phone', customQuestions, cols, {
    width: 180,
    headerName: 'Phone number',
    valueGetter: (params) =>
      params.row.extraInfo.phone?.phone
        ? `${params.row.extraInfo?.phone?.code ?? ''} ${
            params.row.extraInfo?.phone?.phone
          }`
        : null,
  });
  emergencyContactLabels.forEach((label) => {
    addOptionalQuestionIfExists(label, customQuestions, cols, {
      field: 'emergencyContactName',
      width: 190,
      renderHeader: () => multilineHeader('Emergency contact', 'name'),
      valueGetter: (params) => params.row.extraInfo.ice?.name,
    });
    addOptionalQuestionIfExists(label, customQuestions, cols, {
      field: 'emergencyContactRelationship',
      width: 190,
      renderHeader: () => multilineHeader('Emergency contact', 'relationship'),
      valueGetter: (params) => params.row.extraInfo.ice?.relationship,
    });
    addOptionalQuestionIfExists(label, customQuestions, cols, {
      field: 'emergencyContactPhone',
      width: 190,
      renderHeader: () => multilineHeader('Emergency contact', 'phone number'),
      valueGetter: (params) =>
        params.row.extraInfo.ice?.phone
          ? `${params.row.extraInfo.ice?.code ?? ''} ${
              params.row.extraInfo.ice?.phone
            }`
          : null,
    });
  });
  addOptionalQuestionIfExists('Address', customQuestions, cols, {
    field: 'address',
    width: 200,
    valueGetter: (params) =>
      !params.row.extraInfo.address
        ? null
        : params.row.extraInfo.address.address,
  });
  addOptionalQuestionIfExists('Address', customQuestions, cols, {
    field: 'addressCode',
    width: 120,
    valueGetter: (params) =>
      !params.row.extraInfo.address ? null : params.row.extraInfo.address.zip,
  });
  addOptionalQuestionIfExists('Address', customQuestions, cols, {
    field: 'addressCity',
    width: 150,
    valueGetter: (params) =>
      !params.row.extraInfo.address ? null : params.row.extraInfo.address.city,
  });
  addOptionalQuestionIfExists('Address', customQuestions, cols, {
    field: 'addressState',
    width: 150,
    valueGetter: (params) =>
      !params.row.extraInfo.address ? null : params.row.extraInfo.address.state,
  });
  addOptionalQuestionIfExists('Address', customQuestions, cols, {
    field: 'addressCountry',
    width: 150,
    valueGetter: (params) =>
      !params.row.extraInfo.address
        ? null
        : countries[params.row.extraInfo.address.countryCode]?.label,
  });
  addOptionalQuestionIfExists('Address', customQuestions, cols, {
    field: 'addressCountryCode',
    width: 150,
    valueGetter: (params) =>
      !params.row.extraInfo.address || !params.row.extraInfo.address.countryCode
        ? null
        : countries[params.row.extraInfo.address.countryCode]?.code,
  });
  addOptionalQuestionIfExists('Food Preference', customQuestions, cols);
};

const addOptionalQuestionIfExists = (
  label,
  customQuestions,
  cols,
  additionalData = {}
) => {
  let question = customQuestions.find(
    (q) => q.label.toLowerCase() === label.toLowerCase()
  );
  if (question) {
    const useAdditionalDataField =
      emergencyContactLabels.includes(question.label) ||
      question.label === 'Address';
    cols.push({
      field: question.presetQuestionName,
      headerName: useAdditionalDataField
        ? headerNames[additionalData.field]
        : question.label,
      width: 150,
      valueGetter: (params) =>
        params.row.extraInfo[question.presetQuestionName],
      ...additionalData,
    });
  }
};

const multilineHeader = (line1, line2) => (
  <div>
    <div style={{ height: 20 }}>{line1}</div>
    <div>{line2}</div>
  </div>
);

const ParticipantsTable = ({ participants, goToOrderDetails, isLoading }) => {
  const [eventInfo] = useContext(Context);
  const { customQuestions, addOns, basicInfo } = eventInfo;
  const isDataLoaded = !isLoading && customQuestions && addOns;
  return (
    <Table
      onRowClick={goToOrderDetails}
      rows={participants ?? []}
      columns={columns(
        participants,
        customQuestions,
        addOns,
        basicInfo.eventCurrency
      )}
      reportType={PARTICIPANT_BREAKDOWN}
      loading={!isDataLoaded}
      hasCustomExportEnabled={eventInfo.hasCustomExportEnabled}
    />
  );
};

export default ParticipantsTable;
