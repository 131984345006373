import React from 'react';
import { Typography } from '@mui/material';
import SuccessBase from '../../Common/SuccessBase/SuccessBase';

const content = [
  <Typography gutterBottom variant="body1">
    Your event has been created and submitted for review by the RaceOffice team.
  </Typography>,
  <Typography variant="body1">
    We will notify you once your event has been reviewed and is published on the
    World's Marathons website.
  </Typography>,
];

const ConfirmationPage = () => (
  <SuccessBase
    title="Congratulations! Your event has been created and submitted for review!"
    contentArray={content}
  />
);

export default ConfirmationPage;
