import React from 'react';
import clsx from 'clsx';
import { Card, CardHeader, CardContent, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import BlockIcon from '@mui/icons-material/Block';
import KebabMenu from '../../Common/KebabMenu/KebabMenu';
import Grid from '@mui/material/Unstable_Grid2';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(1),
  },
  greyTypography: {
    color: theme.palette.grey[600],
  },
  cardHeader: {
    paddingBottom: theme.spacing(0.5),
  },
  cardContent: {
    paddingTop: 0,
  },
  disabledColor: {
    color: theme.palette.grey[400],
  },
  deactivatedLabel: {
    marginRight: theme.spacing(0.75),
  },
  kebabIcon: {
    marginRight: theme.spacing(1),
  },
}));

const AddOnListItem = ({
  addOn,
  classes: rootClasses,
  onEdit,
  onDelete,
  onToggleActive,
  currency,
  onDuplicate,
}) => {
  const classes = useStyles();
  return (
    <Card variant="outlined" className={classes.card}>
      <CardHeader
        className={clsx('identity', classes.cardHeader)}
        title={
          <Grid container direction="row" spacing={0}>
            <Grid>
              <Typography
                color="textSecondary"
                variant="body1"
                className={clsx(!addOn.active && classes.disabledColor)}
              >
                <strong>{addOn.name}</strong> /{' '}
                {!Boolean(addOn.addOnVariant?.options?.[0].name) &&
                  `${addOn.price ?? 0} ${currency?.toUpperCase()}`}
              </Typography>
            </Grid>
            {!addOn.active && (
              <>
                <Grid
                  className={clsx(
                    rootClasses.deactivatedLabel,
                    classes.deactivatedLabel
                  )}
                >
                  <Typography color="error">
                    <BlockIcon fontSize="small" />
                  </Typography>
                </Grid>
                <Grid>
                  <Typography color="error" variant="body2">
                    Deactivated
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
        }
        action={
          <KebabMenu
            optionActions={[
              {
                label: 'Edit',
                action: () => onEdit(addOn),
                icon: (
                  <EditIcon className={classes.kebabIcon} fontSize="small" />
                ),
              },
              {
                label: 'Duplicate',
                action: () => onDuplicate(addOn),
                icon: (
                  <ContentCopyIcon
                    className={classes.kebabIcon}
                    fontSize="small"
                  />
                ),
              },
              {
                label: addOn.active ? 'Deactivate' : 'Activate',
                action: () => onToggleActive(addOn.id, addOn.active),
                icon: addOn.active ? (
                  <DoNotDisturbIcon
                    className={classes.kebabIcon}
                    fontSize="small"
                  />
                ) : (
                  <CheckIcon className={classes.kebabIcon} fontSize="small" />
                ),
              },
              {
                label: 'Delete',
                action: () => onDelete(addOn.id),
                icon: (
                  <DeleteIcon className={classes.kebabIcon} fontSize="small" />
                ),
              },
            ]}
          />
        }
      />
      {addOn.addOnVariant?.options?.length &&
        addOn.addOnVariant.options.some((x) => !!x.name) && (
          <CardContent
            className={clsx(
              classes.cardContent,
              !addOn.active && classes.disabledColor
            )}
          >
            <Typography
              className={
                !addOn.active ? classes.disabledColor : classes.greyTypography
              }
            >
              {addOn.addOnVariant.name}
            </Typography>
            {addOn.addOnVariant.options.map((opt) => (
              <React.Fragment key={opt.name + opt.price}>
                <Typography>
                  -&nbsp;{opt.name}&nbsp;/&nbsp;{opt.price}&nbsp;
                  {currency.toUpperCase()}
                </Typography>
                {opt.requiredInformationLabel && (
                  <Typography>{opt.requiredInformationLabel}</Typography>
                )}
              </React.Fragment>
            ))}
          </CardContent>
        )}
    </Card>
  );
};

export default AddOnListItem;
