import { useEffect } from 'react';

const StopNumberInputScrolling = (updateEvents) => {
  useEffect(
    () => {
      const handleWheel = (e) => {
        e.preventDefault();
      };

      setTimeout(() => {
        const numberInputs = document.querySelectorAll('input[type="number"]');

        numberInputs.forEach((input) => {
          input.addEventListener('wheel', handleWheel, { passive: false });
        });

        return () => {
          numberInputs.forEach((input) => {
            input.removeEventListener('wheel', handleWheel);
          });
        };
      }, 0);
    },
    updateEvents
      ? Array.isArray(updateEvents)
        ? updateEvents
        : [updateEvents]
      : []
  );
};

export default StopNumberInputScrolling;
