export const extractFileName = (imageUrl) => {
  if (!!imageUrl) {
    const last = (imageUrl + '').lastIndexOf('/');
    if (last === -1) {
      return undefined;
    }

    return (imageUrl + '').substring(last + 1);
  }
  return undefined;
};
