import React, { useEffect } from 'react';
import { Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useDropzone } from 'react-dropzone';
import { CloudUpload } from '@mui/icons-material';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(() => ({
  button: {
    display: 'inline',
  },
}));

const FileUploadButton = ({
  setFile,
  errorMessage,
  fileType,
  fileTypeName = 'PDF',
  size,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    noDrag: true,
    maxFiles: 1,
    accept: fileType,
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length) setFile(acceptedFiles[0]);
    },
    maxSize: size ?? 10485760, //10MB
  });

  useEffect(() => {
    if (fileRejections.length)
      enqueueSnackbar(errorMessage ?? fileRejections[0].errors[0]?.message, {
        variant: 'error',
      });
  }, [fileRejections, enqueueSnackbar]);

  return (
    <div {...getRootProps()} className={classes.button}>
      <input {...getInputProps()} />
      <Button
        startIcon={<CloudUpload />}
        variant="outlined"
        size="large"
        color="secondary"
      >
        Upload {fileTypeName} file
      </Button>
    </div>
  );
};

export default FileUploadButton;
