const Currencies = [
  { code: 'sek', label: 'Swedish Krona' },
  { code: 'cad', label: 'Canadian Dollar' },
  { code: 'cny', label: 'Chinese Yuan', paymentExceptions: ['PAYPAL'] },
  {
    code: 'cop',
    label: 'Colombian Peso',
    paymentExceptions: ['AMEX', 'PAYPAL'],
  },
  { code: 'czk', label: 'Czech Republic Koruna', paymentExceptions: ['AMEX'] },
  { code: 'dkk', label: 'Danish Krone' },
  { code: 'hkd', label: 'Hong Kong Dollar' },
  { code: 'huf', label: 'Hungarian Forint', paymentExceptions: ['AMEX'] },
  { code: 'isk', label: 'Icelandic Króna', paymentExceptions: ['PAYPAL'] },
  { code: 'inr', label: 'Indian Rupee', paymentExceptions: ['AMEX'] },
  { code: 'ils', label: 'Israeli New Shekel' },
  { code: 'jpy', label: 'Japanese Yen' },
  { code: 'kes', label: 'Kenyan Shilling', paymentExceptions: ['PAYPAL'] },
  { code: 'krw', label: 'South Korean Won', paymentExceptions: ['PAYPAL'] },
  { code: 'myr', label: 'Malaysian Ringgit' },
  { code: 'mxn', label: 'Mexican Peso' },
  { code: 'mad', label: 'Moroccan Dirham', paymentExceptions: ['PAYPAL'] },
  { code: 'ngn', label: 'Nigeria Naira', paymentExceptions: ['PAYPAL'] },
  { code: 'nzd', label: 'New Zealand Dollar' },
  { code: 'nok', label: 'Norwegian Krone' },
  {
    code: 'pab',
    label: 'Panamanian Balboa',
    paymentExceptions: ['AMEX', 'PAYPAL'],
  },
  { code: 'qar', label: 'Qatari Rial', paymentExceptions: ['PAYPAL'] },
  { code: 'rub', label: 'Russian Ruble' },
  { code: 'sar', label: 'Saudi Riyal', paymentExceptions: ['PAYPAL'] },
  { code: 'sgd', label: 'Singapore Dollar' },
  { code: 'zar', label: 'South African Rand', paymentExceptions: ['PAYPAL'] },
  { code: 'chf', label: 'Swiss Franc' },
  { code: 'thb', label: 'Thai Baht' },
  {
    code: 'ttd',
    label: 'Trinidad and Tobago Dollar',
    paymentExceptions: ['PAYPAL'],
  },
  {
    code: 'aed',
    label: 'United Arab Emirates Dirham',
    paymentExceptions: ['PAYPAL'],
  },
  { code: 'gbp', label: 'British Pound Sterling' },
  { code: 'usd', label: 'US Dollar' },
  { code: 'twd', label: 'New Taiwan Dollar' },
  { code: 'ron', label: 'Romanian Leu', paymentExceptions: ['PAYPAL'] },
  { code: 'try', label: 'Turkish Lira', paymentExceptions: ['PAYPAL'] },
  { code: 'eur', label: 'Euro' },
  { code: 'uah', label: 'Ukrainian Hryvnia', paymentExceptions: ['PAYPAL'] },
  { code: 'pln', label: 'Polish Zloty' },
  { code: 'brl', label: 'Brazilian Real', paymentExceptions: ['AMEX'] },
  { code: 'rsd', label: 'Serbian Dinar', paymentExceptions: ['PAYPAL'] },
  { code: 'aud', label: 'Australian dollar' },
  { code: 'php', label: 'Philippine peso' },
  {
    code: 'afn',
    label: 'Afghan Afghani',
    paymentExceptions: ['PAYPAL', 'AMEX'],
  },
  { code: 'all', label: 'Albanian lek', paymentExceptions: ['PAYPAL'] },
  { code: 'amd', label: 'Armenian dram', paymentExceptions: ['PAYPAL'] },
  {
    code: 'ang',
    label: 'Netherlands Antillean guilder',
    paymentExceptions: ['PAYPAL'],
  },
  {
    code: 'aoa',
    label: 'Angolan kwanza',
    paymentExceptions: ['PAYPAL', 'AMEX'],
  },
  {
    code: 'ars',
    label: 'Argentine peso',
    paymentExceptions: ['PAYPAL', 'AMEX'],
  },
  { code: 'awg', label: 'Aruban florin', paymentExceptions: ['PAYPAL'] },
  { code: 'azn', label: 'Azerbaijan manat', paymentExceptions: ['PAYPAL'] },
  {
    code: 'bam',
    label: 'Bosnia and Herzegovina convertible mark',
    paymentExceptions: ['PAYPAL'],
  },
  { code: 'bbd', label: 'Barbadian dollar', paymentExceptions: ['PAYPAL'] },
  { code: 'bdt', label: 'Bangladeshi taka', paymentExceptions: ['PAYPAL'] },
  { code: 'bgn', label: 'Bulgarian lev', paymentExceptions: ['PAYPAL'] },
  { code: 'bif', label: 'Burundi franc', paymentExceptions: ['PAYPAL'] },
  { code: 'bmd', label: 'Bermudian dollar', paymentExceptions: ['PAYPAL'] },
  { code: 'bnd', label: 'Brunei dollar', paymentExceptions: ['PAYPAL'] },
  {
    code: 'bob',
    label: 'Bolivian boliviano',
    paymentExceptions: ['PAYPAL', 'AMEX'],
  },
  { code: 'bsd', label: 'Bahamian dollar', paymentExceptions: ['PAYPAL'] },
  { code: 'bwp', label: 'Botswana pula', paymentExceptions: ['PAYPAL'] },
  { code: 'bzd', label: 'Belize dollar', paymentExceptions: ['PAYPAL'] },
  { code: 'cdf', label: 'Congolese franc', paymentExceptions: ['PAYPAL'] },
  { code: 'clp', label: 'Chilean peso', paymentExceptions: ['PAYPAL', 'AMEX'] },
  {
    code: 'crc',
    label: 'Costa Rican colon',
    paymentExceptions: ['PAYPAL', 'AMEX'],
  },
  {
    code: 'cve',
    label: 'Cape Verdean escudo',
    paymentExceptions: ['PAYPAL', 'AMEX'],
  },
  {
    code: 'djf',
    label: 'Djiboutian franc',
    paymentExceptions: ['PAYPAL', 'AMEX'],
  },
  { code: 'dop', label: 'Dominican peso', paymentExceptions: ['PAYPAL'] },
  { code: 'dzd', label: 'Algerian dinar', paymentExceptions: ['PAYPAL'] },
  { code: 'egp', label: 'Egyptian pound', paymentExceptions: ['PAYPAL'] },
  { code: 'etb', label: 'Ethiopian birr', paymentExceptions: ['PAYPAL'] },
  { code: 'fjd', label: 'Fijian dollar', paymentExceptions: ['PAYPAL'] },
  {
    code: 'fkp',
    label: 'Falkland Islands pound',
    paymentExceptions: ['PAYPAL', 'AMEX'],
  },
  { code: 'gel', label: 'Georgian lari', paymentExceptions: ['PAYPAL'] },
  { code: 'gip', label: 'Gibraltar pound', paymentExceptions: ['PAYPAL'] },
  { code: 'gmd', label: 'Gambian dalasi', paymentExceptions: ['PAYPAL'] },
  {
    code: 'gnf',
    label: 'Guinean franc',
    paymentExceptions: ['PAYPAL', 'AMEX'],
  },
  {
    code: 'gtq',
    label: 'Guatemalan quetzal',
    paymentExceptions: ['PAYPAL', 'AMEX'],
  },
  { code: 'gyd', label: 'Guyanese dollar', paymentExceptions: ['PAYPAL'] },
  {
    code: 'hnl',
    label: 'Honduran lempira',
    paymentExceptions: ['PAYPAL', 'AMEX'],
  },
  { code: 'hrk', label: 'Croatian kuna', paymentExceptions: ['PAYPAL'] },
  { code: 'htg', label: 'Haitian gourde', paymentExceptions: ['PAYPAL'] },
  { code: 'idr', label: 'Indonesian rupiah', paymentExceptions: ['PAYPAL'] },
  { code: 'jmd', label: 'Jamaican dollar', paymentExceptions: ['PAYPAL'] },
  { code: 'kgs', label: 'Kyrgyzstani som', paymentExceptions: ['PAYPAL'] },
  { code: 'khr', label: 'Cambodian riel', paymentExceptions: ['PAYPAL'] },
  { code: 'kmf', label: 'Comorian franc', paymentExceptions: ['PAYPAL'] },
  {
    code: 'kyd',
    label: 'Cayman Islands dollar',
    paymentExceptions: ['PAYPAL'],
  },
  { code: 'kzt', label: 'Kazakhstani tenge', paymentExceptions: ['PAYPAL'] },
  { code: 'lak', label: 'Lao kip', paymentExceptions: ['PAYPAL', 'AMEX'] },
  { code: 'lbp', label: 'Lebanese pound', paymentExceptions: ['PAYPAL'] },
  { code: 'lkr', label: 'Lesotho loti', paymentExceptions: ['PAYPAL'] },
  { code: 'lrd', label: 'Liberian dollar', paymentExceptions: ['PAYPAL'] },
  { code: 'lsl', label: 'Lesotho loti', paymentExceptions: ['PAYPAL'] },
  { code: 'mdl', label: 'Moldovan leu', paymentExceptions: ['PAYPAL'] },
  { code: 'mga', label: 'Malagasy ariary', paymentExceptions: ['PAYPAL'] },
  { code: 'mkd', label: 'Macedonian denar', paymentExceptions: ['PAYPAL'] },
  { code: 'mmk', label: 'Myanmar kyat', paymentExceptions: ['PAYPAL'] },
  { code: 'mnt', label: 'Mongolian tugrik', paymentExceptions: ['PAYPAL'] },
  { code: 'mop', label: 'Macanese pataca', paymentExceptions: ['PAYPAL'] },
  {
    code: 'mur',
    label: 'Mauritian rupee',
    paymentExceptions: ['PAYPAL', 'AMEX'],
  },
  { code: 'mvr', label: 'Maldivian rufiyaa', paymentExceptions: ['PAYPAL'] },
  { code: 'mwk', label: 'Malawian kwacha', paymentExceptions: ['PAYPAL'] },
  { code: 'mzn', label: 'Mozambican metical', paymentExceptions: ['PAYPAL'] },
  { code: 'nad', label: 'Namibian dollar', paymentExceptions: ['PAYPAL'] },
  {
    code: 'nio',
    label: 'Nicaraguan cordoba',
    paymentExceptions: ['PAYPAL', 'AMEX'],
  },
  { code: 'npr', label: 'Nepalese rupee', paymentExceptions: ['PAYPAL'] },
  { code: 'pen', label: 'Peruvian sol', paymentExceptions: ['PAYPAL', 'AMEX'] },
  {
    code: 'pgk',
    label: 'Papua New Guinean kina',
    paymentExceptions: ['PAYPAL'],
  },
  { code: 'pkr', label: 'Pakistani rupee', paymentExceptions: ['PAYPAL'] },
  {
    code: 'pyg',
    label: 'Paraguayan guarani',
    paymentExceptions: ['PAYPAL', 'AMEX'],
  },
  { code: 'rwf', label: 'Rwandan franc', paymentExceptions: ['PAYPAL'] },
  {
    code: 'sbd',
    label: 'Solomon Islands dollar',
    paymentExceptions: ['PAYPAL'],
  },
  { code: 'scr', label: 'Seychellois rupee', paymentExceptions: ['PAYPAL'] },
  {
    code: 'shp',
    label: 'Saint Helena pound',
    paymentExceptions: ['PAYPAL', 'AMEX'],
  },
  { code: 'sll', label: 'Sierra Leonean leone', paymentExceptions: ['PAYPAL'] },
  { code: 'sos', label: 'Somali shilling', paymentExceptions: ['PAYPAL'] },
  {
    code: 'srd',
    label: 'Surinamese dollar',
    paymentExceptions: ['PAYPAL', 'AMEX'],
  },
  { code: 'szl', label: 'Swazi lilangeni', paymentExceptions: ['PAYPAL'] },
  { code: 'tjs', label: 'Tajikistani somoni', paymentExceptions: ['PAYPAL'] },
  { code: 'top', label: 'Tongan pa’anga', paymentExceptions: ['PAYPAL'] },
  { code: 'tzs', label: 'Tanzanian shilling', paymentExceptions: ['PAYPAL'] },
  { code: 'ugx', label: 'Ugandan shilling', paymentExceptions: ['PAYPAL'] },
  {
    code: 'uyu',
    label: 'Uruguayan peso',
    paymentExceptions: ['PAYPAL', 'AMEX'],
  },
  { code: 'uzs', label: 'Uzbekistani som', paymentExceptions: ['PAYPAL'] },
  { code: 'vnd', label: 'Vietnamese dong', paymentExceptions: ['PAYPAL'] },
  { code: 'vuv', label: 'Vanuatu vatu', paymentExceptions: ['PAYPAL'] },
  { code: 'wst', label: 'Samoan tala', paymentExceptions: ['PAYPAL'] },
  {
    code: 'xaf',
    label: 'Central African CFA franc',
    paymentExceptions: ['PAYPAL'],
  },
  {
    code: 'xcd',
    label: 'East Caribbean dollar',
    paymentExceptions: ['PAYPAL'],
  },
  {
    code: 'xof',
    label: 'West African CFA franc',
    paymentExceptions: ['PAYPAL', 'AMEX'],
  },
  { code: 'xpf', label: 'CFP franc', paymentExceptions: ['PAYPAL', 'AMEX'] },
  { code: 'yer', label: 'Yemeni rial', paymentExceptions: ['PAYPAL'] },
  { code: 'zmw', label: 'Zambian kwacha', paymentExceptions: ['PAYPAL'] },
];

const PayoutCurrencies = [
  { code: 'aud', label: 'Australian dollar' },
  { code: 'cad', label: 'Canadian Dollar' },
  { code: 'cny', label: 'Chinese Yuan' },
  { code: 'eur', label: 'Euro' },
  { code: 'hkd', label: 'Hong Kong Dollar' },
  { code: 'inr', label: 'Indian Rupee' },
  { code: 'jpy', label: 'Japanese Yen' },
  { code: 'krw', label: 'South Korean Won' },
  { code: 'mad', label: 'Moroccan Dirham' },
  { code: 'mxn', label: 'Mexican Peso' },
  { code: 'thb', label: 'Thai Baht' },
  { code: 'try', label: 'Turkish Lira' },
  { code: 'usd', label: 'US Dollar' },
  { code: 'zar', label: 'South African Rand' },
  { code: 'gbp', label: 'British Pound Sterling' },
  { code: 'sek', label: 'Swedish Krona' },
];

const NewPayoutCurrencies = [
  { code: 'eur', label: 'Euro' },
  { code: 'usd', label: 'US Dollar' },
  { code: 'gbp', label: 'British Pound Sterling' },
  { code: 'sek', label: 'Swedish Krona' },
];

export { Currencies, PayoutCurrencies, NewPayoutCurrencies };
