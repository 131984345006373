import React, { useState } from 'react';
import { Formik } from 'formik';
import { Typography, Drawer, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import FormikTextField from '../Common/Formik/FormikTextField';
import ConfirmationModal from '../Common/ConfirmationModal/ConfirmationModal';
import { getValidationSchema } from './EmailPreferencesValidationSchema';
import Grid from '@mui/material/Unstable_Grid2';
const useStyles = makeStyles((theme) => ({
  paddingBottom: {
    paddingBottom: `${theme.spacing(1.5)} !important`,
  },
  root: {
    width: '100%',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.up('sm')]: {
      width: 567,
    },
  },
  drawerActionsContainer: {
    display: 'flex',
    position: 'fixed',
    marginLeft: `-${theme.spacing(2)}`,
    bottom: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    width: '100%',
    padding: theme.spacing(2),
    backgroundColor: '#fff',
    zIndex: 1000,
    [theme.breakpoints.up('sm')]: {
      width: 567,
      bottom: 0,
      padding: theme.spacing(1),
    },
  },
}));

const EmailPreferencesDrawer = ({
  open,
  handleClose,
  signalEmail,
  updateEmailSignal,
}) => {
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);

  const addEmail = async (values) => {
    if (!signalEmail) {
      await updateEmailSignal(values.email);
    } else {
      await updateEmailSignal(signalEmail + ',' + values.email);
    }
    closeDrawer();
  };

  const closeDrawer = () => {
    setOpenModal(false);
    handleClose();
  };

  return (
    <Formik
      enableReinitialize
      onSubmit={async (values, { resetForm }) => {
        await addEmail(values);
        resetForm();
      }}
      validateOnChange={false}
      validateOnBlur={true}
      initialValues={{ email: '' }}
      validationSchema={() => getValidationSchema(signalEmail)}
    >
      {({ handleSubmit, isSubmitting, handleReset }) => (
        <Drawer anchor="right" open={open} onClose={() => setOpenModal(true)}>
          <Grid
            container
            justifyContent="center"
            className={classes.root}
            spacing={0}
          >
            <Grid xs={12} sm={11} container spacing={2}>
              <Grid xs={12} className={classes.paddingBottom}>
                <Typography gutterBottom variant="h3">
                  Add email address
                </Typography>
                <Typography gutterBottom variant="body1">
                  Add email addresses for users so they automatically receive an
                  email 24 hours after an athlete completes their registration.
                  The reason for the delay is the 24 hour cancellation policy
                  that World's Marathons offers every athlete.
                </Typography>
              </Grid>
              <Grid xs={12}>
                <FormikTextField
                  variant="outlined"
                  label="Email address"
                  required
                  name="email"
                  fullWidth
                />
              </Grid>
              <Grid
                container
                className={classes.drawerActionsContainer}
                spacing={2}
              >
                <Grid xs={4}>
                  <Button
                    variant="outlined"
                    onClick={() => setOpenModal(true)}
                    disabled={isSubmitting}
                    fullWidth
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid xs={8}>
                  <Button
                    variant="contained"
                    fullWidth
                    color="primary"
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                  >
                    {isSubmitting
                      ? 'Adding email address'
                      : 'Add email address'}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {openModal && (
            <ConfirmationModal
              cancel={() => setOpenModal(false)}
              confirm={() => {
                handleReset();
                closeDrawer();
              }}
              open={openModal}
              title="Are you sure you want to cancel the action?"
              cancelText="No, continue"
              confirmText="Yes, cancel"
            />
          )}
        </Drawer>
      )}
    </Formik>
  );
};

export default EmailPreferencesDrawer;
