import React from 'react';
import { Typography } from '@mui/material';
import FormikTextField from '../../../../Common/Formik/FormikTextField';
import Phone from './Phone';
import WrappedDivider from '../../../../Common/WrappedDivider/WrappedDivider';
import Grid from '@mui/material/Unstable_Grid2';
const Ice = ({ withRelationship, setFieldValue }) => (
  <>
    <WrappedDivider />
    <Grid xs={12}>
      <Typography variant="body1" gutterBottom>
        Emergency contact
      </Typography>
    </Grid>
    <Grid xs={12}>
      <FormikTextField
        label="Emergency contact name"
        name="extraInfo.ice.name"
        fullWidth
      />
    </Grid>
    {withRelationship && (
      <Grid xs={12}>
        <FormikTextField
          label="Emergency contact relationship"
          name="extraInfo.ice.relationship"
          fullWidth
        />
      </Grid>
    )}
    <Grid xs={12}>
      <Phone
        codeName="extraInfo.ice.code"
        phoneName="extraInfo.ice.phone"
        setFieldValue={setFieldValue}
        label="Emergency contact phone number"
      />
    </Grid>
  </>
);

export default Ice;
