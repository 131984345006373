import React from 'react';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '0.75rem',
    paddingLeft: '14px',
  },
  error: {
    color: theme.palette.error.main,
  },
  valid: {
    color: theme.palette.primary.main,
  },
  icon: {
    minWidth: '20px',
    '& .MuiSvgIcon-root': { fontSize: '1em' },
  },
  tightList: {
    paddingTop: '0',
    paddingBottom: '0',
  },
  text: {
    marginTop: '0',
    marginBottom: '0',
    fontSize: '0.75rem',
  },
}));

const Requirements = ({ passwordErrors }) => {
  const classes = useStyles();

  return (
    <>
      <Typography className={classes.title}>
        Password must contain the following:
      </Typography>
      <List dense>
        {Object.entries(passwordErrors).map(([key, value]) => {
          return (
            <ListItem className={classes.tightList} key={key}>
              <ListItemIcon className={classes.icon}>
                {!value.isValid ? (
                  <CloseIcon className={classes.error} />
                ) : (
                  <CheckIcon className={classes.valid} />
                )}
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={value.errMessage}
                className={`${!value.isValid ? classes.error : classes.valid} ${
                  classes.text
                }`}
              />
            </ListItem>
          );
        })}
      </List>
    </>
  );
};

export default Requirements;
