import React from 'react';
import { Formik } from 'formik';
import { useParams } from 'react-router';
import { Typography, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import DrawerBase from '../../Common/Drawer/DrawerBase';
import { validationSchema } from './GenerateMockOrderValidationSchema';
import FormikTextField from '../../Common/Formik/FormikTextField';
import BaseFormikSelect from '../../Common/Formik/BaseFormikSelect';
import { countries } from '../../../Consts/countries';
import { useApi, methods } from '../../../Hooks/useApi';
import { useSnackbar } from 'notistack';
import Grid from '@mui/material/Unstable_Grid2';
const useStyles = makeStyles((theme) => ({
  paddingBottom: {
    paddingBottom: `${theme.spacing(5)} !important`,
  },
}));

const GenerateMockOrdersDrawer = ({ open, handleClose }) => {
  const classes = useStyles();
  const { executeApiCall } = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const { eventId, editionId } = useParams();

  const generateMockOrders = async (values) => {
    const { count, nationality } = values;
    const { code } = nationality ?? {};
    const data = { count, nationality: code };
    try {
      await executeApiCall(
        `/order/${eventId}/${editionId}/fake-orders`,
        methods.put,
        data
      );
      enqueueSnackbar('Orders generated successfully', {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={{
        count: 100,
        nationality: null,
      }}
      onSubmit={generateMockOrders}
    >
      {({ setFieldValue, values, handleSubmit, isSubmitting }) => (
        <DrawerBase
          open={open}
          onClose={handleClose}
          handleSubmit={handleSubmit}
          isSubmitting={isSubmitting}
          submittingMessage="Generating orders..."
          submitText="Generate"
          cancelText="Cancel"
        >
          <Grid xs={12} className={classes.paddingBottom}>
            <Typography variant="h3" gutterBottom>
              Generate mock orders
            </Typography>
            <Typography variant="body1" gutterBottom>
              Here you are able to generate and randomize mock orders. Keep in
              mind that it may take up to few minutes
            </Typography>
          </Grid>
          <Grid xs={8}>
            <FormikTextField
              name="count"
              variant="outlined"
              fullWidth
              placeholder="Number of orders"
              required
              type="number"
            />
          </Grid>
          <Grid xs={8}>
            <Tooltip
              arrow
              placement="bottom"
              title={`Not providing nationality causes random countries generation. If you provide so then all orders will come from inputed country`}
            >
              <span>
                <BaseFormikSelect
                  name="nationality"
                  label="Nationality"
                  placeholder="Select nationality"
                  value={values.nationality}
                  options={Object.values(countries)}
                  set={(c) => setFieldValue('nationality', c)}
                  getOptionLabel={(option) => option?.label ?? ''}
                />
              </span>
            </Tooltip>
          </Grid>
        </DrawerBase>
      )}
    </Formik>
  );
};

export default GenerateMockOrdersDrawer;
