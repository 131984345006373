import React, { useEffect, useState } from 'react';
import { Card, CardContent } from '@mui/material';
import Affiliate from './Affiliate';
import { useSnackbar } from 'notistack';
import { useApi, methods } from '../../../Hooks/useApi';
import { useParams, Routes, Route } from 'react-router-dom';
import StepTitleBreadcrumb from '../StepTitleBreadcrumb';
import Breadcrumbs from './Breadcrumbs';
import { useEdition } from '../../../Hooks/useEdition';

const baseURL = (eventId, editionId) =>
  `/app/manage-event/${eventId}/${editionId}`;
const baseAffiliateURL = (eventId, editionId) =>
  `/affiliate/${eventId}/${editionId}`;
const baseAffiliateClickCountURL = (eventId, editionId) =>
  `/affiliate/${eventId}/${editionId}/clicks-count`;

const AffiliateBase = () => {
  const { eventId, editionId } = useParams();
  const { executeApiCall, apiError } = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const { edition } = useEdition(eventId, editionId);
  const { race } = edition;
  const [clickCount, setClickCount] = useState(0);
  const initialrace = {
    affiliate: false,
    affiliateLink: '',
    affiliateType: 'none',
  };

  useEffect(() => {
    if (apiError) enqueueSnackbar(apiError, { variant: 'error' });
  }, [apiError, enqueueSnackbar]);

  useEffect(() => {
    getAffiliateClickCount().then((response) =>
      setClickCount(response.clickCount)
    );
  }, []);

  const addAffiliate = async (values) => {
    try {
      const newAffiliate = await executeApiCall(
        baseAffiliateURL(eventId, editionId),
        methods.put,
        {
          affiliate: values.affiliateLink !== '',
          affiliateLink: values.affiliateLink,
          affiliateType: values.affiliateLink !== '' ? 'active' : 'none',
          partnerId: race.partnerId ?? '',
        }
      );
      enqueueSnackbar('Affiliate link saved successfully', {
        variant: 'success',
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getAffiliateClickCount = async () => {
    try {
      const response = await executeApiCall(
        baseAffiliateClickCountURL(eventId, editionId),
        methods.get
      );
      return response;
    } catch (error) {
      console.log(error);
    }
  };

  const breadcrumb = {
    'All Integrations': baseURL(eventId, editionId) + '/integration',
    'ACTIVE Checkout': baseURL(eventId, editionId) + '/integration/affiliate',
  };

  return (
    <Routes>
      <Route
        path="/"
        element={
          <>
            <StepTitleBreadcrumb
              title="Integration"
              breadcrumb={<Breadcrumbs breadcrumb={breadcrumb} />}
            />
            <Card>
              <CardContent>
                <Affiliate
                  eventId={eventId}
                  editionId={editionId}
                  addAffiliate={addAffiliate}
                  currentAffiliate={race ?? initialrace}
                  clickCount={clickCount}
                />
              </CardContent>
            </Card>
          </>
        }
      />
    </Routes>
  );
};

export default AffiliateBase;
