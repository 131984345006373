import React from 'react';
import {
  Collapse,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  Switch,
  InputAdornment,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import FormikTextField from '../../Common/Formik/FormikTextField';
import Grid from '@mui/material/Unstable_Grid2';
const useStyles = makeStyles((theme) => ({
  radioGroupLabel: {
    marginBottom: theme.spacing(1),
  },
}));
const TicketTypeSelector = ({ formik, currency }) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Grid xs={12}>
        <FormControl
          required
          component="fieldset"
          error={formik.touched.ticketType && Boolean(formik.errors.ticketType)}
        >
          <FormLabel component="legend" className={classes.radioGroupLabel}>
            Ticket type
          </FormLabel>
          <RadioGroup
            value={formik.values.ticketType ?? 'regular'}
            name="ticketType"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          >
            <FormControlLabel
              value="regular"
              control={<Radio />}
              label="Regular ticket"
            />
            <FormControlLabel
              value="team"
              control={<Radio />}
              label="Team/Corporate/Relay Ticket"
            />
          </RadioGroup>
          {formik.touched.ticketType && formik.errors.ticketType && (
            <FormHelperText>{formik.errors.ticketType}</FormHelperText>
          )}
        </FormControl>
      </Grid>

      <Collapse in={formik.values.ticketType === 'team'}>
        <Grid container spacing={2}>
          <Grid xs={6}>
            <FormikTextField
              name="minParticipantCount"
              label="Min participants"
              helperText="The minimum number of team members"
              required
            />
          </Grid>
          <Grid xs={6}>
            <FormikTextField
              name="numParticipantsIncluded"
              label="Max participants"
              helperText="The maximum number of team members included in the price of this ticket"
              required
            />
          </Grid>
          <Grid xs={12}>
            <FormGroup>
              <FormControlLabel
                label="Allow additional team members at extra cost"
                control={
                  <Switch
                    color="primary"
                    name="additionalTickets"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    checked={formik.values.additionalTickets ?? false}
                  />
                }
              />
            </FormGroup>
          </Grid>
          {formik.values.additionalTickets && (
            <Grid xs={6}>
              <FormikTextField
                name="additionalTeamMemberPrice"
                label="Additional cost"
                helperText="Cost for each additional team member"
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment>{currency?.toUpperCase()}</InputAdornment>
                  ),
                }}
              />
            </Grid>
          )}
        </Grid>
      </Collapse>
    </React.Fragment>
  );
};

export default TicketTypeSelector;
