import React from 'react';
import { Chip } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'green',
  },
}));

const AutocompleteChip = (props) => {
  const classes = useStyles();
  return <Chip className={classes.root} color="primary" {...props} />;
};

export default AutocompleteChip;
