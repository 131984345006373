import React, { createElement } from 'react';
import { MenuItem, ListItem, Divider, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import {
  KeyboardReturn,
  Cancel,
  Send,
  Replay,
  Mail,
} from '@mui/icons-material';
import ButtonDropdownMenu from '../../Common/ButtonDropdownMenu/ButtonDropdownMenu';

const useStyles = makeStyles((theme) => ({
  menuIcon: {
    marginRight: theme.spacing(2),
  },
}));

const MenuDivider = withStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))(Divider);

const OrderActionMenu = ({
  resendOrderReceipt,
  cancelOrder,
  refundOrder,
  resendWebhook,
  canCancel,
  canRefund,
  canResend,
  canResendWebhook,
}) => {
  const classes = useStyles();

  const menuItems = [
    {
      element: 'div',
      action: refundOrder,
      children: (
        <MenuItem disabled={!canRefund}>
          <KeyboardReturn className={classes.menuIcon} />
          Refund order
        </MenuItem>
      ),
      id: '1',
    },
    {
      element: MenuItem,
      props: { disabled: !canCancel },
      action: cancelOrder,
      children: (
        <>
          <Cancel className={classes.menuIcon} />
          Cancel order
        </>
      ),
      id: '2',
    },
    {
      element: MenuDivider,
      props: { variant: 'middle', component: 'li' },
      id: '3',
    },
    {
      element: ListItem,
      props: { disabled: true },
      children: <Typography variant="subtitle2">To the buyer</Typography>,
      id: '4',
    },
    {
      element: MenuItem,
      props: { disabled: !canResend },
      action: resendOrderReceipt,
      children: (
        <>
          <Send className={classes.menuIcon} />
          Resend receipt email
        </>
      ),
      id: '5',
    },
    {
      element: MenuDivider,
      props: { variant: 'middle', component: 'li' },
      id: '6',
    },
    {
      element: ListItem,
      props: { disabled: true },
      children: <Typography variant="subtitle2">To you</Typography>,
      id: '7',
    },
    {
      element: MenuItem,
      props: { disabled: !canResendWebhook },
      action: resendWebhook,
      children: (
        <>
          <Replay className={classes.menuIcon} />
          Resend webhook
        </>
      ),
      id: '8',
    },
    {
      element: MenuItem,
      props: { disabled: true },
      children: (
        <>
          <Mail className={classes.menuIcon} />
          Resend order notification
        </>
      ),
      id: '9',
    },
  ];

  return (
    <ButtonDropdownMenu buttonText="Actions">
      {(performAction) =>
        menuItems.map((item) =>
          createElement(
            item.element,
            {
              onClick: item.action ? () => performAction(item.action) : null,
              key: item.id,
              ...item.props,
            },
            item.children
          )
        )
      }
    </ButtonDropdownMenu>
  );
};

export default OrderActionMenu;
