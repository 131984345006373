import { Navigate } from 'react-router-dom';
import Login from './Components/Login/Login';
import ForgotPassword from './Components/Login/ForgotPassword';
import AuthLayout from './Layouts/AuthLayout';
import MainLayout from './Layouts/MainLayout';
import SignupForm from './Components/Signup/SignupForm';
import CreateEventBase from './Components/ManageEvents/CreateEvent/CreateEventBase';
import ConfirmationPage from './Components/ManageEvents/CreateEvent/ConfirmationPage';
import EventsList from './Components/Events/EventsList';
import ManageEventBase from './Components/ManageEvents/ManageEvent/ManageEventBase';
import ManagePayoutsBase from './Components/Payouts/ManagePayoutsBase';
import TeamMembers from './Components/Settings/TeamMembers';
import AdminArea from './Components/Admin/AdminArea';
import ReactivateSuccess from './Components/ManageEvents/ReactivateEvent/ReactivateSuccess';
import EmailConfirmed from './Components/EmailConfirmed/EmailConfirmed';

const routes = (isLoggedIn, roles) => [
  {
    path: '/app',
    element: isLoggedIn ? <MainLayout /> : <Navigate to="/login" />,
    children: [
      {
        path: '/',
        element: <Navigate to="/app/events" />,
      },
      {
        path: '/create-event/:eventId/:editionId',
        element: <CreateEventBase />,
      },
      {
        path: '/create-event',
        element: <CreateEventBase />,
      },
      {
        path: 'reactivate-event/:eventId',
        element: <CreateEventBase isReactivation={true} />,
      },
      {
        path: 'reactivate-event/:eventId/:editionId',
        element: <CreateEventBase isReactivation={true} />,
      },
      {
        path: '/create-event-confirmation',
        element: <ConfirmationPage />,
      },
      {
        path: '/reactivate-event/:eventId/:editionId/success',
        element: <ReactivateSuccess />,
      },
      {
        path: '/events',
        element: <EventsList />,
      },
      {
        path: '/manage-event/:eventId/:editionId/*',
        element: <ManageEventBase />,
      },
      {
        path: '/payouts',
        element: <ManagePayoutsBase />,
      },
      {
        path: '/team',
        element: <TeamMembers />,
      },
      {
        path: '/admin',
        element:
          roles?.indexOf('admin') !== -1 ? (
            <AdminArea />
          ) : (
            <Navigate to="/app/events" />
          ),
      },
    ],
  },
  {
    path: '/',
    element: !isLoggedIn ? <AuthLayout /> : <Navigate to="/app/events" />,
    children: [
      { path: 'login', element: <Login /> },
      { path: 'forgot-password', element: <ForgotPassword /> },
      { path: 'signup', element: <SignupForm /> },
      { path: '/', element: <Navigate to="/login" /> },
    ],
  },
  {
    path: '/email-confirmed',
    element: <AuthLayout />,
    children: [{ path: '', element: <EmailConfirmed /> }],
  },
];

export default routes;
