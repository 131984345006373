import React from 'react';
import { Card, Typography, Modal, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  Close as CloseIcon,
  TrendingUp as TrendingUpIcon,
} from '@mui/icons-material';
import { wmPalette } from '../../../Theme/WorldSportsGroupTheme';
import Grid from '@mui/material/Unstable_Grid2';
const useStyles = makeStyles((theme) => ({
  modal: {
    overflow: 'scroll',
  },
  card: {
    width: '50vw',
    [theme.breakpoints.down('lg')]: {
      width: '80vw',
    },
    marginTop: theme.spacing(4),
    margin: 'auto',
    padding: theme.spacing(4),
  },
  crossIconBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '50px',
    height: '50px',
    cursor: 'pointer',
  },
  trending: {
    color: wmPalette.green[100],
    fontSize: '64px',
  },
  close: {
    color: wmPalette.blue[100],
    cursor: 'pointer',
    fontWeight: 'bold',
  },
}));

const SalesModal = ({ open, handleClose }) => {
  const classes = useStyles();

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={() => handleClose(false)}
      sx={{ outline: 'none' }}
      disableAutoFocus={true}
      disableEnforceFocus={true}
    >
      <Card className={classes.card}>
        <Grid container direction="column" spacing={0}>
          <Grid container direction="row" justifyContent="flex-end" spacing={0}>
            <Box
              className={classes.crossIconBox}
              onClick={() => handleClose(false)}
            >
              <CloseIcon />
            </Box>
          </Grid>
          <Grid container direction="column" spacing={4}>
            <Grid>
              <TrendingUpIcon className={classes.trending} color="green" />
            </Grid>
            <Grid>
              <Typography variant="h4">5 proven ways to drive sales</Typography>
            </Grid>
            <Grid>
              <Typography variant="h6">
                Upload 6 good quality images and 1 video
              </Typography>
              <Typography variant="body1">
                According to research with actual users, images and videos are
                two of the most important factors when choosing an event.
              </Typography>
            </Grid>
            <Grid>
              <Typography variant="h6">
                Avoid images that contain text or logos{' '}
              </Typography>
              <Typography variant="body1">
                Events with text or logos on the image get fewer page views.
              </Typography>
            </Grid>
            <Grid>
              <Typography variant="h6">
                Write a compelling description to attract participants
              </Typography>
              <Typography variant="body1">
                Include details of race highlights such as sights along the
                route, additional activities, after parties and anything else
                that will entice athletes to sign up. You can also include
                information about the local area to let participants know what
                is on offer before and after the event.
              </Typography>
            </Grid>
            <Grid>
              <Typography variant="h6">Set up Early Bird pricing</Typography>
              <Typography variant="body1">
                When setting up price steps, an Early Bird Discount tag will be
                automatically displayed on your event to attract the audience.
                Anyone who showed interest in your event will receive a
                notification email informing them of the discount 3 to 10 days
                before each price step.
              </Typography>
            </Grid>
            <Grid>
              <Typography variant="h6">
                Keep registration forms short and sweet{' '}
              </Typography>
              <Typography variant="body1">
                Lengthy registration forms can discourage participants. Keep it
                short and simple and make registration easy.
              </Typography>
            </Grid>
            <Grid container justifyContent="flex-end" spacing={0}>
              <Typography
                className={classes.close}
                onClick={() => handleClose(false)}
              >
                CLOSE
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </Modal>
  );
};
export default SalesModal;
