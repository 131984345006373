import React, { useContext, useState } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import { mutate } from 'swr';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useApi, methods } from '../../../Hooks/useApi';
import { orderUrl } from '../../../Hooks/useOrders';
import { Context } from '../../../Stores/EventInfoStore';
import DrawerBase from '../../Common/Drawer/DrawerBase';
import TicketSelect from './TicketSelect';
import FormikTextField from '../../Common/Formik/FormikTextField';
import ConfirmationModal from '../../Common/ConfirmationModal/ConfirmationModal';
import Grid from '@mui/material/Unstable_Grid2';
const useStyles = makeStyles((theme) => ({
  paddingBottom: {
    paddingBottom: `${theme.spacing(5)} !important`,
  },
}));

const getInviteParticipantUrl = (eventId, editionId) =>
  `/ro/events/${eventId}/races/${editionId}/orders/invite`;

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email('Invalid email')
    .required('A support email is required'),
  firstName: yup.string().required('A first name is required'),
  lastName: yup.string().required('A last name is required'),
  ticketId: yup.string().required('Please select a ticket'),
});

const getContract = (email, firstName, lastName, ticketId) => ({
  participants: [
    {
      id: 1,
      email,
      firstName,
      lastName,
      extraInfo: {},
      orderLines: [{ productId: ticketId }],
    },
  ],
});

const InviteParticipantDrawer = ({ open, handleClose }) => {
  const [modalOpen, toggleModal] = useState(false);
  const classes = useStyles();
  const { executeApiCall } = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const [eventInfo] = useContext(Context);
  const { tickets } = eventInfo;
  const { eventId, editionId } = eventInfo.event;
  const sendInvitation = async (values) => {
    const data = getContract(
      values.email,
      values.firstName,
      values.lastName,
      values.ticketId
    );
    await mutate(
      [orderUrl(eventId, editionId), true],
      async ({ orders }) => {
        try {
          const newOrder = await executeApiCall(
            getInviteParticipantUrl(eventId, editionId),
            methods.post,
            data
          );
          enqueueSnackbar('Invitation sent successfully', {
            variant: 'success',
          });
          handleClose();
          const ordersObj = orders.length ? [newOrder, ...orders] : [newOrder];
          return { orders: ordersObj };
        } catch (error) {
          enqueueSnackbar(error.message, { variant: 'error' });
        }
      },
      false
    );
  };
  return (
    <>
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          email: '',
          firstName: '',
          lastName: '',
          ticketId: '',
        }}
        onSubmit={sendInvitation}
      >
        {({ handleSubmit, isSubmitting, setFieldValue }) => (
          <DrawerBase
            open={open}
            onClose={() => toggleModal(true)}
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            submittingMessage="Sending invitation email..."
            submitText="Send invitation email"
            cancelText="Cancel"
          >
            <Grid xs={12} className={classes.paddingBottom}>
              <Typography variant="h3" gutterBottom>
                Add free order by sending invitation
              </Typography>
              <Typography variant="body1">
                The invitation email will be sent to the invitee's email address
                from info@worldsmarathons.com. The email will include a unique
                link allowing the invitee to register for a free ticket. The
                invitee will be required to submit their registration
                information.
              </Typography>
            </Grid>
            <Grid xs={12}>
              <Typography variant="body1">
                To which race are you inviting
              </Typography>
            </Grid>
            <Grid xs={12} className={classes.paddingBottom}>
              <TicketSelect
                tickets={tickets}
                setTicket={(id) => setFieldValue('ticketId', id)}
              />
            </Grid>
            <Grid xs={12}>
              <Typography variant="body1">Invitee information</Typography>
            </Grid>
            <Grid xs={6}>
              <FormikTextField
                variant="outlined"
                fullWidth
                label="First name"
                name="firstName"
                required
              />
            </Grid>
            <Grid xs={6}>
              <FormikTextField
                variant="outlined"
                fullWidth
                label="Last name"
                name="lastName"
                required
              />
            </Grid>
            <Grid xs={12}>
              <FormikTextField
                variant="outlined"
                fullWidth
                label="Email address"
                name="email"
                required
              />
            </Grid>
          </DrawerBase>
        )}
      </Formik>
      {modalOpen && (
        <ConfirmationModal
          open={modalOpen}
          cancel={() => toggleModal(false)}
          confirm={() => {
            toggleModal(false);
            handleClose();
          }}
          title="Are you sure you want to cancel the action?"
          cancelText="No, continue"
          confirmText=" Yes, cancel"
        />
      )}
    </>
  );
};

export default InviteParticipantDrawer;
