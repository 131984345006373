import React from 'react';
import BaseFormikSelect from './BaseFormikSelect';
import { countries } from '../../../Consts/countries';

const FormikCountrySelect = ({
  setCountry,
  country,
  placeholder,
  name = 'country',
  label = 'Country',
  required = false,
}) => (
  <BaseFormikSelect
    name={name}
    label={label}
    value={countries[country]}
    options={Object.values(countries)}
    set={(c) => setCountry(c)}
    getOptionLabel={(option) => option?.label ?? ''}
    placeholder={placeholder}
    required={required}
  />
);

export default FormikCountrySelect;
