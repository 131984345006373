export const extraInfo = {
  ice: {
    name: '',
    relationship: '',
    code: '',
    phone: '',
  },
  address: {
    address: '',
    zip: '',
    city: '',
    countryCode: '',
  },
  phone: {
    code: '',
    phone: '',
  },
  size: '',
};

export const questionOrder = [
  'club',
  'idNumber',
  'targets',
  'size',
  'phone',
  'ice',
  'address',
];
