import React from 'react';
import { Button, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import RaceOfficeLogo from '../../images/raceoffice_logo.svg';
import Grid from '@mui/material/Unstable_Grid2';
import { Emails } from '../../Utils/urls';
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  img: {
    marginBottom: '5%',
    width: '50%',
  },
}));

const EmailConfirmed = () => {
  const classes = useStyles();

  return (
    <Grid container spacing={3} className={classes.paper}>
      <Grid xs={12}>
        <img className={classes.img} src={RaceOfficeLogo} alt="Company logo" />
      </Grid>
      <Grid xs={12}>
        <Typography component="h1" variant="h5">
          Thanks for confirming your email!
        </Typography>
      </Grid>
      <Typography component="p">
        Welcome to our global marathoners’ community!
      </Typography>
      <Typography component="p">
        We would love to hear from you! If you have any feedback or suggestions
        of how we can further improve our services, please do not hesitate to
        get in touch.
        <br />
        <a href={`mailto:${Emails.Info}`}>{Emails.Info}</a>{' '}
      </Typography>
      <Button
        fullWidth
        className={classes.submit}
        variant="outlined"
        href="/app"
      >
        Go to your events
      </Button>
    </Grid>
  );
};

export default EmailConfirmed;
