import React, { useState, useEffect } from 'react';
import { Card, CardContent, Tabs, Tab, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import StepTitleBreadcrumb from '../StepTitleBreadcrumb';
import Breadcrumbs from './Breadcrumbs';
import WebhookAbout from './WebhookAbout';
import WebhookEndpoints from './WebhookEndpoints';
import WebhookDetail from './WebhookDetail';
import WebhookCustomIDMapping from './WebhookCustomIDMapping/WebhookCustomIDMapping';
import { useSnackbar } from 'notistack';
import { useApi, methods } from '../../../Hooks/useApi';
import { wmPalette } from '../../../Theme/WorldSportsGroupTheme';
import { useParams, Routes, Route } from 'react-router-dom';
import { useWebhooks } from '../../../Hooks/useWebhooks';
import { mutate } from 'swr';

const useStyles = makeStyles((theme) => ({
  tabLine: {
    borderBottom: `1px solid ${wmPalette.black[20]}`,
  },
}));

const baseURL = (eventId, editionId) =>
  `/app/manage-event/${eventId}/${editionId}`;

const baseWebhookUrl = (eventId) => `/ro/events/${eventId}/webhooks`;

const baseWebhookWithId = (eventId, webhookId) =>
  `${baseWebhookUrl(eventId)}/${webhookId}`;

const getTestWebhookUrl = (eventId, webhookId) =>
  `${baseWebhookWithId(eventId, webhookId)}/test`;

const mapBreadcrumbValue = [
  'Webhook API (About)',
  'Webhook API (Endpoints)',
  'Webhook API (Custom ID)',
];

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`}>
      <Box p={3}>{children}</Box>
    </div>
  );
}

const WebhookBase = () => {
  const { eventId, editionId } = useParams();
  const { executeApiCall, apiError } = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const { webhooks } = useWebhooks(eventId);

  useEffect(() => {
    if (apiError) enqueueSnackbar(apiError, { variant: 'error' });
  }, [apiError, enqueueSnackbar]);

  const addWebhook = async (values, key) => {
    try {
      await mutate(
        key,
        async (webhooks) => {
          const newWebhook = await executeApiCall(
            baseWebhookUrl(eventId),
            methods.post,
            {
              endpoint: values.endpointURL,
              alertEmail: values.email,
              status: 'enabled',
            }
          );
          newWebhook.eventTypes = 'order.successful';
          return [...webhooks, newWebhook];
        },
        false
      );
    } catch (error) {}
  };

  const updateWebhook = async (webhookId, values, key) => {
    try {
      await mutate(
        key,
        async (webhooks) => {
          const updatedWebhook = await executeApiCall(
            baseWebhookWithId(eventId, webhookId),
            methods.put,
            {
              id: webhookId,
              endpoint: values.endpointURL,
              alertEmail: values.email,
              status: 'enabled',
            }
          );
          if (Array.isArray(webhooks)) {
            const replacedWebhooks = [...webhooks];
            const index = webhooks.findIndex(
              (webhook) => webhook.id === webhookId
            );
            replacedWebhooks[index] = updatedWebhook;
            return [...replacedWebhooks];
          } else {
            return updatedWebhook;
          }
        },
        false
      );
    } catch (error) {}
  };

  const deleteWebhook = async (webhookId, key) => {
    try {
      await mutate(
        key,
        async (webhooks) => {
          await executeApiCall(
            baseWebhookWithId(eventId, webhookId),
            methods.delete
          );
          const filteredWebhooks = Array.isArray(webhooks)
            ? webhooks.filter((webhook) => webhook.id !== webhookId)
            : [];
          return [...filteredWebhooks];
        },
        false
      );
    } catch (error) {}
  };

  const disableWebhook = async (webhook, key) => {
    try {
      await mutate(key, async () => {
        await executeApiCall(
          baseWebhookWithId(eventId, webhook.id),
          methods.put,
          {
            id: webhook.id,
            endpoint: webhook.endpoint,
            status: webhook.status === 'enabled' ? 'disabled' : 'enabled',
          }
        );
      });
    } catch (e) {}
  };

  const sendTestWebhook = async (webhookId) => {
    try {
      await executeApiCall(getTestWebhookUrl(eventId, webhookId), methods.get);
      enqueueSnackbar('Test was sent successfully', { variant: 'success' });
    } catch (error) {}
  };

  return (
    <Routes>
      <Route
        path="/"
        element={
          <WebhookTabs
            eventId={eventId}
            editionId={editionId}
            addWebhook={addWebhook}
            updateWebhook={updateWebhook}
            disableWebhook={disableWebhook}
            deleteWebhook={deleteWebhook}
            sendTestWebhook={sendTestWebhook}
            isIntegrated={webhooks?.length === 0 ? 0 : 1}
          />
        }
      />
      <Route
        path="/detail/:id"
        element={
          <WebhookDetail
            updateWebhook={updateWebhook}
            deleteWebhook={deleteWebhook}
            disableWebhook={disableWebhook}
            sendTestWebhook={sendTestWebhook}
          />
        }
      />
    </Routes>
  );
};

const WebhookTabs = ({
  eventId,
  editionId,
  addWebhook,
  updateWebhook,
  disableWebhook,
  deleteWebhook,
  sendTestWebhook,
  isIntegrated,
}) => {
  const classes = useStyles();
  const [value, setValue] = useState(isIntegrated);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const breadcrumb = {
    'All Integrations': baseURL(eventId, editionId) + '/integration',
    [`${mapBreadcrumbValue[value]}`]:
      baseURL(eventId, editionId) + '/integration/webhook',
  };

  return (
    <>
      <StepTitleBreadcrumb
        title="Integration"
        breadcrumb={<Breadcrumbs breadcrumb={breadcrumb} />}
      />
      <Card>
        <CardContent>
          <Box>
            <Tabs
              className={classes.tabLine}
              value={value}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleChange}
            >
              <Tab label="About" />
              <Tab label="Endpoints" />
              <Tab label="Custom ID mapping table" />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <WebhookAbout setValue={setValue} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <WebhookEndpoints
              eventId={eventId}
              addWebhook={addWebhook}
              updateWebhook={updateWebhook}
              disableWebhook={disableWebhook}
              deleteWebhook={deleteWebhook}
              sendTestWebhook={sendTestWebhook}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <WebhookCustomIDMapping />{' '}
          </TabPanel>
        </CardContent>
      </Card>
    </>
  );
};

export default WebhookBase;
