import React from 'react';
import {
  TableContainer,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  Link,
  Paper,
  Box,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Link as RouterLink } from 'react-router-dom';
import { draft } from '../../Consts/eventStatus';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
  },
}));

const EventTableList = ({ events }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      {!events && <span>You don't have any event yet!</span>}
      {events && (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Event</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {events.map((event) => (
                <TableRow key={event.id}>
                  <TableCell>
                    <Link
                      component={RouterLink}
                      to={
                        event.eventPageStatus === draft
                          ? `/app/create-event/${event.id}/${event.activeEditionId}`
                          : `/app/manage-event/${event.id}/${event.activeEditionId}`
                      }
                    >
                      {event.name}
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default EventTableList;
