import React from 'react';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { emptyOption } from './AddOnVariantSelector';
import Grid from '@mui/material/Unstable_Grid2';
const useStyles = makeStyles((theme) => ({
  radioGroupLabel: {
    marginBottom: theme.spacing(2),
  },
}));

const AddOnMultipleOptionsSelector = ({ formik }) => {
  const classes = useStyles();
  const { setFieldValue } = formik;

  const onMultipleOptionsChange = (e) => {
    const { value } = e.target;
    if (value === 'false') {
      setFieldValue('addOnVariant.options', []);
      setFieldValue('addOnVariant.name', undefined);
    } else {
      setFieldValue('addOnVariant.options', [emptyOption, emptyOption]);
    }
    formik.handleChange(e);
  };
  return (
    <Grid xs={12}>
      <FormControl
        required
        component="fieldset"
        error={
          formik.touched.multipleOptions &&
          Boolean(formik.errors.multipleOptions)
        }
      >
        <FormLabel component="legend" className={classes.radioGroupLabel}>
          Does this add-on offer multiple options such as different sizes or
          prices?
        </FormLabel>
        <RadioGroup
          value={formik.values.multipleOptions}
          name="multipleOptions"
          onBlur={formik.handleBlur}
          onChange={onMultipleOptionsChange}
        >
          <FormControlLabel value="false" control={<Radio />} label="No" />
          <FormControlLabel value="true" control={<Radio />} label="Yes" />
        </RadioGroup>
        {formik.touched.multipleOptions && formik.errors.multipleOptions && (
          <FormHelperText>{formik.errors.multipleOptions}</FormHelperText>
        )}
      </FormControl>
    </Grid>
  );
};

export default AddOnMultipleOptionsSelector;
