import React, { useState } from 'react';
import { Typography, Button, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useSnackbar } from 'notistack';
import { useApi, methods } from '../../../Hooks/useApi';
import { wmPalette } from '../../../Theme/WorldSportsGroupTheme';
import GenericModal from '../../Common/GenericModal/GenericModal';
import Grid from '@mui/material/Unstable_Grid2';
const useStyles = makeStyles((theme) => ({
  bannerBackground: {
    backgroundColor: wmPalette.yellow[10],
    padding: `${theme.spacing(3)} ${theme.spacing(6)}`,
  },
}));

const getSubmitForReviewUrl = (eventId) => `/event/${eventId}/review-resubmit`;

const UnderReviewBanner = ({ eventTitle, eventId }) => {
  const [successModal, toggleModal] = useState(false);
  const { executeApiCall } = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const resubmitForReview = async () => {
    try {
      await executeApiCall(getSubmitForReviewUrl(eventId), methods.post, {
        eventTitle,
      });
      toggleModal(true);
    } catch {
      enqueueSnackbar(
        'There was an error resubmitting for review. Please try again later',
        { variant: 'error' }
      );
    }
  };

  return (
    <Box className={classes.bannerBackground}>
      <Grid
        container
        spacing={0}
        align="start"
        direction="row"
        style={{ maxWidth: 1100 }}
      >
        <Grid xs={9}>
          <Typography variant="subtitle2">Are you done editing?</Typography>
          <Typography>
            After saving your edits, please resubmit for review
          </Typography>
        </Grid>
        <Grid xs={3}>
          <Button
            onClick={resubmitForReview}
            variant="outlined"
            color="primary"
            disableRipple
            disableFocusRipple
          >
            Resubmit for review
          </Button>
        </Grid>
      </Grid>
      {successModal && (
        <GenericModal
          open={successModal}
          handleClose={() => toggleModal(false)}
          title={`${eventTitle} is resubmitted for review 🎉`}
          content={`The RaceOffice team will receive a notification and review your changes.
        This process might take a few business days.`}
          closeText="OK"
        />
      )}
    </Box>
  );
};

export default UnderReviewBanner;
