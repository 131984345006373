import React from 'react';
import {
  FormControl,
  Radio,
  FormControlLabel,
  FormLabel,
  RadioGroup,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
const Gender = ({ setFieldValue, gender }) => (
  <Grid xs={12}>
    <FormControl>
      <FormLabel component="legend">Gender</FormLabel>
      <RadioGroup
        name="gender"
        value={gender}
        onChange={(e) => setFieldValue('gender', e.target.value)}
      >
        <FormControlLabel
          color="default"
          value="M"
          control={<Radio />}
          label="Male"
        />
        <FormControlLabel
          color="default"
          value="F"
          control={<Radio />}
          label="Female"
        />
      </RadioGroup>
    </FormControl>
  </Grid>
);

export default Gender;
