import React, { useState, useEffect } from 'react';
import { IconButton, Typography, TableCell } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import makeStyles from '@mui/styles/makeStyles';
import { FileCopy } from '@mui/icons-material';
import { wmPalette } from '../../../Theme/WorldSportsGroupTheme';

const useStyles = makeStyles((theme) => ({
  copied: {
    color: wmPalette.green[70],
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
  },
  showIcon: {
    '&:hover': {
      ' & $hide': {
        visibility: 'visible',
      },
    },
  },
  hide: {
    [theme.breakpoints.up('sm')]: {
      visibility: 'hidden',
    },
  },
}));

const CopyCellText = ({ text }) => {
  const classes = useStyles();
  const [copied, setCopied] = useState(false);
  const copyText = () => {
    navigator.clipboard.writeText(text);
    setCopied(true);
  };

  useEffect(() => {
    let timerId;
    if (copied) {
      timerId = setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
    return () => clearTimeout(timerId);
  }, [copied]);

  return (
    <TableCell className={classes.showIcon}>
      <Grid spacing={2} container direction="row" alignItems="center">
        <Grid>
          <Typography>{text}</Typography>
        </Grid>
        <Grid>
          {!copied && (
            <IconButton
              className={classes.hide}
              onClick={() => copyText()}
              size="large"
            >
              <FileCopy fontSize="small" />
            </IconButton>
          )}
          {copied && (
            <Typography className={classes.copied} variant="body2">
              Copied!
            </Typography>
          )}
        </Grid>
      </Grid>
    </TableCell>
  );
};

export default CopyCellText;
