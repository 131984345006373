export const countries = {
  AB: {
    code: 'AB',
    label: 'Abkhazia',
    continent: 'Europe',
    phoneCode: '+995',
  },
  AF: {
    code: 'AF',
    label: 'Afghanistan',
    continent: 'Asia',
    phoneCode: '+93',
  },
  AX: {
    code: 'AX',
    label: 'Åland Islands',
    continent: 'Europe',
    phoneCode: '+358',
  },
  AL: {
    code: 'AL',
    label: 'Albania',
    continent: 'Europe',
    phoneCode: '+355',
  },
  DZ: {
    code: 'DZ',
    label: 'Algeria',
    continent: 'Africa',
    phoneCode: '+213',
  },
  AS: {
    code: 'AS',
    label: 'American Samoa',
    continent: 'Oceania',
    phoneCode: '+684',
  },
  AD: {
    code: 'AD',
    label: 'Andorra',
    continent: 'Europe',
    phoneCode: '+376',
  },
  AO: {
    code: 'AO',
    label: 'Angola',
    continent: 'Africa',
    phoneCode: '+244',
  },
  AI: {
    code: 'AI',
    label: 'Anguilla',
    continent: 'Central America',
    phoneCode: '+1264',
  },
  AQ: {
    code: 'AQ',
    label: 'Antarctica',
    continent: 'Antarctica',
    phoneCode: '+672',
  },
  AG: {
    code: 'AG',
    label: 'Antigua and Barbuda',
    continent: 'Central America',
    phoneCode: '+1268',
  },
  AR: {
    code: 'AR',
    label: 'Argentina',
    continent: 'South America',
    phoneCode: '+54',
  },
  AM: {
    code: 'AM',
    label: 'Armenia',
    continent: 'Asia',
    phoneCode: '+374',
  },
  AW: {
    code: 'AW',
    label: 'Aruba',
    continent: 'Central America',
    phoneCode: '+297',
  },
  AU: {
    code: 'AU',
    label: 'Australia',
    continent: 'Oceania',
    phoneCode: '+61',
  },
  AT: {
    code: 'AT',
    label: 'Austria',
    continent: 'Europe',
    phoneCode: '+43',
    eu: true,
  },
  AZ: {
    code: 'AZ',
    label: 'Azerbaijan',
    continent: 'Asia',
    phoneCode: '+994',
  },
  BS: {
    code: 'BS',
    label: 'Bahamas',
    continent: 'Central America',
    phoneCode: '+1242',
  },
  BH: {
    code: 'BH',
    label: 'Bahrain',
    continent: 'Asia',
    phoneCode: '+973',
  },
  BD: {
    code: 'BD',
    label: 'Bangladesh',
    continent: 'Asia',
    phoneCode: '+880',
  },
  BB: {
    code: 'BB',
    label: 'Barbados',
    continent: 'Central America',
    phoneCode: '+1246',
  },
  BY: {
    code: 'BY',
    label: 'Belarus',
    continent: 'Europe',
    phoneCode: '+375',
  },
  BE: {
    code: 'BE',
    label: 'Belgium',
    continent: 'Europe',
    phoneCode: '+32',
    eu: true,
  },
  BZ: {
    code: 'BZ',
    label: 'Belize',
    continent: 'Central America',
    phoneCode: '+501',
  },
  BJ: {
    code: 'BJ',
    label: 'Benin',
    continent: 'Africa',
    phoneCode: '+229',
  },
  BM: {
    code: 'BM',
    label: 'Bermuda',
    continent: 'Central America',
    phoneCode: '+1441',
  },
  BT: {
    code: 'BT',
    label: 'Bhutan',
    continent: 'Asia',
    phoneCode: '+975',
  },
  BO: {
    code: 'BO',
    label: 'Bolivia',
    continent: 'South America',
    phoneCode: '+591',
  },
  BA: {
    code: 'BA',
    label: 'Bosnia and Herzegovina',
    continent: 'Europe',
    phoneCode: '+387',
  },
  BW: {
    code: 'BW',
    label: 'Botswana',
    continent: 'Africa',
    phoneCode: '+267',
  },
  BV: {
    code: 'BV',
    label: 'Bouvet Island',
    continent: 'Antarctica',
    phoneCode: '+55',
  },
  BR: {
    code: 'BR',
    label: 'Brazil',
    continent: 'South America',
    phoneCode: '+55',
  },
  IO: {
    code: 'IO',
    label: 'British Indian Ocean Territory',
    continent: 'Asia',
    phoneCode: '+246',
  },
  BN: {
    code: 'BN',
    label: 'Brunei',
    continent: 'Asia',
    phoneCode: '+673',
  },
  BG: {
    code: 'BG',
    label: 'Bulgaria',
    continent: 'Europe',
    phoneCode: '+359',
    eu: true,
  },
  BF: {
    code: 'BF',
    label: 'Burkina Faso',
    continent: 'Africa',
    phoneCode: '+226',
  },
  BI: {
    code: 'BI',
    label: 'Burundi',
    continent: 'Africa',
    phoneCode: '+257',
  },
  KH: {
    code: 'KH',
    label: 'Cambodia',
    continent: 'Asia',
    phoneCode: '+855',
  },
  CM: {
    code: 'CM',
    label: 'Cameroon',
    continent: 'Africa',
    phoneCode: '+237',
  },
  CA: {
    code: 'CA',
    label: 'Canada',
    continent: 'North America',
    phoneCode: '+1',
  },
  CV: {
    code: 'CV',
    label: 'Cape Verde',
    continent: 'Africa',
    phoneCode: '+238',
  },
  KY: {
    code: 'KY',
    label: 'Cayman Islands',
    continent: 'Central America',
    phoneCode: '+1345',
  },
  CF: {
    code: 'CF',
    label: 'Central African Republic',
    continent: 'Africa',
    phoneCode: '+236',
  },
  TD: {
    code: 'TD',
    label: 'Chad',
    continent: 'Africa',
    phoneCode: '+235',
  },
  CL: {
    code: 'CL',
    label: 'Chile',
    continent: 'South America',
    phoneCode: '+56',
  },
  CN: {
    code: 'CN',
    label: 'China',
    continent: 'Asia',
    phoneCode: '+86',
  },
  CX: {
    code: 'CX',
    label: 'Christmas Island',
    continent: 'Asia',
    phoneCode: '+61',
  },
  CC: {
    code: 'CC',
    label: 'Cocos (Keeling) Islands',
    continent: 'Asia',
    phoneCode: '+61',
  },
  CO: {
    code: 'CO',
    label: 'Colombia',
    continent: 'South America',
    phoneCode: '+57',
  },
  KM: {
    code: 'KM',
    label: 'Comoros',
    continent: 'Africa',
    phoneCode: '+269',
  },
  CG: {
    code: 'CG',
    label: 'Congo',
    continent: 'Africa',
    phoneCode: '+242',
  },
  CD: {
    code: 'CD',
    label: 'Congo, The Democratic Republic of the',
    continent: 'Africa',
    phoneCode: '+243',
  },
  CK: {
    code: 'CK',
    label: 'Cook Islands',
    continent: 'Oceania',
    phoneCode: '+682',
  },
  CR: {
    code: 'CR',
    label: 'Costa Rica',
    continent: 'Central America',
    phoneCode: '+506',
  },
  CI: {
    code: 'CI',
    label: 'Côte d’Ivoire',
    continent: 'Africa',
    phoneCode: '+225',
  },
  HR: {
    code: 'HR',
    label: 'Croatia',
    continent: 'Europe',
    phoneCode: '+385',
    eu: true,
  },
  CU: {
    code: 'CU',
    label: 'Cuba',
    continent: 'Central America',
    phoneCode: '+53',
  },
  CW: {
    code: 'CW',
    label: 'Curaçao',
    continent: 'South America',
    phoneCode: '+599',
  },
  CY: {
    code: 'CY',
    label: 'Cyprus',
    continent: 'Europe',
    phoneCode: '+357',
    eu: true,
  },
  CZ: {
    code: 'CZ',
    label: 'Czechia',
    continent: 'Europe',
    phoneCode: '+420',
    eu: true,
  },
  DK: {
    code: 'DK',
    label: 'Denmark',
    continent: 'Europe',
    phoneCode: '+45',
    eu: true,
  },
  DJ: {
    code: 'DJ',
    label: 'Djibouti',
    continent: 'Africa',
    phoneCode: '+253',
  },
  DM: {
    code: 'DM',
    label: 'Dominica',
    continent: 'Central America',
    phoneCode: '+767',
  },
  DO: {
    code: 'DO',
    label: 'Dominican Republic',
    continent: 'Central America',
    phoneCode: '+1809',
  },
  EC: {
    code: 'EC',
    label: 'Ecuador',
    continent: 'South America',
    phoneCode: '+593',
  },
  EG: {
    code: 'EG',
    label: 'Egypt',
    continent: 'Africa',
    phoneCode: '+20',
  },
  SV: {
    code: 'SV',
    label: 'El Salvador',
    continent: 'Central America',
    phoneCode: '+503',
  },
  GQ: {
    code: 'GQ',
    label: 'Equatorial Guinea',
    continent: 'Africa',
    phoneCode: '+240',
  },
  ER: {
    code: 'ER',
    label: 'Eritrea',
    continent: 'Africa',
    phoneCode: '+291',
  },
  EE: {
    code: 'EE',
    label: 'Estonia',
    continent: 'Europe',
    phoneCode: '+372',
    eu: true,
  },
  ET: {
    code: 'ET',
    label: 'Ethiopia',
    continent: 'Africa',
    phoneCode: '+',
  },
  FK: {
    code: 'FK',
    label: 'Falkland Islands (Malvinas)',
    continent: 'South America',
    phoneCode: '+500',
  },
  FO: {
    code: 'FO',
    label: 'Faroe Islands',
    continent: 'Europ',
    phoneCode: '+298',
  },
  FJ: {
    code: 'FJ',
    label: 'Fiji',
    continent: 'Oceania',
    phoneCode: '+679',
  },
  FI: {
    code: 'FI',
    label: 'Finland',
    continent: 'Europe',
    phoneCode: '+358',
    eu: true,
  },
  FR: {
    code: 'FR',
    label: 'France',
    continent: 'Europe',
    phoneCode: '+33',
    eu: true,
  },
  GF: {
    code: 'GF',
    label: 'French Guiana',
    continent: 'South America',
    phoneCode: '+594',
  },
  PF: {
    code: 'PF',
    label: 'French Polynesia',
    continent: 'Oceania',
    phoneCode: '+689',
  },
  TF: {
    code: 'TF',
    label: 'French Southern Territories',
    continent: 'Antarctica',
    phoneCode: '+262',
  },
  GA: {
    code: 'GA',
    label: 'Gabon',
    continent: 'Africa',
    phoneCode: '+241',
  },
  GM: {
    code: 'GM',
    label: 'Gambia',
    continent: 'Africa',
    phoneCode: '+220',
  },
  GE: {
    code: 'GE',
    label: 'Georgia',
    continent: 'Europe',
    phoneCode: '+995',
  },
  DE: {
    code: 'DE',
    label: 'Germany',
    continent: 'Europe',
    phoneCode: '+49',
    eu: true,
  },
  GH: {
    code: 'GH',
    label: 'Ghana',
    continent: 'Africa',
    phoneCode: '+233',
  },
  GI: {
    code: 'GI',
    label: 'Gibraltar',
    continent: 'Europe',
    phoneCode: '+350',
  },
  GR: {
    code: 'GR',
    label: 'Greece',
    continent: 'Europe',
    phoneCode: '+30',
    eu: true,
  },
  GL: {
    code: 'GL',
    label: 'Greenland',
    continent: 'North America',
    phoneCode: '+299',
  },
  GD: {
    code: 'GD',
    label: 'Grenada',
    continent: 'Central America',
    phoneCode: '+1473',
  },
  GP: {
    code: 'GP',
    label: 'Guadeloupe',
    continent: 'Central America',
    phoneCode: '+590',
  },
  GU: {
    code: 'GU',
    label: 'Guam',
    continent: 'Oceania',
    phoneCode: '+1671',
  },
  GT: {
    code: 'GT',
    label: 'Guatemala',
    continent: 'Central America',
    phoneCode: '+502',
  },
  GG: {
    code: 'GG',
    label: 'Guernsey',
    continent: 'Europe',
    phoneCode: '+44',
  },
  GN: {
    code: 'GN',
    label: 'Guinea',
    continent: 'Africa',
    phoneCode: '+224',
  },
  GW: {
    code: 'GW',
    label: 'Guinea-Bissau',
    continent: 'Africa',
    phoneCode: '+245',
  },
  GY: {
    code: 'GY',
    label: 'Guyana',
    continent: 'South America',
    phoneCode: '+592',
  },
  HT: {
    code: 'HT',
    label: 'Haiti',
    continent: 'Central America',
    phoneCode: '+509',
  },
  HM: {
    code: 'HM',
    label: 'Heard Island and Mcdonald Islands',
    continent: 'Oceania',
    phoneCode: '+0',
  },
  VA: {
    code: 'VA',
    label: 'Holy See (Vatican City State)',
    continent: 'Europe',
    phoneCode: '+378',
  },
  HN: {
    code: 'HN',
    label: 'Honduras',
    continent: 'Central America',
    phoneCode: '+504',
  },
  HK: {
    code: 'HK',
    label: 'Hong Kong',
    continent: 'Asia',
    phoneCode: '+852',
  },
  HU: {
    code: 'HU',
    label: 'Hungary',
    continent: 'Europe',
    phoneCode: '+36',
    eu: true,
  },
  IS: {
    code: 'IS',
    label: 'Iceland',
    continent: 'Europe',
    phoneCode: '+354',
  },
  IN: {
    code: 'IN',
    label: 'India',
    continent: 'Asia',
    phoneCode: '+91',
  },
  ID: {
    code: 'ID',
    label: 'Indonesia',
    continent: 'Asia',
    phoneCode: '+62',
  },
  IR: {
    code: 'IR',
    label: 'Iran',
    continent: 'Asia',
    phoneCode: '+98',
  },
  IQ: {
    code: 'IQ',
    label: 'Iraq',
    continent: 'Asia',
    phoneCode: '+964',
  },
  IE: {
    code: 'IE',
    label: 'Ireland',
    continent: 'Europe',
    phoneCode: '+353',
    eu: true,
  },
  IM: {
    code: 'IM',
    label: 'Isle of Man',
    continent: 'Europe',
    phoneCode: '+44',
  },
  IL: {
    code: 'IL',
    label: 'Israel',
    continent: 'Asia',
    phoneCode: '+972',
  },
  IT: {
    code: 'IT',
    label: 'Italy',
    continent: 'Europe',
    phoneCode: '+39',
    eu: true,
  },
  JM: {
    code: 'JM',
    label: 'Jamaica',
    continent: 'Central America',
    phoneCode: '+1876',
  },
  JP: {
    code: 'JP',
    label: 'Japan',
    continent: 'Asia',
    phoneCode: '+81',
  },
  JE: {
    code: 'JE',
    label: 'Jersey',
    continent: 'Europe',
    phoneCode: '+44',
  },
  JO: {
    code: 'JO',
    label: 'Jordan',
    continent: 'Asia',
    phoneCode: '+962',
  },
  KZ: {
    code: 'KZ',
    label: 'Kazakhstan',
    continent: 'Asia',
    phoneCode: '+7',
  },
  KE: {
    code: 'KE',
    label: 'Kenya',
    continent: 'Africa',
    phoneCode: '+254',
  },
  KI: {
    code: 'KI',
    label: 'Kiribati',
    continent: 'Oceania',
    phoneCode: '+686',
  },
  KP: {
    code: 'KP',
    label: 'North Korea',
    continent: 'Asia',
    phoneCode: '+850',
  },
  KR: {
    code: 'KR',
    label: 'South Korea',
    continent: 'Asia',
    phoneCode: '+82',
  },
  XK: {
    code: 'XK',
    label: 'Kosovo',
    continent: 'Europe',
    phoneCode: '+383',
  },
  KW: {
    code: 'KW',
    label: 'Kuwait',
    continent: 'Asia',
    phoneCode: '+965',
  },
  KG: {
    code: 'KG',
    label: 'Kyrgyzstan',
    continent: 'Asia',
    phoneCode: '+996',
  },
  LA: {
    code: 'LA',
    label: 'Laos',
    continent: 'Asia',
    phoneCode: '+856',
  },
  LV: {
    code: 'LV',
    label: 'Latvia',
    continent: 'Europe',
    phoneCode: '+371',
    eu: true,
  },
  LB: {
    code: 'LB',
    label: 'Lebanon',
    continent: 'Asia',
    phoneCode: '+961',
  },
  LS: {
    code: 'LS',
    label: 'Lesotho',
    continent: 'Africa',
    phoneCode: '+266',
  },
  LR: {
    code: 'LR',
    label: 'Liberia',
    continent: 'Africa',
    phoneCode: '+231',
  },
  LY: {
    code: 'LY',
    label: 'Libya',
    continent: 'Africa',
    phoneCode: '+218',
  },
  LI: {
    code: 'LI',
    label: 'Liechtenstein',
    continent: 'Europe',
    phoneCode: '+423',
  },
  LT: {
    code: 'LT',
    label: 'Lithuania',
    continent: 'Europe',
    phoneCode: '+370',
    eu: true,
  },
  LU: {
    code: 'LU',
    label: 'Luxembourg',
    continent: 'Europe',
    phoneCode: '+352',
    eu: true,
  },
  MO: {
    code: 'MO',
    label: 'Macao',
    continent: 'Asia',
    phoneCode: '+853',
  },
  MK: {
    code: 'MK',
    label: 'North Macedonia',
    continent: 'Europe',
    phoneCode: '+389',
  },
  MG: {
    code: 'MG',
    label: 'Madagascar',
    continent: 'Africa',
    phoneCode: '+261',
  },
  MW: {
    code: 'MW',
    label: 'Malawi',
    continent: 'Africa',
    phoneCode: '+265',
  },
  MY: {
    code: 'MY',
    label: 'Malaysia',
    continent: 'Asia',
    phoneCode: '+60',
  },
  MV: {
    code: 'MV',
    label: 'Maldives',
    continent: 'Asia',
    phoneCode: '+960',
  },
  ML: {
    code: 'ML',
    label: 'Mali',
    continent: 'Africa',
    phoneCode: '+223',
  },
  MT: {
    code: 'MT',
    label: 'Malta',
    continent: 'Europe',
    phoneCode: '+356',
    eu: true,
  },
  MH: {
    code: 'MH',
    label: 'Marshall Islands',
    continent: 'Oceania',
    phoneCode: '+692',
  },
  MQ: {
    code: 'MQ',
    label: 'Martinique',
    continent: 'Central America',
    phoneCode: '+596',
  },
  MR: {
    code: 'MR',
    label: 'Mauritania',
    continent: 'Africa',
    phoneCode: '+222',
  },
  MU: {
    code: 'MU',
    label: 'Mauritius',
    continent: 'Africa',
    phoneCode: '+230',
  },
  YT: {
    code: 'YT',
    label: 'Mayotte',
    continent: 'Africa',
    phoneCode: '+269',
  },
  MX: {
    code: 'MX',
    label: 'Mexico',
    continent: 'North America',
    phoneCode: '+52',
  },
  FM: {
    code: 'FM',
    label: 'Micronesia',
    continent: 'Oceania',
    phoneCode: '+691',
  },
  MD: {
    code: 'MD',
    label: 'Moldova',
    continent: 'Europe',
    phoneCode: '+373',
  },
  MC: {
    code: 'MC',
    label: 'Monaco',
    continent: 'Europe',
    phoneCode: '+377',
  },
  MN: {
    code: 'MN',
    label: 'Mongolia',
    continent: 'Asia',
    phoneCode: '+976',
  },
  ME: {
    code: 'ME',
    label: 'Montenegro',
    continent: 'Europe',
    phoneCode: '+382',
  },
  MS: {
    code: 'MS',
    label: 'Montserrat',
    continent: 'Central America',
    phoneCode: '+1664',
  },
  MA: {
    code: 'MA',
    label: 'Morocco',
    continent: 'Africa',
    phoneCode: '+212',
  },
  MZ: {
    code: 'MZ',
    label: 'Mozambique',
    continent: 'Africa',
    phoneCode: '+258',
  },
  MM: {
    code: 'MM',
    label: 'Myanmar',
    continent: 'Asia',
    phoneCode: '+95',
  },
  NA: {
    code: 'NA',
    label: 'Namibia',
    continent: 'Africa',
    phoneCode: '+264',
  },
  NR: {
    code: 'NR',
    label: 'Nauru',
    continent: 'Oceania',
    phoneCode: '+674',
  },
  NP: {
    code: 'NP',
    label: 'Nepal',
    continent: 'Asia',
    phoneCode: '+977',
  },
  NL: {
    code: 'NL',
    label: 'Netherlands',
    continent: 'Europe',
    phoneCode: '+31',
    eu: true,
  },
  AN: {
    code: 'AN',
    label: 'Netherlands Antilles',
    continent: 'Central America',
    phoneCode: '+599',
  },
  NC: {
    code: 'NC',
    label: 'New Caledonia',
    continent: 'Oceania',
    phoneCode: '+687',
  },
  NZ: {
    code: 'NZ',
    label: 'New Zealand',
    continent: 'Oceania',
    phoneCode: '+64',
  },
  NI: {
    code: 'NI',
    label: 'Nicaragua',
    continent: 'Central America',
    phoneCode: '+505',
  },
  NE: {
    code: 'NE',
    label: 'Niger',
    continent: 'Africa',
    phoneCode: '+227',
  },
  NG: {
    code: 'NG',
    label: 'Nigeria',
    continent: 'Africa',
    phoneCode: '+234',
  },
  NU: {
    code: 'NU',
    label: 'Niue',
    continent: 'Oceania',
    phoneCode: '+683',
  },
  NF: {
    code: 'NF',
    label: 'Norfolk Island',
    continent: 'Oceania',
    phoneCode: '+683',
  },
  MP: {
    code: 'MP',
    label: 'Northern Mariana Islands',
    continent: 'Oceania',
    phoneCode: '+1670',
  },
  NO: {
    code: 'NO',
    label: 'Norway',
    continent: 'Europe',
    phoneCode: '+47',
  },
  OM: {
    code: 'OM',
    label: 'Oman',
    continent: 'Asia',
    phoneCode: '+968',
  },
  PK: {
    code: 'PK',
    label: 'Pakistan',
    continent: 'Asia',
    phoneCode: '+92',
  },
  PW: {
    code: 'PW',
    label: 'Palau',
    continent: 'Oceania',
    phoneCode: '+680',
  },
  PS: {
    code: 'PS',
    label: 'Palestinian Territory, Occupied',
    continent: 'Asia',
    phoneCode: '+970',
  },
  PA: {
    code: 'PA',
    label: 'Panama',
    continent: 'Central America',
    phoneCode: '+507',
  },
  PG: {
    code: 'PG',
    label: 'Papua New Guinea',
    continent: 'Oceania',
    phoneCode: '+675',
  },
  PY: {
    code: 'PY',
    label: 'Paraguay',
    continent: 'South America',
    phoneCode: '+595',
  },
  PE: {
    code: 'PE',
    label: 'Peru',
    continent: 'South Africa',
    phoneCode: '+51',
  },
  PH: {
    code: 'PH',
    label: 'Philippines',
    continent: 'Asica',
    phoneCode: '+63',
  },
  PN: {
    code: 'PN',
    label: 'Pitcairn',
    continent: 'Oceania',
    phoneCode: '+872',
  },
  PL: {
    code: 'PL',
    label: 'Poland',
    continent: 'Europe',
    phoneCode: '+48',
    eu: true,
  },
  PT: {
    code: 'PT',
    label: 'Portugal',
    continent: 'Europe',
    phoneCode: '+351',
    eu: true,
  },
  PR: {
    code: 'PR',
    label: 'Puerto Rico',
    continent: 'Central America',
    phoneCode: '+1787',
  },
  QA: {
    code: 'QA',
    label: 'Qatar',
    continent: 'Asia',
    phoneCode: '+974',
  },
  RE: {
    code: 'RE',
    label: 'Reunion',
    continent: 'Africa',
    phoneCode: '+262',
  },
  RO: {
    code: 'RO',
    label: 'Romania',
    continent: 'Europe',
    phoneCode: '+40',
    eu: true,
  },
  RU: {
    code: 'RU',
    label: 'Russia',
    continent: 'Europe',
    phoneCode: '+7',
  },
  RW: {
    code: 'RW',
    label: 'Rwanda',
    continent: 'Africa',
    phoneCode: '+250',
  },
  BL: {
    code: 'BL',
    label: 'Saint Barthelemy',
    continent: 'North America',
    phoneCode: '+590',
  },
  SH: {
    code: 'SH',
    label: 'Saint Helena',
    continent: 'Central America',
    phoneCode: '+290',
  },
  KN: {
    code: 'KN',
    label: 'Saint Kitts and Nevis',
    continent: 'Central America',
    phoneCode: '+1869',
  },
  LC: {
    code: 'LC',
    label: 'Saint Lucia',
    continent: 'Central America',
    phoneCode: '+1758',
  },
  MF: {
    code: 'MF',
    label: 'Saint Martin (French part)',
    continent: 'North America',
    phoneCode: '+590',
  },
  PM: {
    code: 'PM',
    label: 'Saint Pierre and Miquelon',
    continent: 'Central America',
    phoneCode: '+508',
  },
  VC: {
    code: 'VC',
    label: 'Saint Vincent and the Grenadines',
    continent: 'Central America',
    phoneCode: '+1784',
  },
  WS: {
    code: 'WS',
    label: 'Samoa',
    continent: 'Oceania',
    phoneCode: '+685',
  },
  SM: {
    code: 'SM',
    label: 'San Marino',
    continent: 'Europe',
    phoneCode: '+378',
  },
  ST: {
    code: 'ST',
    label: 'Sao Tome and Principe',
    continent: 'Africa',
    phoneCode: '+239',
  },
  SA: {
    code: 'SA',
    label: 'Saudi Arabia',
    continent: 'Asia',
    phoneCode: '+966',
  },
  SN: {
    code: 'SN',
    label: 'Senegal',
    continent: 'Africa',
    phoneCode: '+221',
  },
  RS: {
    code: 'RS',
    label: 'Serbia',
    continent: 'Europe',
    phoneCode: '+381',
  },
  SC: {
    code: 'SC',
    label: 'Seychelles',
    continent: 'Africa',
    phoneCode: '+248',
  },
  SL: {
    code: 'SL',
    label: 'Sierra Leone',
    continent: 'Africa',
    phoneCode: '+232',
  },
  SG: {
    code: 'SG',
    label: 'Singapore',
    continent: 'Asia',
    phoneCode: '+65',
  },
  SX: {
    code: 'SX',
    label: 'Sint Maarten (Dutch part)',
    continent: 'North America',
    phoneCode: '+721',
  },
  SK: {
    code: 'SK',
    label: 'Slovakia',
    continent: 'Europe',
    phoneCode: '+421',
    eu: true,
  },
  SI: {
    code: 'SI',
    label: 'Slovenia',
    continent: 'Europe',
    phoneCode: '+386',
    eu: true,
  },
  SB: {
    code: 'SB',
    label: 'Solomon Islands',
    continent: 'Oceania',
    phoneCode: '+677',
  },
  SO: {
    code: 'SO',
    label: 'Somalia',
    continent: 'Africa',
    phoneCode: '+252',
  },
  ZA: {
    code: 'ZA',
    label: 'South Africa',
    continent: 'Africa',
    phoneCode: '+27',
  },
  GS: {
    code: 'GS',
    label: 'South Georgia and the South Sandwich Islands',
    continent: 'South America',
    phoneCode: '+500',
  },
  SS: {
    code: 'SS',
    label: 'South Sudan',
    continent: 'Africa',
    phoneCode: '+211',
  },
  ES: {
    code: 'ES',
    label: 'Spain',
    continent: 'Europe',
    phoneCode: '+34',
    eu: true,
  },
  LK: {
    code: 'LK',
    label: 'Sri Lanka',
    continent: 'Asia',
    phoneCode: '+94',
  },
  SD: {
    code: 'SD',
    label: 'Sudan',
    continent: 'Africa',
    phoneCode: '+249',
  },
  SR: {
    code: 'SR',
    label: 'Suriname',
    continent: 'South America',
    phoneCode: '+597',
  },
  SJ: {
    code: 'SJ',
    label: 'Svalbard and Jan Mayen',
    continent: 'Europe',
    phoneCode: '+47',
  },
  SZ: {
    code: 'SZ',
    label: 'Swaziland',
    continent: 'Africa',
    phoneCode: '+268',
  },
  SE: {
    code: 'SE',
    label: 'Sweden',
    continent: 'Europe',
    phoneCode: '+46',
    eu: true,
  },
  CH: {
    code: 'CH',
    label: 'Switzerland',
    continent: 'Europe',
    phoneCode: '+41',
  },
  SY: {
    code: 'SY',
    label: 'Syria',
    continent: 'Asia',
    phoneCode: '+963',
  },
  TW: {
    code: 'TW',
    label: 'Taiwan',
    continent: 'Asia',
    phoneCode: '+886',
  },
  TJ: {
    code: 'TJ',
    label: 'Tajikistan',
    continent: 'Asia',
    phoneCode: '+992',
  },
  TZ: {
    code: 'TZ',
    label: 'Tanzania',
    continent: 'Africa',
    phoneCode: '+255',
  },
  TH: {
    code: 'TH',
    label: 'Thailand',
    continent: 'Asia',
    phoneCode: '+66',
  },
  TL: {
    code: 'TL',
    label: 'East Timor',
    continent: 'Asia',
    phoneCode: '+670',
  },
  TG: {
    code: 'TG',
    label: 'Togo',
    continent: 'Africa',
    phoneCode: '+229',
  },
  TK: {
    code: 'TK',
    label: 'Tokelau',
    continent: 'Oceania',
    phoneCode: '+690',
  },
  TO: {
    code: 'TO',
    label: 'Tonga',
    continent: 'Oceania',
    phoneCode: '+676',
  },
  TT: {
    code: 'TT',
    label: 'Trinidad and Tobago',
    continent: 'Central America',
    phoneCode: '+868',
  },
  TN: {
    code: 'TN',
    label: 'Tunisia',
    continent: 'Africa',
    phoneCode: '+216',
  },
  TR: {
    code: 'TR',
    label: 'Turkey',
    continent: 'Asia',
    phoneCode: '+90',
  },
  TM: {
    code: 'TM',
    label: 'Turkmenistan',
    continent: 'Asia',
    phoneCode: '+993',
  },
  TC: {
    code: 'TC',
    label: 'Turks and Caicos Islands',
    continent: 'Central America',
    phoneCode: '+1649',
  },
  TV: {
    code: 'TV',
    label: 'Tuvalu',
    continent: 'Oceania',
    phoneCode: '+688',
  },
  UG: {
    code: 'UG',
    label: 'Uganda',
    continent: 'Africa',
    phoneCode: '+256',
  },
  UA: {
    code: 'UA',
    label: 'Ukraine',
    continent: 'Europe',
    phoneCode: '+380',
  },
  AE: {
    code: 'AE',
    label: 'United Arab Emirates',
    continent: 'Asia',
    phoneCode: '+971',
  },
  GB: {
    code: 'GB',
    label: 'United Kingdom',
    continent: 'Europe',
    phoneCode: '+44',
  },
  US: {
    code: 'US',
    label: 'United States of America',
    continent: 'North America',
    phoneCode: '+1',
  },
  UM: {
    code: 'UM',
    label: 'United States Minor Outlying Islands',
    continent: 'Central America',
    phoneCode: '+246',
  },
  UY: {
    code: 'UY',
    label: 'Uruguay',
    continent: 'South America',
    phoneCode: '+598',
  },
  UZ: {
    code: 'UZ',
    label: 'Uzbekistan',
    continent: 'Asia',
    phoneCode: '+998',
  },
  VU: {
    code: 'VU',
    label: 'Vanuatu',
    continent: 'Oceania',
    phoneCode: '+678',
  },
  VE: {
    code: 'VE',
    label: 'Venezuela',
    continent: 'South America',
    phoneCode: '+58',
  },
  VN: {
    code: 'VN',
    label: 'Vietnam',
    continent: 'Asia',
    phoneCode: '+84',
  },
  VG: {
    code: 'VG',
    label: 'Virgin Islands, British',
    continent: 'Central America',
    phoneCode: '+1284',
  },
  VI: {
    code: 'VI',
    label: 'Virgin Islands, U.S.',
    continent: 'Central America',
    phoneCode: '+1340',
  },
  WF: {
    code: 'WF',
    label: 'Wallis and Futuna',
    continent: 'Oceania',
    phoneCode: '+681',
  },
  EH: {
    code: 'EH',
    label: 'Western Sahara',
    continent: 'Africa',
    phoneCode: '+212',
  },
  YE: {
    code: 'YE',
    label: 'Yemen',
    continent: 'Asia',
    phoneCode: '+967',
  },
  ZM: {
    code: 'ZM',
    label: 'Zambia',
    continent: 'Africa',
    phoneCode: '+260',
  },
  ZW: {
    code: 'ZW',
    label: 'Zimbabwe',
    continent: 'Africa',
    phoneCode: '+263',
  },
};
