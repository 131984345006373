import React from 'react';
import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { wmPalette } from '../../../Theme/WorldSportsGroupTheme';
import Grid from '@mui/material/Unstable_Grid2';
const useStyles = makeStyles((theme) => ({
  timefilter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: `1px solid ${wmPalette.green[70]}`,
    cursor: 'pointer',
    borderRadius: '68px',
    height: '32px',
    padding: '0px 20px',
    margin: '2px 2px',
    whiteSpace: 'nowrap',
  },
  timefilterInactive: {
    color: wmPalette.green[70],
    backgroundColor: wmPalette.white[100],
  },
  timefilterActive: {
    color: wmPalette.white[100],
    backgroundColor: wmPalette.green[70],
  },
  typography: {
    display: 'flex',
    verticalAlign: 'middle',
    fontSize: '14px',
  },
}));

const TimefilterCard = ({ id, activeTimefilter, name, onClick }) => {
  const classes = useStyles();
  return (
    <Grid>
      <Box
        className={clsx(
          classes.timefilter,
          id === activeTimefilter
            ? classes.timefilterActive
            : classes.timefilterInactive
        )}
        onClick={() => onClick(id)}
      >
        <Typography className={classes.typography}>{name}</Typography>
      </Box>
    </Grid>
  );
};

export default TimefilterCard;
