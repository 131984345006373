import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { SWRConfig } from 'swr';
import handleApiError from '../../../Utils/handleApiError';
import config from '../../../config';

const SWRWrapper = (props) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const displayError = (message) =>
    enqueueSnackbar(handleApiError(message), { variant: 'error' });
  const fetcher = async (url, hideError = false) => {
    const res = await fetch(config.API_URL + url, {
      credentials: 'include',
    });
    if (!res.ok) {
      const text = await res.text();
      switch (res.status) {
        case 401:
          navigate(`/login?loginRedirect=${pathname}`);
          break;
        case 404:
          displayError(text);
          break;
        default:
          if (!hideError) {
            displayError(
              "An error occurred whilst fetching your data. We're on it"
            );
          }
      }
      const error = new Error('An error occurred while fetching the data.');
      // Attach extra info to the error object.
      error.info = res;
      error.status = res.status;
      throw error;
    }

    return res.json();
  };

  return (
    <SWRConfig
      value={{
        fetcher,
        revalidateOnFocus: false,
        onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
          // Never retry for a specific key.
          if (key.includes('/orders')) return;
        },
      }}
    >
      {props.children}
    </SWRConfig>
  );
};

export default SWRWrapper;
