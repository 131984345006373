import useSWR from 'swr';

const useCoupons = (eventId, editionId) => {
  const couponUrl = `/ro/events/${eventId}/editions/${editionId}/coupons`;
  const { data, error } = useSWR(couponUrl);

  return {
    couponUrl,
    coupons: data,
    isLoading: !error && !data,
    error,
  };
};

export default useCoupons;
