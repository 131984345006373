import React from 'react';
import { TableRow, TableCell } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import FormikTextField from '../../../Common/Formik/FormikTextField';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import { wmPalette } from '../../../../Theme/WorldSportsGroupTheme';

const useStyles = makeStyles((theme) => ({
  mainRow: {
    borderBottom: 'none',
    borderTop: '1px solid #D1D7DB',
  },
  subRow: {
    borderBottom: 'none',
  },
  arrowIcon: {
    color: wmPalette.black[50],
  },
}));

const ItemRow = (props) => {
  const classes = useStyles();

  if (props.itemId !== '') {
    return (
      <TableRow>
        <TableCell
          className={props.itemName ? classes.mainRow : classes.subRow}
        >
          {props.itemName}
        </TableCell>
        <TableCell
          className={props.itemName ? classes.mainRow : classes.subRow}
        >
          {props.showAdditional && (
            <SubdirectoryArrowRightIcon className={classes.arrowIcon} />
          )}
          {props.itemId}
        </TableCell>
        <TableCell
          className={props.itemName ? classes.mainRow : classes.subRow}
        >
          <FormikTextField
            variant="outlined"
            size="small"
            name={props.fieldName}
          />
        </TableCell>
      </TableRow>
    );
  } else return null;
};

export default ItemRow;
