import * as yup from 'yup';
import regexvalidation from '../../../Utils/regexvalidation';
import {
  fieldContainsEmail,
  fieldContainsLink,
} from '../../../Consts/validationErrorMessages';

const maxCharsRule = (chars) =>
  yup.string().nullable().max(chars, `Max. ${chars} characters are allowed`);

export const validationSchema = yup.object().shape({
  whereWhen: maxCharsRule(1000)
    .test('test-name', fieldContainsEmail, (value) =>
      regexvalidation(value, 'email')
    )
    .test('test-name', fieldContainsLink, (value) =>
      regexvalidation(value, 'link')
    ),
  someoneCollectPack: maxCharsRule(1000)
    .test('test-name', fieldContainsEmail, (value) =>
      regexvalidation(value, 'email')
    )
    .test('test-name', fieldContainsLink, (value) =>
      regexvalidation(value, 'link')
    ),
  howToArrive: maxCharsRule(1000)
    .test('test-name', fieldContainsEmail, (value) =>
      regexvalidation(value, 'email')
    )
    .test('test-name', fieldContainsLink, (value) =>
      regexvalidation(value, 'link')
    ),
  changeDistance: maxCharsRule(1000)
    .test('test-name', fieldContainsEmail, (value) =>
      regexvalidation(value, 'email')
    )
    .test('test-name', fieldContainsLink, (value) =>
      regexvalidation(value, 'link')
    ),
  refundPolicy: maxCharsRule(1000)
    .test('test-name', fieldContainsEmail, (value) =>
      regexvalidation(value, 'email')
    )
    .test('test-name', fieldContainsLink, (value) =>
      regexvalidation(value, 'link')
    ),
  customs: yup.array().of(
    yup.object().shape({
      question: maxCharsRule(100)
        .test(
          'must be filled if answered',
          'You must provide a question if you have provided an answer',
          function (value) {
            if (Boolean(this.parent.answer)) {
              return Boolean(value);
            }
            return true;
          }
        )
        .test('email', fieldContainsEmail, function (value) {
          return regexvalidation(value, 'email');
        })
        .test('url', fieldContainsLink, function (value) {
          return regexvalidation(value, 'link');
        }),
      answer: maxCharsRule(1000)
        .test(
          'must be filled if question',
          'You must provide an answer if you have provided a question',
          function (value) {
            if (Boolean(this.parent.question)) {
              return Boolean(value);
            }
            return true;
          }
        )
        .test('email', fieldContainsEmail, function (value) {
          return regexvalidation(value, 'email');
        })
        .test('url', fieldContainsLink, function (value) {
          return regexvalidation(value, 'link');
        }),
    })
  ),
});
