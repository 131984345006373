import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import GridWrapper from '../Common/GridWrapper/GridWrapper';
import { Box, Typography, IconButton, Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useNavigate } from 'react-router-dom';
import EventTableList from './EventTableList';
import EventGridList from './EventGridList';
import { useEvents } from '../../Hooks/useEvents';
import { Add, Apps, List } from '@mui/icons-material';
import { useTracking } from '../../Analytics/TrackingContext';
import ReactivateInfoModal from '../ManageEvents/ReactivateEvent/ReactivateInfoModal';
import TrackingButton from '../Common/TrackingButton/TrackingButton';
import EmptyEventView from './EmptyEventView';
import BlueLink from '../Common/BlueLink/BlueLink';
import { wmPalette } from '../../Theme/WorldSportsGroupTheme';
import Loading from '../../Components/Loading/Loading';
import Grid from '@mui/material/Unstable_Grid2';
const useStyles = makeStyles((theme) => ({
  grid: {
    margin: 'auto',
    paddingBottom: `${theme.spacing(16)} !important`,
  },
  topHeading: {
    flexGrow: 1,
  },
  headerBox: {
    flex: 1,
    display: 'flex',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(4),
      marginTop: theme.spacing(4),
    },
  },
  reactivationBanner: {
    backgroundColor: wmPalette.yellow[10],
    padding: theme.spacing(3),
    borderRadius: theme.spacing(1),
    flex: 1,
    display: 'flex',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(4),
    },
  },
  reloadLink: {
    fontWeight: 'bold',
    color: theme.palette.error.main,
  },
  paper: {
    padding: theme.spacing(2),
  },
  toggleViewButton: {
    fontSize: 8,
  },
  loadingContainer: {
    paddingTop: theme.spacing(30),
    paddingBottom: theme.spacing(30),
  },
}));
const EventsList = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { events, isLoading } = useEvents();
  const [viewMode, setViewMode] = useState('grid');
  const { trackEvent } = useTracking();
  const [reactivateInfoModal, toggleReactivateInfoModal] = useState(false);
  const handleViewModeChange = () => {
    setViewMode((mode) => (mode === 'list' ? 'grid' : 'list'));
  };

  const handleCreateClick = () => {
    trackEvent({
      category: 'Create Event',
      action: 'Start Create Event',
      label: 'Event creation start',
    });
    navigate('/app/create-event');
  };

  return (
    <>
      {isLoading && (
        <>
          <Paper className={classes.loadingContainer}>
            <Loading disableElevation={true} variant="spinner" />
          </Paper>
        </>
      )}
      <GridWrapper>
        {!isLoading && isEmpty(events) && (
          <EmptyEventView handleCreateClick={handleCreateClick} />
        )}
        {!isLoading && !isEmpty(events) && (
          <Grid xs={12} className={classes.grid}>
            <Box className={classes.headerBox}>
              <Typography
                variant="h4"
                color="initial"
                className={classes.topHeading}
              >
                Events
              </Typography>
              <Box>
                <TrackingButton
                  href="/app/create-event"
                  label={'Add an event'}
                  icon={<Add />}
                  eventCategory="Create Event"
                  eventAction="Start create event"
                  eventLabel="Event creation start"
                  onClick={handleCreateClick}
                  variant="outlined"
                />
              </Box>
            </Box>
            {!isLoading && !isEmpty(events) && (
              <>
                {events.some(
                  (x) => x.reactivationInfo?.reactivationNecessary
                ) && (
                  <Box className={classes.reactivationBanner}>
                    <Typography variant="body1">
                      You seem to have one or several finished editions. Have
                      some questions about activating the next edition?{' '}
                      <BlueLink
                        onClick={() => toggleReactivateInfoModal(true)}
                        component="button"
                        text=" Click here."
                      />
                    </Typography>
                  </Box>
                )}
                <IconButton
                  className={classes.toggleViewButton}
                  color={viewMode === 'list' ? 'primary' : 'default'}
                  onClick={handleViewModeChange}
                  size="large"
                >
                  <List />
                </IconButton>
                <IconButton
                  className={classes.toggleViewButton}
                  color={viewMode === 'grid' ? 'primary' : 'default'}
                  onClick={handleViewModeChange}
                  size="large"
                >
                  <Apps />
                </IconButton>
                {viewMode === 'list' && <EventTableList events={events} />}
                {viewMode === 'grid' && <EventGridList events={events} />}
                {reactivateInfoModal && (
                  <ReactivateInfoModal
                    open={reactivateInfoModal}
                    handleClose={() => toggleReactivateInfoModal(false)}
                  />
                )}
              </>
            )}
          </Grid>
        )}
      </GridWrapper>
    </>
  );
};

export default EventsList;
