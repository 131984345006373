import React from 'react';
import {
  Box,
  Chip,
  FormControlLabel,
  Checkbox,
  Typography,
  FormHelperText,
  Tooltip,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  checkbox: {
    marginBottom: theme.spacing(2),
  },
  chip: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
    '&:hover': {
      backgroundColor: '#eafff6 !important',
    },
  },
  selectedChip: {
    '&:hover': {
      backgroundColor: '#00ac78 !important',
    },
  },
  error: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

function ChipWrapper(props) {
  if (props.teamTicket && props.disableTeamTickets) {
    return (
      <Tooltip title={'Add-ons are not available for teams'}>
        <span>{props.children}</span>
      </Tooltip>
    );
  }
  return props.children;
}

const TicketSelectBox = ({
  availableTickets,
  selectedTickets,
  setTickets,
  disabled = false,
  error,
  disableTeamTickets = false,
}) => {
  const classes = useStyles();
  const selectableTickets = disableTeamTickets
    ? availableTickets.filter((x) => !x.teamTicket)
    : availableTickets;

  const toggleTicket = (ticket) => {
    let idx = selectedTickets.findIndex((t) => t === ticket);
    if (idx === -1) setTickets([...selectedTickets, ticket]);
    else setTickets(selectedTickets.filter((t, i) => i !== idx));
  };

  const toggleSelectAll = () => {
    setTickets(
      selectedTickets.length === selectableTickets.length
        ? []
        : selectableTickets.map((x) => x.id)
    );
  };

  return (
    <>
      <FormControlLabel
        name="allTickets"
        className={classes.checkbox}
        control={
          <Checkbox
            checked={selectedTickets.length === selectableTickets.length}
            onChange={(e) => toggleSelectAll(e.target.checked)}
            color="primary"
            disabled={disabled}
          />
        }
        label={<Typography>All tickets</Typography>}
      />
      <Box flexDirection="row">
        {availableTickets.map((ticket) => (
          <ChipWrapper
            teamTicket={ticket.teamTicket}
            disableTeamTickets={disableTeamTickets}
          >
            <Chip
              className={clsx(
                classes.chip,
                selectedTickets.some((x) => x === ticket.id)
                  ? classes.selectedChip
                  : ''
              )}
              key={ticket.id}
              label={ticket.name}
              onClick={() => toggleTicket(ticket.id)}
              variant={
                selectedTickets.some((x) => x === ticket.id)
                  ? 'default'
                  : 'outlined'
              }
              color={
                ticket.teamTicket && disableTeamTickets ? 'default' : 'primary'
              }
              disabled={disableTeamTickets ? ticket.teamTicket : disabled}
            />
          </ChipWrapper>
        ))}
      </Box>
      {Boolean(error) && (
        <FormHelperText className={classes.error} error={true}>
          {error}
        </FormHelperText>
      )}
    </>
  );
};

export default TicketSelectBox;
