import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

const useStyles = makeStyles((theme) => ({
  cardContent: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),

    '&:last-child': {
      paddingBottom: theme.spacing(5),
    },
  },
  topPaper: {
    overflow: 'visible',
  },
}));
const StepWrapper = (props) => {
  const classes = useStyles();
  return (
    <Paper>
      <Card className={classes.topPaper}>
        <CardContent className={`${classes.cardContent} ${props.className}`}>
          {props.children}
        </CardContent>
      </Card>
    </Paper>
  );
};

export default StepWrapper;
