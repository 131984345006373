import React from 'react';
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ItemRow from './ItemRow';

const useStyles = makeStyles((theme) => ({
  tableHead: {
    '& .MuiTableCell-head': {
      backgroundColor: 'white',
      borderBottom: '2px solid #B3B8BC',
      width: '33%',
    },
  },
}));

const ItemTable = (props) => {
  const classes = useStyles();

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow className={classes.tableHead}>
            <TableCell>{props.category}</TableCell>
            <TableCell>Original ID</TableCell>
            <TableCell>Custom ID</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data.map((row) => (
            <>
              <ItemRow
                itemName={row.name ?? row.label}
                itemId={row.id}
                externalId={row.externalProductId ?? row.externalOptionId}
                fieldName={row.id.toString()}
              />
              {row.addOnVariant && (
                <>
                  <ItemRow
                    itemId={row.addOnVariant.optionName}
                    externalId={row.addOnVariant.externalOptionId}
                    fieldName={row.id.toString() + row.addOnVariant.optionName}
                  />
                  {row.addOnVariant.options.map((option) => (
                    <>
                      <ItemRow
                        itemId={option.name}
                        externalId={option.externalValueId}
                        fieldName={
                          row.id.toString() +
                          row.addOnVariant.optionName +
                          option.name
                        }
                      />
                      {option.requiredInformationLabel && (
                        <ItemRow
                          itemId={option.requiredInformationLabel}
                          externalId={option.externalValueCustomMappingId}
                          fieldName={
                            row.id.toString() +
                            row.addOnVariant.optionName +
                            option.name +
                            option.requiredInformationLabel.replace(
                              /[^A-Z0-9]+/gi,
                              ''
                            )
                          }
                          showAdditional={true}
                        />
                      )}
                    </>
                  ))}
                </>
              )}

              {row.customQuestionValues &&
                row.customQuestionValues.map((customQuestionValue) => (
                  <>
                    <ItemRow
                      itemId={customQuestionValue.value}
                      externalId={customQuestionValue.externalValueId}
                      fieldName={
                        row.id.toString() + customQuestionValue.id.toString()
                      }
                    />
                    {row.subQuestions &&
                      row.subQuestions
                        .filter(
                          (sq) =>
                            sq.previousAnswer === customQuestionValue.value
                        )
                        .map((subQuestion) => (
                          <>
                            <ItemRow
                              itemId={subQuestion.label}
                              externalId={subQuestion.externalOptionId}
                              fieldName={
                                row.id.toString() + subQuestion.id.toString()
                              }
                              showAdditional={true}
                            />
                            {subQuestion.customQuestionValues &&
                              subQuestion.customQuestionValues.map(
                                (customQuestionValue) => (
                                  <>
                                    <ItemRow
                                      itemId={customQuestionValue.value}
                                      externalId={
                                        customQuestionValue.externalValueId
                                      }
                                      fieldName={
                                        row.id.toString() +
                                        subQuestion.id.toString() +
                                        customQuestionValue.id.toString()
                                      }
                                      showAdditional={true}
                                    />
                                  </>
                                )
                              )}
                          </>
                        ))}
                  </>
                ))}
            </>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ItemTable;
