import React, { useState } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import {
  InputAdornment,
  Typography,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
  FormControl,
  Box,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import makeStyles from '@mui/styles/makeStyles';
import isAfter from 'date-fns/isAfter';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import DrawerBase from '../../../Common/Drawer/DrawerBase';
import FormikTextField from '../../../Common/Formik/FormikTextField';
import WrappedDivider from '../../../Common/WrappedDivider/WrappedDivider';
import ConfirmationModal from '../../../Common/ConfirmationModal/ConfirmationModal';
import { useApi, methods } from '../../../../Hooks/useApi';
import { wmPalette } from '../../../../Theme/WorldSportsGroupTheme';

const useStyles = makeStyles((theme) => ({
  paddingBottom: {
    paddingBottom: `${theme.spacing(4)} !important`,
  },
  participant: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  box: {
    backgroundColor: wmPalette.grey[20],
    padding: theme.spacing(2),
  },
  grey: {
    color: wmPalette.black[40],
  },
  refundBox: {
    backgroundColor: wmPalette.green[5],
    padding: theme.spacing(2),
  },
  bold: {
    fontWeight: 'bold',
  },
  boldGrey: {
    fontWeight: 'bold',
    color: wmPalette.black[40],
  },
  line: {
    textDecoration: 'line-through',
  },
  paddingB: {
    paddingBottom: theme.spacing(2),
  },
}));

const validationSchema = yup.object().shape({
  serviceFee: yup
    .number()
    .min(0, 'Service fee must be greater than or equal to 0'),
  eMail: yup.string().email('Invalid email').required('An email is required'),
});

const feeURL = (value, orderCurrency) =>
  `/settings/currency/converttodisplaycurrency/${value}?forceCurrency=${orderCurrency}`;
const ticketURL = (eventId, editionId, orderId) =>
  `/ro/events/${eventId}/races/${editionId}/orders/${orderId}/changeticket`;

const RefundOrderDrawer = ({
  open,
  eventId,
  editionId,
  handleClose,
  order,
  ticket,
  eventInfo,
  setOrder,
}) => {
  const [modalOpen, toggleModal] = useState(false);
  const classes = useStyles();
  const { executeApiCall } = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const [processingFee, setProcessingFee] = useState(0);
  const currencyFormatted = order.currency?.toUpperCase();

  const getPriceStepPrice = (newTicket) => {
    const timeOfPurchase = new Date(order.createdAt);
    const pricePointOfPurchase = newTicket.priceSteps
      .map((step) => {
        const priceActivationDate = new Date(step.activationAt);
        if (isAfter(timeOfPurchase, priceActivationDate)) {
          return step.price;
        }
        return undefined;
      })
      .filter((x) => x !== undefined);
    return pricePointOfPurchase.length === 0
      ? newTicket.price
      : pricePointOfPurchase[pricePointOfPurchase.length - 1];
  };

  const getTicketPriceDifference = (oldTicket, values) => {
    const oldPrice = oldTicket.ticket.price;
    if (values.priceSteps === 'previous')
      return getPriceStepPrice(values.newTicket) - oldPrice;
    else return values.newTicket.price - oldPrice;
  };

  const calcFees = (serviceFee) => {
    return processingFee + serviceFee;
  };

  const getTotalAmount = (oldTicket, values) => {
    const ticketDifference = getTicketPriceDifference(oldTicket, values);
    if (ticketDifference + values.serviceFee === 0) return 0;
    else return ticketDifference + calcFees(values.serviceFee);
  };

  const fetchFee = async () => {
    try {
      const data = await executeApiCall(feeURL(1, order.currency), methods.get);
      setProcessingFee(data);
    } catch (e) {}
  };

  const changeTicket = async (values, ticket) => {
    const payload = {
      email: values.eMail,
      freeChange: values.free,
      serviceFee: values.serviceFee,
      participants: [
        {
          id: ticket.id,
          ticketId: ticket.ticket.productId,
          newTicketId: values.newTicket.id,
          useCurrentPrice: values.priceSteps === 'current' ? true : false,
        },
      ],
    };

    try {
      const data = await executeApiCall(
        ticketURL(eventId, editionId, order.orderId),
        methods.put,
        payload
      );

      if (data.orderer.ticket.productId === 'ticketupgrade') {
        const updateTimeLine = order;
        updateTimeLine.timeline = data.timeline;
        setOrder((order) => ({
          ...order,
          timeline: data.timeline,
          status: data.status,
          statusTitle: data.statusTitle,
        }));
      } else {
        setOrder(data);
      }
      handleClose();
      enqueueSnackbar('Ticket changed successfully', { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' });
    }
  };

  React.useEffect(() => {
    fetchFee();
  }, []);

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          currentTicket: ticket,
          free: false,
          priceSteps: 'current',
          serviceFee: 0,
          eMail: order.orderer.email,
        }}
        validationSchema={validationSchema}
        onSubmit={(v) => changeTicket(v, ticket)}
      >
        {({
          handleSubmit,
          isSubmitting,
          values,
          setFieldValue,
          handleChange,
          setErrors,
        }) => (
          <DrawerBase
            open={open}
            onClose={() => toggleModal(true)}
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            submittingMessage="Changing ticket.."
            submitText="Confirm & change ticket"
            cancelText="Cancel"
          >
            <Grid xs={12} className={classes.paddingBottom}>
              <Typography variant="h3" gutterBottom>
                Change ticket
              </Typography>
              <Typography variant="body1">
                This feature allows you to change a ticket for participants. It
                is useful when a participant wants to change their chosen
                distance to another distance. You can either make a partial
                refund or ask the participant for an additional payment
                depending on the price difference. Team orders can't be changed
                at this point.
              </Typography>
              <Typography className={classes.participant} variant="body1">
                Participant: {ticket?.fullName}
              </Typography>
              <Grid container spacing={2}>
                <Grid xs={12}>
                  <FormikTextField
                    name="currentTicket"
                    label="Current ticket"
                    value={ticket}
                    required
                    fullWidth
                    select
                    disabled
                  >
                    <MenuItem value={ticket}>
                      {ticket?.ticket.productName} (
                      {ticket?.ticket.priceFormatted} {currencyFormatted})
                    </MenuItem>
                  </FormikTextField>
                </Grid>
                <Grid xs={12}>
                  <FormikTextField
                    name="newTicket"
                    label="Select new ticket"
                    required
                    fullWidth
                    select
                  >
                    {eventInfo.tickets.map((t) => (
                      <MenuItem
                        key={t.id}
                        value={t}
                        disabled={
                          t.teamTicket || t.id === ticket?.ticket.productId
                        }
                      >
                        {t.name}
                      </MenuItem>
                    ))}
                  </FormikTextField>
                </Grid>
                {values.newTicket && (
                  <>
                    <Grid xs={12}>
                      <FormControlLabel
                        label={
                          ticket?.ticket.price > values.newTicket.price
                            ? 'Change the ticket without refunding'
                            : 'Change the ticket for free'
                        }
                        control={
                          <Checkbox
                            checked={values.free}
                            name="free"
                            onChange={(e, val) => {
                              setFieldValue('free', val);
                            }}
                          />
                        }
                      />
                    </Grid>
                    {values.newTicket.priceSteps.length > 0 &&
                      getPriceStepPrice(values.newTicket) !==
                        values.newTicket.price && (
                        <Grid xs={12}>
                          <FormControl disabled={values.free}>
                            <RadioGroup
                              aria-labelledby="demo-radio-buttons-group-label"
                              defaultValue="current"
                              name="priceSteps"
                              value={values.priceSteps}
                              onChange={handleChange}
                            >
                              <FormControlLabel
                                value="current"
                                control={<Radio />}
                                label={`Use current price (${values.newTicket.price} ${currencyFormatted})`}
                              />
                              <FormControlLabel
                                value="previous"
                                control={<Radio />}
                                label={`Use price at the point of purchase (${getPriceStepPrice(
                                  values.newTicket
                                )} ${currencyFormatted})`}
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                      )}
                    <WrappedDivider />
                    <Grid xs={12}>
                      <FormikTextField
                        label="Your service fee"
                        type="number"
                        disabled={values.free ? true : false}
                        placeholder=""
                        InputProps={{
                          endAdornment: (
                            <InputAdornment>
                              <Typography
                                className={values.free ? classes.grey : ''}
                              >
                                {currencyFormatted}
                              </Typography>
                            </InputAdornment>
                          ),
                        }}
                        name="serviceFee"
                        value={values.serviceFee}
                        onChange={(e) => {
                          if (e.target.value >= 0)
                            setFieldValue('serviceFee', Number(e.target.value));
                        }}
                      />
                    </Grid>
                    <WrappedDivider />
                    <Grid xs={12}>
                      <Box
                        className={clsx(
                          classes.box,
                          values.free ? classes.grey : ''
                        )}
                      >
                        <Typography className={classes.boldGrey} gutterBottom>
                          Price difference
                        </Typography>
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          spacing={0}
                        >
                          <Typography gutterBottom>
                            {ticket?.ticket.productName}
                          </Typography>
                          <Typography gutterBottom>
                            {ticket?.ticket.priceFormatted} {currencyFormatted}
                          </Typography>
                        </Grid>
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          spacing={0}
                        >
                          <Typography gutterBottom>
                            {values.newTicket.name}
                          </Typography>
                          <Typography gutterBottom>
                            {values.priceSteps === 'current'
                              ? values.newTicket.price?.toFixed(2)
                              : getPriceStepPrice(values.newTicket).toFixed(
                                  2
                                )}{' '}
                            {currencyFormatted}
                          </Typography>
                        </Grid>
                        <WrappedDivider />
                        <Grid container justifyContent="flex-end" spacing={0}>
                          <Typography>
                            {getTicketPriceDifference(ticket, values)?.toFixed(
                              2
                            )}{' '}
                            {currencyFormatted}
                          </Typography>
                        </Grid>
                        <Typography className={classes.boldGrey} gutterBottom>
                          Fees
                        </Typography>
                        <Grid
                          container
                          justifyContent="space-between"
                          spacing={0}
                        >
                          <Typography gutterBottom>
                            World’s Marathons payment processing fee
                          </Typography>
                          <Typography gutterBottom>
                            {getTotalAmount(ticket, values) === 0
                              ? '0.00'
                              : processingFee.toFixed(2)}{' '}
                            {currencyFormatted}
                          </Typography>
                        </Grid>
                        <Grid
                          container
                          justifyContent="space-between"
                          spacing={0}
                        >
                          <Typography gutterBottom>Your service fee</Typography>
                          <Typography gutterBottom>
                            {values.serviceFee?.toFixed(2)} {currencyFormatted}
                          </Typography>
                        </Grid>
                        <WrappedDivider />
                        <Grid container justifyContent="flex-end" spacing={0}>
                          <Typography>
                            {getTotalAmount(ticket, values) === 0
                              ? '0.00'
                              : calcFees(values.serviceFee).toFixed(2)}{' '}
                            {currencyFormatted}
                          </Typography>
                        </Grid>
                      </Box>
                      <Box className={classes.refundBox}>
                        <Grid
                          container
                          justifyContent="space-between"
                          spacing={0}
                        >
                          <Typography className={classes.bold}>
                            {`Amount to be 
                                                      ${
                                                        Math.sign(
                                                          getTotalAmount(
                                                            ticket,
                                                            values
                                                          )
                                                        ) === -1
                                                          ? 'refunded to the buyer'
                                                          : 'paid by the ticket buyer'
                                                      }`}
                          </Typography>
                          <Typography
                            className={clsx(
                              classes.bold,
                              values.free ? classes.line : ''
                            )}
                          >
                            {getTotalAmount(ticket, values).toFixed(2)}{' '}
                            {currencyFormatted}
                          </Typography>
                        </Grid>
                      </Box>
                      <WrappedDivider paddingTop={4} paddingBottom={3} />
                      <Grid xs={12}>
                        <Typography
                          className={classes.paddingB}
                          variant="body1"
                        >
                          {values.free || getTotalAmount(ticket, values) === 0
                            ? 'The ticket change will be completed once the confirmation email is sent to the participant. The email will be sent to the following email address.'
                            : Math.sign(getTotalAmount(ticket, values)) === 1
                            ? 'The participant will receive an email with a link to complete the payment. The ticket change will be completed once the participant makes the payment. The email will be sent to the following email address.'
                            : 'The ticket change will be completed once the refund is completed and a confirmation email is sent to the participant. The email will be sent to the following email address. (The refund can take up to 10 business days)'}
                        </Typography>
                      </Grid>
                      <Grid xs={12}>
                        <FormikTextField
                          autoFocus
                          variant="outlined"
                          fullWidth
                          required
                          label="Email address"
                          name="eMail"
                        />
                      </Grid>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </DrawerBase>
        )}
      </Formik>
      {modalOpen && (
        <ConfirmationModal
          open={modalOpen}
          cancel={() => toggleModal(false)}
          confirm={() => {
            toggleModal(false);
            handleClose();
          }}
          title="Are you sure you want to cancel the ticket change?"
          cancelText="No, continue"
          confirmText=" Yes, cancel"
        />
      )}
    </>
  );
};

export default RefundOrderDrawer;
