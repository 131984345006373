import * as yup from 'yup';

export const getValidationSchema = (order) =>
  yup.object().shape({
    refundValue: yup
      .number()
      .moreThan(0, 'The amount to refund must be greater than zero')
      .max(
        order.totalValueForOrganizerWithoutRefundedValue,
        'You cannot refund more than the order total'
      )
      .required('Enter an amount to refund'),
    reasonType: yup.string().required('Please choose a reason for the refund'),
    reason: yup.string().when('reasonType', {
      is: (x) => x === 'other',
      then: yup.string().required('You must clarify the reason for the refund'),
    }),
    messageToParticipant: yup.string(),
    email: yup.string().email('Invalid email').required('An email is required'),
  });
