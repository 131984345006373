export const resendOrderConfirmation = 'resendOrderConfirmation';
export const resendOrderReceipt = 'resendOrderReceipt';

export const getResendDrawerFields = (
  type,
  orderId,
  eventId,
  editionId,
  participantId
) => {
  if (type === resendOrderConfirmation)
    return {
      title: 'Resend confirmation email to participant',
      main: `After every sign up, World's Marathons sends a confirmation email to all participants with basic information about the event and an order reference number. 
                Here you can resend the confirmation email to the participant`,
      inputTitle:
        'The confirmation email will be send to following email address:',
      cta: 'Resend confirmation email',
      successMessage: 'Confirmation email resent successfully',
      url: `/ro/events/${eventId}/races/${editionId}/orders/${orderId}/participants/${participantId}/resendconfirmation`,
    };
  else
    return {
      title: 'Resend receipt email to purchaser',
      main: `After every order, World's Marathons sends an email with the receipt to the email address of the participant who purchased the ticket(s). 
                    Here you can resend the receipt.`,
      inputTitle: 'The receipt will be send to following email address:',
      cta: 'Resend receipt email',
      successMessage: 'Receipt email resent successfully',
      url: `/orders/${orderId}/sendreceipt`,
    };
};
