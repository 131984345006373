import React from 'react';
import clsx from 'clsx';
import { Chip, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { wmPalette } from '../../../Theme/WorldSportsGroupTheme';

const useStyles = makeStyles((theme) => ({
  pill: {
    borderRadius: theme.spacing(1),
    maxWidth: theme.spacing(20),
  },
  orange: {
    backgroundColor: wmPalette.orange[100],
    color: wmPalette.white[100],
  },
}));

const StatusPill = ({ text, colour }) => {
  const classes = useStyles();
  return (
    <Chip
      className={clsx(classes[colour], classes.pill)}
      label={
        <Typography style={{ fontWeight: 'bold' }} variant="body2">
          {text}
        </Typography>
      }
    />
  );
};

export default StatusPill;
