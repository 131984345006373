import * as yup from 'yup';

yup.addMethod(yup.array, 'checkSameEmail', function (email) {
  return this;
});
const getValidationSchema = (email) => {
  return yup.object().shape({
    email: yup
      .string()
      .email('Invalid email address')
      .required('An email address is required')
      .test('checkSameEmail', 'Email address already added', function (values) {
        if (email) {
          const emails = email.split(',');
          return !emails.includes(values);
        } else {
          return true;
        }
      }),
  });
};

export { getValidationSchema };
