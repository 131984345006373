import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { makeStyles } from '@mui/styles';
import webhook from '../../images/integrations/webhook.svg';
import active from '../../images/integrations/active.svg';
import comingsoon from '../../images/integrations/comingsoon.svg';
import StepTitle from '../Common/PageLayout/StepTitle';
import IntegrationCard from './IntegrationCard';
import { useParams } from 'react-router-dom';
import { useWebhooks } from '../../Hooks/useWebhooks';
import { useAuth } from '../../Contexts/AuthContext';
import { useEdition } from '../../Hooks/useEdition';
const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
  },
}));

const Integration = () => {
  const { eventId, editionId } = useParams();
  const { webhooks } = useWebhooks(eventId);
  const { isAdmin } = useAuth();
  const { edition } = useEdition(eventId, editionId);
  const { race } = edition;

  const classes = useStyles();
  return (
    <>
      <StepTitle
        title="Integration"
        subtitle="We offer multiple integration options for your event. Which integration would you like to use?"
      />
      <Grid container spacing={0} className={classes.root}>
        <Grid container spacing={2} className={classes.root}>
          <Grid xs={12} sm={6} lg={4} xl={2}>
            <IntegrationCard
              media={webhook}
              title="Webhook API"
              content="The World's Marathons Webhook API will fully automate the data transfer from World's Marathons to your own registration system."
              url="webhook"
              btnText={webhooks?.length === 0 ? 'Learn more' : 'Settings'}
              isIntegrated={webhooks?.length === 0 ? false : true}
              active={true}
            />
          </Grid>
          {isAdmin && (
            <Grid xs={12} sm={6} lg={4} xl={2}>
              <IntegrationCard
                media={active}
                title="ACTIVE"
                content="Activate the affiliation with ACTIVE by adding the ACTIVE checkout URL in the next step. When active the event page will redirect to the ACTIVE checkout."
                url="affiliate"
                btnText={
                  race === null || race.affiliateType === 0
                    ? 'Learn more'
                    : 'Settings'
                }
                isIntegrated={
                  race === null || race.affiliateType === 0 ? false : true
                }
                active={true}
              />
            </Grid>
          )}
          <Grid xs={12} sm={6} lg={4} xl={2}>
            <IntegrationCard
              media={comingsoon}
              title="Coming soon ..."
              content="More integration options are coming soon!"
              url=""
              btnText="LEARN MORE"
              active={false}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Integration;
