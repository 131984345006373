import React from 'react';
import { Typography, Link } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  paddingBottom: {
    paddingBottom: `${theme.spacing(5)} !important`,
  },
  link: {
    cursor: 'pointer',
  },
}));

const AddOrderManuallyInfo = ({ goToInvitationDrawer }) => {
  const classes = useStyles();
  return (
    <Grid xs={12} className={classes.paddingBottom}>
      <Typography variant="h3" gutterBottom>
        Add free order manually
      </Typography>
      <Typography variant="body1" gutterBottom>
        Here you can add a participant manually. Participants added in this way
        are not required to complete the registration process themselves, as it
        has been done on their behalf.
      </Typography>
      <Typography variant="body1">
        If you do not have their information or would prefer for them to
        complete the registration, you can use the send{' '}
        <Link className={classes.link} onClick={() => goToInvitationDrawer()}>
          invitation feature
        </Link>
        .
      </Typography>
    </Grid>
  );
};

export default AddOrderManuallyInfo;
