import { convertFromHTML, ContentState, convertFromRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';

export const getContentState = (html) => {
  const contentHTML = convertFromHTML(html);
  return ContentState.createFromBlockArray(
    contentHTML.contentBlocks,
    contentHTML.entityMap
  );
};

export const convertRawToHtml = (raw) => {
  const state = convertFromRaw(JSON.parse(raw));
  return stateToHTML(state, {
    defaultBlockTag: 'p',
  });
};
