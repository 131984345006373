import { useEffect } from 'react';
import { useState } from 'react';
import { methods, useApi } from '../../Hooks/useApi';
import { OnboardingModal } from './OnboardingModal';

const onboardingUrl = '/useraccount/new-infos';

export const Onboarding = ({
  organiserNewInfoShown,
  canOnboard,
  onOnboard,
}) => {
  const [isModalVisible, toggleModal] = useState(true);
  const { executeApiCall } = useApi();

  useEffect(() => {
    toggleModal(organiserNewInfoShown === null);
  }, [organiserNewInfoShown]);

  const handleOnEnd = async () => {
    try {
      await executeApiCall(onboardingUrl, methods.post);
    } catch (err) {
      // no need to show anything, if error occured, just close modal
    } finally {
      toggleModal(false);
      onOnboard();
    }
  };

  return (
    <>
      {canOnboard && isModalVisible && (
        <OnboardingModal
          onEnd={handleOnEnd}
          stepsToShow={[0]}
          open={isModalVisible}
        ></OnboardingModal>
      )}
    </>
  );
};
