import React, { useState } from 'react';
import { Button, Drawer, Typography, Link } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import * as yup from 'yup';
import { wmPalette } from '../../../Theme/WorldSportsGroupTheme';
import FormikTextField from '../../Common/Formik/FormikTextField';
import ConfirmationModal from '../../Common/ConfirmationModal/ConfirmationModal';
import { useApi, methods } from '../../../Hooks/useApi';
import Grid from '@mui/material/Unstable_Grid2';
import { ExternalLinks } from '../../../Utils/urls';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.up('sm')]: {
      width: 567,
    },
  },
  link: {
    color: wmPalette.blue[100],
    textDecoration: 'none',
  },
  drawerActionsContainer: {
    display: 'flex',
    position: 'fixed',
    marginLeft: `-${theme.spacing(2)}`,
    bottom: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    width: '100%',
    padding: theme.spacing(2),
    backgroundColor: '#fff',
    zIndex: 1000,
    [theme.breakpoints.up('sm')]: {
      width: 567,
      bottom: 0,
      padding: theme.spacing(1),
    },
  },
  sectionText: {
    paddingBottom: theme.spacing(4),
  },
}));

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email('Invalid email')
    .required('A support email is required'),
});

const MailDrawer = ({ open, handleCloseDrawer }) => {
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { executeApiCall } = useApi();

  const sendSupportEmail = async (values) => {
    try {
      await executeApiCall('/webhook/email-docs', methods.post, {
        email: values.email,
      });
      enqueueSnackbar(`The documentation has been send to ${values.email}`, {
        variant: 'success',
      });
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' });
    }

    closeDrawer();
  };

  const closeDrawer = () => {
    setOpenModal(false);
    handleCloseDrawer();
  };

  return (
    <Drawer anchor="right" open={open} onClose={() => setOpenModal(true)}>
      <Grid
        container
        justifyContent="center"
        className={classes.root}
        spacing={0}
      >
        <Grid xs={12} sm={11} container spacing={2}>
          <Grid xs={12}>
            <Typography variant="h3" gutterBottom>
              Send documentation
            </Typography>
            <Typography variant="body1">
              Connecting with the Webhook API does require technical
              implementation on your registration system. Send the&nbsp;
              <Link
                class={classes.link}
                target="_blank"
                rel="noopener"
                href={ExternalLinks.WebHookDocumentation}
              >
                Documentation
              </Link>
              &nbsp;to your tech team so that they can start preparing the
              integration.
            </Typography>
          </Grid>
          <Grid xs={12}>
            <Typography variant="body1" className={classes.sectionText}>
              The email also contains support contact information.
            </Typography>
          </Grid>
          <Grid xs={12}>
            <Typography variant="body1">
              The documentation will be sent to the following email address:
            </Typography>
          </Grid>
          <Formik
            validationSchema={validationSchema}
            validateOnChange={false}
            validateOnBlur={true}
            initialValues={{
              email: '',
            }}
            onSubmit={sendSupportEmail}
          >
            {({ handleSubmit, isSubmitting }) => (
              <>
                <Grid xs={12}>
                  <FormikTextField
                    variant="outlined"
                    fullWidth
                    label="Email address*"
                    name="email"
                  />
                </Grid>
                <Grid
                  container
                  className={classes.drawerActionsContainer}
                  spacing={2}
                >
                  <Grid xs={4}>
                    <Button
                      variant="outlined"
                      onClick={() => setOpenModal(true)}
                      disabled={isSubmitting}
                      fullWidth
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid xs={8}>
                    <Button
                      variant="contained"
                      fullWidth
                      color="primary"
                      onClick={handleSubmit}
                      disabled={isSubmitting}
                    >
                      {isSubmitting
                        ? 'Sending documentation... '
                        : 'Send documentation'}
                    </Button>
                  </Grid>
                </Grid>
              </>
            )}
          </Formik>
        </Grid>
      </Grid>
      {openModal && (
        <ConfirmationModal
          cancel={() => setOpenModal(false)}
          confirm={closeDrawer}
          open={openModal}
          title="Are you sure you want to cancel the action?"
          cancelText="No, continue"
          confirmText="Yes, cancel"
        />
      )}
    </Drawer>
  );
};

export default MailDrawer;
