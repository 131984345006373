import React from 'react';
import {
  FormControl,
  Radio,
  FormControlLabel,
  FormLabel,
  RadioGroup,
} from '@mui/material';

const FormikRadioSelect = ({ setFieldValue, label, name, values, value }) => (
  <FormControl>
    <FormLabel component="legend">{label}</FormLabel>
    <RadioGroup
      name={name}
      value={value}
      onChange={(e) => setFieldValue(name, e.target.value)}
    >
      {values.map((option) => (
        <FormControlLabel
          color="default"
          key={option}
          value={option}
          label={option}
          control={<Radio />}
        />
      ))}
    </RadioGroup>
  </FormControl>
);

export default FormikRadioSelect;
