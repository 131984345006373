import React from 'react';
import { useParams } from 'react-router';
import { mutate } from 'swr';
import { Typography, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import DrawerBase from '../../../Common/Drawer/DrawerBase';
import FormikTextField from '../../../Common/Formik/FormikTextField';
import FormikTimeTextfield from '../../../Common/Formik/FormikTimeTextfield';
import { useApi, methods } from '../../../../Hooks/useApi';
import { orderUrl } from '../../../../Hooks/useOrders';
import { onKeyDown } from '../../../../Utils/dateUtils';
import Grid from '@mui/material/Unstable_Grid2';
const useStyles = makeStyles((theme) => ({
  paddingBottom: {
    paddingBottom: `${theme.spacing(5)} !important`,
  },
}));

const validationSchema = yup.object().shape({
  note: yup.string().required('Please add a note to add to the order'),
  date: yup.date().required('Please add a valid date to the order note'),
  time: yup
    .string()
    .matches(/^([0-2][0-9]):[0-5][0-9]$/, 'Time format must be HH:mm')
    .required('Please add a valid time to the order note'),
});

const getUrl = (eventId, editionId, orderId) =>
  `/order/${eventId}/${editionId}/${orderId}/note`;

const AddOrderNoteDrawer = ({ open, handleClose }) => {
  const { executeApiCall } = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { orderId, eventId, editionId } = useParams();
  const currentDate = new Date();
  const addNote = async (values) => {
    let { date, time, note } = values;
    const timeArray = time.split(':');
    date.setHours(timeArray[0]);
    date.setMinutes(timeArray[1]);
    const data = { note, date };
    await mutate([orderUrl(eventId, editionId), true], async ({ orders }) => {
      try {
        const updatedTimeline = await executeApiCall(
          getUrl(eventId, editionId, orderId),
          methods.post,
          data
        );
        enqueueSnackbar('Order note added successfully', {
          variant: 'success',
        });
        const newArray = [...orders];
        const idx = newArray.findIndex((o) => o.orderId === orderId);
        const order = newArray[idx];
        order.timeline = updatedTimeline;
        newArray.splice(idx, 1, order);
        handleClose();
        return { orders: newArray };
      } catch (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    });
  };

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        note: '',
        date: currentDate,
        time: `${currentDate.getHours()}:${currentDate.getMinutes()}`,
      }}
      onSubmit={addNote}
    >
      {({
        handleSubmit,
        isSubmitting,
        values,
        touched,
        errors,
        handleBlur,
        setFieldValue,
      }) => (
        <DrawerBase
          open={open}
          onClose={handleClose}
          handleSubmit={handleSubmit}
          isSubmitting={isSubmitting}
          submittingMessage="Adding note..."
          submitText="Add note"
          cancelText="Cancel"
        >
          <Grid xs={12} className={classes.paddingBottom}>
            <Typography variant="h3" gutterBottom>
              Add note in timeline
            </Typography>
            <Typography variant="body1" gutterBottom>
              Here you can add a new note to the timeline of this order. Notes
              are not visible to participants.
            </Typography>
            <Typography variant="body1">
              Please note: you cannot edit or delete notes once they are added.
            </Typography>
          </Grid>
          <Grid xs={6}>
            <DatePicker
              fullWidth
              variant="inline"
              label="Date"
              name="date"
              format="PPP"
              inputFormat="dd MMM yyyy"
              value={values.date}
              error={touched.date && Boolean(errors.date)}
              onChange={(date) => setFieldValue('date', date)}
              onBlur={handleBlur}
              renderInput={(props) => (
                <TextField
                  fullWidth
                  onKeyDown={onKeyDown}
                  required
                  {...props}
                />
              )}
            />
          </Grid>
          <Grid xs={6}>
            <FormikTimeTextfield
              label="Time"
              name="time"
              value={values.time}
              handleBlur={handleBlur}
            />
          </Grid>
          <Grid xs={12}>
            <FormikTextField
              variant="outlined"
              fullWidth
              placeholder="Type your note here"
              label="Order note"
              name="note"
              multiline
              rows={3}
              required
            />
          </Grid>
        </DrawerBase>
      )}
    </Formik>
  );
};

export default AddOrderNoteDrawer;
