import React from 'react';
import BaseFormikSelect from '../Common/Formik/BaseFormikSelect';
import { NewPayoutCurrencies, PayoutCurrencies } from '../../Consts/currencies';

const PayoutCurrencySelect = ({ setCurrency, currency }) => (
  <BaseFormikSelect
    name="currency"
    label="Currency"
    placeholder="Please select a currency for payout *"
    value={PayoutCurrencies.find((x) => x.code === currency) ?? ''}
    options={NewPayoutCurrencies}
    set={(c) => setCurrency(c?.code)}
    getOptionLabel={(option) =>
      option ? `${option.label} (${option.code?.toUpperCase()})` : ''
    }
    required
  />
);

export default PayoutCurrencySelect;
