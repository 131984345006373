import React from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { makeStyles } from '@mui/styles';
import { Divider } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  linkWrapper: {
    padding: theme.spacing(3, 4, 2, 4),
  },
  link: {
    fontSize: 17,

    '&:hover, &:visited, &:link, &:active': {
      textDecoration: 'none',
      color: 'black',
    },
  },
  arrow: {
    fontSize: 13,
    marginRight: 5,
    verticalAlign: 'middle',
  },
}));

const LinkLevelUp = ({ label, query }) => {
  const classes = useStyles();
  const location = useLocation();
  let urlToLevelUp = location.pathname.substring(
    0,
    location.pathname.lastIndexOf('/')
  );
  urlToLevelUp += query ? `?${query}` : '';
  return (
    <div>
      <div className={classes.linkWrapper}>
        <Link to={urlToLevelUp} className={classes.link}>
          <ArrowBackIosIcon className={classes.arrow} />
          {label}
        </Link>
      </div>
      <Divider />
    </div>
  );
};

export default LinkLevelUp;
