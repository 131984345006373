import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';

const DeleteTicketModal = ({
  open,
  cancel,
  confirm,
  ticket,
  customQuestions,
  deleteText,
}) => (
  <Dialog open={open} onClose={cancel}>
    <DialogTitle>Delete {deleteText}?</DialogTitle>
    <DialogContent>
      <DialogContentText>
        <Typography>
          Please confirm you want to delete {deleteText}
          <strong> {ticket}</strong>. This action cannot be undone.
        </Typography>
        {customQuestions?.length ? (
          <Typography>
            <br />
            Note: By deleting this ticket, your custom question
            <ul>
              {customQuestions.map((q) => (
                <li key={q.id}>{q.label}</li>
              ))}
            </ul>
            will be deleted as this custom question is only asked on this ticket{' '}
            <strong>{ticket}</strong>
          </Typography>
        ) : (
          ''
        )}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={cancel} color="primary">
        Cancel
      </Button>
      <Button onClick={confirm} color="primary">
        Ok
      </Button>
    </DialogActions>
  </Dialog>
);

export default DeleteTicketModal;
