const handleApiError = (res) => {
  //To deal with error messages coming from the proxy
  let error = '';
  const modelState = Boolean(res.modelState) && Object.values(res.modelState);
  if (modelState.length) {
    error = modelState[0];
  } else if (res.message) {
    error = res.message;
  } else {
    error = res;
  }
  return error;
};

export default handleApiError;
