import React from 'react';
import { SnackbarProvider } from 'notistack';
import { makeStyles } from '@mui/styles';
import { wmPalette } from '../../../Theme/WorldSportsGroupTheme';

const useStyles = makeStyles((theme) => ({
  //sounds like in a future task, the theme colours will be aligned with WM so
  //the shade of blue can be taken from the theme when changed
  variantSuccess: { backgroundColor: wmPalette.blue[70] },
  variantError: { backgroundColor: theme.palette.error.main },
  variantWarning: { backgroundColor: theme.palette.warning.main },
  variantInfo: { backgroundColor: theme.palette.info.main },
}));

const Snackbar = (props) => {
  const classes = useStyles();

  return (
    <SnackbarProvider
      maxSnack={3}
      classes={classes}
      autoHideDuration={3000}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      {props.children}
    </SnackbarProvider>
  );
};

export default Snackbar;
