import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { formatDateTime } from '../../../Format';

const useStyles = makeStyles(() => ({
  headCell: {
    fontWeight: 'normal',
    backgroundColor: 'inherit',
    color: 'black',
  },
}));
const Timeline = ({ data }) => {
  const classes = useStyles();
  return (
    <TableContainer>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.headCell}>Date</TableCell>
            <TableCell className={classes.headCell}>Description</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow key={row.date}>
              <TableCell width="25%" component="th" scope="row">
                {formatDateTime(row.date)}
              </TableCell>
              <TableCell>
                <span
                  dangerouslySetInnerHTML={{
                    __html: row.description,
                  }}
                ></span>
                {}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Timeline;
