import React, { useState } from 'react';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Link, useLocation } from 'react-router-dom';
import {
  ShowChart as ChartIcon,
  FormatListBulleted as FormatListBulletedIcon,
  Edit as EditIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  Link as LinkIcon,
  CheckCircle as CheckCircleIcon,
  RadioButtonUnchecked as RadioButtonUncheckedIcon,
  LocalAtm,
  MailOutline,
} from '@mui/icons-material';
import { wmPalette } from '../../../Theme/WorldSportsGroupTheme';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.text.primary,
  },
  selectedLink: {
    color: theme.palette.text.primary,
    fontWeight: 'bold',
  },
  icon: {
    color: theme.palette.text.primary,
  },
  button: {
    '&:hover': {
      backgroundColor: wmPalette.grey[20],
    },
  },
  green: {
    color: wmPalette.green[70],
  },
  grey: {
    color: wmPalette.black[50],
  },
}));

const urlPrefix = (eventId, editionId) =>
  `/app/manage-event/${eventId}/${editionId}/`;
const menuItems = (eventId, editionId) => [
  {
    icon: <ChartIcon />,
    label: 'Dashboard',
    url: `${urlPrefix(eventId, editionId)}dashboard`,
    extension: 'dashboard',
  },
  {
    icon: <FormatListBulletedIcon />,
    label: 'Order management',
    url: `${urlPrefix(eventId, editionId)}orders`,
    extension: 'orders',
  },
  {
    icon: <MailOutline />,
    label: 'Email preferences',
    url: `${urlPrefix(eventId, editionId)}email-preferences`,
    extension: 'email-preferences',
  },
  {
    icon: <LinkIcon />,
    label: 'Integration',
    url: `${urlPrefix(eventId, editionId)}integration`,
    extension: 'integration',
  },
  {
    icon: <LocalAtm />,
    label: 'Coupons',
    url: `${urlPrefix(eventId, editionId)}coupons`,
    extension: 'coupons',
  },
  {
    icon: <EditIcon />,
    label: 'Event information',
    url: undefined,
    subMenu: [
      {
        label: 'Basic Info',
        url: `${urlPrefix(eventId, editionId)}basicinfo`,
        extension: 'basicinfo',
      },
      {
        label: 'Course Details',
        url: `${urlPrefix(eventId, editionId)}coursedetails`,
        extension: 'coursedetails',
      },
      {
        label: "What's included",
        url: `${urlPrefix(eventId, editionId)}whats-included`,
        extension: 'whats-included',
      },
      {
        label: 'Tickets',
        url: `${urlPrefix(eventId, editionId)}tickets`,
        extension: 'tickets',
      },
      {
        label: 'Add-ons',
        url: `${urlPrefix(eventId, editionId)}add-ons`,
        extension: 'add-ons',
      },
      {
        label: 'Registration Form',
        url: `${urlPrefix(eventId, editionId)}regform`,
        extension: 'regform',
      },
      {
        label: 'Confirmation email',
        url: `${urlPrefix(eventId, editionId)}confirmationemail`,
        extension: 'confirmationemail',
      },
      {
        label: 'FAQs',
        url: `${urlPrefix(eventId, editionId)}faqs`,
        extension: 'faqs',
      },
    ],
  },
];

const requiredFields = {
  basicinfo: [
    'eventTitle',
    'eventType',
    'eventLocation',
    'eventStartDate',
    'eventEndDate',
    'eventSaleStartDate',
    'eventSaleEndDate',
    'eventImages',
    'eventDescription',
    'eventCurrency',
  ],
  courseDetails: [
    'courseHighlights',
    'courseMap',
    'courseProfile',
    'courseSurface',
  ],
  confirmationemail: ['supportEmail'],
};

const EventNavItems = ({ eventInfo = {} }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const classes = useStyles();
  const { pathname } = useLocation();
  const splitPath = pathname.split('/');
  const slug = splitPath[5];
  const editionId = splitPath[4];
  const eventId = splitPath[3];
  const items = menuItems(eventId, editionId);
  const eventInfoTabs = items
    .find((x) => x.label === 'Event information')
    .subMenu.map((y) => y.extension);
  const [activeSubMenu, toggleSubMenu] = useState(
    eventInfoTabs.some((x) => x === slug) ? undefined : 'Event information'
  );

  const toggle = (label) => () => {
    toggleSubMenu(activeSubMenu === label ? undefined : label);
  };

  const iconProps = (active) => ({
    color: 'disabled',
    className: active ? classes.icon : '',
  });

  const isComplete = (extension) => {
    let isComplete = true;
    switch (extension) {
      case 'basicinfo':
        requiredFields.basicinfo.forEach((value) => {
          if (!eventInfo.basicInfo?.hasOwnProperty(value)) {
            isComplete = false;
          }
        });
        break;
      case 'coursedetails':
        isComplete = false;
        if (eventInfo.courseDetails !== undefined) {
          Object.values(eventInfo.courseDetails)?.forEach((value) => {
            if (value?.length > 0) isComplete = true;
          });
        }
        break;
      case 'tickets':
        if (eventInfo.tickets?.length === 0) isComplete = false;
        break;
      case 'add-ons':
        if (eventInfo.addOns?.length === 0) isComplete = false;
        break;
      case 'whats-offered':
        isComplete = eventInfo.offeredServices.length > 0;
        break;
      case 'regform':
        break;
      case 'confirmationemail':
        requiredFields.confirmationemail.forEach((value) => {
          if (!eventInfo.additionalInfo?.hasOwnProperty(value)) {
            isComplete = false;
          }
        });
        break;
      case 'faqs':
        if (eventInfo.faqs?.length === 0) isComplete = false;
        break;
      default:
        break;
    }
    return isComplete;
  };

  const mapSubMenuItem = ({ label, icon, subMenu }) => {
    const active = !!subMenu.find((x) => x.extension === slug);

    return (
      <React.Fragment key={label}>
        <ListItem
          button
          key={label}
          selected={active}
          className={(active ? classes.selectedLink : '', classes.button)}
          onClick={toggle(label)}
        >
          {icon && <ListItemIcon {...iconProps(active)}>{icon}</ListItemIcon>}
          <ListItemText
            primary={label}
            primaryTypographyProps={{
              className: active ? classes.selectedLink : classes.link,
            }}
          />
          {activeSubMenu === label ? (
            <ExpandLessIcon {...iconProps()} />
          ) : (
            <ExpandMoreIcon {...iconProps()} />
          )}
        </ListItem>
        <Collapse in={activeSubMenu === label} timeout="auto" unmountOnExit>
          <List disablePadding>
            {subMenu &&
              subMenu.map((x) => mapMenuItem(x, true, isComplete, isMobile))}
          </List>
        </Collapse>
      </React.Fragment>
    );
  };

  const mapMenuItem = (
    { label, url, extension, icon },
    isWithinSubMenu = false,
    isComplete,
    isMobile
  ) => {
    const active = extension === slug;

    return (
      <ListItem
        button
        key={label}
        selected={!isWithinSubMenu && active}
        component={Link}
        to={url}
        color="primary"
        className={classes.button}
      >
        {(icon || isWithinSubMenu) && (
          <ListItemIcon {...iconProps(active)}>{icon}</ListItemIcon>
        )}
        <ListItemText
          primary={label}
          primaryTypographyProps={{
            className: active ? classes.selectedLink : classes.link,
          }}
        />
        {isWithinSubMenu &&
          !isMobile &&
          (isComplete(extension) ? (
            <CheckCircleIcon className={classes.green} />
          ) : (
            <RadioButtonUncheckedIcon className={classes.grey} />
          ))}
      </ListItem>
    );
  };

  return (
    <>
      {items.map((item) =>
        !!item.subMenu ? mapSubMenuItem(item) : mapMenuItem(item)
      )}
    </>
  );
};

export default EventNavItems;
