import React, { useState } from 'react';
import {
  Button,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import makeStyles from '@mui/styles/makeStyles';
import { Add } from '@mui/icons-material';
import ConfirmationModal from '../Common/ConfirmationModal/ConfirmationModal';
import ImagePreviewModal from '../Common/ImageUpload/ImagePreviewModal';
import gdprCompliant from '../../images/email/GRPRok@2x.png';
import gdprNonCompliant from '../../images/email/GDPRng@2x.png';
import BlueLink from '../Common/BlueLink/BlueLink';

const useStyles = makeStyles((theme) => ({
  emailTable: {
    marginBottom: theme.spacing(16),
  },
  emailHead: {
    backgroundColor: '#e6e6e6',
  },
  gdprControl: {
    marginBottom: theme.spacing(8),
    paddingLeft: theme.spacing(5),
  },
  btnMargin: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  removeCell: {
    width: theme.spacing(20),
  },
  paddingZero: {
    padding: 0,
  },
}));

const EmailPreferencesTable = ({
  handleAddEmail,
  signalEmail,
  gdprStatus,
  updateEmailSignal,
  updateGdpr,
}) => {
  const classes = useStyles();
  const [openRemoveModal, setOpenRemoveModal] = useState(false);
  const [openGdprModal, setOpenGdprModal] = useState(false);
  const [emailToRemove, setEmailToRemove] = useState();
  const [previewModal, toggleModal] = useState({
    open: false,
    image: {},
  });

  const showImagePreview = (emailTemplate) => {
    var image = {
      preview: emailTemplate,
    };
    toggleModal({ open: true, image: image });
  };

  const removeEmail = async (emailIndex) => {
    setOpenRemoveModal(false);
    var arr = signalEmail.split(',');
    arr.splice(emailIndex, 1);
    setEmailToRemove();
    await updateEmailSignal(arr.join(', '));
  };

  const changeGdpr = async () => {
    setOpenGdprModal(false);
    await updateGdpr();
  };

  const handleRemoveEmail = async (emailIndex) => {
    setOpenRemoveModal(true);
    setEmailToRemove(emailIndex);
  };

  return (
    <>
      <Grid
        container
        justifyContent="flex-end"
        spacing={0}
        className={classes.paddingZero}
      >
        <Grid container xs={3} md={3} justifyContent="flex-end" spacing={0}>
          <Button
            className={classes.btnMargin}
            variant="contained"
            color="primary"
            onClick={handleAddEmail}
            startIcon={<Add />}
          >
            Add email address
          </Button>
        </Grid>
      </Grid>
      <Grid className={classes.paddingZero}>
        <TableContainer>
          <Table className={classes.emailTable}>
            <TableHead>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {signalEmail.split(',').map((email, index) => (
                <TableRow key={index}>
                  <TableCell>{email}</TableCell>
                  <TableCell align="center" className={classes.removeCell}>
                    <Button
                      onClick={() => {
                        handleRemoveEmail(index);
                      }}
                      color="secondary"
                    >
                      Remove
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid className={classes.gdprControl}>
        <Typography variant="h6">
          What information would you like to have in the notification emails?
        </Typography>
        <RadioGroup aria-label="event-type" name="eventType" value={gdprStatus}>
          <FormControlLabel
            color="default"
            value={true}
            control={<Radio />}
            label={
              <>
                <span>Only the order details (GDPR compliant) </span>
                <BlueLink
                  component="button"
                  onClick={() => {
                    showImagePreview(gdprCompliant);
                  }}
                  text="Example"
                />
              </>
            }
            onChange={() => {
              changeGdpr();
            }}
          />
          <FormControlLabel
            color="default"
            value={false}
            control={<Radio />}
            label={
              <>
                <span>
                  The order details and the participants' personal information
                  (GDPR not compliant){' '}
                </span>
                <BlueLink
                  component="button"
                  onClick={() => {
                    showImagePreview(gdprNonCompliant);
                  }}
                  text="Example"
                />
              </>
            }
            onChange={() => {
              setOpenGdprModal(true);
            }}
          />
        </RadioGroup>
        {openRemoveModal && (
          <ConfirmationModal
            cancel={() => setOpenRemoveModal(false)}
            confirm={() => {
              removeEmail(emailToRemove);
            }}
            open={openRemoveModal}
            title="Are you sure you want to remove this email address?"
            cancelText="No, keep email"
            confirmText="Yes, remove email"
          />
        )}
        {openGdprModal && (
          <ConfirmationModal
            cancel={() => setOpenGdprModal(false)}
            confirm={() => {
              changeGdpr();
            }}
            open={openGdprModal}
            title="Are you sure you want to receive emails that are not GDPR compliant?"
            cancelText="Cancel"
            confirmText="Yes"
          />
        )}
        {previewModal.open && (
          <ImagePreviewModal
            handleClose={() => toggleModal({ open: false })}
            image={previewModal.image}
            open={previewModal.open}
            isExampleImage={true}
          />
        )}
      </Grid>
    </>
  );
};

export default EmailPreferencesTable;
