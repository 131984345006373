import React from 'react';
import { Outlet } from 'react-router-dom';
import AppNav from '../Components/AppNav/AppNav';

const MainLayout = () => (
  <main>
    <AppNav />
    <Outlet />
  </main>
);

export default MainLayout;
