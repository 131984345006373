import React from 'react';
import { Link, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import TrackingButton from '../Common/TrackingButton/TrackingButton';
import { wmPalette } from '../../Theme/WorldSportsGroupTheme';
import Grid from '@mui/material/Unstable_Grid2';
const useStyles = makeStyles((theme) => ({
  emptyContainer: {
    height: '90vh',
  },
  emptyText: {
    marginBottom: theme.spacing(2),
  },
  linkColor: {
    color: wmPalette.blue[70],
    '&:hover': {
      color: wmPalette.blue[100],
    },
  },
}));

const EmptyEventView = (props) => {
  const classes = useStyles();
  return (
    <Grid
      container
      className={classes.emptyContainer}
      justifyContent="center"
      alignItems="center"
      direction="column"
      spacing={0}
    >
      <Grid>
        <Typography className={classes.emptyText}>
          You don’t have any event yet!
        </Typography>
      </Grid>
      <Grid>
        <Typography className={classes.emptyText}>
          Let’s create your event page on&nbsp;
          <Link
            className={classes.linkColor}
            href="https://worldsmarathons.com/"
            target="_blank"
            rel="noopener"
          >
            World’s Marathons!
          </Link>
        </Typography>
      </Grid>
      <TrackingButton
        href="/app/create-event"
        label={'Start creating your event'}
        eventCategory="Events"
        eventAction="Create event click"
        eventLabel="Click button"
        onClick={props.handleCreateClick}
        variant="contained"
      />
    </Grid>
  );
};

export default EmptyEventView;
