import React, { useContext, useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { Card, CardContent } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import StepTitle from '../../Common/PageLayout/StepTitle';
import CouponsEmptyState from './CouponsEmptyState';
import CouponDrawer from './CouponDrawer';
import CouponTable from './CouponTable';
import Loading from '../../Loading/Loading';
import { Context } from '../../../Stores/EventInfoStore';
import useCoupons from '../../../Hooks/useCoupons';

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(1),
  },
  cardListEmpty: {
    minHeight: '400px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  topToolbar: {
    marginBottom: theme.spacing(2),
  },
}));

const Coupons = () => {
  const classes = useStyles();
  const [eventInfo] = useContext(Context);
  const { eventId, editionId } = eventInfo.event;
  const { eventCurrency } = eventInfo.basicInfo;
  const [couponDrawer, setCouponDrawer] = useState({
    open: false,
    coupon: {},
  });
  const addNewCoupon = () => setCouponDrawer({ open: true, coupon: {} });
  const updateCoupon = (coupon) =>
    setCouponDrawer({ open: true, coupon: coupon });
  const { coupons, isLoading, couponUrl } = useCoupons(eventId, editionId);

  return (
    <>
      <StepTitle
        title="Coupons"
        subtitle={`Create coupons with unique codes to offer discounts to athletes and try it for yourself to experience the entire checkout without making a payment. 
                Coupons can be redeemed during the checkout process.`}
      />
      <Card>
        <CardContent className={isEmpty(coupons) ? classes.cardListEmpty : ''}>
          {isLoading && isEmpty(coupons) ? (
            <Loading disableElevation={true} variant="spinner" />
          ) : (
            isEmpty(coupons) && (
              <CouponsEmptyState handleAddCoupon={addNewCoupon} />
            )
          )}
          {!isEmpty(coupons) && (
            <CouponTable
              handleAddCoupon={addNewCoupon}
              handleUpdateCoupon={updateCoupon}
              currency={eventCurrency}
              coupons={coupons}
            />
          )}
        </CardContent>
      </Card>
      <CouponDrawer
        open={couponDrawer.open}
        handleClose={() => setCouponDrawer({ open: false, coupon: {} })}
        couponUrl={couponUrl}
        currency={eventCurrency}
        coupon={couponDrawer.coupon}
      />
    </>
  );
};

export default Coupons;
