import React from 'react';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';
import config from '../config';

const TrackingContext = React.createContext();

const TrackingProvider = (props) => {
  const location = useLocation();
  const [analytics, setAnalytics] = React.useState({
    isInitialized: false,
    hasUser: false,
  });
  const handleRouteChange = (url) => {
    console.log('handleRouteChange', url);
    ReactGA.set({ page: url });
    ReactGA.pageview(url);
  };

  const trackEvent = ({ category = '', action = '', label = '' }) => {
    if (analytics.isInitialized) {
      ReactGA.event({ category, action, label });
    }
  };

  React.useEffect(() => {
    console.log('location has changed');
    const { isInitialized, hasUser } = analytics;

    if (!isInitialized) {
      console.log('initializing GA');
      ReactGA.initialize(config.TRACKING_CODE, {
        debug: process.env.NODE_ENV !== 'production',
      });

      setAnalytics((prev) => ({
        ...prev,
        isInitialized: true,
        hasUser: false,
      }));
    } else if (isInitialized && !hasUser) {
      console.log('initializing GA without user');

      setAnalytics((prev) => ({ ...prev, hasUser: false }));
    }
    handleRouteChange(location.pathname);
  }, [location]);
  return <TrackingContext.Provider value={{ trackEvent }} {...props} />;
};

const useTracking = () => React.useContext(TrackingContext);
export { TrackingProvider, useTracking };
