import { Link } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { wmPalette } from '../../../Theme/WorldSportsGroupTheme';

const useStyles = makeStyles(() => ({
  link: {
    color: wmPalette.blue[70],
    fontSize: 'inherit',
  },
}));

const BlueLink = ({ text, ...props }) => {
  const classes = useStyles();
  return (
    <Link className={classes.link} {...props}>
      {text}
    </Link>
  );
};

export default BlueLink;
