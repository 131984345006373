import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSWRConfig } from 'swr';
import {
  Typography,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useSnackbar } from 'notistack';
import GridWrapper from '../Common/GridWrapper/GridWrapper';
import { useApi, methods } from '../../Hooks/useApi';
import { AntTabs, AntTab } from '../Common/AntTabs/AntTabs';

const searchEventTitlesEndpoint = '/search/events-slim?title=';
const getSearchByEmailUrl = (email) => `/admin/search?email=${email}`;
const getImpersonateUrl = (eventId) => `/admin/impersonate?eventId=${eventId}`;
const getImpersonateByEmailUrl = (email) =>
  `/admin/impersonate-user?email=${email}`;

const AdminArea = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [input, setInput] = useState('');
  const [options, setOptions] = useState([]);
  const { executeApiCall, apiError } = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { cache } = useSWRConfig();

  const handleTabChange = (_, newValue) => {
    setInput('');
    setOptions([]);
    setTabIndex(newValue);
  };

  useEffect(() => {
    if (apiError) enqueueSnackbar(apiError, { variant: 'error' });
  }, [apiError]);

  const searchInput = async () => {
    try {
      const result = await executeApiCall(
        tabIndex === 0
          ? searchEventTitlesEndpoint + input
          : getSearchByEmailUrl(input),
        methods.get
      );

      setOptions(
        tabIndex === 1 ? result : result.filter((x) => x.claimed) || []
      );
    } catch {}
  };

  const impersonate = async (toImpersonate) => {
    try {
      await executeApiCall(
        tabIndex === 0
          ? getImpersonateUrl(toImpersonate)
          : getImpersonateByEmailUrl(encodeURIComponent(toImpersonate)),
        methods.get
      );
      cache.clear();
      navigate('/app/events');
    } catch {}
  };

  return (
    <GridWrapper>
      <Grid xs={12}>
        <AntTabs value={tabIndex} onChange={handleTabChange}>
          <AntTab label="By event" />
          <AntTab label="By email" />
        </AntTabs>
      </Grid>
      <Grid xs={12}>
        <Typography>
          {tabIndex === 0
            ? `Type some or all of the title or id of the event you're looking for`
            : 'Enter the email of the user you want to impersonate'}
        </Typography>
      </Grid>
      <Grid xs={6}>
        <TextField
          variant="outlined"
          required
          fullWidth
          label={
            tabIndex === 0
              ? 'Enter an event id or title'
              : 'Enter an email address'
          }
          onChange={(e) => setInput(e.target.value)}
          value={input}
        />
      </Grid>
      <Grid xs={6}>
        <Button
          variant="contained"
          color="primary"
          disabled={!input}
          onClick={searchInput}
        >
          Search
        </Button>
      </Grid>
      <Grid xs={12}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {tabIndex === 0 ? (
                  <>
                    <TableCell>Event name</TableCell>
                    <TableCell>Event Id</TableCell>
                  </>
                ) : (
                  <TableCell>Email address</TableCell>
                )}
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tabIndex === 0
                ? options.map((e) => (
                    <TableRow key={e.id}>
                      <TableCell>{e.post_title}</TableCell>
                      <TableCell>{e.id}</TableCell>
                      <TableCell>
                        <Button onClick={() => impersonate(e.id)}>
                          Impersonate
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                : options.map((e) => (
                    <TableRow key={e}>
                      <TableCell>{e}</TableCell>
                      <TableCell>
                        <Button onClick={() => impersonate(e)}>
                          Impersonate
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </GridWrapper>
  );
};

export default AdminArea;
