import * as yup from 'yup';
import * as inputType from '../../../../Consts/inputType';
import {
  fieldContainsEmail,
  fieldContainsLink,
} from '../../../../Consts/validationErrorMessages';
import regexvalidation from '../../../../Utils/regexvalidation';

export const getValidationSchema = () =>
  yup.object().shape({
    id: yup.number().nullable().notRequired(),
    label: yup
      .string()
      .required('A question is required')
      .test('link', fieldContainsLink, function (value) {
        return regexvalidation(value, 'urlonly');
      }),
    type: yup.string().required('An input type is required'),
    validation: yup.string().required(),
    values: yup.array().when('type', {
      is: (val) => val === inputType.dropdown || val === inputType.multi_choice,
      then: yup
        .array()
        .of(
          yup
            .string()
            .required('An option label is missing')
            .test('link', fieldContainsLink, function (value) {
              return regexvalidation(value, 'urlonly');
            })
        )
        .min(2, 'You must provide at least two options'),
      otherwise: yup.array().notRequired(),
    }),
    file: yup
      .mixed()
      .nullable()
      .when('type', {
        is: inputType.file_download,
        then: yup
          .mixed()
          .required('Please add a file to be downloaded by the participant'),
      }),
    information: yup.string().nullable(),
    filesToRemove: yup.array().of(yup.string()).notRequired().nullable(),
    subQuestions: yup.array().of(
      yup.object().shape({
        label: yup
          .string()
          .required('A question is required')
          .test('link', fieldContainsLink, function (value) {
            return regexvalidation(value, 'urlonly');
          }),
        type: yup
          .string()
          .required('An input type is required')
          .test('email', fieldContainsEmail, function (value) {
            return regexvalidation(value, 'email');
          })
          .test('link', fieldContainsLink, function (value) {
            return regexvalidation(value, 'link');
          }),
        previousAnswer: yup
          .string()
          .required('You must choose previous answer')
          .test('email', fieldContainsEmail, function (value) {
            return regexvalidation(value, 'email');
          })
          .test('link', fieldContainsLink, function (value) {
            return regexvalidation(value, 'link');
          }),
        values: yup.array().when('type', {
          is: (val) =>
            val === inputType.dropdown || val === inputType.multi_choice,
          then: yup
            .array()
            .of(
              yup
                .string()
                .required('An option label is missing')
                .test('email', fieldContainsEmail, function (value) {
                  return regexvalidation(value, 'email');
                })
                .test('link', fieldContainsLink, function (value) {
                  return regexvalidation(value, 'link');
                })
            )
            .min(2, 'You must provide at least two options'),
        }),
        file: yup
          .mixed()
          .nullable()
          .when('type', {
            is: inputType.file_download,
            then: yup
              .mixed()
              .required(
                'Please add a file to be downloaded by the participant'
              ),
          }),
      })
    ),
  });
