import * as yup from 'yup';
import regexvalidation from '../../../Utils/regexvalidation';
import {
  titleContainsEmail,
  titleContainsLink,
  descriptionContainsEmail,
  descriptionContainsLink,
} from '../../../Consts/validationErrorMessages';

export const getValidationSchema = (addOns) =>
  yup.object().shape({
    id: yup.string().nullable(),
    name: yup
      .string()
      .required('Please make sure to add a name here')
      .test(
        'no duplicates',
        'Please make sure to use a unique add-on name here',
        function (value) {
          const currentId = this.parent.id;
          let found = false;
          if (addOns.length && value) {
            found = addOns
              .filter((addOn) => addOn.id !== currentId)
              .some(
                (addOn) => addOn.name.toLowerCase() === value.toLowerCase()
              );
          }
          return !found;
        }
      )
      .test('email-test name', titleContainsEmail, (value) =>
        regexvalidation(value, 'email')
      )
      .test('link-test name', titleContainsLink, (value) =>
        regexvalidation(value, 'link')
      ),
    description: yup
      .string()
      .nullable()
      .max(500, 'Max. 500 characters are allowed')
      .test('email-test description', descriptionContainsEmail, (value) =>
        regexvalidation(value, 'email')
      )
      .test('link-test description', descriptionContainsLink, (value) =>
        regexvalidation(value, 'link')
      ),
    multipleOptions: yup
      .string()
      .nullable()
      .required('Please make sure to select an option'),
    price: yup
      .number('Price must be a number')
      .min(0, 'Price cannot be lower than 0')
      .nullable(),
    vat: yup
      .number('VAT must be a number')
      .min(0, 'VAT cannot be lower than 0')
      .max(100, 'VAT cannot be greater than 100'),
    addOnVariant: yup.object().when('multipleOptions', {
      is: 'true',
      then: yup.object().shape({
        name: yup.string().nullable(),
        options: yup
          .array()
          .nullable()
          .of(
            yup.object().shape({
              name: yup
                .string()
                .required('​Please add an option name')
                .test(
                  'no duplicates',
                  'Please make sure to use a unique option name',
                  function (value) {
                    if (!value) {
                      return true;
                    }
                    const addOnVariant =
                      this.options.from[2].value.addOnVariant;
                    return !addOnVariant.options
                      .filter((x) => x !== this.parent)
                      .some(
                        (x) =>
                          x.name && x.name.toLowerCase() === value.toLowerCase()
                      );
                  }
                ),
              price: yup.number('Please only use numbers'),
              requiredInformationLabel: yup
                .string()
                .nullable()
                .test(
                  'is required',
                  'Please make sure to add a question here',
                  function (value) {
                    if (!!this.parent.requiredAdditionalInfo) {
                      return !!value;
                    }
                    return true;
                  }
                ),
            })
          )
          .test(
            'if no options',
            'At least one option must be added',
            function (_) {
              const addOn = this.options.from[1].value;
              if (
                addOn.multipleOptions === 'true' &&
                addOn.addOnVariant.options.length < 1
              ) {
                return false;
              }
              return true;
            }
          ),
      }),
      otherwise: yup.object(),
    }),
    required: yup.string().nullable().required('​Please select an option '),
    availableToTickets: yup
      .array()
      .of(yup.string())
      .test(
        'if undefined',
        'Please select at least one ticket',
        // at least one ticket must be selected in order to save the add-on
        (value) => {
          return value && value.length > 0;
        }
      ),
  });
