import { createTheme, responsiveFontSizes } from '@mui/material';

const wmPalette = {
  green: {
    100: '#01AD6F',
    70: '#00C282',
    30: '#00d186',
    20: '#97EBBD',
    10: '#D4FDE6',
    5: '#EAFFF4',
  },
  black: {
    100: '#000000',
    90: '#353E44',
    50: '#7F8589',
    40: '#B3B8BC',
    30: '#D1D7DB',
    20: '#EBEEEF',
    10: 'F6F8FA',
  },
  blue: {
    100: '#2394C3',
    70: '#3DADDC',
    20: '#DBEFF8',
  },
  grey: {
    20: '#f9f9f9',
    10: '#F3F5F6',
  },
  red: {
    100: '#EA1919',
    20: '#FCD9E0',
  },
  white: {
    100: '#FFFFFF',
  },
  orange: {
    100: '#F98C0C',
  },
  yellow: {
    100: '#F6DD62',
    10: '#FFF9C8',
  },
  purple: {
    100: '#8D29CD',
    90: '#A152D3',
    10: '#EFD9FC',
  },
};

const headings = {
  fontWeight: 'bold',
};

const htmlFontSize = 16;

const pxToRem = (inputPx, base = htmlFontSize) => (1 / base) * inputPx;
const pxToRemCssValue = (inputPx, base = htmlFontSize) =>
  `${pxToRem(inputPx, base)}rem`;

const baseTheme = createTheme();

let WSGTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  props: {
    MuiRadio: {
      color: 'primary',
    },
    MuiButton: {
      disableElevation: true,
      color: 'primary',
    },
    MuiTextField: {
      variant: 'outlined',
    },
    DatePicker: {
      variant: 'outlined',
      format: 'MMMM do, yyyy',
    },
  },
  palette: {
    primary: {
      main: wmPalette.green[70],
      dark: wmPalette.green[100],
      light: wmPalette.green[50],
      contrastText: '#ffffff',
    },
    secondary: {
      main: wmPalette.red[100], // wmPalette.blue[70],
      dark: wmPalette.blue[100],
      light: wmPalette.blue[20],
      contrastText: '#ffffff',
    },
    text: {
      primary: '#353E44',
      secondary: '#353E44',
    },
    background: {
      default: wmPalette.grey[20],
    },
    error: {
      main: wmPalette.red[100],
    },
  },
  components: {
    MUIRichTextEditor: {
      styleOverrides: {
        editorContainer: {
          border: `1px solid ${wmPalette.black[30]}`,
          borderRadius: '4px',
          padding: '18.5px 14px',
          minHeight: baseTheme.spacing(20),
          '&:focus': {
            border: `1px solid ${wmPalette.green[70]}`,
          },
        },
        error: {
          border: `1px solid ${wmPalette.red[100]}`,
          borderBottom: `1px solid ${wmPalette.red[100]}`,
          borderRadius: '4px',
        },
        placeHolder: {
          paddingLeft: '18.5px',
          paddingBottom: ' 14px',
          position: 'static',
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          border: `1px solid ${wmPalette.black[30]}`,
          borderLeft: 0,
          borderRight: 0,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontFamily: 'Inter',
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: `0 0 ${baseTheme.spacing(3)} 0`,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginTop: baseTheme.spacing(1),
          paddingTop: 0,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: wmPalette.black[90],
          },
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          paddingTop: pxToRemCssValue(48),
          paddingRight: pxToRemCssValue(48),
          paddingLeft: pxToRemCssValue(48),
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          paddingRight: pxToRemCssValue(48),
          paddingLeft: pxToRemCssValue(48),
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          paddingRight: pxToRemCssValue(48),
          paddingLeft: pxToRemCssValue(48),
          paddingBottom: pxToRemCssValue(32),
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          textTransform: 'none',
          borderRadius: '8px',
          padding: '8px 24px',
          letterSpacing: '.1px',
          fontWeight: 600,
          '&.Mui-disabled': {
            color: wmPalette.black[40],
          },
          ...(ownerState.variant === 'contained' && {
            '&.Mui-disabled': {
              backgroundColor: wmPalette.black[40],
              color: wmPalette.white[100],
            },
          }),
          ...(ownerState.variant === 'outlined' && {
            border: `2px solid ${wmPalette.black[90]}`,
            color: wmPalette.black[90],
            padding: '8px 24px',
            fontWeight: 600,
            '&:hover': {
              border: `2px solid ${wmPalette.black[100]}`,
              color: wmPalette.black[100],
            },
            ...(ownerState.color === 'primary' && {
              borderWidth: '2px !important',
              borderColor: wmPalette.green[70],
              fontWeight: 600,
              color: wmPalette.green[70],
              '&:hover': {
                color: wmPalette.green[70],
                background: wmPalette.green[5],
              },
              '&:active': {
                background: wmPalette.green[10],
              },
              '&.Mui-disabled': {
                borderColor: wmPalette.black[40],
                color: wmPalette.black[40],
              },
            }),
            ...(ownerState.color === 'secondary' && {
              borderWidth: '2px !important',
              borderColor: wmPalette.black[90],
              fontWeight: 600,
              color: wmPalette.black[90],
              '&:hover': {
                color: wmPalette.black[100],
                borderColor: wmPalette.black[100],
                background: wmPalette.white[100],
              },
              '&:active': {
                color: wmPalette.black[50],
                borderColor: wmPalette.black[50],
                background: wmPalette.white[100],
              },
              '&.Mui-disabled': {
                borderColor: wmPalette.black[40],
                color: wmPalette.black[40],
                background: wmPalette.white[100],
              },
            }),
          }),
          ...(ownerState.variant === 'text' && {
            color: wmPalette.black[90],
            '&:hover': {
              background: wmPalette.green[10],
            },
            '&:active': {
              background: wmPalette.green[20],
            },
            ...(ownerState.color === 'secondary' && {
              color: wmPalette.green[70],
            }),
          }),
        }),
      },
    },
    MuiPaper: {
      styleOverrides: {
        rounded: {
          borderRadius: '8px',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: 'normal',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          color: wmPalette.black[90],
          fontWeight: 'bold',
          backgroundColor: wmPalette.grey[10],
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        borderRadius: 8,

        root: {
          '& .MuiDataGrid-colCellTitle': {
            fontWeight: 'bold',
          },
          '& .MuiDataGrid-columnsContainer': {
            backgroundColor: wmPalette.grey[10],
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            background: `${wmPalette.green[5]} !important`,
            color: wmPalette.green[70],
          },
        },
      },
    },
    selectedIcon: {
      color: 'red',
    },
    MuiTableRow: {
      styleOverrides: {
        hover: {
          '&:hover': {
            background: `${wmPalette.green[5]} !important`,
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontWeight: 'bold',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        root: {},
        arrow: {
          color: '#ffffff',
        },
        tooltip: {
          fontSize: pxToRemCssValue(14),
          backgroundColor: '#ffffff',
          color: '#000000',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.08)',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        '@global': {
          actionButton: {
            fontWeight: 'bold',
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
        },
        outlinedError: {
          backgroundColor: '#fff',
        },
        standardError: {
          border: '1px solid #FB2424',
          color: '#FB2424',
        },
      },
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          fontWeight: 'bold',
        },
      },
    },
    MuiDropzoneArea: {
      styleOverrides: {
        root: {
          borderWidth: 2,
        },
        text: {
          color: 'rgba(0, 0, 0, .5)',
        },
        icon: {
          color: 'rgba(0, 0, 0, .5)',
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: wmPalette.black[50],
        },
        colorSecondary: {
          '&.Mui-checked': {
            color: wmPalette.green[70],
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        colorSecondary: {
          '&.Mui-checked': {
            color: wmPalette.green[70],
          },
        },
      },
    },
    MuiGrid2: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.container && {
            width: '100%',
          }),
        }),
      },
    },
  },
  shadows: [
    '0px 4px 8px rgba(0, 0, 0, 0.08)',
    '0px 4px 8px rgba(0, 0, 0, 0.08)',
    '0px 4px 8px rgba(0, 0, 0, 0.08)',
    '0px 4px 9px rgba(0, 0, 0, 0.08)',
    '0px 4px 9px rgba(0, 0, 0, 0.08)',
    '0px 4px 9px rgba(0, 0, 0, 0.08)',
    '0px 4px 10px rgba(0, 0, 0, 0.09)',
    '0px 4px 10px rgba(0, 0, 0, 0.09)',
    '0px 4px 10px rgba(0, 0, 0, 0.09)',
    '0px 4px 11px rgba(0, 0, 0, 0.09)',
    '0px 4px 11px rgba(0, 0, 0, 0.09)',
    '0px 4px 11px rgba(0, 0, 0, 0.10)',
    '0px 4px 12px rgba(0, 0, 0, 0.10)',
    '0px 4px 12px rgba(0, 0, 0, 0.10)',
    '0px 4px 12px rgba(0, 0, 0, 0.10)',
    '0px 4px 13px rgba(0, 0, 0, 0.10)',
    '0px 4px 13px rgba(0, 0, 0, 0.10)',
    '0px 4px 13px rgba(0, 0, 0, 0.11)',
    '0px 4px 14px rgba(0, 0, 0, 0.11)',
    '0px 4px 14px rgba(0, 0, 0, 0.11)',
    '0px 4px 14px rgba(0, 0, 0, 0.11)',
    '0px 4px 15px rgba(0, 0, 0, 0.11)',
    '0px 4px 15px rgba(0, 0, 0, 0.12)',
    '0px 4px 15px rgba(0, 0, 0, 0.12)',
    '0px 4px 16px rgba(0, 0, 0, 0.12)',
    '0px 4px 16px rgba(0, 0, 0, 0.12)',
    '0px 4px 16px rgba(0, 0, 0, 0.12)',
  ],
  typography: {
    fontFamily: ['Inter', 'Helvetica Neue', 'Helvetica', 'Arial'].join(','),
    h1: {
      fontSize: pxToRemCssValue(56),
      ...headings,
    },
    h2: {
      fontSize: pxToRemCssValue(40),
      ...headings,
    },
    h3: {
      fontSize: pxToRemCssValue(32),
      ...headings,
    },
    h4: {
      fontSize: pxToRemCssValue(24),
      ...headings,
    },
    h5: {
      fontSize: pxToRemCssValue(20),
      ...headings,
    },
    h6: {
      fontSize: pxToRemCssValue(18),
      ...headings,
    },
    subtitle2: {
      fontSize: pxToRemCssValue(16),
      fontWeight: 'bold',
      ...headings,
    },
    subtitle3: {
      fontSize: pxToRemCssValue(14),
      ...headings,
    },
  },
});

WSGTheme = responsiveFontSizes(WSGTheme);

/*
"0px 4px 8px rgba(0, 0, 0, 0.08)",
"0px 4px 8px rgba(0, 0, 0, 0.08)",
"0px 4px 8px rgba(0, 0, 0, 0.08)",
"0px 4px 9px rgba(0, 0, 0, 0.08)",
"0px 4px 9px rgba(0, 0, 0, 0.08)",
"0px 4px 9px rgba(0, 0, 0, 0.08)",
"0px 4px 10px rgba(0, 0, 0, 0.08)",
"0px 4px 10px rgba(0, 0, 0, 0.08)",
"0px 4px 10px rgba(0, 0, 0, 0.08)",
"0px 4px 11px rgba(0, 0, 0, 0.08)",
"0px 4px 11px rgba(0, 0, 0, 0.08)",
"0px 4px 11px rgba(0, 0, 0, 0.08)",
"0px 4px 12px rgba(0, 0, 0, 0.08)",
"0px 4px 12px rgba(0, 0, 0, 0.08)",
"0px 4px 12px rgba(0, 0, 0, 0.08)",
"0px 4px 13px rgba(0, 0, 0, 0.08)",
"0px 4px 13px rgba(0, 0, 0, 0.08)",
"0px 4px 13px rgba(0, 0, 0, 0.08)",
"0px 4px 14px rgba(0, 0, 0, 0.08)",
"0px 4px 14px rgba(0, 0, 0, 0.08)",
"0px 4px 14px rgba(0, 0, 0, 0.08)",
"0px 4px 15px rgba(0, 0, 0, 0.08)",
"0px 4px 15px rgba(0, 0, 0, 0.08)",
"0px 4px 15px rgba(0, 0, 0, 0.08)",
"0px 4px 16px rgba(0, 0, 0, 0.08)",
"0px 4px 16px rgba(0, 0, 0, 0.08)",
"0px 4px 16px rgba(0, 0, 0, 0.08)",
*/

export default WSGTheme;
export { wmPalette, pxToRemCssValue };
