import useSWR from 'swr';

const useEvents = () => {
  const { data, error } = useSWR(`/event`);

  return {
    events: data,
    isLoading: !error && !data,
    error,
  };
};

export { useEvents };
