import React, { useState, useEffect } from 'react';
import {
  Card,
  Typography,
  Box,
  Button,
  Chip,
  ToggleButton,
  ToggleButtonGroup,
  Popover,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import makeStyles from '@mui/styles/makeStyles';
import { wmPalette } from '../../Theme/WorldSportsGroupTheme';
import { useTheme } from '@mui/material/styles';
import { isEmpty } from 'lodash';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { formatPrice } from '../../Format';
import { useApi, methods } from '../../Hooks/useApi';
import { getAvatar, getAvatarColor } from '../../Utils/distanceUtils';
import SoldItemPopover from './SoldItemPopover';

const useStyles = makeStyles((theme) => ({
  itemsCard: {
    padding: theme.spacing(4),
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(2),
    },
  },
  marginVertical: {
    marginTop: '0.5rem',
    marginBottom: '0.5rem',
  },
  marginBottom: {
    marginBottom: '0.5rem',
  },
  link: {
    color: wmPalette.blue[70],
    cursor: 'pointer',
    [theme.breakpoints.down('lg')]: {
      paddingTop: theme.spacing(1),
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(2),
    },
  },
  orderMgmt: {
    fontWeight: '600',
    fontSize: '16px',
    paddingLeft: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(0),
    },
  },
  addonsEmpty: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(2),
    minHeight: '250px',
  },
  marginLeftAuto: {
    marginLeft: 'auto',
  },
  selected: {
    '&&': {
      backgroundColor: wmPalette.white[100],
      borderColor: wmPalette.green[70],
      color: wmPalette.green[70],
    },
  },
  avatarMarathon: {
    fontWeight: 'bold',
    color: wmPalette.white[100],
    backgroundColor: wmPalette.blue[70],
    marginRight: 4,
  },
  avatarHalf: {
    backgroundColor: wmPalette.green[30],
  },
  chipBox: {
    width: '55px',
  },
  trunc: {
    flex: 1,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  paddingZero: {
    padding: 0,
  },
  paddingSixteen: {
    padding: theme.spacing(2),
  },
  buttonToggleGroup: {
    height: theme.spacing(4),
  },
  revenueToggle: {
    borderRadius: `${theme.spacing(1)} 0 0 ${theme.spacing(1)}`,
    textTransform: 'none',
  },
  countToggle: {
    borderRadius: `0 ${theme.spacing(1)} ${theme.spacing(1)} 0`,
    textTransform: 'none',
  },
  popoverText: {
    textAlign: 'center',
    padding: 0,
    margin: 0,
  },
  chip: { marginLeft: 4 },
}));

const baseUrl = (eventId, editionId) =>
  `/app/manage-event/${eventId}/${editionId}`;

const statisticsURL = (eventId, editionId) =>
  `/statistics/${eventId}/${editionId}/products`;

// const dataURL = (eventId, editionId, category) =>
//   `/eventstats/${eventId}/races/${editionId}/orders/${category}?from=${from}&to=${to}&currency=${currency}`;

const getShare = (base, partial) => {
  return partial === 0 ? 0 : ((100 / partial) * base).toFixed(0);
};

const SoldItems = ({
  eventId,
  editionId,
  currency,
  eventTickets,
  eventAddons,
  underReview,
  startDate,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { executeApiCall } = useApi();
  const theme = useTheme();
  const [tickets, setTickets] = useState([]);
  const [ticketsRevenue, setTicketsRevenue] = useState(0);
  const [ticketsCount, setTicketsCount] = useState(0);
  const [addonsRevenue, setAddonsRevenue] = useState(0);
  const [addonsCount, setAddonsCount] = useState(0);
  const [addons, setAddons] = useState([]);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [alignment, setAlignment] = useState('revenue');
  const [donation, setDonation] = useState({
    exists: false,
    name: '',
    revenue: 0,
  });

  const handleChange = (event, newAlignment) => {
    if (newAlignment !== null) setAlignment(newAlignment);
  };

  const fetchSoldItems = async () => {
    try {
      const data = await executeApiCall(
        statisticsURL(eventId, editionId, 'products'),
        methods.get
      );

      /** HACK:
       * This should be returned as a separate response.
       * We are currently using the same endpoing as in the OverviewGraph component to fetch the donationData
       */
      const dateRange = {
        from: new Date(startDate).toISOString().split('.')[0] + 'Z',
        to: new Date().toISOString().split('.')[0] + 'Z',
      };

      const dataUrl = `/eventstats/${eventId}/races/${editionId}/orders/addons?from=${dateRange.from}&to=${dateRange.to}&currency=${currency}`;
      const addOns = await executeApiCall(dataUrl, methods.get);

      const donationData = addOns.series.filter(
        (s) => s.name === 'Donations'
      )?.[0];

      if (donationData && donationData.total !== 0) {
        setDonation({
          exists: true,
          name: donationData.name ?? 'Total',
          revenue: donationData.total,
        });
      } else {
        setDonation({
          exists: false,
          name: '',
          revenue: 0,
        });
      }

      const ticketRevenue = data.tickets.reduce(
        (a, b) => ({
          revenue: a.revenue + b.revenue,
        }),
        { revenue: 0 }
      );
      const ticketCount = data.tickets.reduce(
        (a, b) => ({
          count: a.count + b.count,
        }),
        { count: 0 }
      );
      const addonsRevenue = data.addOns.reduce(
        (a, b) => ({
          revenue: a.revenue + b.revenue,
        }),
        { revenue: 0 }
      );
      const addonsCount = data.addOns.reduce(
        (a, b) => ({
          count: a.count + b.count,
        }),
        { count: 0 }
      );

      setAddonsRevenue(addonsRevenue.revenue);
      setTicketsRevenue(ticketRevenue.revenue);
      setTicketsCount(ticketCount.count.toFixed(0));
      setAddonsCount(addonsCount.count.toFixed(0));

      let newArray = data.tickets.filter((x) => x.count > 0);
      eventTickets.forEach((t) => {
        const isFound = newArray.some((el) => {
          return el.id === t.id ? true : false;
        });
        if (!isFound) newArray.push({ ...t, revenue: 0, count: 0 });
      });
      setTickets(newArray);

      const newAddons = data.addOns;
      eventAddons.forEach((ea) => {
        const found = data.addOns.some((el) => el.id === ea.id);
        if (!found) newAddons.push({ ...ea, revenue: 0, count: 0 });
      });

      setAddons(newAddons);
    } catch (e) {}
  };

  useEffect(() => {
    const fetch = async () => {
      await fetchSoldItems();
    };
    fetch();
  }, []);

  return (
    <>
      <Card className={classes.itemsCard}>
        <Grid container spacing={4} className={classes.paddingZero}>
          <Grid
            container
            xs={12}
            direction={isMobile ? 'column' : 'row'}
            alignItems={isMobile ? 'flex-start' : 'flex-end'}
            spacing={2}
            className={classes.paddingSixteen}
          >
            <Typography variant="h5">Total sold items</Typography>
            <Typography
              className={clsx(classes.link, classes.orderMgmt)}
              onClick={() => {
                window.scrollTo(0, 0);
                navigate(`${baseUrl(eventId, editionId)}/orders`);
              }}
            >
              Order management
            </Typography>
            <ToggleButtonGroup
              exclusive
              className={clsx(
                isMobile ? '' : classes.marginLeftAuto,
                classes.buttonToggleGroup
              )}
              value={alignment}
              onChange={handleChange}
            >
              <ToggleButton
                value="revenue"
                classes={{ selected: classes.selected }}
                className={classes.revenueToggle}
              >
                Revenue
              </ToggleButton>
              <ToggleButton
                value="count"
                classes={{ selected: classes.selected }}
                className={classes.countToggle}
              >
                Count
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid xs={12} lg={6}>
            <Grid
              container
              direction="row"
              spacing={0}
              className={classes.paddingZero}
            >
              <Grid xs={6}>
                <Typography className={classes.marginBottom} variant="h5">
                  Tickets
                </Typography>
                {tickets.map((ticket) => (
                  <Grid
                    container
                    direction="row"
                    key={ticket.id}
                    spacing={0}
                    className={classes.paddingZero}
                  >
                    {!isMobile && (
                      <SoldItemPopover
                        classes={classes}
                        avatar={
                          getAvatar(ticket.distance) === 'Custom'
                            ? 'C'
                            : getAvatar(ticket.distance)
                        }
                        avatarcolor={classes[getAvatarColor(ticket.distance)]}
                        distance={ticket.distance}
                      />
                    )}
                    <Box className={classes.trunc}>
                      <Typography className={classes.marginBottom}>
                        {ticket.name}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
              <Grid xs={6}>
                <Typography
                  className={classes.marginBottom}
                  variant="h5"
                  align="right"
                >
                  {alignment === 'revenue'
                    ? underReview
                      ? `${currency.toUpperCase()} -`
                      : formatPrice(ticketsRevenue, currency)
                    : underReview
                    ? '-'
                    : ticketsCount}
                </Typography>
                {tickets.map((ticket) => (
                  <Typography
                    key={ticket.id}
                    className={clsx(classes.marginBottom, classes.trunc)}
                    align="right"
                  >
                    {alignment === 'revenue'
                      ? underReview
                        ? `${currency.toUpperCase()} -`
                        : `${formatPrice(ticket.revenue, currency)} (${getShare(
                            ticket.revenue,
                            ticketsRevenue
                          )}%)`
                      : underReview
                      ? '-'
                      : `${ticket.count} (${getShare(
                          ticket.count,
                          ticketsCount
                        )}%)`}
                  </Typography>
                ))}
              </Grid>
            </Grid>
          </Grid>
          {isEmpty(addons) && (
            <Grid
              container
              xs={12}
              lg={6}
              direction="row"
              justifyContent="space-between"
              spacing={0}
            >
              <Typography className={classes.marginBottom} variant="h5">
                Add-ons
              </Typography>
              <Typography className={classes.marginBottom} variant="h5">
                {alignment === 'revenue' ? `${currency.toUpperCase()} -` : '-'}
              </Typography>
              <Grid
                container
                xs={12}
                justifyContent="center"
                alignItems="center"
                alignContent="center"
                spacing={0}
              >
                <Box className={classes.addonsEmpty}>
                  <Typography>You have no add-ons yet</Typography>
                  <Button
                    variant="outlined"
                    size="large"
                    onClick={() =>
                      navigate(`${baseUrl(eventId, editionId)}/add-ons`)
                    }
                  >
                    Start adding add-ons
                  </Button>
                </Box>
              </Grid>
            </Grid>
          )}
          <Grid xs={12} lg={6}>
            <Grid container direction="row" spacing={0}>
              <Grid xs={6}>
                <Box hidden={isEmpty(addons)}>
                  <Typography className={classes.marginBottom} variant="h5">
                    Add-ons
                  </Typography>
                  {addons.map((addon) => (
                    <Typography
                      key={addon.id}
                      className={clsx(classes.marginBottom, classes.trunc)}
                    >
                      {addon.name}
                    </Typography>
                  ))}
                </Box>
                <Box hidden={!donation.exists}>
                  <Typography className={classes.marginBottom} variant="h5">
                    Donations
                  </Typography>
                  <Typography
                    className={clsx(classes.marginBottom, classes.trunc)}
                  >
                    {donation.name}
                  </Typography>
                </Box>
              </Grid>
              <Grid xs={6}>
                <Box hidden={isEmpty(addons)}>
                  <Typography
                    className={classes.marginBottom}
                    variant="h5"
                    align="right"
                  >
                    {alignment === 'revenue'
                      ? underReview
                        ? `${currency.toUpperCase()} -`
                        : formatPrice(addonsRevenue, currency)
                      : underReview
                      ? '-'
                      : addonsCount}
                  </Typography>
                  {addons.map((addon) => (
                    <Typography
                      key={addon.id}
                      className={clsx(classes.marginBottom, classes.trunc)}
                      align="right"
                    >
                      {alignment === 'revenue'
                        ? underReview
                          ? `${currency.toUpperCase()} -`
                          : `${formatPrice(
                              addon.revenue,
                              currency
                            )} (${getShare(addon.revenue, addonsRevenue)}%)`
                        : underReview
                        ? '-'
                        : `${addon.count} (${getShare(
                            addon.count,
                            addonsCount
                          )}%)`}
                    </Typography>
                  ))}
                </Box>
                <Box hidden={!donation.exists}>
                  <Typography
                    className={classes.marginVertical}
                    variant="h5"
                    align="right"
                    hidden={!donation.exists}
                  >
                    {/* Default is 1 only because you can't add multiple donation sources in the old RO */}
                    {alignment === 'count'
                      ? 1
                      : `${formatPrice(donation.revenue, currency)}`}
                  </Typography>
                  <Typography
                    className={clsx(classes.marginBottom, classes.trunc)}
                    align="right"
                  >
                    {`${formatPrice(donation.revenue, currency)}`}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default SoldItems;
