import React, { useState } from 'react';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import DrawerBase from '../../../Common/Drawer/DrawerBase';
import FormikTextField from '../../../Common/Formik/FormikTextField';
import ConfirmationModal from '../../../Common/ConfirmationModal/ConfirmationModal';
import { useApi, methods } from '../../../../Hooks/useApi';
import Grid from '@mui/material/Unstable_Grid2';
const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email('Invalid email')
    .required('A support email is required'),
});

const useStyles = makeStyles((theme) => ({
  paddingBottom: {
    paddingBottom: `${theme.spacing(5)} !important`,
  },
}));

const ResendEmailDrawer = ({ handleClose, drawerState }) => {
  const { executeApiCall } = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { content } = drawerState;
  const [modalOpen, toggleModal] = useState(false);
  const sendEmail = async (values) => {
    try {
      await executeApiCall(
        `${content.url}?otherEmail=${values.email}`,
        methods.get
      );
      enqueueSnackbar(content.successMessage, { variant: 'success' });
      handleClose();
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };
  return (
    <>
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur={true}
        initialValues={{ email: drawerState?.prefilledEmail ?? '' }}
        onSubmit={sendEmail}
      >
        {({ handleSubmit, isSubmitting }) => (
          <DrawerBase
            open={drawerState.open}
            onClose={() => toggleModal(true)}
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            submittingMessage="Resending confirmation email..."
            submitText={content?.cta}
            cancelText="Cancel"
          >
            <Grid xs={12} className={classes.paddingBottom}>
              <Typography variant="h3" gutterBottom>
                {content?.title}
              </Typography>
              <Typography variant="body1">{content?.main}</Typography>
            </Grid>
            <Grid xs={12}>
              <Typography variant="body1">{content?.inputTitle}</Typography>
            </Grid>
            <Grid xs={12}>
              <FormikTextField
                variant="outlined"
                fullWidth
                label="Email address*"
                name="email"
              />
            </Grid>
          </DrawerBase>
        )}
      </Formik>
      {modalOpen && (
        <ConfirmationModal
          open={modalOpen}
          cancel={() => toggleModal(false)}
          confirm={() => {
            toggleModal(false);
            handleClose();
          }}
          title="Are you sure you want to cancel the action?"
          cancelText="No, continue"
          confirmText="Yes, cancel"
        />
      )}
    </>
  );
};

export default ResendEmailDrawer;
