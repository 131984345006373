import React from 'react';
import { useField } from 'formik';
import { TextField } from '@mui/material';

//This component must only be used with Formik tags
const FormikTextField = ({ label, helperText, explicitError, ...props }) => {
  const [field, meta] = useField(props);
  let error = explicitError ?? meta.error ?? '';
  let helper = meta.touched && Boolean(error) ? error : helperText ?? '';
  return (
    <TextField
      {...field}
      {...props}
      label={label}
      error={meta.touched && Boolean(error)}
      helperText={helper}
    />
  );
};

export default FormikTextField;
