import format from 'date-fns/format';

const prepareDate = (date, dateFormat) =>
  !date
    ? null
    : typeof date === 'string'
    ? format(new Date(date), dateFormat)
    : format(date, dateFormat);
export const formatEventPriceRange = (range) =>
  `€${range[0].toFixed(2)} - €${range[1].toFixed(2)}`;
export const formatEventDate = (date) => format(date, 'dd MMM yyyy (ccc)');
export const formatDateTime = (date) =>
  prepareDate(date, "dd MMM, yyyy' at 'HH:mm");
export const formatDate = (date) => prepareDate(date, 'dd MMM, yyyy');
export const formatDateStandard = (date) => prepareDate(date, 'dd MMM yyyy');
export const formatApiDateTime = (date) => {
  return format(date, "yyyy-MM-dd'T'HH:mm:ss");
};
export const formatPrice = (number, currency = 'USD') =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    useGrouping: false,
  }).format(number);
