import React, { useState } from 'react';
import { IconButton, Menu, MenuItem, Button } from '@mui/material';
import { MoreVert, ArrowDropDown } from '@mui/icons-material';

const KebabMenu = ({
  optionActions,
  button = 'icon',
  dense = true,
  buttonText,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleActionClick = (action) => {
    handleClose();
    action();
  };

  return (
    <>
      {button === 'icon' && (
        <IconButton size="small" onClick={handleClick}>
          <MoreVert controls="action-menu" />
        </IconButton>
      )}
      {button === 'contained' && (
        <Button onClick={handleClick} variant="contained" color="primary">
          {buttonText}
          <ArrowDropDown />
        </Button>
      )}
      <Menu
        id="action-menu"
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorEl={anchorEl}
      >
        {optionActions.map((option) => (
          <MenuItem
            key={option.label}
            dense={dense}
            onClick={() => handleActionClick(option.action)}
          >
            {option.icon ? option.icon : ''}
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default KebabMenu;
