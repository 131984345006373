import { extractFileName } from '../../../Utils/imageUtils';
const emptyOption = {
  name: '',
  price: 0,
  requiredAdditionalInfo: false,
  requiredInformationLabel: '',
};

export const mapAddOnForApiCall = (addOn, eventInfo) => {
  const { tickets } = eventInfo;
  const { availableToTickets } = addOn;

  if (!addOn) {
    return addOn;
  }
  return {
    ...addOn,
    excludedTickets: tickets
      .filter((x) => !availableToTickets.includes(x.id))
      .map((x) => x.id),
    image: addOn.image ? addOn.image.path : addOn.image,
    multipleOptions: addOn.multipleOptions === 'true',
    price: addOn.multipleOptions === 'true' ? 0 : addOn.price,
    required: addOn.required === 'true',
    addOnVariant: {
      ...addOn.addOnVariant,
      options: addOn.addOnVariant.options?.length
        ? addOn.addOnVariant.options
        : [emptyOption],
    },
  };
};

export const prepareAddOnForEdit = (addOn) => {
  const variant = {
    name: '',
    options: [],
  };

  return {
    ...addOn,
    multipleOptions:
      addOn.addOnVariant?.options &&
      addOn.addOnVariant.options.length > 0 &&
      addOn.addOnVariant.options.some((x) => !!x.name)
        ? 'true'
        : 'false',
    required: !!addOn.required + '',
    addOnVariant: {
      ...variant,
      ...(addOn.addOnVariant ?? {}),
    },
    image: addOn.image
      ? {
          id: addOn.image,
          name: extractFileName(addOn.image),
          path: addOn.image,
        }
      : null,
  };
};

export const prepareEmptyAddOnOption = (option, addOnOptions) => {
  const newOption = { ...option };
  if (!addOnOptions || addOnOptions.length < 1) {
    return newOption;
  }

  newOption.price = addOnOptions[addOnOptions.length - 1].price;

  return newOption;
};
