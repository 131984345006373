import React, { useContext, useEffect } from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import makeStyles from '@mui/styles/makeStyles';
import { useSnackbar } from 'notistack';
import StepWrapper from '../../Common/PageLayout/StepWrapper';
import ConfigurableQuestions from './ConfigurableQuestions';
import DefaultQuestionsBox from './DefaultQuestionsBox';
import WaiverUpload from './WaiverUpload';
import { actionTypes, Context } from '../../../Stores/EventInfoStore';
import { useApi, methods } from '../../../Hooks/useApi';
import StepTitle from '../../Common/PageLayout/StepTitle';

const customQuestionUrl =
  '/ro/events/{eventId}/races/{editionId}/customquestions';
const getUrl = (url, eventId, editionId) =>
  url.replace('{eventId}', eventId).replace('{editionId}', editionId);

const useStyles = makeStyles((theme) => ({
  content: {},
  list: {
    width: '100%',
  },
  listItem: {
    borderBottom: '1px solid rgba(0, 0, 0, .12)',
    marginBottom: theme.spacing(1),
  },
  requiredListItem: {
    borderBottom: '1px solid rgba(0, 0, 0, .12)',
    marginBottom: theme.spacing(1),
  },
  listSubHeader: {
    fontWeight: 'bold',
  },
}));

const RegistrationForm = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [eventInfo, dispatch] = useContext(Context);
  const { executeApiCall, apiSuccess } = useApi();
  const { event, tickets, customQuestions } = eventInfo;
  const { eventId, editionId } = event;
  const dispatchUpdate = (question) =>
    dispatch({ type: actionTypes.customQuestions_update, payload: question });

  const apiFlow = (call, then) =>
    call()
      .then(then)
      .catch((err) => enqueueSnackbar(err.message, { variant: 'error' }));

  const customQuestionHandlers = {
    addCustomQuestion: (question) => {
      if (question.type && question.subQuestions) {
        if (question.type === 'file_download' || question.type === 'text') {
          question.subQuestions = [];
          question.values = ['', ''];
        }
        if (
          question.subQuestions[0] &&
          (question.subQuestions[0].type === 'file_download' ||
            question.subQuestions[0].type === 'text')
        ) {
          question.subQuestions[0].values = ['', ''];
        }
      }
      return apiFlow(
        () =>
          executeApiCall(
            getUrl(customQuestionUrl, eventId, editionId),
            methods.post,
            question
          ),
        (newQuestion) => {
          dispatch({
            type: actionTypes.customQuestions_add,
            payload: newQuestion,
          });
          return newQuestion;
        }
      );
    },
    updateCustomQuestion: (question) => {
      if (question.type && question.subQuestions) {
        if (question.type === 'file_download' || question.type === 'text') {
          question.subQuestions = [];
          question.values = ['', ''];
        }
        if (
          question.subQuestions[0] &&
          (question.subQuestions[0].type === 'file_download' ||
            question.subQuestions[0].type === 'text')
        ) {
          question.subQuestions[0].values = ['', ''];
        }
      }
      return apiFlow(
        () =>
          executeApiCall(
            `${getUrl(customQuestionUrl, eventId, editionId)}/${question.id}`,
            methods.put,
            question
          ),
        () => {
          dispatchUpdate(question);
          return question;
        }
      );
    },
    deleteCustomQuestion: (question) => {
      apiFlow(
        () =>
          executeApiCall(
            `${getUrl(customQuestionUrl, eventId, editionId)}/${question.id}`,
            methods.delete,
            null
          ),
        () =>
          dispatch({
            type: actionTypes.customQuestions_delete,
            payload: question,
          })
      );
    },
    updateRequired: (question) => {
      apiFlow(
        () =>
          executeApiCall(
            `${getUrl(customQuestionUrl, eventId, editionId)}/${
              question.id
            }/require`,
            methods.put,
            { includeInEmail: true, required: question.required }
          ),
        () => dispatchUpdate(question)
      );
    },
    updateAppliedTickets: (question) => {
      return apiFlow(
        () =>
          executeApiCall(
            `${getUrl(customQuestionUrl, eventId, editionId)}/${
              question.id
            }/tickets`,
            methods.put,
            { ticketsApplied: question.appliedTickets }
          ),
        () => dispatchUpdate(question)
      );
    },
  };

  useEffect(() => {
    if (apiSuccess)
      enqueueSnackbar('Registration form saved successfully', {
        variant: 'success',
      });
  }, [enqueueSnackbar, apiSuccess]);

  return (
    <>
      <StepTitle
        title="Registration form"
        subtitle="Customise your registration form to collect participants' information."
      />
      <StepWrapper>
        <Grid
          container
          spacing={0}
          justifyContent="center"
          className={classes.content}
        >
          <Grid
            xs={12}
            sm={10}
            direction="column"
            justifyContent="flex-start"
            container
            spacing={3}
          >
            <DefaultQuestionsBox />
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={2}>Additional questions</TableCell>
                  <TableCell align="center">Required*</TableCell>
                  <TableCell align="center">Ticket</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <ConfigurableQuestions
                  {...customQuestionHandlers}
                  customQuestions={customQuestions}
                  availableTickets={tickets}
                />
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </StepWrapper>
      <WaiverUpload />
    </>
  );
};

export default RegistrationForm;
