import React, { useContext } from 'react';
import { Email, Add } from '@mui/icons-material';
import { MenuItem } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ButtonDropdownMenu from '../Common/ButtonDropdownMenu/ButtonDropdownMenu';
import { Context } from '../../Stores/EventInfoStore';
import { useAuth } from '../../Contexts/AuthContext';

const useStyles = makeStyles((theme) => ({
  menuIcon: {
    marginRight: theme.spacing(2),
  },
}));

const AddOrderMenu = ({
  openinviteDrawer,
  openAddOrderDrawer,
  openGenerateMockOrdersDrawer,
}) => {
  const classes = useStyles();
  const [eventInfo] = useContext(Context);
  const { isAdmin } = useAuth();
  const { underReview, isRegistrationPossible } = eventInfo;
  const menuItems = [
    {
      text: 'Send invitation',
      icon: <Email className={classes.menuIcon} />,
      action: openinviteDrawer,
    },
    {
      text: 'Add manually',
      icon: <Add className={classes.menuIcon} />,
      action: openAddOrderDrawer,
    },
    isAdmin && {
      text: 'Generate mock orders',
      disabled: false,
      action: openGenerateMockOrdersDrawer,
    },
  ];

  return (
    <ButtonDropdownMenu
      disabled={underReview || !isRegistrationPossible}
      buttonText="Add order"
      startIcon={<Add />}
    >
      {(performAction) =>
        menuItems.filter(Boolean).map((x) => (
          <MenuItem
            key={x.text}
            disabled={x.disabled}
            onClick={() => performAction(x.action)}
          >
            {x.icon}
            {x.text}
          </MenuItem>
        ))
      }
    </ButtonDropdownMenu>
  );
};

export default AddOrderMenu;
