export const mapBackendValues = (billingDetails) => ({
  contactName: billingDetails?.referenceName ?? '',
  email: billingDetails?.invoiceEmail ?? '',
  organisationName: billingDetails?.organizationName ?? '',
  registrationNumber: billingDetails?.organizationCode ?? '',
  billingAddress: {
    address1: billingDetails?.organizationBillingAddress?.address1 ?? '',
    address2: billingDetails?.organizationBillingAddress?.address2 ?? '',
    city: billingDetails?.organizationBillingAddress?.city ?? '',
    state: billingDetails?.organizationBillingAddress?.countryState ?? '',
    postalCode: billingDetails?.organizationBillingAddress?.postalCode ?? '',
    countryCode: billingDetails?.organizationBillingAddress?.countryCode ?? '',
  },
  notForProfit: billingDetails?.nfpOrganization ?? false,
  vatNumber:
    billingDetails?.vatCountryCode && billingDetails?.europeanVatNumber
      ? `${billingDetails.vatCountryCode}${billingDetails.europeanVatNumber}`
      : '',
  eUMember: billingDetails?.europeanMember ?? false,
  bankName: billingDetails?.bankName ?? '',
  bankAddress: billingDetails?.bankAddress ?? '',
  iban: billingDetails?.iban ?? '',
  swiftCode: billingDetails?.swiftCode ?? '',
  currency: billingDetails?.bankCurrencies?.length
    ? billingDetails.bankCurrencies[0].toLowerCase()
    : '',
  otherPaymentDetails: billingDetails?.otherPaymentDetails ?? '',
  confirm: false,
});
