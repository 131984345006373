import React, { useState } from 'react';
import FormikTextField from './FormikTextField';

const FormikTimeTextfield = ({ name, label, handleBlur, value }) => {
  //This is a hack due to a bug with the TextField component using the time type
  const [timeFocused, setFocus] = useState(false);
  return (
    <FormikTextField
      variant="outlined"
      label={label}
      fullWidth
      name={name}
      type={timeFocused || Boolean(value) ? 'time' : 'text'}
      onFocus={() => setFocus(true)}
      onBlur={(e) => {
        setFocus(false);
        if (handleBlur) handleBlur(e);
      }}
    />
  );
};

export default FormikTimeTextfield;
