import React from 'react';
import { Typography } from '@mui/material';
import FormikTextField from '../../../../Common/Formik/FormikTextField';
import FormikCountrySelect from '../../../../Common/Formik/FormikCountrySelect';
import WrappedDivider from '../../../../Common/WrappedDivider/WrappedDivider';
import Grid from '@mui/material/Unstable_Grid2';
const Address = ({ address, setFieldValue }) => (
  <>
    <WrappedDivider />
    <Grid xs={12}>
      <Typography variant="body1" gutterBottom>
        Address
      </Typography>
    </Grid>
    <Grid xs={12}>
      <FormikTextField
        label="Address line"
        name="extraInfo.address.address"
        fullWidth
      />
    </Grid>
    <Grid xs={12}>
      <FormikTextField
        label="Zip/Postal code"
        name="extraInfo.address.zip"
        fullWidth
      />
    </Grid>
    <Grid xs={12}>
      <FormikTextField label="City" name="extraInfo.address.city" fullWidth />
    </Grid>
    <Grid xs={12}>
      <FormikTextField label="State" name="extraInfo.address.state" fullWidth />
    </Grid>
    <Grid xs={12}>
      <FormikCountrySelect
        label="Country"
        name="extraInfo.address.countryCode"
        setCountry={(c) =>
          setFieldValue('extraInfo.address.countryCode', c.code)
        }
        country={address.countryCode}
      />
    </Grid>
  </>
);

export default Address;
