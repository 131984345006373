export const refundReasons = [
  { value: 0, label: 'Double registration' },
  { value: 1, label: 'Registered to wrong category' },
  { value: 2, label: 'Injury' },
  { value: 3, label: 'Personal matters' },
  { value: 4, label: 'Flight cancellation' },
  { value: 7, label: 'Event cancellation' },
  { value: 8, label: '24 hour cancellation policy' },
  { value: 5, label: 'Other' },
];
