import React, { useContext, useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  Link,
  LinearProgress,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import makeStyles from '@mui/styles/makeStyles';
import { PictureAsPdf, Delete } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import FileUploadButton from '../../Common/FileUploadButton/FileUploadButton';
import { actionTypes, Context } from '../../../Stores/EventInfoStore';
import { useFormData } from '../../../Hooks/useFormData';
import { useApi, methods } from '../../../Hooks/useApi';
import { extractFileName } from '../../../Utils/imageUtils';

const useStyles = makeStyles((theme) => ({
  waiverUploadWrapper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignContent: 'left',
  },
  waiverUploadContent: {
    display: 'flex',
    flex: 1,
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(10),
  },
  cardContent: {
    display: 'flex',
    margin: 'auto',
    paddingBottom: '16px !important',
  },
  fileName: {
    wordBreak: 'break-all',
  },
}));

const getWaiverUrl = (eventId, editionId) =>
  `/ro/events/${eventId}/races/${editionId}/settings/wavier`;

const WaiverUpload = () => {
  const classes = useStyles();
  const { executeFormDataCall, formLoading } = useFormData();
  const [eventInfo, dispatch] = useContext(Context);
  const { enqueueSnackbar } = useSnackbar();
  const { executeApiCall, apiError } = useApi();
  const { waiver, event } = eventInfo;
  const { eventId, editionId } = event;
  const [tempFile, setTempFile] = useState({});

  useEffect(() => {
    if (apiError)
      enqueueSnackbar(apiError, {
        variant: 'error',
      });
  }, [enqueueSnackbar, apiError]);

  const setWaiver = async (waiver) => {
    try {
      setTempFile(waiver);
      const waiverUrl = await executeFormDataCall(
        getWaiverUrl(eventId, editionId),
        methods.post,
        [waiver]
      );
      if (!formLoading) {
        setTempFile({});
      }
      dispatch({ type: actionTypes.waiver_set, payload: waiverUrl });
      enqueueSnackbar('Waiver successfully updated', { variant: 'success' });
    } catch {}
  };

  const deleteWaiver = async () => {
    try {
      await executeApiCall(
        getWaiverUrl(eventId, editionId),
        methods.delete,
        null
      );
      dispatch({ type: actionTypes.waiver_set, payload: null });
      enqueueSnackbar('Waiver successfully removed', { variant: 'success' });
    } catch {}
  };

  return (
    <Card className={classes.waiverUploadWrapper}>
      <CardContent className={classes.waiverUploadContent}>
        <Grid container spacing={0} justifyContent="center">
          <Grid container xs={12} spacing={1}>
            <Grid xs={12}>
              <Typography variant="h4" color="initial" gutterBottom>
                Waiver / Terms & Conditions
              </Typography>
            </Grid>
            <Grid xs={12}>
              <Typography color="initial" gutterBottom>
                Upload your Waiver or Terms & Conditions as a PDF file here, to
                be displayed during registration.
              </Typography>
            </Grid>
            <Grid xs={12}>
              <PDFUploadSection
                setFile={setWaiver}
                deleteFile={deleteWaiver}
                file={waiver}
                classes={classes}
                tempFile={tempFile}
                isLoading={formLoading}
              />
            </Grid>
            <Grid xs={12}>
              <Typography color="initial" gutterBottom>
                Waiver / Terms & Conditions must be read and accepted by the
                participant in order to complete the purchase.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const PDFUploadSection = ({
  file,
  classes,
  deleteFile,
  setFile,
  tempFile,
  isLoading,
}) => {
  const fileName = file?.name ?? extractFileName(file) ?? 'File uploaded';
  const tempFileName =
    tempFile?.name ?? extractFileName(tempFile) ?? 'File uploaded';
  return (
    <>
      {!Boolean(file) && (
        <>
          {!isLoading && (
            <FileUploadButton
              errorMessage="The file must be a pdf and smaller than 10 MB"
              fileType="application/pdf"
              setFile={setFile}
              size={10485760}
            />
          )}
          {isLoading && (
            <Grid container spacing={0}>
              <Grid xs={10}>
                <Card>
                  <CardContent className={classes.cardContent}>
                    <Grid container spacing={0} alignItems="center">
                      <Grid xs={2}>
                        <PictureAsPdf fontSize="large" />
                      </Grid>
                      <Grid xs={8}>
                        <Typography
                          className={classes.fileName}
                          variant="h5"
                          component="h5"
                        >
                          {tempFileName}
                        </Typography>
                        {Boolean(tempFile.size) ? (
                          <Typography variant="caption" color="textSecondary">
                            {tempFile.size / 1000} KB
                          </Typography>
                        ) : null}
                        <LinearProgress />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          )}
        </>
      )}
      {Boolean(file) && (
        <Grid container spacing={0}>
          <Grid xs={10}>
            <Card>
              <CardContent className={classes.cardContent}>
                <Grid container spacing={0} alignItems="center">
                  <Grid xs={2}>
                    <PictureAsPdf fontSize="large" />
                  </Grid>
                  <Grid xs={8}>
                    <Typography
                      className={classes.fileName}
                      variant="h5"
                      component="h5"
                    >
                      <Link
                        variant="inherit"
                        color="inherit"
                        href={file}
                        rel="noreferrer"
                        target="_blank"
                      >
                        {fileName}
                      </Link>
                    </Typography>
                    <Typography variant="caption" color="textSecondary">
                      {Boolean(file.size) ? `${file.size / 1000} KB` : null}
                    </Typography>
                  </Grid>
                  <Grid xs={2}>
                    <IconButton onClick={deleteFile} size="large">
                      <Delete fontSize="inherit" />
                    </IconButton>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default WaiverUpload;
