import useSWR from 'swr';

const useEdition = (eventId, editionId) => {
  const { data, error } = useSWR(
    Boolean(eventId && editionId)
      ? `/event/${eventId}/edition/${editionId}`
      : null
  );

  return {
    edition: data,
    isLoading: !error && !data,
    error,
  };
};

export { useEdition };
