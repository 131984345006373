import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Paper } from '@mui/material';
import OrdersTable from './OrdersTable';
import ParticipantsTable from './ParticipantsTable';
import Loading from '../Loading/Loading';
import AddOrderMenu from './AddOrderMenu';
import InviteParticipantDrawer from './OrderActionDrawers/InviteParticipantDrawer';
import AddOrderManuallyDrawer from './OrderActionDrawers/AddOrderManuallyDrawer/AddOrderManuallyDrawer';
import GenerateMockOrdersDrawer from './MockOrders/GenerateMockOrdersDrawer';
import { AntTabs, AntTab } from '../Common/AntTabs/AntTabs';
import useQueryParams from '../../Hooks/useQueryParams';
import Grid from '@mui/material/Unstable_Grid2';
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: 10,
  },
}));

const OrdersPage = ({ orders, participants, isLoading }) => {
  const classes = useStyles();
  const tab = parseInt(useQueryParams().get('tab'));
  const [tabIndex, setTabIndex] = useState(tab === 0 || tab === 1 ? tab : 0);
  const [inviteDrawer, toggleinviteDrawer] = useState(false);
  const [addOrderDrawer, toggleAddOrderDrawer] = useState(false);
  const [isGenerateMockOrdersDrawerOpen, toggleGenerateMockOrdersDrawerOpen] =
    useState(false);
  const navigate = useNavigate();

  const handleTabChange = (_, newValue) => {
    setTabIndex(newValue);
  };

  const goToOrderDetails = (params) => {
    navigate(`${params.row.orderId}?tab=${tabIndex}`);
  };

  const goToInviationFromManualOrder = () => {
    toggleAddOrderDrawer(false);
    toggleinviteDrawer(true);
  };

  return (
    <>
      <Paper className={classes.paper}>
        <Grid container spacing={1}>
          <Grid md={10} xs={12}>
            <AntTabs value={tabIndex} onChange={handleTabChange}>
              <AntTab label="Participants" />
              <AntTab label="Orders" />
            </AntTabs>
          </Grid>
          <Grid md={2} xs={12}>
            <AddOrderMenu
              openGenerateMockOrdersDrawer={() =>
                toggleGenerateMockOrdersDrawerOpen(true)
              }
              openAddOrderDrawer={() => toggleAddOrderDrawer(true)}
              openinviteDrawer={() => toggleinviteDrawer(true)}
            />
          </Grid>
        </Grid>
        {!orders && !participants && <Loading variant="spinner" />}
        {tabIndex === 0 && (
          <ParticipantsTable
            participants={participants}
            goToOrderDetails={goToOrderDetails}
            isLoading={isLoading}
          />
        )}
        {tabIndex === 1 && (
          <OrdersTable
            orders={orders}
            isLoading={isLoading}
            goToOrderDetails={goToOrderDetails}
          />
        )}
      </Paper>
      <InviteParticipantDrawer
        open={inviteDrawer}
        handleClose={() => toggleinviteDrawer(false)}
      />
      <AddOrderManuallyDrawer
        open={addOrderDrawer}
        handleClose={() => toggleAddOrderDrawer(false)}
        goToInvitationDrawer={goToInviationFromManualOrder}
      />
      <GenerateMockOrdersDrawer
        open={isGenerateMockOrdersDrawerOpen}
        handleClose={() => toggleGenerateMockOrdersDrawerOpen(false)}
      />
    </>
  );
};

export default OrdersPage;
