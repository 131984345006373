import React, { useContext } from 'react';
import { isEmpty } from 'lodash';
import { Context } from '../../Stores/EventInfoStore';
import { Table } from './Table';
import { formatDateTime, formatPrice } from '../../Format';
import OrderStatusPill from './OrderStatusPill';
import EmailWithContextOption from '../Common/EmailWithContextOption/EmailWithContextOption';
import { ORDER_BREAKDOWN } from './reportType';

const columns = (currency) => {
  let cols = [
    {
      field: 'orderReference',
      headerName: 'Order reference',
      width: 160,
    },
    {
      field: 'orderDate2',
      headerName: 'Order date',
      type: 'dateTime',
      width: 180,
      valueGetter: (params) => new Date(params.row.orderDate),
      valueFormatter: (params) => formatDateTime(params.value),
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 150,
      valueGetter: (params) => `${params.row.firstName} ${params.row.lastName}`,
      sortComparator: (v1, v2, param1, param2) =>
        (param1.row.lastName + param1.row.firstName).localeCompare(
          param2.row.lastName + param2.row.firstName
        ),
    },
    {
      field: 'email',
      headerName: 'Email address',
      width: 210,
      renderCell: (params) => (
        <EmailWithContextOption email={params.row.email} />
      ),
    },
    {
      field: 'statusTitle',
      headerName: 'Order status',
      width: 150,
      renderCell: (params) => {
        return (
          <OrderStatusPill
            title={params.row.statusTitle}
            status={params.row.status}
            size="small"
          />
        );
      },
    },
    {
      field: 'coupon?.code',
      headerName: 'Coupon',
      valueGetter: (params) => {
        const tickets = params.row.participants.flatMap((p) =>
          p.orderLines.filter((l) => l.productType === 'race')
        );
        const productDiscountCodes = tickets.map((t) => t.productDiscountCode);
        return !isEmpty(params.row.coupon?.code)
          ? params.row.coupon.code
          : productDiscountCodes
          ? productDiscountCodes[0]
          : null;
      },
      width: 120,
    },
    {
      field: 'totalValue',
      headerName: 'Paid amount',
      type: 'number',
      width: 140,
      valueGetter: (params) => params.row.totalValueForOrganizer,
      valueFormatter: (params) => formatPrice(params.value, currency),
    },
    {
      field: 'ticketStatus2',
      headerName: 'Ticket status',
      width: 150,
      hide: true,
      valueGetter: (params) =>
        !isEmpty(params.row.ticketStatus)
          ? params.row.ticketStatus.title
          : null,
      renderCell: (params) => {
        return (
          !isEmpty(params.row.ticketStatus) && (
            <OrderStatusPill
              title={params.row.ticketStatus.title}
              status={params.row.ticketStatus.status}
              size="small"
            />
          )
        );
      },
    },
  ];
  return cols;
};

const OrdersTable = ({ orders, goToOrderDetails, isLoading }) => {
  const [eventInfo] = useContext(Context);
  return (
    <Table
      rows={orders ?? []}
      columns={columns(eventInfo.basicInfo.eventCurrency)}
      getRowId={(row) => row.orderReference}
      loading={isLoading}
      onRowClick={goToOrderDetails}
      reportType={ORDER_BREAKDOWN}
      initialState={{
        sorting: {
          sortModel: [{ field: 'orderDate2', sort: 'desc' }],
        },
      }}
      hasCustomExportEnabled={eventInfo.hasCustomExportEnabled}
    />
  );
};

export default OrdersTable;
