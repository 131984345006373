import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

const EventTitleWarningModal = ({ open, handleClose }) => (
  <Dialog open={open}>
    <DialogContent>
      <DialogContentText>
        Please make sure the number in your event name is none of the following:
      </DialogContentText>
      <DialogContentText>- Edition number</DialogContentText>
      <DialogContentText>- Year of the event</DialogContentText>
      <DialogContentText>- Date of the event</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} color="primary" autoFocus>
        Ok
      </Button>
    </DialogActions>
  </Dialog>
);

export default EventTitleWarningModal;
