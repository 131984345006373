import React, { useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';

const locationMap = { current: null };
const placesService = { current: null };

const useStyles = makeStyles((theme) => ({
  noLocation: {
    width: '100%',
    height: 150,
    marginTop: theme.spacing(1),
    borderRadius: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '4px dashed rgba(0, 0, 0, 0.12)',
  },
  map: {
    width: '100%',
    minHeight: 150,
    marginTop: theme.spacing(1),
    borderRadius: theme.spacing(1),
  },
}));

const getCountryAndCity = (components) => {
  var obj = {};
  components.forEach((c) => {
    if (c.types.some((x) => x === 'postal_town')) obj.city = c.long_name;
    if (c.types.some((x) => x === 'locality')) obj.city = c.long_name;
    if (c.types.some((x) => x === 'country')) {
      obj.country = c.long_name;
      obj.countryCode = c.short_name;
    }
  });

  if (obj.city === undefined) {
    components.forEach((c) => {
      if (c.types.some((x) => x === 'administrative_area_level_1'))
        obj.city = c.long_name;
    });
  }

  return obj;
};

const LocationMap = (props) => {
  const classes = useStyles();
  const { place, setFieldValue } = props;

  useEffect(() => {
    if (!placesService.current && window.google) {
      placesService.current = new window.google.maps.places.PlacesService(
        document.getElementById('locationMap')
      );
    }
    if (!placesService.current) {
      return undefined;
    }
    const mapOptions = {
      center: new window.google.maps.LatLng(0, 0),
      zoom: 1,
      minZoom: 1,
    };

    locationMap.current = new window.google.maps.Map(
      document.getElementById('locationMap'),
      mapOptions
    );
  }, []);

  useEffect(() => {
    if (!placesService.current && window.google) {
      placesService.current = new window.google.maps.places.PlacesService(
        document.getElementById('locationMap')
      );
    }
    if (!placesService.current) {
      return undefined;
    }

    if (!place) {
      return;
    }
    placesService.current.getDetails(
      { placeId: place.place_id },
      (details, status) => {
        locationMap.current = new window.google.maps.Map(
          document.getElementById('locationMap'),
          { zoom: 8, center: details.geometry.location }
        );

        new window.google.maps.Marker({
          map: locationMap.current,
          position: details.geometry.location,
        });

        let location = getCountryAndCity(details.address_components);
        setFieldValue('eventLocation.lat', details.geometry.location.lat());
        setFieldValue('eventLocation.lng', details.geometry.location.lng());
        setFieldValue('eventLocation.city', location.city);
        setFieldValue('eventLocation.country', location.country);
        setFieldValue('eventLocation.countryCode', location.countryCode);
      }
    );
  }, [place, setFieldValue]);

  return <>{place && <div className={classes.map} id="locationMap"></div>}</>;
};

export default LocationMap;
