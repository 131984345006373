import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    margin: `${theme.spacing(2)} ${theme.spacing(5)} ${theme.spacing(
      4
    )} ${theme.spacing(5)}`,
  },
}));

const StepTitleBreadcrumb = ({ title, breadcrumb }, props) => {
  const classes = useStyles();
  return (
    <Box className={classes.sectionTitle}>
      <Typography variant="h3" gutterBottom>
        {title}
      </Typography>
      {breadcrumb}
    </Box>
  );
};

export default StepTitleBreadcrumb;
