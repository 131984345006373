import React from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { InputAdornment, TextField } from '@mui/material';
import { CalendarToday } from '@mui/icons-material';
import { onKeyDown } from '../../../../../Utils/dateUtils';
import Grid from '@mui/material/Unstable_Grid2';
const Birthdate = ({ setFieldValue, birthDate, touched, errors }) => {
  return (
    <Grid xs={12}>
      <DatePicker
        variant="inline"
        disableFuture
        fullWidth
        label="Date of birth"
        name="birthDate"
        value={birthDate}
        autoOk
        onChange={(date) => setFieldValue('birthDate', date, true)}
        error={touched.birthDate && Boolean(errors.birthDate)}
        helperText={touched.birthDate && errors.birthDate && errors.birthDate}
        format="PPP"
        inputFormat="dd MMM yyyy"
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <CalendarToday />
            </InputAdornment>
          ),
        }}
        renderInput={(props) => (
          <TextField
            onKeyDown={onKeyDown}
            fullWidth
            {...props}
            error={touched.birthDate && Boolean(errors.birthDate)}
            helperText={
              touched.birthDate && errors.birthDate && errors.birthDate
            }
          />
        )}
      />
    </Grid>
  );
};

export default Birthdate;
