import * as yup from 'yup';
import { electronicFormatIBAN, isValidIBAN, isValidBIC } from 'ibantools';
import { countries } from '../../Consts/countries';
import { euVatRegexes } from '../../Consts/euVATCodes';
import { PayoutCurrencies } from '../../Consts/currencies';
import { partialIbanRegexes } from '../../Consts/partialIBANCodes';

export const validationSchema = yup.object().shape({
  contactName: yup.string().required('A name is required'),
  email: yup.string().email().required('An email address is required'),
  organisationName: yup.string().required('An organisation name is required'),
  billingAddress: yup.object().shape({
    address1: yup
      .string()
      .required('A first line of the billing address is required'),
    address2: yup.string(),
    city: yup.string().required('A city for the billing address is required'),
    postalCode: yup
      .string()
      .required('A post code for the billing address is required'),
    countryCode: yup
      .string()
      .test('country exists', 'Please select a country from the list', (v) =>
        Object.keys(countries).some((c) => c === v)
      ),
  }),
  registrationNumber: yup.string(),
  notForProfit: yup.bool(),
  vatNumber: yup
    .string()
    .when(
      ['billingAddress.countryCode', 'notForProfit'],
      (billingAddressCountry, notForProfit, schema) => {
        if (!notForProfit && countries[billingAddressCountry]?.eu) {
          const rule = euVatRegexes[billingAddressCountry];
          if (!rule) return 'The country code does not match any EU VAT codes';

          return schema
            .required(
              'A VAT number is required for companies based in EU, unless the company is a not-for-profit organisation.'
            )
            .matches(rule.regex, rule.error);
        }
        return schema;
      }
    ),
  bankName: yup.string().required('A bank name is required'),
  bankAddress: yup.string().required('A bank address is required'),
  iban: yup.string().test(
    'check iban',
    `Make sure your IBAN contains:
      a 2-3 letter country code (A-Z)
      a 3 - 32 digits (0-9) `,
    (v) => {
      if (!v) return true;
      const iban = electronicFormatIBAN(v);

      if (iban) {
        const billingAddressCountry = iban.slice(0, 2);
        const rule = partialIbanRegexes[billingAddressCountry];
        if (rule) {
          return Boolean(iban.match(rule.regex));
        }
        return isValidIBAN(iban);
      }
      return false;
    }
  ),
  swiftCode: yup.string().test(
    'check bic',
    `Make sure your SWIFT/BIC contains:
      a 4 letter bank code (A-Z)
      a 2 letter country code (A - Z)
      a 2 letter or number location code (A-Z ; 0-9)
      a 3 letter or number branch code (optional)`,
    (v) => (!!v ? isValidBIC(v) : true)
  ),
  currency: yup
    .string()
    .required('Please enter a payout currency')
    .test(
      'check currency',
      'Please select a currency from the available currencies list',
      (v) => PayoutCurrencies.some((c) => c.code === v)
    ),
  otherPaymentDetails: yup.string().when('iban', {
    is: (iban) => !iban,
    then: yup
      .string()
      .required(
        'If you have not added an IBAN, other payment deatils are required'
      ),
  }),
  confirm: yup
    .boolean()
    .required('You must declare that the details are correct before submitting')
    .oneOf(
      [true],
      'You must declare that the details are correct before submitting'
    ),
});
