import React from 'react';
import {
  Step,
  StepButton,
  Stepper,
  Typography,
  Paper,
  Box,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import makeStyles from '@mui/styles/makeStyles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { wmPalette } from '../../../Theme/WorldSportsGroupTheme';

export const getSteps = () =>
  [
    {
      title: 'Basic information',
      isOptional: false,
      options: {
        gaEventAction: 'Event Info Success',
        gaEventLabel: 'Added basic information',
      },
    },
    {
      title: 'Course details',
      isOptional: true,
      getNextStepLabel: (edition, formRefValues) => {
        if (!formRefValues) {
          return 'Skip & go to';
        }
        let isFilled = false;
        Object.values(formRefValues).forEach((value) => {
          if (
            value?.length > 0 ||
            (value[0] !== '' && value !== '' && value[0]?.length > 0)
          ) {
            isFilled = true;
          } else if (
            value.hasOwnProperty('place') &&
            value.place.description !== edition.basicInfo.eventLocation.place &&
            value.place.description !== null
          ) {
            isFilled = true;
          }
        });
        if (isFilled) return 'Save & go to';
        return 'Skip & go to';
      },
      needConfirmation: (edition, formRefValues) => {
        let isFilled = true;
        Object.values(formRefValues).forEach((value) => {
          if (
            value?.length > 0 ||
            (value[0] !== '' && value !== '' && value[0]?.length > 0)
          ) {
            isFilled = false;
          }
        });
        return isFilled;
      },
      confirmationText: 'We recommend you add course details',
      confirmationBody:
        'Showing details of the course such as tags, a route map or highlights of the course, will make your event look more attractive and help athletes to have a better idea of what to expect from your event.',
      confirmationButton: 'Add course details',
      options: {
        gaEventAction: 'Course details Success',
        gaEventLabel: 'Added course details',
      },
    },
    {
      title: "What's included",
      isOptional: true,
      getNextStepLabel: (edition, formRefValues) => {
        if (!formRefValues) {
          return 'Skip & go to';
        }

        if (formRefValues.services?.length > 0 ?? false) {
          return 'Save & go to';
        }
        return 'Skip & go to';
      },
      options: {
        gaEventAction: "What's offered Success",
        gaEventLabel: 'Added offered services',
      },
      needConfirmation: (edition, formRefValues) => {
        return !formRefValues?.services?.some((x) =>
          x[1].some((y) => !!y.checked)
        );
      },
      confirmationText: 'We recommend you to select what’s included',
      confirmationBody:
        'Showing what is included in the entry will make your event look more attractive and give athletes a better idea of what to expect. Select all offered services, facilities, and products to attract more athletes to register!',
      confirmationButton: 'Select what’s included',
    },
    {
      title: 'Tickets',
      isOptional: false,
      options: {
        gaEventAction: 'Ticket Success',
        gaEventLabel: 'Tickets added',
      },
    },
    {
      title: 'Add-ons',
      isOptional: true,
      getNextStepLabel: (edition) => {
        if (edition.addOns?.length > 0 ?? false) {
          return 'Save & go to';
        }
        return 'Skip & go to';
      },
      options: {
        gaEventAction: 'Add-ons Success',
        gaEventLabel: 'Add-ons added',
      },
    },
    {
      title: 'Registration form',
      isOptional: false,
      options: {
        gaEventAction: 'Registration Form Success',
        gaEventLabel: 'Registration form added',
      },
    },
    {
      title: 'Confirmation email',
      options: {},
    },
  ].map((x, i) => ({ ...x, step: i }));

const useStyles = makeStyles((theme) => ({
  root: {
    '& .Mui-disabled .MuiStepIcon-root': {
      color: wmPalette.white[100],
      border: `1px solid ${wmPalette.black[50]}`,
      borderRadius: '100%',
    },
    '& .Mui-disabled .MuiStepIcon-text': {
      fill: wmPalette.black[50],
    },
  },
  paper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(1),
  },
  grey: {
    color: wmPalette.black[50],
  },
}));

const maxSteps = Object.keys(getSteps()).length;

export const CreateEventSteps = ({ activeStep, isBottomNaviation }) => {
  const classes = useStyles();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('lg'));
  const optional = (
    <Typography className={classes.grey} variant="caption">
      Optional
    </Typography>
  );

  const steps = getSteps();
  return (
    <Grid xs={12}>
      {!isMobile ? (
        !isBottomNaviation && (
          <Paper square>
            <Stepper
              style={{ padding: 24 }}
              className={classes.root}
              alternativeLabel
              activeStep={activeStep}
            >
              {steps.map((step, index) => (
                <Step key={index}>
                  <StepButton optional={step.isOptional ? optional : ''}>
                    {step.title}
                  </StepButton>
                </Step>
              ))}
            </Stepper>
          </Paper>
        )
      ) : (
        <Box
          component={!isBottomNaviation ? Paper : ''}
          className={classes.paper}
          elevation={0}
        >
          <Typography variant="body2">
            Step {activeStep + 1} / {maxSteps}
          </Typography>
        </Box>
      )}
    </Grid>
  );
};
