import React from 'react';
import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Button,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useNavigate, useLocation } from 'react-router-dom';
import { wmPalette } from '../../Theme/WorldSportsGroupTheme';
import CheckIcon from '@mui/icons-material/Check';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  cardDisabled: {
    cursor: 'default',
  },
  cardText: {
    flexGrow: 1,
  },
  media: {
    height: 140,
  },
  cardContent: {
    padding: theme.spacing(2),
  },
  content: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  cta: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  ctaIntegrated: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  disabled: {
    color: wmPalette.black[40],
    cursor: 'default',
  },
  integrated: {
    display: 'flex',
    alignItems: 'center',
  },
  actionButton: {
    padding: '4px 5px',
  },
}));

const IntegrationCard = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const link = `${location.pathname}/${props.url}`;
  const handleClick = () => {
    if (props.active) navigate(link);
  };

  return (
    <>
      <Card className={classes.root} onClick={handleClick}>
        <CardActionArea
          className={
            (props.active ? '' : classes.cardDisabled, classes.cardText)
          }
        >
          <CardMedia
            className={classes.media}
            image={props.media}
            title={props.title}
          />
          <CardContent className={classes.cardContent}>
            <Typography gutterBottom variant="h5">
              {props.title}
            </Typography>
            <Typography
              className={classes.content}
              variant="body2"
              color="textSecondary"
            >
              {props.content}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions
          className={props.isIntegrated ? classes.ctaIntegrated : classes.cta}
        >
          {props.isIntegrated && (
            <Box className={classes.integrated}>
              Integrated <CheckIcon></CheckIcon>
            </Box>
          )}
          <Button
            size="small"
            color="secondary"
            className={clsx(
              classes.actionButton,
              props.active ? '' : classes.disabled
            )}
            endIcon={<ArrowForwardIosIcon />}
          >
            {props.btnText}
          </Button>
        </CardActions>
      </Card>
    </>
  );
};

export default IntegrationCard;
