import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

const PriceStepsModal = ({
  open,
  tickets,
  handleClose,
  eventId,
  editionId,
}) => {
  const ticketURL = `/app/manage-event/${eventId}/${editionId}/tickets`;
  const navigate = useNavigate();
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        You have changed the sales dates. Please make sure that price activation
        dates are also updated.
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please check following ticket(s) with price activation dates.
        </DialogContentText>
        <ul style={{ padding: 0, listStylePosition: 'inside' }}>
          {tickets.map((ticket) => (
            <li key={ticket.id}>
              <Typography component="span">{ticket.name}</Typography>
            </li>
          ))}
        </ul>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => navigate(ticketURL)} color="primary">
          GO TO TICKET PAGE NOW
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PriceStepsModal;
