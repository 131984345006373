import React from 'react';
import { MenuItem, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import FormikTextField from '../../../../Common/Formik/FormikTextField';
import FormikTimeTextfield from '../../../../Common/Formik/FormikTimeTextfield';
import DownloadFile from '../../../../Common/DownloadFile/DownloadFile';
import MultipleChoice from './MultipleChoice';
import * as inputTypes from '../../../../../Consts/inputType';
import * as validationType from '../../../../../Consts/customQuestionInputValidationType';
import WrappedDivider from '../../../../Common/WrappedDivider/WrappedDivider';

const useStyles = makeStyles((theme) => ({
  paddingBottom: {
    paddingBottom: `${theme.spacing(2)} !important`,
  },
}));

const CustomQuestion = ({
  values,
  setFieldValue,
  question,
  includeDivider = true,
}) => {
  const classes = useStyles();
  switch (question.type) {
    case inputTypes.dropdown:
      return (
        <>
          {includeDivider && <WrappedDivider paddingBottom={4} />}
          <FormikTextField
            name={`extraInfo.${question.id}`}
            label={question.label}
            fullWidth
            select
            className={classes.paddingBottom}
          >
            {question.values.map((v, i) => (
              <MenuItem key={`${v}-${i}`} value={v}>
                {v}
              </MenuItem>
            ))}
          </FormikTextField>
          {Boolean(question.subQuestions.length) && (
            <SubQuestion
              subQuestions={question.subQuestions}
              values={values}
              setFieldValue={setFieldValue}
              questionId={question.id}
            />
          )}
        </>
      );
    case inputTypes.file_download:
      return (
        <>
          {includeDivider && <WrappedDivider paddingBottom={4} />}
          <Typography>{question.label}</Typography>
          <DownloadFile fileLink={question.fileName} />
        </>
      );
    case inputTypes.multi_choice:
      return (
        <>
          {includeDivider && <WrappedDivider paddingBottom={4} />}
          <MultipleChoice
            setValues={(arr) =>
              setFieldValue(`extraInfo.${question.id}`, arr.join('|'))
            }
            currentValues={
              values.extraInfo[question.id]
                ? values.extraInfo[question.id].split('|')
                : []
            }
            values={question.values}
            label={question.label}
          />
        </>
      );
    default:
      return (
        <>
          {includeDivider && <WrappedDivider paddingBottom={4} />}
          {question.validation === validationType.time ? (
            <FormikTimeTextfield
              name={`extraInfo.${question.id}`}
              label={question.label}
              value={values.extraInfo[question.id]}
            />
          ) : (
            <FormikTextField
              name={`extraInfo.${question.id}`}
              label={question.label}
              fullWidth
              type={
                question.validation === validationType.number
                  ? 'number'
                  : 'text'
              }
            />
          )}
        </>
      );
  }
};

const SubQuestion = ({ subQuestions, values, setFieldValue, questionId }) =>
  subQuestions
    .filter((q) => q.previousAnswer === values.extraInfo[questionId])
    .map((q) => (
      <CustomQuestion
        key={q.id}
        values={values}
        setFieldValue={setFieldValue}
        question={q}
        includeDivider={false}
      />
    ));

export default CustomQuestion;
