import React, { useState } from 'react';
import { InputAdornment, FormHelperText } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import FormikTextField from '../../../Common/Formik/FormikTextField';
import FileUploadButton from '../../../Common/FileUploadButton/FileUploadButton';
import ImagePreview from '../../../Common/ImageUpload/ImagePreview';
import { useSnackbar } from 'notistack';
import { useFormData } from '../../../../Hooks/useFormData';
import Grid from '@mui/material/Unstable_Grid2';
import StopNumberInputScrolling from '../../../../Utils/StopNumberInputScrolling';
const helperTexts = {
  vat: 'VAT (value-added tax) should be included in the price.',
  price: 'Keep it "0" if the price is included in the ticket price',
  description: 'Describe this add on to your participant. Max 500 characters',
};

const useStyles = makeStyles((theme) => ({
  helperText: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  description: {
    marginBottom: theme.spacing(0.75),
    marginTop: theme.spacing(0.75),
  },
  numberInput: {
    '& ::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
    },
    '& [type=number]': { '-moz-appearance': 'none' },
  },
}));

const AddOnBasicInformation = ({ formik, currency, eventId, editionId }) => {
  const classes = useStyles();
  const { executeFormDataCall, formLoading } = useFormData();
  const { enqueueSnackbar } = useSnackbar();
  const { setFieldValue } = formik;
  const [tempFiles, setTempFiles] = useState([]);

  const handleFileUpload = async (file) => {
    const url = `/ro/events/${eventId}/races/${editionId}/products/productimage`;
    const result = await executeFormDataCall(url, 'POST', [file]).catch((err) =>
      enqueueSnackbar(JSON.parse(err.message)?.message, { variant: 'error' })
    );
    return result;
  };

  const onFileChange = async (file) => {
    if (!file) {
      return;
    }
    setTempFiles(tempFiles.concat(file));
    const response = await handleFileUpload(file);
    if (!formLoading) {
      setTempFiles([]);
    }
    if (typeof response !== 'object' || !response) {
      return;
    }

    const renamed = {
      name: file.name,
      id: response.image,
      path: response.image,
      size: file.size,
    };
    setFieldValue('image', renamed);
    setFieldValue('imageSmall', response.imageSmall);
  };

  const handleDeleteImage = () => {
    setFieldValue('image', null);
  };
  StopNumberInputScrolling(formik.values);

  return (
    <Grid container spacing={2} justifyContent="space-between" xs={12}>
      {formik.values.multipleOptions === 'false' && (
        <>
          <Grid xs={6}>
            <FormikTextField
              label="Price"
              type="number"
              placeholder="100"
              required
              fullWidth
              className={classes.numberInput}
              InputProps={{
                endAdornment: (
                  <InputAdornment>{currency?.toUpperCase()}</InputAdornment>
                ),
              }}
              helperText={
                (formik.touched.price && formik.errors.price) ||
                helperTexts.price
              }
              name="price"
            />
          </Grid>
          <Grid xs={6}>
            <FormikTextField
              label="VAT"
              fullWidth
              InputProps={{
                endAdornment: <InputAdornment>%</InputAdornment>,
              }}
              name="vat"
              helperText={
                (formik.touched.vat && formik.errors.vat) || helperTexts.vat
              }
            />
          </Grid>
        </>
      )}

      <Grid xs={12}>
        <FormikTextField
          label="Add-on description"
          multiline
          rows={4}
          className={classes.description}
          placeholder="Add-on description"
          variant="outlined"
          fullWidth
          name="description"
          helperText={
            (formik.touched.description && formik.errors.description) ||
            helperTexts.description
          }
        />
      </Grid>
      <Grid xs={12}>
        <FileUploadButton
          setFile={onFileChange}
          fileType="image/jpeg, image/png"
          fileTypeName="image"
          name="image"
          errorMessage={
            (formik.touched.image &&
              Boolean(formik.errors.image) &&
              formik.errors.image) ||
            'File must be an image and less than 500KB'
          }
          size={512000}
        />
        <FormHelperText className={classes.helperText}>
          Up to 1 image (Max. 500KB)
        </FormHelperText>
        <ImagePreview
          showHelpText={false}
          files={Boolean(formik.values.image) ? [formik.values.image] : []}
          onDelete={handleDeleteImage}
          isLoading={formLoading}
          tempFiles={tempFiles}
        />
      </Grid>
    </Grid>
  );
};

export default AddOnBasicInformation;
