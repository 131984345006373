import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const useStyles = makeStyles((theme) => ({
  borderBottom: {
    borderBottom: 'none',
  },
  textOpacity: {
    opacity: '0.54',
  },
  contentPadding: {
    paddingLeft: theme.spacing(2),
  },
}));

const content = [
  {
    title: 'What happens to the old edition when a new one is created?',
    content: `Information on the old edition (such as numbers in the dashboard, order details, participant information, event information, tickets, add-ons, registration from setting, confirmation email contents, etc) will not be deleted. 
    You can easily access information from past editions by switching the edition in RaceOffice.`,
  },
  {
    title: 'Why should I activate the next edition as soon as possible?',
    content: `By activating the next edition, your event page will be updated accordingly and World’s Marathons users around the globe will get the latest information about your event.`,
  },
  {
    title: 'Do I have to start selling tickets immediately?',
    content: `No, you can activate the next edition without opening the registration. 
    Just schedule the sales start date so that you will have the updated information on your event page but without selling tickets. 
    Athletes can add your event to their bucket list to get notification emails upon the registration opening.`,
  },
  {
    title: ' Do I need to set up everything from scratch again?',
    content: `No, you don’t. 
    Creating the next edition is very easy. 
    Most of the information will be copied from the old edition and you only need to change information that requires an update such as dates, description, course map, price steps, etc.`,
  },
];

const ReactivateInfoModal = ({ open, handleClose }) => {
  const classes = useStyles();
  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>
        <Typography variant="h4">Activate the next edition</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText variant="body2" className={classes.textOpacity}>
          Activating the next edition is quick and easy! Simply follow the steps
          to check/update your event information and settings. Here are some
          Frequently Asked Questions around activating the next edition.
        </DialogContentText>
        {content.map((x) => (
          <Accordion key={x.title} className={classes.borderBottom}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle2">{x.title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2" className={classes.contentPadding}>
                {x.content}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReactivateInfoModal;
