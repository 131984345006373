import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import makeStyles from '@mui/styles/makeStyles';
import { useSnackbar } from 'notistack';
import useSWR, { mutate } from 'swr';
import { Formik } from 'formik';
import GridWrapper from '../Common/GridWrapper/GridWrapper';
import StepTitle from '../Common/PageLayout/StepTitle';
import StepWrapper from '../Common/PageLayout/StepWrapper';
import ManagePayoutForm from './ManagePayoutForm';
import Loading from '../Loading/Loading';
import { validationSchema } from './PayoutValidationSchema';
import { methods, useApi } from '../../Hooks/useApi';
import { mapBackendValues } from './PayoutDetailsMapping';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  payoutMargin: {
    marginBottom: theme.spacing(11),
  },
}));

const saveDetailsUrl = '/payout';
const getDetailsUrl = '/billing/bank-account-details';

const ManagePayoutsBase = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { executeApiCall } = useApi();
  const {
    data: payoutDetails,
    error,
    mutate: mutatePayoutDetails,
  } = useSWR(getDetailsUrl);

  const updateDetails = async (values) => {
    try {
      const newDetails = await executeApiCall(
        saveDetailsUrl,
        methods.post,
        values
      );
      enqueueSnackbar('Payout details successfully updated', {
        variant: 'success',
      });
      return newDetails;
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' });
      throw e;
    }
  };

  const updateRevalidateDetails = async (values, resetForm) => {
    try {
      await mutatePayoutDetails(updateDetails(values), false);
      //mutate key in header to remove the badge and popover
      mutate('/payout/highlight-payout', false, false);
      resetForm(mapBackendValues(payoutDetails));
    } catch {}
  };

  return (
    <GridWrapper className={classes.root}>
      <Grid xs={12}>
        <StepTitle title={'Payout details'} />
      </Grid>
      <Grid xs={12}>
        {payoutDetails === undefined && !error && <Loading variant="spinner" />}
        {payoutDetails !== undefined && (
          <StepWrapper className={classes.payoutMargin}>
            <Formik
              enableReinitialize
              initialValues={mapBackendValues(payoutDetails)}
              validationSchema={validationSchema}
              onSubmit={async (values, actions) =>
                await updateRevalidateDetails(values, actions.resetForm)
              }
            >
              {(formik) => <ManagePayoutForm formik={formik} />}
            </Formik>
          </StepWrapper>
        )}
      </Grid>
    </GridWrapper>
  );
};

export default ManagePayoutsBase;
