import { useState } from 'react';
import handleApiError from '../Utils/handleApiError';
import { useNavigate, useLocation } from 'react-router-dom';
import config from '../config.js';

export const useFormData = () => {
  const [formLoading, setIsLoading] = useState(false);
  const [formError, setError] = useState(null);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const executeFormDataCall = async (url, method, files, properties) => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      files.forEach((file) => formData.append(file.name, file));
      if (properties) formData.append('json', JSON.stringify(properties));

      const response = await fetch(config.API_URL + url, {
        credentials: 'include',
        method: method,
        body: formData,
      });

      const text = await response.text();
      if (!response.ok) {
        const error = handleApiError(text);
        switch (response.status) {
          case 400:
            throw new Error(error);
          case 401:
            navigate(`/login?loginRedirect=${pathname}`);
            break;
          default:
            throw new Error(
              "An error occurred while processing your request. We're on it"
            );
        }
      }
      return text ? JSON.parse(text) : {};
    } catch (e) {
      setError(e.message);
      throw e;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    formLoading,
    formError,
    executeFormDataCall,
  };
};
