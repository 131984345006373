import React from 'react';
import { Chip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { wmPalette } from '../../Theme/WorldSportsGroupTheme';

const useStyles = makeStyles(() => ({
  pill: {
    maxWidth: '100%',
  },
  regular: {
    fontSize: '0.875rem',
  },
  small: {
    fontSize: '0.75rem',
  },
  yellow: {
    color: wmPalette.orange[100],
    backgroundColor: wmPalette.yellow[10],
  },
  purple: {
    color: wmPalette.purple[100],
    backgroundColor: wmPalette.purple[10],
  },
  green: {
    color: wmPalette.green[100],
    backgroundColor: wmPalette.green[10],
  },
  red: {
    color: wmPalette.red[100],
    backgroundColor: wmPalette.red[20],
  },
}));

const getClass = (status) => {
  switch (status) {
    case 'awaitingInformationFromParticipant':
    case 'refundPending':
    case 'refundPendingOrganiserCancelled':
    case 'refundPendingCancellationPolicy':
    case 'awaitingPayment':
    case 'invitationPending':
      return 'yellow';
    case 'partiallyRefunded':
    case 'refunded':
      return 'purple';
    case 'successful':
    case 'changed':
      return 'green';
    case 'cancelled':
      return 'red';
    default:
      return 'yellow';
  }
};

const OrderStatusPill = ({ title, status, size = 'regular' }) => {
  const classes = useStyles();
  return (
    <Chip
      label={title}
      className={clsx(classes.pill, classes[getClass(status)], classes[size])}
    />
  );
};

export default OrderStatusPill;
