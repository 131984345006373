import React from 'react';
import { useParams, Navigate, Route, Routes } from 'react-router-dom';
import { Box } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import makeStyles from '@mui/styles/makeStyles';
import EventInfoStore from '../../../Stores/EventInfoStore';
import BasicInfo from '../BasicInfo/BasicInfo';
import RegistrationForm from '../RegistrationForm/RegistrationForm';
import Tickets from '../Tickets/Tickets';
import AddOns from '../AddOns/AddOns';
import FAQs from '../FAQs/FAQs';
import Coupons from '../Coupons/Coupons';
import ConfirmationEmail from '../ConfirmationEmail/ConfirmationEmail';
import EventNavSidebar from '../../AppNav/ManageEvents/EventNavSidebar';
import Loading from '../../Loading/Loading';
import { useEdition } from '../../../Hooks/useEdition';
import OrdersBase from '../../Orders/OrdersBase';
import Integration from '../../Integrations/Integration';
import WebhookBase from '../../Integrations/Webhook/WebhookBase';
import AffiliateBase from '../../Integrations/Affiliate/AffiliateBase';
import EventInfoUpdater from '../EventInfoUpdater/EventInfoUpdater';
import UnderReviewBanner from './UnderReviewBanner';
import CourseDetail from '../CourseDetail/CourseDetail';
import WhatsOffered from '../WhatsOffered/WhatsOffered';
import DashboardBase from '../../Dashboard/DashboardBase';
import EmailPreferences from '../../EmailPreferences/EmailPreferences';
import { useAuth } from '../../../Contexts/AuthContext';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
  },
  stepContent: {
    padding: theme.spacing(4),
  },
  contentMargin: {
    margin: 'auto',
  },
}));

const ManageEventBase = () => {
  const classes = useStyles();
  const { eventId, editionId } = useParams();
  const { edition, isLoading, error } = useEdition(eventId, editionId);
  const { isAdmin } = useAuth();

  return (
    <div className={classes.root}>
      {!error && Boolean(edition) && (
        <EventInfoStore
          initialState={{
            ticketIndex: -1,
            event: { eventId, editionId },
            ...edition,
          }}
        >
          <EventNavSidebar />
          <div className={classes.content}>
            {edition?.underReview && (
              <UnderReviewBanner
                eventTitle={edition?.basicInfo?.eventTitle}
                eventId={eventId}
              />
            )}
            <Box className={classes.stepContent}>
              <Grid container spacing={3} className={classes.contentMargin}>
                <Grid xs={12}>
                  {isLoading && <Loading variant="spinner" />}
                  <Routes>
                    <Route
                      path="basicinfo"
                      element={<BasicInfo isManage={true} isUpdate={true} />}
                    />
                    <Route
                      path="coursedetails"
                      element={<CourseDetail isManage={true} />}
                    />
                    <Route
                      path="tickets"
                      element={<Tickets isManage={true} />}
                    />
                    <Route
                      path="whats-included"
                      element={<WhatsOffered isManage={true} />}
                    />
                    <Route path="add-ons" element={<AddOns />} />
                    <Route path="regform" element={<RegistrationForm />} />
                    <Route
                      path="confirmationemail"
                      element={<ConfirmationEmail isManage={true} />}
                    />
                    <Route path="faqs" element={<FAQs />} />
                    <Route path="orders/*" element={<OrdersBase />} />
                    <Route path="integration/" element={<Integration />} />
                    <Route
                      path="integration/webhook/*"
                      element={<WebhookBase />}
                    />
                    <Route
                      path="integration/affiliate/*"
                      element={
                        isAdmin ? (
                          <AffiliateBase />
                        ) : (
                          <Navigate to="/app/events" />
                        )
                      }
                    />
                    <Route path="coupons" element={<Coupons />} />
                    <Route
                      path="email-preferences"
                      element={<EmailPreferences />}
                    />
                    <Route path="dashboard" element={<DashboardBase />} />
                    <Route path="/" element={<Navigate to="basicinfo" />} />
                    <Route path="*" element={<Navigate to="basicinfo" />} />
                  </Routes>
                </Grid>
              </Grid>
            </Box>
          </div>
          <EventInfoUpdater edition={edition} />
        </EventInfoStore>
      )}
    </div>
  );
};

export default ManageEventBase;
