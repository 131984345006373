import React from 'react';
import MuiPhoneInput from 'material-ui-phone-number';
import Grid from '@mui/material/Unstable_Grid2';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  phoneInput: {
    '& .MuiPhoneNumber-flagButton': {
      width: 'min-content',
      '& svg': {
        width: 'fill-available',
      },
    },
  },
}));

const Phone = ({ label, codeName, phoneName, setFieldValue }) => {
  const classes = useStyles();
  const setPhoneNumber = (value, countryData) => {
    let phone = '';
    const dialCode = countryData.dialCode;
    if (!dialCode) {
      setFieldValue(codeName, '');
      setFieldValue(phoneName, '');
      return;
    }
    if (value.length) {
      phone = value.substring(value.indexOf(dialCode.slice(-1)) + 1);
    }
    setFieldValue(codeName, `+${dialCode}`);
    setFieldValue(phoneName, phone);
  };

  return (
    <Grid xs={12}>
      <MuiPhoneInput
        defaultCountry="se"
        variant="outlined"
        fullWidth
        name={phoneName}
        label={label}
        onChange={setPhoneNumber}
        className={classes.phoneInput}
      />
    </Grid>
  );
};

export default Phone;
