import * as yup from 'yup';
import * as discountType from './discountType';

export const getValidationSchema = () => {
  return yup.object().shape({
    code: yup.string().required('A code is required'),
    discountType: yup.string().required('A discount type is required'),
    discount: yup
      .number()
      .typeError('Discount amount must be a number')
      .positive('Must be a positive number')
      .required('A discount amount is required'),
    redeemBy: yup.date().nullable(),
    maxRedemptions: yup.number().nullable(),
    discountedTickets: yup.array().when('discountType', {
      is: discountType.PERCENTAGE,
      then: yup
        .array()
        .of(yup.string())
        .min(1, 'At least one ticket must be selected.'),
    }),
  });
};
