import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { formatDate, formatDateTime, formatPrice } from '../../../Format';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  displayField: {
    padding: '2px 0px',
  },
}));

const DisplayField = ({
  label,
  children,
  boldLabel,
  type,
  currency,
  containerSize = 12,
}) => {
  const classes = useStyles();

  return (
    <>
      <Grid
        container
        alignItems="center"
        xs={containerSize}
        className={classes.displayField}
        spacing={0}
      >
        <Grid
          xs={6}
          sm={5}
          md={4}
          lg={3}
          style={{ fontWeight: boldLabel ? 'bold' : 'normal' }}
        >
          {label}:
        </Grid>
        <Grid xs={6} md={6}>
          {type === 'date'
            ? formatDate(children)
            : type === 'dateTime'
            ? formatDateTime(children)
            : type === 'price'
            ? formatPrice(children, currency)
            : children}
        </Grid>
      </Grid>
    </>
  );
};

export default DisplayField;
