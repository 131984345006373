import React, { useEffect, useState } from 'react';
import { Typography, Card, Link, Modal, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  Star as StarIcon,
  FileCopyOutlined as FileCopyOutlinedIcon,
  Close as CloseIcon,
} from '@mui/icons-material';
import { useApi, methods } from '../../Hooks/useApi';
import clsx from 'clsx';
import { wmPalette } from '../../Theme/WorldSportsGroupTheme';
import { useSnackbar } from 'notistack';
import Grid from '@mui/material/Unstable_Grid2';
const useStyles = makeStyles((theme) => ({
  marginTB: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  reviewCard: {
    padding: theme.spacing(4),
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(2),
    },
  },
  star: {
    width: '35px',
    height: '35px',
  },
  starYellow: {
    color: wmPalette.yellow[100],
  },
  starGrey: {
    color: wmPalette.black[30],
  },
  link: {
    color: wmPalette.blue[100],
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  modal: {
    overflow: 'scroll',
  },
  cardModal: {
    width: '50vw',
    [theme.breakpoints.down('lg')]: {
      width: '80vw',
    },
    marginTop: theme.spacing(4),
    margin: 'auto',
    padding: theme.spacing(4),
  },
  crossIconBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '50px',
    height: '50px',
    cursor: 'pointer',
  },
  starBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100px',
    height: '100px',
    backgroundColor: wmPalette.green[5],
    borderRadius: '50%',
  },
  starModal: {
    width: '56px',
    height: '56px',
    color: wmPalette.yellow[100],
  },
  copyBox: {
    whiteSpace: 'pre-wrap',
    backgroundColor: wmPalette.grey[20],
    padding: theme.spacing(2),
    cursor: 'pointer',
  },
  copy: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
  },
  close: {
    color: wmPalette.blue[100],
    cursor: 'pointer',
    fontWeight: 'bold',
  },
  paddingZero: {
    padding: 0,
  },
}));

const reviewURL = (eventId, editionId) =>
  `/statistics/${eventId}/${editionId}/reviews`;

const Review = ({ eventId, editionId, underReview }) => {
  const [averageReviewScore, setAverageReviewScore] = useState(0);
  const [reviewCount, setReviewCount] = useState(0);
  const { executeApiCall } = useApi();

  const [openModal, setOpenModal] = useState(false);
  const modalText = `Hi,\nWhat did you think of ${eventId}? The running community would love to hear all about your experience!\nPlease give ${eventId} a rating on World’s Marathons!\n\nYou will find the page for ${eventId} here:\nhttps://www.worldsmarathons.com/marathon/${eventId}\nClick “Write your review” in the review section!\n\nBest regards,\n${eventId}`;

  const { enqueueSnackbar } = useSnackbar();

  const fetchReviews = async () => {
    try {
      const reviews = await executeApiCall(
        reviewURL(eventId, editionId),
        methods.get
      );
      setAverageReviewScore(reviews.averageReviewScore);
      setReviewCount(reviews.reviewCount);
    } catch (e) {}
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(modalText);
    enqueueSnackbar('Text copied to clipboard', {
      variant: 'success',
    });
  };

  useEffect(() => {
    const fetch = async () => {
      await fetchReviews();
    };
    fetch();
  }, []);

  const classes = useStyles();
  return (
    <Card className={classes.reviewCard}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={0}
        className={classes.paddingZero}
      >
        <Typography className={classes.marginTB} variant="h6" align="center">
          Average review score
        </Typography>
        <Typography className={classes.marginTB} variant="h2">
          {underReview ? '-' : averageReviewScore}
        </Typography>
        <Grid
          className={clsx(classes.paddingZero, classes.marginTB)}
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={0}
        >
          <StarIcon
            className={clsx(
              averageReviewScore > 0.5 ? classes.starYellow : classes.starGrey,
              classes.star
            )}
          />
          <StarIcon
            className={clsx(
              averageReviewScore > 1.5 ? classes.starYellow : classes.starGrey,
              classes.star
            )}
          />
          <StarIcon
            className={clsx(
              averageReviewScore > 2.5 ? classes.starYellow : classes.starGrey,
              classes.star
            )}
          />
          <StarIcon
            className={clsx(
              averageReviewScore > 3.5 ? classes.starYellow : classes.starGrey,
              classes.star
            )}
          />
          <StarIcon
            className={clsx(
              averageReviewScore > 4.5 ? classes.starYellow : classes.starGrey,
              classes.star
            )}
          />
        </Grid>
        <Typography align="center" className={classes.marginTB}>
          {reviewCount} athletes wrote a review about this event
        </Typography>
        <Typography>
          <Link
            className={clsx(classes.link, classes.marginTB)}
            target="_blank"
            rel="noopener"
            onClick={() => setOpenModal(true)}
          >
            Get more reviews
          </Link>
        </Typography>
      </Grid>
      <Modal
        className={classes.modal}
        open={openModal}
        onClose={() => setOpenModal(false)}
        sx={{ outline: 'none' }}
        disableAutoFocus={true}
        disableEnforceFocus={true}
      >
        <Card className={classes.cardModal}>
          <Grid container direction="column" spacing={2}>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              spacing={0}
            >
              <Box
                className={classes.crossIconBox}
                onClick={() => setOpenModal(false)}
              >
                <CloseIcon />
              </Box>
            </Grid>
            <Grid xs={12}>
              <Box className={classes.starBox}>
                <StarIcon className={classes.starModal} fontSize="large" />
              </Box>
            </Grid>
            <Grid xs={12}>
              <Typography variant="h4">How to get more reviews</Typography>
            </Grid>
            <Grid xs={12}>
              <Typography variant="body1">
                Use this template to encourage previous participants to leave a
                review. Simply copy and paste the below text and send it using
                your preferred tool (email, SMS, etc.)
              </Typography>
            </Grid>
            <Grid xs={12}>
              <Box className={classes.copyBox}>
                <Typography variant="body1">{modalText}</Typography>
                <Grid
                  container
                  xs={12}
                  alignItems="center"
                  alignContent="center"
                  justifyContent="flex-end"
                  direction="row"
                  spacing={0}
                >
                  <Box
                    className={classes.copy}
                    spacing={2}
                    onClick={copyToClipboard}
                  >
                    <FileCopyOutlinedIcon />
                    <Typography>Copy the text</Typography>
                  </Box>
                </Grid>
              </Box>
            </Grid>
            <Grid container justifyContent="flex-end" spacing={0}>
              <Typography
                className={classes.close}
                onClick={() => setOpenModal(false)}
              >
                CLOSE
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </Modal>
    </Card>
  );
};

export default Review;
