import React from 'react';
import {
  Button,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import makeStyles from '@mui/styles/makeStyles';
import { Add } from '@mui/icons-material';
import KebabMenu from '../../Common/KebabMenu/KebabMenu';
import CopyCellText from '../../Common/CopyCellText/CopyCellText';

const useStyles = makeStyles((theme) => ({
  btnMargin: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
}));

const CouponTable = ({
  coupons,
  handleAddCoupon,
  handleUpdateCoupon,
  currency,
}) => {
  const classes = useStyles();
  return (
    <>
      <Grid container spacing={0} justifyContent="flex-end">
        <Grid xs={3} md={2}>
          <Button
            className={classes.btnMargin}
            variant="outlined"
            color="primary"
            onClick={handleAddCoupon}
            startIcon={<Add />}
          >
            Add coupon
          </Button>
        </Grid>
      </Grid>
      <Grid>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Coupon code</TableCell>
                <TableCell>Discount</TableCell>
                <TableCell>Expiry date</TableCell>
                <TableCell>Redemption limit</TableCell>
                <TableCell>Redeemed</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {coupons.map((c) => (
                <TableRow key={c.code}>
                  <CopyCellText text={c.code} />
                  <TableCell>{`${c.discount} ${
                    c.discountType === 0 ? '%' : currency?.toUpperCase()
                  }`}</TableCell>
                  <TableCell>
                    {new Date(c.localRedeemBy).toDateString()}
                  </TableCell>
                  <TableCell>
                    {Boolean(c.maxRedemptions) || c.maxRedemptions === 0
                      ? c.maxRedemptions
                      : 'Unlimited'}
                  </TableCell>
                  <TableCell>{`${c.redeemedCount} time${
                    c.redeemedCount === 1 ? '' : 's'
                  }`}</TableCell>
                  <TableCell>
                    <KebabMenu
                      optionActions={[
                        { label: 'Edit', action: () => handleUpdateCoupon(c) },
                      ]}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </>
  );
};

export default CouponTable;
