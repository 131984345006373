export const formatEventStartDate = (basicInfo) => {
  return basicInfo.eventStartDate !== '0001-01-01T00:00:00'
    ? basicInfo.eventStartDate ?? null
    : basicInfo.eventEndDate ?? null;
};

export const onKeyDown = (e) => {
  e.preventDefault();
};

const hasDaylightSavings = (dt) => {
  var dst = null;
  for (var i = 0; i < 12; ++i) {
    var offset = dt.getTimezoneOffset();
    if (dst === null) {
      dst = offset;
    } else if (offset < dst) {
      dst = offset;
      break;
    } else if (offset > dst) {
      break;
    }
  }
  return dt.getTimezoneOffset() === dst;
};

export const getEndOfDay = (day) => {
  const endOfDay = new Date(day.setHours(23, 59, 59, 999));
  if (hasDaylightSavings(new Date())) {
    return new Date(endOfDay.getTime() + 60 * 60 * 1000);
  }
  return endOfDay;
};
