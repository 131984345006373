import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import makeStyles from '@mui/styles/makeStyles';
import { FiberManualRecord, EmojiObjectsOutlined } from '@mui/icons-material';
import clsx from 'clsx';
import { wmPalette } from '../../../Theme/WorldSportsGroupTheme';

const useStyles = makeStyles((theme) => ({
  paddingTop: {
    paddingTop: theme.spacing(5),
  },
  greenBox: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: wmPalette.green[5],
    boxShadow: 'none',
  },
  icon: {
    minWidth: theme.spacing(4),
  },
  green: {
    color: wmPalette.green[70],
  },
}));

const modalList = [
  'Click on the︙icon',
  'Select “Edit” from the options',
  'The details of the ticket show up from right',
];

const TicketReactivationModal = ({ open, handleClose }) => {
  const classes = useStyles();
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle className={classes.paddingTop}>
        <Typography variant="h4">Please review each ticket</Typography>
      </DialogTitle>
      <DialogContent>
        <Paper elevation={0} className={classes.greenBox} p={2}>
          <List dense>
            {modalList.map((x) => (
              <ListItem key={x} disableGutters>
                <ListItemIcon className={clsx(classes.icon, classes.green)}>
                  <FiberManualRecord />
                </ListItemIcon>
                <ListItemText primary={x} />
              </ListItem>
            ))}
          </List>
        </Paper>
        <Grid container spacing={0}>
          <Grid xs={1}>
            <EmojiObjectsOutlined className={classes.green} />
          </Grid>
          <Grid xs={11}>
            <DialogContentText>
              Please check and update the details of each tickets.
            </DialogContentText>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TicketReactivationModal;
