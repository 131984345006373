import React, {
  forwardRef,
  useImperativeHandle,
  useContext,
  useState,
  useEffect,
} from 'react';
import { Add, Block } from '@mui/icons-material';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { wmPalette } from '../../../Theme/WorldSportsGroupTheme';
import { Context, actionTypes } from '../../../Stores/EventInfoStore';
import { methods, useApi } from '../../../Hooks/useApi';
import ConfirmationModal from '../../Common/ConfirmationModal/ConfirmationModal';
import DeleteConfirmationModal from '../../Common/ConfirmationModal/DeleteConfirmationModal';
import KebabMenu from '../../Common/KebabMenu/KebabMenu';
import StepTitle from '../../Common/PageLayout/StepTitle';
import TicketFormDrawer from './TicketFormDrawer';
import * as distanceType from '../../../Consts/distanceType';
import TicketReactivationModal from './TicketReactivationModal';
import { getAvatar, getAvatarColor } from '../../../Utils/distanceUtils';
import { publish } from '../../../Consts/eventStatus';
import Grid from '@mui/material/Unstable_Grid2';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';

const useStyles = makeStyles((theme) => ({
  ticketCard: {
    marginBottom: theme.spacing(1),
  },
  cardTicketsListEmpty: {
    minHeight: '400px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  avatarMarathon: {
    fontWeight: 'bold',
    color: '#fff',
    backgroundColor: '#3daddc',
    marginRight: 4,
  },
  avatarHalf: {
    backgroundColor: '#00d186',
  },
  topToolbar: {
    marginBottom: theme.spacing(2),
  },
  deactivatedLabel: {
    marginLeft: theme.spacing(3),
  },
  updateNeededLabel: {
    marginLeft: theme.spacing(3),
  },
  red: {
    color: wmPalette.red[100],
  },
  grey: {
    color: wmPalette.black[50],
  },
  kebabIcon: {
    marginRight: theme.spacing(1),
  },
}));

const baseURL = (eventId, editionId, target) =>
  `/app/manage-event/${eventId}/${editionId}/${target}`;

const getDistanceText = (distance) => {
  if (distance === distanceType.marathon) return 'Marathon';
  if (distance === distanceType.halfMarathon) return 'Half Marathon';
  return `${distance / 1000} Km / ${(distance / 1000 / 1.609).toFixed(2)} Mi`;
};

const getTicketPrice = (ticket, currency) => {
  return ticket.priceSteps.length
    ? ticket.priceSteps
        .map((step) => `${step.price} ${currency?.toUpperCase()}`)
        .join(', ')
    : `${ticket.price} ${currency?.toUpperCase()}`;
};

const CheckIsUpdateNeeded = (ticket) => {
  if (ticket.priceSteps.length) {
    if (ticket.priceSteps.some((vendor) => vendor['isUpdateNeeded'] === true)) {
      return true;
    }
  }
  return false;
};

const Tickets = forwardRef(({ setHasTickets, isReactivation }, ref) => {
  const classes = useStyles();
  const { executeApiCall, apiError } = useApi();
  const navigate = useNavigate();

  const [isEditing, setIsEditing] = useState(false);
  const [ticketEditState, setTicketEditState] = useState({});
  const [deleteTicketIndex, setDeleteTicketIndex] = useState(null);
  const [deleteCustomQuestion, setDeleteCustomQuestion] = useState([]);
  const [editCustomQuestion, setEditCustomQuestion] = useState([]);
  const [openAddonCustomModal, setOpenAddonCustomModal] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [eventInfo, dispatch] = useContext(Context);
  const { eventId, editionId } = eventInfo.event;
  const { eventCurrency } = eventInfo.basicInfo;
  const { tickets, createEventWizardStep } = eventInfo;
  const [reactivationModal, setReactivationModal] = useState(
    isReactivation && createEventWizardStep === 1
  );

  useImperativeHandle(ref, () => ({
    checkIsUpdateNeeded() {
      let ticketIndex = tickets.findIndex((ticket) =>
        ticket.priceSteps.some(
          (priceStep) => priceStep['isUpdateNeeded'] === true
        )
      );
      if (ticketIndex !== -1) {
        handleEdit(ticketIndex);
        return true;
      }
      return false;
    },
  }));

  const handleDelete = (index) => {
    const deleteCustomQuestions = [];
    const editCustomQuestions = [];
    eventInfo.customQuestions.forEach((q) => {
      if (
        q.appliedTickets.length === 1 &&
        q.appliedTickets[0] === tickets[index].id
      ) {
        deleteCustomQuestions.push(q);
      }
      if (
        q.appliedTickets.length > 1 &&
        q.appliedTickets.includes(tickets[index].id)
      ) {
        editCustomQuestions.push(q);
      }
    });
    setEditCustomQuestion(editCustomQuestions);
    setDeleteCustomQuestion(deleteCustomQuestions);
    setDeleteTicketIndex(index);
  };

  useEffect(() => {
    if (setHasTickets) setHasTickets(!!tickets.length);
  }, [tickets.length]);

  const handleDeleteConfirm = async (index) => {
    try {
      const ticketId = tickets[index].id;
      await executeApiCall(
        `/ro/events/${eventId}/races/${editionId}/products/${ticketId}`,
        methods.delete
      );
      dispatch({
        type: actionTypes.tickets_remove,
        payload: { index: index },
      });

      if (deleteCustomQuestion.length > 0) {
        deleteCustomQuestion.forEach(async (q) => {
          await executeApiCall(
            `/ro/events/${eventId}/races/${editionId}/customquestions/${q.id}`,
            methods.delete,
            null
          );
          dispatch({
            type: actionTypes.customQuestions_delete,
            payload: q,
          });
        });
      }
      if (editCustomQuestion.length > 0) {
        editCustomQuestion.forEach(async (q) => {
          const index = q.appliedTickets.indexOf(ticketId);
          if (index > -1) {
            q.appliedTickets.splice(index, 1);
          }
          await executeApiCall(
            `/ro/events/${eventId}/races/${editionId}/customquestions/${q.id}/tickets`,
            methods.put,
            { ticketsApplied: q.appliedTickets }
          );
          dispatch({
            type: actionTypes.customQuestions_update,
            payload: q,
          });
        });
      }

      setIsEditing(false);
      setDeleteTicketIndex(null);
      setDeleteCustomQuestion([]);
      setEditCustomQuestion([]);
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' });
    }
  };

  const handleDuplicate = (ticket) => {
    setTicketEditState({
      isDuplicate: true,
      ticketIndex: ticket,
      ticket: { ...tickets[ticket] },
    });
    setIsEditing(true);
  };
  const handleEdit = (ticket) => {
    setTicketEditState({
      isEditing: true,
      ticketIndex: ticket,
      ticket: { ...tickets[ticket] },
    });
    setIsEditing(true);
  };
  const handleAddTicket = () => {
    setTicketEditState({
      isNew: true,
      ticketIndex: -1,
    });
    setIsEditing(true);
  };

  const handleTicketFormDrawerClose = (updateModal = false) => {
    setIsEditing(false);
    if (eventInfo.eventPageStatus === publish && updateModal)
      setOpenAddonCustomModal(updateModal);
  };

  const toggleActive = async (index) => {
    try {
      const id = tickets[index].id;
      await executeApiCall(
        `/ro/events/${eventId}/races/${editionId}/products/${id}/toggleactive`,
        methods.put
      );
      dispatch({
        type: actionTypes.tickets_toggleactive,
        payload: index,
      });
    } catch {}
  };

  const [deactivateDrawer, toggleDeactivateDrawer] = useState(false);
  const [ticketDeactivate, setTicketDeactivate] = useState({});

  const textDeactivate = (
    <p>
      Please confirm you want to deactivate the ticket{' '}
      <strong>{ticketDeactivate.name}</strong>. Deactivating this ticket will
      remove it from your event page and it will not be available to be
      purchased. You can reactivate ticket at any time.
    </p>
  );
  const textActivate = (
    <p>
      Please confirm you want to activate the ticket{' '}
      <strong>{ticketDeactivate.name}</strong>. Activating this ticket will add
      it to your event page and it will be available to be purchased.You can
      deactivate ticket at any time.
    </p>
  );

  const deactivateTicket = (name, active, index) => {
    setTicketDeactivate({ name: name, active: active, index: index });
    toggleDeactivateDrawer(true);
  };

  useEffect(() => {
    if (apiError) enqueueSnackbar(apiError, { variant: 'error' });
  }, [apiError, enqueueSnackbar]);

  return (
    <>
      <StepTitle title="Tickets" subtitle="Create tickets for your event." />
      <Card>
        <CardContent
          className={tickets.length === 0 ? classes.cardTicketsListEmpty : ''}
        >
          {tickets.length === 0 && (
            <Grid
              container
              spacing={3}
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Grid>
                <Typography>
                  You need at least one ticket to publish a event.
                </Typography>
              </Grid>
              <Grid justifyContent="center" alignItems="center">
                <Button
                  onClick={handleAddTicket}
                  variant="contained"
                  color="primary"
                  startIcon={<Add />}
                >
                  Start adding tickets
                </Button>
              </Grid>
            </Grid>
          )}
          {tickets.length > 0 && (
            <Grid
              container
              spacing={2}
              justifyContent="flex-end"
              className={classes.topToolbar}
            >
              <Grid>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={handleAddTicket}
                >
                  + Add ticket
                </Button>
              </Grid>
            </Grid>
          )}
          {tickets.map((ticket, index) => (
            <TicketListItem
              key={ticket.id}
              index={index}
              ticket={ticket}
              classes={classes}
              onDelete={handleDelete}
              onDuplicate={handleDuplicate}
              onEdit={handleEdit}
              toggleActive={() =>
                deactivateTicket(ticket.name, ticket.active, index)
              }
              currency={eventCurrency}
            />
          ))}
        </CardContent>
      </Card>
      {isEditing && (
        <TicketFormDrawer
          open={isEditing}
          ticketState={ticketEditState}
          onClose={handleTicketFormDrawerClose}
          tickets={tickets}
        />
      )}
      {deleteTicketIndex !== null && (
        <DeleteConfirmationModal
          open={deleteTicketIndex !== null}
          confirm={() => handleDeleteConfirm(deleteTicketIndex)}
          cancel={() => setDeleteTicketIndex(null)}
          ticket={tickets[deleteTicketIndex]?.name}
          customQuestions={deleteCustomQuestion}
          deleteText={'ticket'}
        />
      )}
      {openAddonCustomModal && (
        <ConfirmationModal
          title="This ticket will be added to your existing registration form fields and add-ons."
          content="In case you want to exclude this ticket from certain add-ons or registration form fields, please edit it on the corresponding page."
          open={openAddonCustomModal}
          cancel={() => setOpenAddonCustomModal(false)}
          cancelText="Skip"
          cancelColor="primary"
          additionalText="Go to Add-on page"
          additionalColor="primary"
          handleAdditional={() =>
            navigate(baseURL(eventId, editionId, 'add-ons'))
          }
          confirmText="Go to Registration Form page"
          confirm={() => navigate(baseURL(eventId, editionId, 'regform'))}
        />
      )}
      {deactivateDrawer && (
        <ConfirmationModal
          open={deactivateDrawer}
          title={`${
            ticketDeactivate.active ? 'Deactivate' : 'Activate'
          } ticket?`}
          content={ticketDeactivate.active ? textDeactivate : textActivate}
          cancelText="Cancel"
          cancel={() => toggleDeactivateDrawer(false)}
          confirmText={`${
            ticketDeactivate.active ? 'Deactivate' : 'Activate'
          } `}
          confirm={() => {
            toggleActive(ticketDeactivate.index);
            toggleDeactivateDrawer(false);
          }}
        />
      )}
      {reactivationModal && (
        <TicketReactivationModal
          open={reactivationModal}
          handleClose={() => setReactivationModal(false)}
        />
      )}
    </>
  );
});

const TicketListItem = ({
  ticket,
  index,
  classes,
  onEdit,
  onDelete,
  onDuplicate,
  currency,
  toggleActive,
}) => {
  const handleTicketAction = (action) => {
    action(index);
  };

  const handleDelete = (_) => handleTicketAction(onDelete);
  const handleEdit = (_) => handleTicketAction(onEdit);
  const handleDuplicate = (_) => handleTicketAction(onDuplicate);

  return (
    <Card className={classes.ticketCard} variant="outlined">
      <CardHeader
        className="identity"
        title={
          <Grid container spacing={0}>
            <Typography
              variant="body1"
              className={ticket.active ? '' : classes.grey}
            >
              <strong>{ticket.name}</strong>
              <Chip
                style={{ marginLeft: 4 }}
                size="small"
                label={getAvatar(ticket.distance)}
                className={
                  ticket.active
                    ? classes[getAvatarColor(ticket.distance)]
                    : classes.grey
                }
              />
            </Typography>
            {CheckIsUpdateNeeded(ticket) && (
              <Typography
                className={clsx(classes.red, classes.updateNeededLabel)}
                variant="body1"
              >
                Update Needed
              </Typography>
            )}

            {!ticket.active && (
              <Grid>
                <Grid container spacing={0}>
                  <Block
                    className={clsx(classes.red, classes.deactivatedLabel)}
                  />
                  <Typography className={classes.red} variant="body1">
                    Deactivated
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
        }
        subheader={
          <Typography className={ticket.active ? '' : classes.grey}>
            Distance: {getDistanceText(ticket.distance)}, Price:{' '}
            {getTicketPrice(ticket, currency)}
          </Typography>
        }
        action={
          <KebabMenu
            optionActions={[
              {
                label: 'Edit',
                action: handleEdit,
                icon: (
                  <EditIcon className={classes.kebabIcon} fontSize="small" />
                ),
              },
              {
                label: 'Duplicate',
                action: handleDuplicate,
                icon: (
                  <ContentCopyIcon
                    className={classes.kebabIcon}
                    fontSize="small"
                  />
                ),
              },
              {
                label: ticket.active ? 'Deactivate' : 'Activate',
                action: toggleActive,
                icon: ticket.active ? (
                  <DoNotDisturbIcon
                    className={classes.kebabIcon}
                    fontSize="small"
                  />
                ) : (
                  <CheckIcon className={classes.kebabIcon} fontSize="small" />
                ),
              },
              {
                label: 'Delete',
                action: handleDelete,
                icon: (
                  <DeleteIcon className={classes.kebabIcon} fontSize="small" />
                ),
              },
            ]}
          />
        }
      />
    </Card>
  );
};

export default Tickets;
