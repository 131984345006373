import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    margin: `${theme.spacing(2)} ${theme.spacing(5)} ${theme.spacing(
      4
    )} ${theme.spacing(5)}`,
  },
}));
const StepTitle = ({ title, subtitle }) => {
  const classes = useStyles();
  return (
    <Box className={classes.sectionTitle}>
      <Typography variant="h3" gutterBottom>
        {title}
      </Typography>
      {Boolean(subtitle) && (
        <Typography variant="body1" gutterBottom>
          {subtitle}
        </Typography>
      )}
    </Box>
  );
};

export default StepTitle;
