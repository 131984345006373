export const ExternalLinks = {
  WebHookDocumentation:
    'https://app.tettra.co/teams/worldsmarathons/pages/4-use-our-webhook-api',
  TermsOfService:
    'https://help.worldsmarathons.com/article/44-terms-of-service',
  PrivacyPolicy: 'https://help.worldsmarathons.com/article/48-privacy-policy',
  OrganiserAgreement:
    'https://app.tettra.co/teams/worldsmarathons/pages/organiser-agreement',
};

export const Emails = {
  CustomerSuccess: 'customersuccess@worldsmarathons.com',
  Info: 'info@worldsmarathons.com',
  Partners: 'partners@worldssportsgroup.com',
};
