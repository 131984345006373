import { Link, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  underlineEmail: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
  },
}));

//obviously can be extended to encompass other text as needed
const EmailWithContextOption = ({ email }) => {
  const classes = useStyles();
  return (
    <Typography
      variant="body2"
      color="textPrimary"
      className={classes.underlineEmail}
      onClick={(e) => e.preventDefault()}
      component={Link}
      href={`mailto:${email}`}
    >
      {email}
    </Typography>
  );
};

export default EmailWithContextOption;
