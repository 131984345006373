export const mapToSuitableFormat = (data) => {
  data
    .filter(([country]) => !!MapDictionary[country])
    .forEach((arr) => (arr[0] = MapDictionary[arr[0]].replacement));

  return data;
};

const MapDictionary = {
  'United States of America': {
    replacement: 'United States',
  },
};
