import React from 'react';
import { Typography, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ReactCountryFlag from 'react-country-flag';
import Grid from '@mui/material/Unstable_Grid2';
const useStyles = makeStyles((theme) => ({
  idBox: {
    width: '24px',
  },
  flagBox: {
    height: '24px',
    width: '24px',
    borderRadius: '48px',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  nationBox: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.spacing(2),
  },
  leftBold: {
    marginLeft: 'auto',
    fontWeight: 'bold',
  },
  countryFlagWide: {
    width: '280%',
    height: '140%',
  },
  countryFlag: {
    width: '140%',
    height: '140%',
  },
}));

const Nationalities = ({ nationalities }) => {
  const classes = useStyles();
  return (
    <>
      {nationalities?.map((chunk, i) => (
        <Grid key={i} direction="column" xs={12} lg={5}>
          {chunk.map((nation) => (
            <Box key={nation.code} className={classes.nationBox}>
              <Box className={classes.idBox}>
                <Typography>{nation.id}</Typography>
              </Box>
              <Box className={classes.flagBox}>
                {nation.code.toLowerCase() === 'xx' ? (
                  <img
                    className={classes.countryFlagWide}
                    title="Unknown"
                    src="https://upload.wikimedia.org/wikipedia/commons/b/b0/No_flag.svg"
                  />
                ) : (
                  <ReactCountryFlag
                    countryCode={nation.code}
                    svg
                    className={classes.countryFlag}
                    title={nation.label}
                  />
                )}
              </Box>
              <Typography>
                {nation.label === '01/01' ? 'Unknown' : nation.label}
              </Typography>
              <Typography className={classes.leftBold}>
                {nation.val} ({nation.share}%)
              </Typography>
            </Box>
          ))}
        </Grid>
      ))}
    </>
  );
};

export default Nationalities;
