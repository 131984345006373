import React, { useState } from 'react';
import {
  Button,
  FormControlLabel,
  Checkbox,
  Link,
  Typography,
  Collapse,
  Alert,
  Stack,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import FormikTextField from '../Common/Formik/FormikTextField';
import PasswordRequirements from './PasswordRequirements';
import PasswordField from '../Common/PasswordField/PasswordField';
import RaceOfficeLogo from '../../images/raceoffice_logo.svg';
import Grid from '@mui/material/Unstable_Grid2';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { ExternalLinks } from '../../Utils/urls';
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  img: {
    marginBottom: '5%',
    width: '50%',
  },
  terms: {
    fontSize: '0.7rem',
  },
  alert: {
    border: 'none',
    boxShadow: 'none',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: theme.typography.pxToRem(14),
    paddingInline: '.5rem',
  },
}));

const formFields = [
  {
    id: 'organizationName',
    label: 'Name of your organisation or event',
  },
  {
    id: 'firstName',
    label: 'First Name',
    autoComplete: 'fname',
  },
  {
    id: 'lastName',
    label: 'Last Name',
    autoComplete: 'lname',
  },
  {
    id: 'email',
    label: 'Email Address',
    autoComplete: 'email',
  },
];

const SignupFormFields = (props) => {
  const classes = useStyles();
  const [focusPassword, setFocus] = useState(false);
  const handleFocus = () => setFocus(!focusPassword);

  return (
    <div className={classes.paper}>
      <img className={classes.img} src={RaceOfficeLogo} alt="Company logo" />
      <Stack gap="2rem" alignItems="center" width="100%">
        <Typography component="h1" variant="h5">
          Sign up as an organiser
        </Typography>
        {props.registrationError && (
          <Alert
            severity="error"
            icon={<ReportProblemIcon />}
            className={classes.alert}
          >
            {props.registrationError} or try to login{' '}
            <Link href="/login" color="inherit" fontSize="inherit">
              here
            </Link>
          </Alert>
        )}
        <form
          className={classes.form}
          onSubmit={(e) => {
            e.preventDefault();
            props.handleSubmit();
          }}
          noValidate
        >
          <Grid container spacing={2} margin={0}>
            {formFields.map((x) => {
              return (
                <Grid key={x.id} xs={12}>
                  <FormikTextField
                    variant="outlined"
                    required
                    fullWidth
                    label={x.label}
                    name={x.id}
                  />
                </Grid>
              );
            })}
            <Grid xs={12}>
              <PasswordField
                onFocus={handleFocus}
                onBlur={(e) => {
                  handleFocus();
                  props.handleBlur(e);
                }}
                password={props.values.password}
                handleChange={props.handleChange}
                error={props.touched.password && Boolean(props.errors.password)}
                helperText={
                  !focusPassword &&
                  'Use 8 or more characters with a mix of lowercase and uppercase letters, numbers and symbols'
                }
                isNew
              />
              <Collapse in={focusPassword} timeout="auto">
                <PasswordRequirements passwordErrors={props.passwordErrors} />
              </Collapse>
            </Grid>
            <Grid xs={12}>
              <FormControlLabel
                name="tosandpp"
                control={
                  <Checkbox
                    value={props.values.tosandpp}
                    onChange={props.handleChange}
                    color="primary"
                  />
                }
                label={
                  <>
                    <Typography className={classes.terms}>
                      <span>I have read and agree to </span>
                      <Link target="_blank" href={ExternalLinks.TermsOfService}>
                        World's Marathons Terms of Service
                      </Link>
                      <span>, </span>
                      <Link target="_blank" href={ExternalLinks.PrivacyPolicy}>
                        Privacy Policy
                      </Link>
                      <span> and accept the </span>
                      <Link
                        href={ExternalLinks.OrganiserAgreement}
                        target="_blank"
                      >
                        <span>Organiser agreement. </span>
                      </Link>
                    </Typography>
                    {props.touched.tosandpp && (
                      <Typography className={classes.terms} color="error">
                        {props.errors.tosandpp}
                      </Typography>
                    )}
                  </>
                }
              />
            </Grid>
            <Grid xs={12}>
              <FormControlLabel
                name="newsletter"
                control={
                  <Checkbox
                    value={props.values.newsletter}
                    onChange={props.handleChange}
                    color="primary"
                  />
                }
                label={
                  <Typography className={classes.terms}>
                    It's ok to send me emails about new features and other
                    important information to help you make the most of the
                    World's Marathons platform. (Optional)
                  </Typography>
                }
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={props.isSubmitting}
          >
            {props.isSubmitting ? 'Signing up...' : 'Sign up'}
          </Button>
          <Grid container justifyContent="center" spacing={0}>
            <Grid>
              <Typography>
                Already have an account?{' '}
                <Link href="/login" fontSize="inherit">
                  Log in
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </form>
      </Stack>
    </div>
  );
};

export default SignupFormFields;
