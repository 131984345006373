import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import FormikTextField from '../../../../Common/Formik/FormikTextField';
import FormikRadioSelect from '../../../../Common/Formik/FormikRadioSelect';
import Ice from './Ice';
import Phone from './Phone';
import Address from './Address';

const PresetCustomQuestion = ({ question, ...questionProps }) => {
  switch (question.presetQuestionName) {
    case 'ice':
      return (
        <Ice
          setFieldValue={questionProps.setFieldValue}
          withRelationship={
            question.values.length && question.values[0] === 'yes'
          }
        />
      );
    case 'phone':
      return (
        <Phone
          setFieldValue={questionProps.setFieldValue}
          codeName="extraInfo.phone.code"
          phoneName="extraInfo.phone.phone"
          label="Phone number"
        />
      );
    case 'size':
      return (
        <Grid xs={12}>
          <FormikRadioSelect
            {...question}
            setFieldValue={questionProps.setFieldValue}
          />
        </Grid>
      );
    case 'address':
      return (
        <Address
          setFieldValue={questionProps.setFieldValue}
          address={questionProps.values.extraInfo?.address}
        />
      );
    default:
      return (
        <Grid xs={12}>
          <FormikTextField
            name={`extraInfo.${question.presetQuestionName}`}
            label={question.label}
            fullWidth
          />
        </Grid>
      );
  }
};

export default PresetCustomQuestion;
