import { Divider, Box } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

const WrappedDivider = ({ paddingTop = 1, paddingBottom = 2 }) => {
  return (
    <Grid xs={12}>
      <Box pt={paddingTop} pb={paddingBottom}>
        <Divider />
      </Box>
    </Grid>
  );
};
export default WrappedDivider;
