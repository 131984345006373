import * as yup from 'yup';
import regexvalidation from '../../../Utils/regexvalidation';
import {
  fieldContainsEmail,
  fieldContainsLink,
} from '../../../Consts/validationErrorMessages';

const getValidationSchema = () => {
  return yup.object().shape({
    services: yup
      .array()
      .transform((_, orig) => {
        if (!orig) {
          return [];
        }

        const transf = orig.map((x) => x[1]).flat();
        return transf;
      })
      .of(
        yup.object().shape({
          name: yup
            .string()
            .nullable()
            .when('checked', {
              is: true,
              then: yup
                .string()
                .typeError('This field is required when checked')
                .max(25, 'Maximum length of 25 is allowed')
                .required(
                  'Input is missing. Uncheck the checkbox if nothing to add.'
                ),
            })
            .test('test-name', fieldContainsEmail, (value) =>
              regexvalidation(value, 'email')
            )
            .test('test-name', fieldContainsLink, (value) =>
              regexvalidation(value, 'link')
            ),
          details: yup
            .string()
            .nullable()
            .test('test-name', fieldContainsEmail, (value) =>
              regexvalidation(value, 'email')
            )
            .test('test-name', fieldContainsLink, (value) =>
              regexvalidation(value, 'link')
            ),
        })
      ),
  });
};

export { getValidationSchema };
