import React from 'react';
import {
  Typography,
  FormControlLabel,
  Checkbox,
  Tooltip,
  Button,
  FormHelperText,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PayoutCurrencySelect from './PayoutCurrencySelect';
import PayoutAddressInput from './PayoutAddressInput';
import FormikTextField from '../Common/Formik/FormikTextField';
import FocusFormikError from '../Common/Formik/FocusFormikError';
import WrappedDivider from '../Common/WrappedDivider/WrappedDivider';
import Grid from '@mui/material/Unstable_Grid2';
const useStyles = makeStyles((theme) => ({
  buttonsGrid: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    backgroundColor: '#FFFFFF',
    zIndex: 1,
    paddingTop: `${theme.spacing(2)} !important`,
    paddingBottom: `${theme.spacing(2)} !important`,
    paddingRight: `15% !important`,
    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.12)',
  },
}));

const contactFields = [
  { name: 'contactName', label: 'Main contact name' },
  { name: 'email', label: 'Email address' },
];
const organisationFields = [
  {
    name: 'organisationName',
    label: 'Name of your organisation',
    required: true,
  },
  {
    name: 'registrationNumber',
    label: 'Registration No/ID of your organisation',
    required: false,
  },
];
const bankFields = [
  { name: 'bankName', label: 'Bank name', required: true },
  { name: 'bankAddress', label: 'Bank address', required: true },
  { name: 'iban', label: 'IBAN', required: false },
  { name: 'swiftCode', label: 'BIC / SWIFT', required: false },
];

const ManagePayoutForm = ({ formik }) => {
  const classes = useStyles();
  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container justifyContent="center" spacing={0}>
        <Grid xs={12} sm={8} container spacing={3}>
          <Grid xs={12}>
            <Typography variant="h4" gutterBottom>
              Contact details
            </Typography>
          </Grid>
          <Grid xs={12}>
            <Typography gutterBottom>
              Please add the details of the main contact person here.
            </Typography>
          </Grid>
          {contactFields.map((f, i) => (
            <Grid xs={12} key={f.name}>
              <FormikTextField
                name={f.name}
                variant="outlined"
                fullWidth
                label={f.label}
                placeholder={f.label}
                required
                autoFocus={i === 0}
              />
            </Grid>
          ))}

          <WrappedDivider />

          <Grid xs={12}>
            <Typography variant="h4" gutterBottom>
              Organisation details
            </Typography>
          </Grid>
          <Grid xs={12}>
            <Typography gutterBottom>
              This information will be also used on your receipt.
            </Typography>
          </Grid>
          {organisationFields.map((f) => (
            <Grid xs={12} key={f.name}>
              <FormikTextField
                name={f.name}
                variant="outlined"
                fullWidth
                label={f.label}
                placeholder={f.label}
                required={f.required}
              />
            </Grid>
          ))}
          <PayoutAddressInput
            country={formik.values.billingAddress.countryCode}
            setCountry={(c) => {
              formik.setFieldValue('billingAddress.countryCode', c?.code);
              formik.setFieldValue('eUMember', c?.eu ?? false);
            }}
          />
          <Grid xs={12}>
            <FormControlLabel
              name="notForProfit"
              control={
                <Checkbox
                  value={formik.values.notForProfit}
                  checked={formik.values.notForProfit}
                  onChange={formik.handleChange}
                  color="primary"
                />
              }
              label={<Typography>Not-for-profit organisation</Typography>}
            />
          </Grid>
          <Grid xs={12}>
            <FormikTextField
              name="vatNumber"
              variant="outlined"
              fullWidth
              label="VAT Number"
              placeholder="VAT Number"
            />
          </Grid>

          <WrappedDivider />

          <Grid xs={12}>
            <Typography variant="h4" gutterBottom>
              Bank details
            </Typography>
          </Grid>
          <Grid xs={12}>
            <Typography gutterBottom>
              Please make sure to add your correct bank account details for a
              successful payout to you.
            </Typography>
          </Grid>
          <Grid xs={12}>
            <Tooltip
              arrow
              placement="bottom"
              title={`Bank account holder needs to match the name of your organisation. 
          Please change organisation name if the bank account holder is not correct. 
          Correct bank account holder is necessary for a successful payout.`}
            >
              <span>
                <FormikTextField
                  name="organisationName"
                  variant="outlined"
                  fullWidth
                  placeholder="Bank account holder name*"
                  required
                  disabled
                />
              </span>
            </Tooltip>
          </Grid>
          {bankFields.map((f) => (
            <Grid xs={12} key={f.name}>
              <FormikTextField
                name={f.name}
                variant="outlined"
                fullWidth
                label={f.label}
                placeholder={f.label}
                required={f.required}
              />
            </Grid>
          ))}
          <Grid xs={12}>
            <PayoutCurrencySelect
              currency={formik.values.currency}
              setCurrency={(c) => formik.setFieldValue('currency', c)}
            />
          </Grid>
          <Grid xs={12}>
            <FormikTextField
              name="otherPaymentDetails"
              variant="outlined"
              fullWidth
              label="Other payment details"
              placeholder="Other payment details"
              multiline
              rows={4}
              required={!formik.values.iban}
            />
            <FormHelperText>
              If IBAN or Swift/BIC is not applicable add your bank account
              number here. If required add your Branch Code (ABA routing number
              in the USA and BSB in Australia). If none of these apply, provide
              other payment details which will allow us to pay you.
            </FormHelperText>
          </Grid>
          <Grid xs={12}>
            <FormControlLabel
              name="confirm"
              control={
                <Checkbox
                  value={formik.values.confirm}
                  checked={formik.values.confirm}
                  onChange={formik.handleChange}
                  color="primary"
                />
              }
              label={
                <Typography>
                  I hereby confirm that the above information is correct *
                </Typography>
              }
            />
            {formik.touched.confirm && (
              <Typography
                style={{
                  fontSize: '0.7rem',
                }}
                color="error"
              >
                {formik.errors.confirm}
              </Typography>
            )}
          </Grid>
          <Grid
            container
            justifyContent="flex-end"
            className={classes.buttonsGrid}
            spacing={0}
          >
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={!formik.dirty || formik.isSubmitting}
            >
              {formik.isSubmitting ? 'Saving…' : 'Save'}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <FocusFormikError />
    </form>
  );
};

export default ManagePayoutForm;
