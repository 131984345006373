import React, { useEffect } from 'react';
import { Button, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useSnackbar } from 'notistack';
import { isEmpty } from 'lodash';
import { wmPalette } from '../../../Theme/WorldSportsGroupTheme';
import { useApi } from '../../../Hooks/useApi';
import { Formik } from 'formik';
import * as yup from 'yup';
import FormikTextField from '../../Common/Formik/FormikTextField';
import { useEdition } from '../../../Hooks/useEdition';
import Grid from '@mui/material/Unstable_Grid2';
const useStyles = makeStyles((theme) => ({
  root: {
    padding: 10,
    paddingTop: theme.spacing(2),
    backgroundColor: wmPalette.white[100],
    '& .MuiDataGrid-columnsContainer': {
      backgroundColor: wmPalette.white[100],
    },
    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    '& .MuiTableCell-head': {
      color: wmPalette.black[90],
    },
  },
  noBorder: {
    border: 'none',
  },
  btnEndpoint: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  webhookDetail: {
    cursor: 'pointer',
    maxWidth: '400px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  kebabIcon: {
    marginRight: theme.spacing(1),
  },
  disabled: {
    color: wmPalette.black[50],
  },
}));

const Affiliate = ({
  eventId,
  editionId,
  addAffiliate,
  currentAffiliate,
  clickCount,
}) => {
  const { apiError } = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { edition } = useEdition(eventId, editionId);
  const { race } = edition;
  useEffect(() => {
    if (apiError) enqueueSnackbar(apiError, { variant: 'error' });
  }, [enqueueSnackbar, apiError]);

  const handleAffiliateSubmit = async (values) => {
    await addAffiliate(values);
  };

  const validationSchema = yup.object().shape({
    affiliateLink: yup
      .string()
      .url("Invalid URL. Ensure to include 'http://' or 'https://'"),
  });

  const removeRef = (url) => {
    if (url.includes('?ref')) {
      return url.split('?ref')[0];
    } else if (url.includes('&ref')) {
      return url.split('&ref')[0];
    } else {
      return url;
    }
  };

  return (
    <>
      {isEmpty() && (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
          spacing={0}
        >
          <Formik
            validationSchema={validationSchema}
            validateOnChange={false}
            validateOnBlur={true}
            enableReinitialize={true}
            initialValues={{
              affiliateLink:
                race.affiliateType !== 0 ? removeRef(race.affiliateLink) : '',
            }}
            onSubmit={handleAffiliateSubmit}
          >
            {({
              handleSubmit,
              isSubmitting,
              handleBlur,
              handleChange,
              values,
            }) => (
              <Grid
                container
                justifyContent="center"
                className={classes.root}
                spacing={0}
              >
                <Grid xs={12} sm={11} container spacing={2}>
                  <Grid>
                    <Typography>{`Number of Affiliate Clicks: ${
                      clickCount ?? 0
                    }`}</Typography>
                  </Grid>
                  <Grid xs={12}>
                    <FormikTextField
                      variant="outlined"
                      fullWidth
                      label="ACTIVE Checkout link"
                      name="affiliateLink"
                    />
                  </Grid>
                  <Grid xs={2}>
                    <Button
                      variant="contained"
                      fullWidth
                      color="primary"
                      onClick={handleSubmit}
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? 'Saving' : 'Save'}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Formik>
        </Grid>
      )}
    </>
  );
};

export default Affiliate;
