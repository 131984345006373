import React, { useState } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { mutate } from 'swr';
import DrawerBase from '../Common/Drawer/DrawerBase';
import { useApi, methods } from '../../Hooks/useApi';
import FormikTextField from '../Common/Formik/FormikTextField';
import ConfirmationModal from '../Common/ConfirmationModal/ConfirmationModal';
import Grid from '@mui/material/Unstable_Grid2';
const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email('Invalid email')
    .required("Your team member's email is required"),
  firstName: yup.string().required('A first name is required'),
  lastName: yup.string().required('A last name is required'),
});

const useStyles = makeStyles((theme) => ({
  paddingBottom: {
    paddingBottom: `${theme.spacing(5)} !important`,
  },
}));

const inviteTeamMemberUrl = '/ro/settings/teammembers';

const AddTeamMemberDrawer = ({ open, handleClose, teamMemberCacheKey }) => {
  const { executeApiCall } = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const [modalOpen, toggleModal] = useState(false);
  const classes = useStyles();

  const invite = async (values, resetForm) => {
    await mutate(teamMemberCacheKey, async () => {
      try {
        await executeApiCall(inviteTeamMemberUrl, methods.post, values);
        enqueueSnackbar('Team member added successfully', {
          variant: 'success',
        });
        handleClose();
        resetForm();
      } catch (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    });
  };

  return (
    <>
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={{
          email: '',
          firstName: '',
          lastName: '',
        }}
        onSubmit={async (values, actions) =>
          await invite(values, actions.resetForm)
        }
      >
        {({ handleSubmit, isSubmitting }) => (
          <DrawerBase
            open={open}
            onClose={() => toggleModal(true)}
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            submittingMessage="Adding team member..."
            submitText="Add team member"
            cancelText="Cancel"
          >
            <Grid xs={12} className={classes.paddingBottom}>
              <Typography variant="h3" gutterBottom>
                Add team member
              </Typography>
              <Typography variant="body1">
                Your team member will receive an invitation email. By accepting
                the invite, the team member will be able to create a password to
                login to your Race Office account.
              </Typography>
            </Grid>
            <Grid xs={12}>
              <FormikTextField
                variant="outlined"
                fullWidth
                label="First name"
                name="firstName"
                required
              />
            </Grid>
            <Grid xs={12}>
              <FormikTextField
                variant="outlined"
                fullWidth
                label="Last name"
                name="lastName"
                required
              />
            </Grid>
            <Grid xs={12}>
              <FormikTextField
                variant="outlined"
                fullWidth
                label="Email"
                name="email"
                required
              />
            </Grid>
          </DrawerBase>
        )}
      </Formik>
      {modalOpen && (
        <ConfirmationModal
          open={modalOpen}
          cancel={() => toggleModal(false)}
          confirm={() => {
            toggleModal(false);
            handleClose();
          }}
          title="Are you sure you want to cancel the action?"
          cancelText="No, continue"
          confirmText=" Yes, cancel"
        />
      )}
    </>
  );
};

export default AddTeamMemberDrawer;
