import React from 'react';
import { mutate } from 'swr';
import { useParams } from 'react-router';
import { useSnackbar } from 'notistack';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import DrawerBase from '../../../Common/Drawer/DrawerBase';
import { useApi, methods } from '../../../../Hooks/useApi';
import { orderUrl } from '../../../../Hooks/useOrders';
import Grid from '@mui/material/Unstable_Grid2';
const useStyles = makeStyles((theme) => ({
  paddingBottom: {
    paddingBottom: theme.spacing(4),
  },
}));

const getCancelUrl = (eventId, editionId, orderId) =>
  `/ro/events/${eventId}/races/${editionId}/orders/${orderId}/cancel`;

const CancelOrderDrawer = ({ open, handleClose }) => {
  const classes = useStyles();
  const { executeApiCall, apiLoading } = useApi();
  const { eventId, editionId, orderId } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const cancelOrder = async () => {
    await mutate([orderUrl(eventId, editionId), true], async ({ orders }) => {
      try {
        const updatedOrder = await executeApiCall(
          getCancelUrl(eventId, editionId, orderId),
          methods.put
        );
        enqueueSnackbar('Order cancelled successfully', { variant: 'success' });
        handleClose();
        const ordersObj = orders.filter((o) => o.id !== orderId);
        return { orders: [updatedOrder, ...ordersObj] };
      } catch (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    });
  };

  return (
    <DrawerBase
      open={open}
      onClose={handleClose}
      handleSubmit={cancelOrder}
      isSubmitting={apiLoading}
      submittingMessage="Cancelling order..."
      submitText="Confirm & cancel order"
      cancelText="Cancel"
    >
      <Grid xs={12}>
        <Typography variant="h3" className={classes.paddingBottom}>
          Cancel order
        </Typography>
        <Typography variant="subtitle2">
          When you cancel an order, the following changes will happen:
        </Typography>
        <ul>
          <li>
            <Typography variant="body1">
              The order will still appear in your order list but will be marked
              as "Cancelled".
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              The participant will still appear in your participant list but
              will be marked as "Cancelled".
            </Typography>
          </li>
        </ul>
      </Grid>
      <Grid xs={12}>
        <Typography variant="subtitle2">Please note:</Typography>
        <ul>
          <li>
            <Typography variant="body1">
              Cancelling the order does not affect the refund status. If the
              order is cancelled and no money has been refunded to the
              participant, the order will be included in your next invoice. This
              will also be the case for partially refunded orders.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              A cancelled order can still be refunded further or refunded fully.
              However, if an order is subsequently fully refunded, the order
              status will be changed to “fully refunded”.
            </Typography>
          </li>
        </ul>
      </Grid>
    </DrawerBase>
  );
};

export default CancelOrderDrawer;
