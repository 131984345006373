import React, { useState } from 'react';
import {
  Typography,
  FormControlLabel,
  Checkbox,
  IconButton,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import FormikTextField from '../../Common/Formik/FormikTextField';
import clsx from 'clsx';
import { useEffect } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
const useStyles = makeStyles((theme) => ({
  detailsWrapper: {
    maxWidth: '100%',
    marginLeft: theme.spacing(4),
  },
  name: {
    '& .MuiCheckbox-root': {
      alignSelf: 'flex-start',
      marginTop: theme.spacing(0.5),
    },
  },
  arrowButton: {
    margin: theme.spacing(0.5, 0, 0, 1),
    alignSelf: 'flex-start',
  },
  nameField: {
    margin: theme.spacing(1, 0),
    maxWidth: '300px',
    [theme.breakpoints.up('md')]: {
      width: '300px',
    },
  },
  extraMargin: {
    marginBottom: theme.spacing(1.5),
    transition: theme.transitions.create(),
  },
  errorLabel: {
    justifyContent: 'flex-start',
  },
}));

const OfferedServiceItem = ({
  service,
  setFieldValue,
  formRefNotify,
  prefix,
  errors,
}) => {
  const [isOpened, toggle] = useState(service.checked && service.details);
  const classes = useStyles();

  useEffect(() => {
    if (isOpened && !service.checked) {
      toggle(false);
    }
  }, [service.checked]);

  const notify = async (func) => {
    func();
    await Promise.resolve();
    formRefNotify && formRefNotify();
  };
  const onCheckBoxChange = (e) =>
    notify(() => {
      const { target } = e;
      setFieldValue(prefix + '.checked', target.checked);
    });

  const onNameChange = (e) => {
    const { target } = e;
    if (!service.checked) {
      setFieldValue(prefix + '.checked', true);
    }

    setFieldValue(prefix + '.name', target.value);
  };

  const onDetailsChange = (e) => {
    const { target } = e;
    setFieldValue(prefix + '.details', target.value);
  };

  return (
    <Grid
      direction="column"
      className={clsx(!service.presetId && isOpened && classes.extraMargin)}
    >
      <Grid>
        <FormControlLabel
          className={clsx(
            !service.presetId && classes.name,
            !service.presetId && errors && classes.errorLabel
          )}
          name={prefix + '.checked'}
          control={
            <Checkbox
              checked={service.checked}
              defaultChecked={service.checked}
              onChange={onCheckBoxChange}
              color="primary"
            />
          }
          label={
            service.detailsAllowed ? (
              <Grid container alignItems="center" wrap="nowrap" spacing={0}>
                {!service.presetId ? (
                  <>
                    <FormikTextField
                      explicitError={errors?.name}
                      className={classes.nameField}
                      label="Additional (optional)"
                      type="text"
                      size="small"
                      fullWidth
                      value={service.name}
                      onChange={onNameChange}
                      name={prefix + '.name'}
                    />
                  </>
                ) : (
                  <Typography>{service.name}</Typography>
                )}
                {service.checked && (
                  <IconButton
                    className={classes.arrowButton}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      toggle(!isOpened);
                    }}
                    size="large"
                  >
                    {isOpened ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                  </IconButton>
                )}
              </Grid>
            ) : (
              <Typography>{service.name}</Typography>
            )
          }
        />
      </Grid>
      {isOpened && (
        <Grid className={classes.detailsWrapper}>
          <FormikTextField
            label="Details (optional)"
            explicitError={errors?.details}
            type="text"
            fullWidth
            multiline
            rowsMax={3}
            value={service.details}
            onChange={onDetailsChange}
            name={prefix + '.details'}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default OfferedServiceItem;
