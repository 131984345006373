import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import ReactivationButton from './ReactivationButton';
import ReactivateInfoModal from './ReactivateInfoModal';
import BlueLink from '../../Common/BlueLink/BlueLink';
import { wmPalette } from '../../../Theme/WorldSportsGroupTheme';
import Grid from '@mui/material/Unstable_Grid2';
const useStyles = makeStyles((theme) => ({
  reactivateBox: {
    backgroundColor: wmPalette.yellow[10],
    padding: theme.spacing(3),
    borderRadius: theme.spacing(1),
  },
  bannerMargin: {
    marginRight: theme.spacing(6),
    marginBottom: theme.spacing(3),
  },
  widenButton: {
    display: 'block',
  },
}));

const ReactivateBanner = ({ isNav }) => {
  const classes = useStyles();
  const [reactivateInfoModal, toggleReactivateInfoModal] = useState(false);
  return (
    <>
      <Box
        className={
          isNav
            ? classes.reactivateBox
            : clsx(classes.reactivateBox, classes.bannerMargin)
        }
      >
        <Grid
          container
          spacing={1}
          direction={isNav ? 'column' : 'row'}
          className={isNav ? classes.widenButton : null}
          alignItems={isNav ? null : 'center'}
        >
          <Grid xs={isNav ? 12 : 9}>
            <Typography variant={isNav ? 'caption' : 'body1'}>
              Your latest event edition has a passed date. Please create next
              edition to keep your event page updated!{' '}
              <BlueLink
                onClick={() => toggleReactivateInfoModal(true)}
                text="Learn more"
                component="button"
              />
            </Typography>
          </Grid>
          <Grid xs={isNav ? 12 : 3}>
            <ReactivationButton />
          </Grid>
        </Grid>
      </Box>
      {reactivateInfoModal && (
        <ReactivateInfoModal
          open={reactivateInfoModal}
          handleClose={() => toggleReactivateInfoModal(false)}
        />
      )}
    </>
  );
};

export default ReactivateBanner;
