import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { FiberManualRecord, Loop } from '@mui/icons-material';
import {
  wmPalette,
  pxToRemCssValue,
} from '../../../Theme/WorldSportsGroupTheme';

const useStyles = makeStyles((theme) => ({
  greenBox: {
    padding: theme.spacing(4, 0, 4, 3),
    marginBottom: theme.spacing(2),
    backgroundColor: wmPalette.green[5],
    boxShadow: 'none',
  },
  icon: {
    color: wmPalette.green[70],
    minWidth: theme.spacing(4),
  },
  headerIcon: {
    fontSize: pxToRemCssValue(56),
    marginBottom: theme.spacing(3),
  },
}));

const modalList = [
  'Event description',
  'Ticket details',
  'Waiver',
  'Important notes to the participants',
];

const ReactivationModal = ({ open, handleClose, eventId, eventTitle }) => {
  const navigate = useNavigate();
  const classes = useStyles();
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        <Loop
          fontSize="large"
          className={clsx(classes.icon, classes.headerIcon)}
        />
        <Typography variant="h4">
          Let's activate the next edition of {eventTitle}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          We have copied the information from the last edition. Please go though
          the each step and update the information for the next edition. Learn
          more
        </DialogContentText>
        <Paper elevation={0} className={classes.greenBox} p={2}>
          <Typography variant="subtitle2">
            Usually updated for each edition
          </Typography>
          <List dense>
            {modalList.map((x) => (
              <ListItem key={x} disableGutters>
                <ListItemIcon className={classes.icon}>
                  <FiberManualRecord />
                </ListItemIcon>
                <ListItemText primary={x} />
              </ListItem>
            ))}
          </List>
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Not now
        </Button>
        <Button
          onClick={() => navigate(`/app/reactivate-event/${eventId}`)}
          color="primary"
        >
          Start activating
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReactivationModal;
