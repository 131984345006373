import React from 'react';
import { Button } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  drawerActionsContainer: {
    display: 'flex',
    position: 'fixed',
    bottom: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    width: '100%',
    padding: theme.spacing(2),
    backgroundColor: '#fff',
    zIndex: 1000,
    [theme.breakpoints.up('sm')]: {
      width: 567,
      bottom: 0,
      padding: theme.spacing(2),
    },
  },
  buttonCancel: {
    padding: `${theme.spacing(0.7)} ${theme.spacing(3)}`,
    marginLeft: theme.spacing(2),
  },
  buttonSubmit: {
    marginLeft: theme.spacing(2),
    width: '90%',
  },
}));

const DrawerActions = ({
  onClose,
  handleSubmit,
  isSubmitting,
  submittingMessage,
  submitText,
  cancelText,
  setValidateMultiple = () => {},
}) => {
  const classes = useStyles();
  return (
    <Grid
      container
      xs={12}
      spacing={0}
      className={classes.drawerActionsContainer}
    >
      <Grid>
        <Button
          variant="outlined"
          className={classes.buttonCancel}
          onClick={onClose}
          disabled={isSubmitting}
          color="secondary"
        >
          {cancelText}
        </Button>
      </Grid>
      <Grid style={{ flexGrow: 1 }}>
        <Button
          className={classes.buttonSubmit}
          variant="contained"
          color="primary"
          onClick={(e) => {
            setValidateMultiple(true);
            setTimeout(() => {
              handleSubmit(e);
            }, 0);
          }}
          disabled={isSubmitting}
        >
          {isSubmitting ? submittingMessage : submitText}
        </Button>
      </Grid>
    </Grid>
  );
};

export default DrawerActions;
