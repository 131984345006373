import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import FormikTextField from './FormikTextField';

const BaseFormikSelect = ({
  set,
  options,
  value = '',
  placeholder,
  label,
  name,
  getOptionLabel,
  required = false,
}) => (
  <Autocomplete
    id={name}
    style={{ width: '100%' }}
    options={options}
    classes={{}}
    autoHighlight
    getOptionLabel={getOptionLabel}
    onChange={(_, v) => set(v)}
    value={value}
    renderInput={(params) => (
      <FormikTextField
        {...params}
        name={name}
        fullWidth
        label={label}
        placeholder={placeholder}
        variant="outlined"
        required={required}
        inputProps={{ ...params.inputProps }}
      />
    )}
  />
);

export default BaseFormikSelect;
