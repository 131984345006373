import React from 'react';
import { Paper, Typography, Link, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useNavigate } from 'react-router-dom';
import GridWrapper from '../GridWrapper/GridWrapper';
import SuccessTick from '../../../images/success_tick.svg';
import { wmPalette } from '../../../Theme/WorldSportsGroupTheme';
import Grid from '@mui/material/Unstable_Grid2';
import { Emails } from '../../../Utils/urls';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(5),
  },
  contentContainer: {
    marginLeft: theme.spacing(6),
    marginRight: theme.spacing(6),
  },
  emailLink: {
    color: wmPalette.black[90],
    textDecoration: 'underline',
    [theme.breakpoints.down('md')]: {
      wordBreak: 'break-all',
    },
  },
  buttonSpacing: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  mainGrid: {
    width: '80%',
    margin: 'auto',
    paddingTop: theme.spacing(2),
  },
}));

const SuccessBase = ({ title, contentArray }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <GridWrapper>
      <Grid xs={12} className={classes.root}>
        <Paper>
          <Grid container spacing={3} className={classes.mainGrid}>
            <Grid xs={12} className={classes.contentContainer}>
              <img src={SuccessTick} alt="Success tick" />
            </Grid>
            <Grid xs={12} className={classes.contentContainer}>
              <Typography variant="h4" gutterBottom>
                {title}
              </Typography>
            </Grid>
            {contentArray.map((x) => (
              <Grid key={x} xs={12} className={classes.contentContainer}>
                {x}
              </Grid>
            ))}
            <Grid
              container
              xs={12}
              className={classes.contentContainer}
              style={{ backgroundColor: wmPalette.green[5] }}
              spacing={0}
            >
              <Grid xs={10} className={classes.contentContainer}>
                <Typography variant="h6" gutterBottom>
                  Need any help?
                </Typography>
              </Grid>
              <Grid xs={10} className={classes.contentContainer}>
                <Typography gutterBottom variant="body1">
                  Please don't hesitate to reach out to the RaceOffice team in
                  case you need any assistance or have any questions. Email us
                  at
                  <Link
                    className={classes.emailLink}
                    href={`mailto:${Emails.CustomerSuccess}`}
                  >
                    {' '}
                    {Emails.CustomerSuccess}
                  </Link>
                </Typography>
              </Grid>
            </Grid>
            <Grid
              alignItems="center"
              direction="column"
              container
              xs={12}
              className={classes.buttonSpacing}
              spacing={0}
            >
              <Grid>
                <Button
                  onClick={() => navigate('/app/events')}
                  variant="outlined"
                >
                  Check out your events list
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </GridWrapper>
  );
};

export default SuccessBase;
