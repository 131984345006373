import React from 'react';
import { Button } from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

const DownloadFile = ({ fileLink }) => {
  return (
    <Button href={fileLink} color="primary" startIcon={<CloudDownloadIcon />}>
      Download file
    </Button>
  );
};

export default DownloadFile;
