import React from 'react';
import { Button, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';
import Grid from '@mui/material/Unstable_Grid2';
const EmailPreferencesEmptyState = ({ handleAddCoupon }) => {
  return (
    <Grid
      container
      spacing={3}
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Grid
        container
        xs={12}
        alignItems="center"
        direction="column"
        spacing={0}
      >
        <Typography>You have no email address.</Typography>
        <Typography>
          Add email address if you wish to receive notification emails on
          registrations.
        </Typography>
      </Grid>
      <Grid xs={12}>
        <Button
          onClick={() => handleAddCoupon()}
          variant="contained"
          color="primary"
          startIcon={<Add />}
        >
          Add email address
        </Button>
      </Grid>
    </Grid>
  );
};

export default EmailPreferencesEmptyState;
