import React, { useState, useMemo } from 'react';
import { TextField, Typography, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useApi, methods } from '../../../Hooks/useApi';
import debounce from 'lodash/debounce';
import Autocomplete from '@mui/material/Autocomplete';
import GenericModal from '../../Common/GenericModal/GenericModal';
import { wmPalette } from '../../../Theme/WorldSportsGroupTheme';
import Grid from '@mui/material/Unstable_Grid2';
const searchEventTitlesEndpoint = '/search/allsg?search=';
const checkEventClaimed = (eventId) => `/ro/events/${eventId}/claimed`;

const useStyles = makeStyles(() => ({
  option: {
    fontWeight: 700,
    '&:hover': {
      ' & $hide': {
        display: 'block',
      },
    },
  },
  hide: {
    display: 'none',
    float: 'right',
    color: wmPalette.green[70],
  },
}));

const EventTitle = ({ values, touched, setFieldValue, errors, handleBlur }) => {
  const classes = useStyles();
  const { executeApiCall } = useApi();
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);

  const [state, setState] = useState({
    eventClaimedModal: false,
  });

  const updateState = (name, check) => {
    if (values.eventTitle === '') return;

    setState((prevState) => ({
      ...prevState,
      [name]: check,
    }));
  };

  const selectOption = async (_, value) => {
    if (!value) return;
    try {
      const res = await executeApiCall(
        checkEventClaimed(value.id),
        methods.get
      );
      if (res) {
        updateState('eventClaimedModal', true);
        return;
      }
    } catch {}

    setFieldValue('eventId', value.id);
    setFieldValue('claimedEvent', true);
  };

  const fetch = useMemo(
    () =>
      debounce((request, callback) => {
        executeApiCall(request, methods.get)
          .then(callback)
          .catch((err) => console.log(err));
      }, 500),
    [executeApiCall]
  );

  const handleTitleChange = (value) => {
    setFieldValue('claimedEvent', false);
    setFieldValue('eventTitle', value);
    setInputValue(value);

    if (value === '') {
      setOptions(values.eventTitle ? [values.eventTitle] : []);
      return;
    }

    fetch(searchEventTitlesEndpoint + inputValue, (results) => {
      let newOptions = [];

      if (results) {
        newOptions = [...results.filter((x) => !x.claimed && x.type === 0)];
      }

      setOptions(newOptions);
    });
  };

  return (
    <>
      <Grid xs={12}>
        <Box pb={2}>
          <Typography gutterBottom>Event name*</Typography>
          <Typography variant="body2">
            Please search your event name. If your event is already on World’s
            Marathons, it will show as a suggestion. To avoid duplicating
            events, if yours appears as a suggestion, click “Update this event”.
            Updating the event will allow you to take over the reviews, traffic
            and bucket list history of the event.
          </Typography>
        </Box>
        <Autocomplete
          id="event-title-picker"
          getOptionLabel={(option) =>
            typeof option === 'string' ? option : option.text
          }
          filterOptions={(x) => x}
          options={options}
          freeSolo
          autoComplete
          includeInputInList
          filterSelectedOptions
          placeholder="Name of your event"
          value={values.eventTitle}
          onInputChange={(_, newInputValue) => handleTitleChange(newInputValue)}
          onChange={selectOption}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              required
              autoFocus
              fullWidth
              label="Event name"
              name="eventTitle"
              value={values.eventTitle}
              onBlur={handleBlur}
              error={touched.eventTitle && Boolean(errors.eventTitle)}
              helperText={touched.eventTitle && errors.eventTitle}
              placeholder="Name of your event"
            />
          )}
          renderOption={(props, option) => {
            return (
              <li {...props}>
                <Grid container alignItems="center" spacing={0}>
                  <Grid className={classes.option} xs>
                    <span>{option.text}</span>
                    <span className={classes.hide}>Update this event</span>
                  </Grid>
                </Grid>
              </li>
            );
          }}
        />
      </Grid>
      {state.eventClaimedModal && (
        <GenericModal
          open={state.eventClaimedModal}
          handleClose={() => updateState('eventClaimedModal', false)}
          title="Event already claimed"
          content="This event is already claimed. If it is yours please contact us at info@worldsmarathons.com"
        />
      )}
    </>
  );
};

export default EventTitle;
