import React from 'react';
import PropTypes from 'prop-types';
import { useTracking } from '../../../Analytics/TrackingContext';
import { Button } from '@mui/material';

const TrackingButton = (props) => {
  const { trackEvent } = useTracking();

  const handleClick = (buttonEvent) => {
    buttonEvent.preventDefault();

    trackEvent({
      category: props.eventCategory,
      action: props.eventAction,
      label: props.eventLabel,
    });

    if (props.onClick) {
      props.onClick();
    }
  };

  let buttonProps = {
    variant: props.variant,
    color: 'primary',
    disabled: props.disabled,
  };
  if (props.href) {
    buttonProps.href = props.href;
  }
  if (props.icon) {
    buttonProps.startIcon = props.icon;
  }

  return (
    <Button {...buttonProps} onClick={handleClick}>
      {props.label}
    </Button>
  );
};

TrackingButton.propTypes = {
  eventCategory: PropTypes.string.isRequired,
  eventAction: PropTypes.string,
  eventLabel: PropTypes.string,
};

export default TrackingButton;
