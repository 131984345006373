import React, { useContext, useRef } from 'react';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import * as yup from 'yup';
import { convertToRaw } from 'draft-js';
import { Typography, Divider, Button, FormHelperText } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import makeStyles from '@mui/styles/makeStyles';
import MUIRichTextEditor from 'mui-rte';
import MuiPhoneInput from 'material-ui-phone-number';
import FormikTextField from '../../Common/Formik/FormikTextField';
import StepWrapper from '../../Common/PageLayout/StepWrapper';
import { actionTypes, Context } from '../../../Stores/EventInfoStore';
import { useApi, methods } from '../../../Hooks/useApi';
import StepTitle from '../../Common/PageLayout/StepTitle';
import { getContentState, convertRawToHtml } from '../../../Utils/rteUtils';

const useStyles = makeStyles((theme) => ({
  divider: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  notesInput: {
    marginTop: theme.spacing(2),
  },
  buttonsGrid: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    backgroundColor: '#FFFFFF',
    zIndex: 1,
    paddingTop: `${theme.spacing(2)} !important`,
    paddingBottom: `${theme.spacing(2)} !important`,
    paddingRight: `15% !important`,
    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.12)',
  },
  editMargin: {
    marginBottom: theme.spacing(7),
  },
  phoneInput: {
    '& .MuiPhoneNumber-flagButton': {
      width: 'min-content',
      '& svg': {
        width: 'fill-available',
      },
    },
  },
}));

const validationSchema = yup.object().shape({
  supportEmail: yup
    .string()
    .email('Invalid email')
    .required('A support email is required'),
  website: yup
    .string()
    .url("Invalid URL. Ensure to include 'http://' or 'https://'"),
  importantNotes: yup.string(),
});

const getUrl = (eventId) => `/event/${eventId}/additionalinfo`;

const ConfirmationEmail = ({ formRef, isManage }) => {
  const classes = useStyles();
  const [eventInfo, dispatch] = useContext(Context);
  const { additionalInfo, event } = eventInfo;
  const { eventId } = event;
  const { executeApiCall } = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const editorRef = useRef(null);

  const submitAdditionalInfo = async (values) => {
    if (values.phoneNumber === '+') {
      values.phoneNumber = null;
    }
    try {
      //convert info into html for email
      const data = {
        ...values,
        importantNotes: values.importantNotes
          ? convertRawToHtml(values.importantNotes)
          : values.importantNotes,
      };
      await executeApiCall(getUrl(eventId), methods.post, data);
      dispatch({ type: actionTypes.additionalInfo_set, payload: data });
      enqueueSnackbar('Confirmation email saved successfully', {
        variant: 'success',
      });
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' });
      if (!isManage) throw err;
    }
  };
  function isDirty(values) {
    if (values.phoneNumber === '+') {
      values.phoneNumber = null;
    }

    return (
      (additionalInfo.supportEmail ?? '') === values.supportEmail &&
      (additionalInfo.website ?? '') === values.website &&
      additionalInfo.phoneNumber === values.phoneNumber &&
      (additionalInfo.importantNotes
        ? getContentState(additionalInfo.importantNotes).getPlainText()
        : '') === values.importantNotesPlain
    );
  }

  return (
    <>
      <StepTitle
        title="Confirmation email"
        subtitle="Add information that your participant should receive in their confirmation email. The confirmation email is automatically sent to the participant right after a successful registration."
      />
      <StepWrapper className={classes.editMargin}>
        <Formik
          validateOnChange={false}
          validateOnBlur={true}
          initialValues={{
            supportEmail: additionalInfo.supportEmail ?? '',
            website: additionalInfo.website ?? '',
            phoneNumber: additionalInfo.phoneNumber,
            importantNotes: additionalInfo.importantNotes
              ? JSON.stringify(
                  convertToRaw(getContentState(additionalInfo.importantNotes))
                )
              : '',
            importantNotesPlain: additionalInfo.importantNotes
              ? getContentState(additionalInfo.importantNotes).getPlainText()
              : '',
          }}
          validationSchema={validationSchema}
          innerRef={formRef}
          onSubmit={async (values, { resetForm }) => {
            await submitAdditionalInfo(values);
            resetForm({ values });
          }}
        >
          {({
            values,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            errors,
            touched,
            setTouched,
            dirty,
          }) => (
            <>
              <Grid container justifyContent="center" spacing={0}>
                <Grid xs={12} sm={8} container spacing={3}>
                  <Grid xs={12}>
                    <Typography gutterBottom variant="h5">
                      Contact details
                    </Typography>
                    <Typography gutterBottom>
                      Add your contact details in the confirmation email to let
                      your participants know that they can reach you.
                    </Typography>
                  </Grid>
                  <Grid xs={12}>
                    <FormikTextField
                      autoFocus
                      variant="outlined"
                      fullWidth
                      required
                      label="Support email address"
                      name="supportEmail"
                    />
                  </Grid>
                  <Grid xs={12}>
                    <FormikTextField
                      variant="outlined"
                      fullWidth
                      label="Official event website"
                      name="website"
                    />
                  </Grid>
                  <Grid xs={12}>
                    <MuiPhoneInput
                      variant="outlined"
                      fullWidth
                      label="Phone number"
                      name="phone"
                      value={values.phoneNumber}
                      defaultCountry="se"
                      onChange={(e) => setFieldValue('phoneNumber', e)}
                      className={classes.phoneInput}
                    />
                  </Grid>
                  <Divider className={classes.divider} />
                  <Grid xs={12}>
                    <Typography gutterBottom variant="h5">
                      Important notes
                    </Typography>
                    <Typography gutterBottom>
                      Those notes will be also included in the confirmation
                      email that participants receive after their registration.
                    </Typography>
                    <MUIRichTextEditor
                      className={classes.notesInput}
                      label="Important notes to participants"
                      name="importantNotes"
                      defaultValue={values.importantNotes}
                      ref={editorRef}
                      onSave={(data) => setFieldValue('importantNotes', data)}
                      onBlur={() => {
                        setTouched({ ...touched, importantNotes: true });
                        editorRef.current?.save();
                      }}
                      controls={[]}
                      onChange={(state) =>
                        setFieldValue(
                          'importantNotesPlain',
                          state.getCurrentContent().getPlainText(),
                          true
                        )
                      }
                      error={
                        touched.importantNotes && Boolean(errors.importantNotes)
                      }
                    />
                    <FormHelperText
                      error={
                        touched.importantNotes && Boolean(errors.importantNotes)
                      }
                    >
                      {touched.importantNotes && Boolean(errors.importantNotes)
                        ? errors.importantNotes
                        : ``}
                    </FormHelperText>
                    <FormHelperText>
                      For example: Where, when and how to pick up a race pack?
                      Where and when the expo takes place? Where the free
                      shuttle service goes etc.
                    </FormHelperText>
                  </Grid>
                  {isManage && (
                    <Grid
                      container
                      className={classes.buttonsGrid}
                      justifyContent="flex-end"
                      spacing={0}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        disabled={isDirty(values) || isSubmitting}
                      >
                        {isSubmitting
                          ? 'Saving confirmation email…'
                          : 'Save confirmation email'}
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </>
          )}
        </Formik>
      </StepWrapper>
    </>
  );
};

export default ConfirmationEmail;
