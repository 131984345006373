import React from 'react';
import {
  FormHelperText,
  Link,
  Button,
  FormControlLabel,
  Switch,
  Typography,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { Add, Close } from '@mui/icons-material';
import { FieldArray } from 'formik';
import FormikTextField from '../../../Common/Formik/FormikTextField';
import WrappedDivider from './WrappedDivider';
import { prepareEmptyAddOnOption } from '../addOnsUtils';
import Grid from '@mui/material/Unstable_Grid2';
export const emptyOption = {
  name: '',
  price: 0,
  requiredAdditionalInfo: false,
  requiredInformationLabel: '',
};

const AddOnVariantSelector = ({ addOnVariant, handleChange, formik }) => {
  return (
    <>
      <Grid xs={12}>
        <FormikTextField
          name="addOnVariant.name"
          variant="outlined"
          fullWidth
          label="Option headline"
        />
        <FormHelperText>
          e.g.: "T-shirt size", "Shipping area" etc.
        </FormHelperText>
      </Grid>
      <WrappedDivider />
      <FieldArray
        name={'addOnVariant.options'}
        render={(helpers) => (
          <>
            {addOnVariant.options.map((option, index) => (
              <React.Fragment key={index}>
                <Grid container alignItems="center" spacing={0}>
                  <Grid xs={11}>
                    <Typography variant="body1">Option {index + 1}</Typography>
                  </Grid>
                  <Grid xs={1}>
                    <IconButton
                      color="primary"
                      onClick={() => helpers.remove(index)}
                      size="large"
                    >
                      <Close />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid xs={12}>
                  <FormikTextField
                    name={`addOnVariant.options[${index}].name`}
                    variant="outlined"
                    fullWidth
                    required
                    label="Option name"
                  />
                </Grid>
                <Grid xs={12}>
                  <FormikTextField
                    name={`addOnVariant.options[${index}].price`}
                    variant="outlined"
                    fullWidth
                    label="Price"
                    type="number"
                  />
                  <FormHelperText>
                    Keep it "0" if the price is included in the ticket price
                  </FormHelperText>
                </Grid>
                <Grid xs={12}>
                  <FormControlLabel
                    label="Ask additional question to participant"
                    control={
                      <Switch
                        checked={
                          addOnVariant.options[index].requiredAdditionalInfo
                        }
                        color="primary"
                        name={`addOnVariant.options[${index}].requiredAdditionalInfo`}
                        onChange={handleChange}
                      />
                    }
                  />
                </Grid>
                {option.requiredAdditionalInfo && (
                  <Grid xs={12}>
                    <FormikTextField
                      name={`addOnVariant.options[${index}].requiredInformationLabel`}
                      variant="outlined"
                      fullWidth
                      required
                      label="Type your question here"
                    />
                    <FormHelperText>
                      e.g.: “Red or black?”, “What is your timing chip number?”,
                      etc.
                    </FormHelperText>
                  </Grid>
                )}
                <WrappedDivider />
              </React.Fragment>
            ))}
            <Grid xs={12}>
              <Button
                component={Link}
                startIcon={<Add />}
                onClick={() =>
                  helpers.push(
                    prepareEmptyAddOnOption(emptyOption, addOnVariant.options)
                  )
                }
              >
                Add option
              </Button>
            </Grid>
            {formik.touched.addOnVariant &&
              formik.touched.addOnVariant.options &&
              formik.errors.addOnVariant &&
              formik.errors.addOnVariant.options &&
              !Array.isArray(formik.errors.addOnVariant.options) && (
                <Grid xs={12}>
                  <FormHelperText error>
                    {formik.errors.addOnVariant.options}
                  </FormHelperText>
                </Grid>
              )}
            <WrappedDivider />
            <Grid xs={6}>
              <FormikTextField
                label="VAT"
                fullWidth
                InputProps={{
                  endAdornment: <InputAdornment>%</InputAdornment>,
                }}
                name="vat"
                helperText={
                  (formik.touched.vat && formik.errors.vat) ||
                  'VAT (value-added tax) should be included in the price '
                }
              />
            </Grid>
            <WrappedDivider />
          </>
        )}
      />
    </>
  );
};

export default AddOnVariantSelector;
