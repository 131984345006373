import { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import useQueryParams from '../../../Hooks/useQueryParams';

const messages = {
  emailConfirmed: 'Thanks for confirming your email',
  loginRedirect:
    'Welcome to the new login page for Race Office. Please use this page to login',
};

const QueryStringAlertHandler = () => {
  const [message, setMessage] = useState(useQueryParams().get('msg'));
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (message && messages[message]) {
      enqueueSnackbar(messages[message], { variant: 'success' });
    }
    setMessage(null);
  }, [enqueueSnackbar, message]);

  return null;
};

export default QueryStringAlertHandler;
