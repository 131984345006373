import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { makeStyles } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { add, sub } from 'date-fns';
import { Context } from '../../Stores/EventInfoStore';
import OverviewGraph from './OverviewGraph/OverviewGraph';
import SoldItems from './SoldItems';
import ParticipantData from './ParticipantData';
import EventPages from './EventPages';
import EventTimeline from './EventTimeline';
import Review from './Review';
import StepTitle from '../Common/PageLayout/StepTitle';
import ReactivateBanner from '../ManageEvents/ReactivateEvent/ReactivateBanner';
import { formatApiDateTime } from '../../Format';
import { scrollToTop } from '../../Utils/scrollUtils';
import { publish } from '../../Consts/eventStatus';
import { formatEventStartDate } from '../../Utils/dateUtils';
const useStyles = makeStyles((theme) => ({
  paddingZero: {
    padding: 0,
  },
  paddingEight: {
    padding: 8,
  },
}));
const DashboardBase = () => {
  const { eventId, editionId } = useParams();
  const [eventInfo] = useContext(Context);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const needsReactivate =
    eventInfo.isActiveEdition &&
    new Date(eventInfo?.basicInfo?.eventEndDate) < new Date();

  const getInititalStartDate = () => {
    if (eventInfo.firstOrderDate !== null) {
      return formatApiDateTime(
        sub(new Date(eventInfo.firstOrderDate), { days: 1 })
      );
    } else {
      return formatApiDateTime(
        new Date(eventInfo.basicInfo.eventSaleStartDate)
      );
    }
  };

  const getInitialEndDate = () => {
    const today = new Date();
    const eventEndDate = add(new Date(eventInfo.basicInfo.eventEndDate), {
      days: 1,
    });
    if (new Date() < new Date(eventInfo.basicInfo.eventEndDate)) {
      return formatApiDateTime(today);
    } else {
      return formatApiDateTime(eventEndDate);
    }
  };

  const startDate = getInititalStartDate();
  const endDate = getInitialEndDate();
  eventInfo.basicInfo.eventStartDate = formatEventStartDate(
    eventInfo.basicInfo
  );
  React.useEffect(() => {
    scrollToTop();
  }, []);

  const classes = useStyles();
  return (
    <>
      <StepTitle title="Dashboard" />
      <Box style={{ maxWidth: '1500px' }}>
        {needsReactivate && <ReactivateBanner isNav={false} />}
        <Grid
          container
          direction={isMobile ? 'column' : 'row'}
          xs={12}
          spacing={2}
          className={classes.paddingZero}
        >
          <Grid
            container
            xs={12}
            lg={9}
            spacing={2}
            className={classes.paddingEight}
          >
            <Grid xs={12}>
              <OverviewGraph
                eventId={eventId}
                editionId={editionId}
                eventInfo={eventInfo}
                startDate={startDate}
                endDate={endDate}
              />
            </Grid>
            <Grid xs={12}>
              <SoldItems
                eventId={eventId}
                editionId={editionId}
                currency={eventInfo.basicInfo.eventCurrency}
                eventTickets={eventInfo.tickets}
                eventAddons={eventInfo.addOns}
                underReview={eventInfo.underReview}
                startDate={startDate}
              />
            </Grid>
            <Grid xs={12}>
              <ParticipantData
                eventId={eventId}
                editionId={editionId}
                eventInfo={eventInfo}
                underReview={eventInfo.underReview}
                startDate={startDate}
                endDate={endDate}
              />
            </Grid>
            {!isMobile && eventInfo.eventPageStatus === publish && (
              <Grid xs={12}>
                <EventPages
                  eventId={eventId}
                  eventTitle={eventInfo.basicInfo.eventTitle}
                  permalink={eventInfo.permalink}
                />
              </Grid>
            )}
          </Grid>
          <Grid
            container
            xs={12}
            lg={3}
            direction="row"
            alignContent="flex-start"
            spacing={2}
            className={classes.paddingEight}
          >
            <Grid xs={12}>
              <EventTimeline eventInfo={eventInfo} />
            </Grid>
            <Grid xs={12}>
              <Review
                eventId={eventId}
                editionId={editionId}
                underReview={eventInfo.underReview}
              />
            </Grid>
            {isMobile && eventInfo.eventPageStatus === publish && (
              <Grid xs={12}>
                <EventPages
                  eventId={eventId}
                  eventTitle={eventInfo.basicInfo.eventTitle}
                  permalink={eventInfo.permalink}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default DashboardBase;
